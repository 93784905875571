import {
  StudentGappingResultDto,
  AbstractStudentCourseTaskAnswerDto,
  StudentAssociationTaskResultDto,
  StudentMultiInputResultDto,
  StudentMultiTestItemResultDto,
  StudentMultiAnswerTaskItemResultDto,
} from '../../model/student-model'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { OrderingTaskItemDto } from '../../model/task-model/OrderingTaskDto'
import { AssociationTaskStudentAnswerDto } from '../../model/task-model/AssociationTaskDto'
import { MultiInputTaskStudentAnswerDto } from '../../model/task-model/multiInputTaskDto'
import { MultiTestTaskStudentAnswerDto } from '../../model/task-model/MultiTestTaskDto'
import { MultiAnswerTaskStudentAnswerDto } from '../../model/task-model/MultiAnswerTaskDto'
import { GappingTaskStudentAnswerDto } from '../../model/task-model/GappingTaskDto'
import { MentorCheckTaskAnswerType } from '../../model/task-model/MentorCheckTaskDto'

import { HTTPMethods } from '../http-enums-methods'
import {
  FilterDate,
  FilterResult,
  StudentCourseTaskInfoServiceInterface,
} from '../interfaces/student-services/student-course-task-service'

class StudentCourseTaskInfoService implements StudentCourseTaskInfoServiceInterface {
  private readonly sourceURL = `${serverHost}/api/student/taskInfo`

  public getUsersTaskSolutions = <
    StudentCourseTaskAnswerDto extends AbstractStudentCourseTaskAnswerDto & { answerType?: MentorCheckTaskAnswerType }
  >(
    studentCourseTaskId: number,
    filterResult: FilterResult = FilterResult.All,
    filterDate: FilterDate = FilterDate.New
  ) => {
    const url = `${this.sourceURL}/${studentCourseTaskId}/solutions?filterResult=${filterResult}&filterDate=${filterDate}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<StudentCourseTaskAnswerDto>(url, queryOption)
  }

  public solveCodeCourseTask = (courseTaskId: number, solution: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: solution,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${courseTaskId}/code`, queryOption)
  }

  public solveTheoryTask = (courseTaskId: number, answerIds: number[]) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(answerIds),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${courseTaskId}/theory`, queryOption)
  }

  public solveWordTask = (courseTaskId: number, answer: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: answer,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}/${courseTaskId}/word`, queryOption)
  }

  public solveOrderingTask = (courseTaskId: number, answers: OrderingTaskItemDto[] | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<string>(`${this.sourceURL}/${courseTaskId}/ordering`, queryOption)
  }

  public solveAssociationTask = (courseTaskId: number, answers: AssociationTaskStudentAnswerDto) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentAssociationTaskResultDto[]>(
      `${this.sourceURL}/${courseTaskId}/association`,
      queryOption
    )
  }

  public solveMultiInputTask = (courseTaskId: number, answers: MultiInputTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiInputResultDto[]>(`${this.sourceURL}/${courseTaskId}/multiInput`, queryOption)
  }

  public solveMultiTestTask = (courseTaskId: number, answers: MultiTestTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiTestItemResultDto[]>(
      `${this.sourceURL}/${courseTaskId}/multiTest`,
      queryOption
    )
  }

  public solveMultiAnswerTask = (courseTaskId: number, answers: MultiAnswerTaskStudentAnswerDto | undefined) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentMultiAnswerTaskItemResultDto[]>(
      `${this.sourceURL}/${courseTaskId}/multiAnswer`,
      queryOption
    )
  }

  public solveGappingTask = (courseTaskId: number, answers: GappingTaskStudentAnswerDto) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request<StudentGappingResultDto[]>(`${this.sourceURL}/${courseTaskId}/gapping`, queryOption)
  }

  public sendToCheck = (courseTaskId: number, answer: string) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(
      `${this.sourceURL}/${courseTaskId}/mentorCheckTask?answer=${encodeURI(answer)}`,
      queryOption
    )
  }

  public solveLectureTask = (courseTaskId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/${courseTaskId}/solve-lecture`, queryOption)
  }

  public resetTask = (studentCourseTaskId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}/${studentCourseTaskId}/reset`, queryOption)
  }

  public cancelTaskCheck = (courseTaskId: number) => {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${this.sourceURL}/${courseTaskId}/mentorCheckTask`, queryOption)
  }
}

export default StudentCourseTaskInfoService
