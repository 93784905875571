import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Actions: FC<Props> = ({
  taskResettingLoading,
  taskSolvingLoading,
  canBeSolved,
  isSolved,
  resetTask,
  solveTask,
}) => {
  const { t } = useTranslation()
  return (
    <div className="lesson-result-right-wrap">
      <button
        type="button"
        style={{ cursor: 'pointer' }}
        className="reset-value-btn"
        disabled={taskResettingLoading}
        onClick={() => resetTask()}
      >
        {taskResettingLoading ? t('ResettingSolution') : t('ResetSolution')}
      </button>

      <button
        type="button"
        className="send-result-btn"
        disabled={!canBeSolved || taskSolvingLoading || isSolved}
        onClick={() => solveTask()}
      >
        {taskSolvingLoading ? t('SubmittingSolution') : t('SubmitSolution')}
      </button>
    </div>
  )
}

interface Props {
  taskResettingLoading: boolean | undefined
  taskSolvingLoading: boolean | undefined
  canBeSolved: boolean
  isSolved: boolean
  resetTask: () => void
  solveTask: () => void
}

export default Actions
