import RaceConditionGuard from '../student/course-page/raceConditionGuard'
import DirectionTasksService from '../../../services/direction-task-service'
import { TaskType } from '../../../model/task-dto/task-type-enum'

const raceConditionGuard = new RaceConditionGuard()
const directionTaskService = new DirectionTasksService()

const getLoadTaskCallback = (courseTaskId: number, type: TaskType) => {
  switch (type) {
    default:
      return () => {
        // @ts-ignore
        return raceConditionGuard.getGuardedPromise(directionTaskService.getCourseTask(courseTaskId, type))
      }
  }
}

export default getLoadTaskCallback
