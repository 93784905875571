import { serverHost } from '../config'
import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { Locale } from '../model/Locale'
import { PasswordRecoveryServiceInterface } from './interfaces/password-recovery-service'

class PasswordRecoveryService implements PasswordRecoveryServiceInterface {
  URL: string

  constructor() {
    this.URL = `${serverHost}/api/recovery-password`
  }

  tokenPasswordRecovery = (token: string, locale: Locale) => {
    const queryOption = {
      method: HTTPMethods.GET,
    }
    return ApiService.request<void>(`${this.URL}/ensure?token=${token}&locale=${locale}`, queryOption)
  }

  sendEmailToRecovery = (email: string, locale: Locale) => {
    const queryOptions = {
      method: HTTPMethods.POST,
      body: `email=${email}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    return ApiService.request<void>(`${this.URL}/send-recovery-link/${locale}`, queryOptions)
  }
}

export default PasswordRecoveryService
