import { MultiInputTaskDto } from '../../../../../../model/task-model/multiInputTaskDto'
import { Answer } from './index'
import { MultiInputDirectionTaskDto } from '../../../../../../model/direction-model'

const useCanBeSolved = (
  multiInputTask: MultiInputTaskDto | MultiInputDirectionTaskDto | undefined,
  answers: Answer[]
) => {
  if (!multiInputTask) {
    return false
  }

  return (
    multiInputTask.items.length === answers.length &&
    answers.every(answer => {
      return answer.answerText && answer.answerText !== ''
    })
  )
}

export default useCanBeSolved
