import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PasswordRecoveryService from '../../../../services/password-recovery-service'

const TokenizedPasswordRecovery = props => {
  const passwordRecoveryService = new PasswordRecoveryService()
  const loadingGifElement = React.createRef()
  const authWrapElement = React.createRef()
  const { t, i18n } = useTranslation()

  const hideLoading = () => {
    loadingGifElement.current.style.display = 'none'
  }

  const { token } = props

  const state = {
    title: '',
    description: '',
    instruction: '',
  }

  const titleElement = React.createRef()
  const descriptionElement = React.createRef()
  const instructionElement = React.createRef()

  passwordRecoveryService
    .tokenPasswordRecovery(token, i18n.language)
    .then(
      () => {
        titleElement.current.innerHTML = t('passwordRecoverySuccess')
        descriptionElement.current.innerHTML = t('passwordRecoverySuccessDescription')
        instructionElement.current.innerHTML = t('passwordRecoverySuccessCheckYourInbox')
      },
      () => {
        titleElement.current.innerHTML = t('passwordRecoveryErrorInvalidToken')
        descriptionElement.current.innerHTML = t('passwordRecoveryErrorInvalidTokenDescription')
        instructionElement.current.innerHTML = t('passwordRecoveryErrorGoToMainPage')
      }
    )
    .finally(() => {
      hideLoading()
    })

  return (
    <div className="auth-wrap login hid-after" ref={authWrapElement}>
      <div className="logo">
        <img src="../../images/header-logo.svg" alt="" />
      </div>

      <div className="auth-head">
        <img ref={loadingGifElement} id="loading_gif" src="../../images/loading.gif" alt="" />
        <p ref={titleElement} className="title">
          {state.title}
        </p>
        <p ref={descriptionElement} className="description">
          {state.description}
        </p>
        <p ref={instructionElement} className="instruction">
          {state.instruction}
        </p>
      </div>

      <form action="">
        <div className="lost-password-wrap">
          <NavLink to="/login" className="lost-password-link">
            {t('toMainPage')}
          </NavLink>
        </div>
      </form>
    </div>
  )
}

export default TokenizedPasswordRecovery
