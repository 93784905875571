import { serverHost } from '../../../config'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'

class FakeOpenStudentCourseTaskInfoService {
  openSourceURL

  constructor() {
    this.openSourceURL = `${serverHost}/api/fake/user/taskInfo`
  }

  getUsersTaskSolutions = (studentCourseTaskId, filterResult = 'ALL', filterDate = 'NEW') => {
    const url = `${this.openSourceURL}/${studentCourseTaskId}/solutions?filterResult=${filterResult}&filterDate=${filterDate}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(url, queryOption)
  }
}

export default FakeOpenStudentCourseTaskInfoService
