import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import produce from 'immer'
import TaskDescription from '../task-description'
import ItemArranger from '../../../../../item-arranger'
import Spinner from '../../../../../spinner'
import Result from '../result'
import Actions from '../actions'
import useCanBeSolved from './useCanBeSolved'
import {
  OrderingTaskAnswerDto,
  OrderingTaskDto,
  OrderingTaskItemDto,
} from '../../../../../../model/task-model/OrderingTaskDto'
import useLoadTaskCallback from '../useLoadTaskCallback'
import useSolveTaskCallback from '../useSolveTaskCallback'
import useIsSolvedRight from '../useIsSolvedRight'
import useResetTaskCallback from '../useResetTaskCallback'
import usePostSolveAnswerSetter from './usePostSolveAnswerSetter'
import getIsSolved from './getIsSolved'
import { OrderingDirectionTaskDto } from '../../../../../../model/direction-model'

const OrderingTask: FC<Props> = ({
  orderingTask,
  loadTask,
  onSolveTask,
  onResetTask,
  postResetInterceptor,
  postSolveInterceptor,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<Partial<Loading>>({})
  const [taskItems, setTaskItems] = useState<OrderingTaskItemDto[]>([])

  const [answeredItems, setAnsweredItems] = useState<OrderingTaskAnswerDto[]>([])

  const [isAnswersUpdatable, setIsAnswersUpdatable] = useState<boolean>(true)

  const [isSolved, setIsSolved] = useState<boolean>(getIsSolved(orderingTask, answeredItems))

  const isSolvedRight = useIsSolvedRight(orderingTask, answeredItems, answeredItem => answeredItem.right)
  const canBeSolved = useCanBeSolved(taskItems)

  useEffect(() => {
    setIsSolved(getIsSolved(orderingTask, answeredItems))
  }, [answeredItems])

  useEffect(() => {
    if (isAnswersUpdatable) setAnsweredItems(orderingTask.answeredItems)
  }, [orderingTask])

  useEffect(() => {
    if (isAnswersUpdatable) setTaskItems(orderingTask.taskItems)
  }, [orderingTask])

  const loadOrderingTask = useLoadTaskCallback(setLoading, loadTask)

  const resetTaskLocal = useResetTaskCallback(setLoading, onResetTask, loadTask, postResetInterceptor, () => {
    setTaskItems(
      taskItems.map(item => {
        return { ...item, position: null }
      })
    )
    setAnsweredItems([])
  })

  const postSolveAnswerSetter = usePostSolveAnswerSetter(setAnsweredItems, taskItems)

  const resetOrderingTask = () => {
    return resetTaskLocal()
  }

  const solveTaskLocal = useSolveTaskCallback(
    orderingTask,
    async () => onSolveTask(taskItems || []),
    async skipLoading => loadOrderingTask(skipLoading),
    canBeSolved,
    setLoading
  )

  const solveOrderingTask = () => {
    return solveTaskLocal()
      .then(solveResult => {
        if (solveResult) setIsAnswersUpdatable(false)
        return solveResult
      })
      .then(postSolveAnswerSetter)
      .then(() => {
        postSolveInterceptor(isSolved, loadTask, dispatch)
      })
  }

  const setOrderingTaskItemPosition = (itemId: number, position: number | null) => {
    setTaskItems(oldItems =>
      produce(oldItems, draftItems => {
        if (!draftItems) {
          return []
        }
        return draftItems.map(item => {
          if (item.id === itemId) {
            return {
              ...item,
              position,
            }
          }
          if (item.position === position) {
            return {
              ...item,
              position: null,
            }
          }
          return item
        })
      })
    )
  }

  const { description } = orderingTask || {}

  if (loading.taskLoading) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('OrderTask')}</div>
      <TaskDescription description={description} />

      <ItemArranger
        disabled={isSolved}
        items={isSolved ? answeredItems : taskItems}
        resolveItemId={item => item.id}
        resolveItemLabel={item => item.itemText}
        resolveItemPosition={item => item.position}
        isItemRight={item => item.right}
        onItemDrop={setOrderingTaskItemPosition}
      />

      <div className="lesson-result-row">
        <Result isSolved={isSolved} isSolvedRight={isSolvedRight} />
        <Actions
          taskResettingLoading={loading.taskResetting}
          taskSolvingLoading={loading.taskSolving}
          canBeSolved={canBeSolved}
          isSolved={isSolved}
          resetTask={resetOrderingTask}
          solveTask={solveOrderingTask}
        />
      </div>
    </div>
  )
}

interface Props {
  orderingTask: OrderingTaskDto | OrderingDirectionTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<OrderingTaskDto | OrderingDirectionTaskDto>
  onResetTask: () => Promise<any>
  onSolveTask: any
  postResetInterceptor: (val: any, ...args: any[]) => any
  postSolveInterceptor: (val: any, ...args: any[]) => any
}

export interface Loading {
  taskSolving: boolean
  taskResetting: boolean
  taskLoading: boolean
}

export default OrderingTask
