import { format } from 'date-fns'

type FormatDateToDDMMYYYYArg = Date | string | number

export const formatDateToDDMMYYYY = (date: FormatDateToDDMMYYYYArg) => format(new Date(date), 'dd.MM.yyyy')

export function formatDate(date: string) {
  return date
    ? date
        .split('.')
        .reverse()
        .join('-')
    : ''
}

export function decodeDate(date: string) {
  return date
    ? date
        .split('-')
        .reverse()
        .join('.')
    : ''
}

export const getLastDayOfNextMonth = (): Date => {
  const today = new Date()
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0)
  return lastDayOfMonth
}

export const MONTHS: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const MONTHS_RU: string[] = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]
