import { TaskType } from '../../../../model/task-dto/task-type-enum'

export const getPostResetInterceptorFake: (type: TaskType | undefined) => (val: any) => any = type => {
  switch (type) {
    case TaskType.Theory:
      return (val: any) => {
        const newVal = val
        newVal.answer = 0
        newVal.lastActionIsRight = false
        newVal.lastActionIsWrong = false
        return newVal
      }
    case TaskType.Word:
      return (val: any) => {
        const newVal = val
        newVal.answer = ''
        newVal.lastActionIsRight = false
        newVal.lastActionIsWrong = false
        return newVal
      }
    case TaskType.MultiInput:
      return (val: any) => {
        const newVal = val
        newVal.studentMultiInputResults = []
        newVal.lastActionIsRight = false
        newVal.lastActionIsWrong = false
        return newVal
      }
    case TaskType.Association:
      return (val: any) => {
        const newVal = val
        newVal.studentAssociationResults = []
        newVal.lastActionIsRight = false
        newVal.lastActionIsWrong = false
        return newVal
      }
    case TaskType.Gapping:
      return (val: any) => {
        const newVal = val
        newVal.studentGappingResults = []
        newVal.lastActionIsRight = false
        newVal.lastActionIsWrong = false
        return newVal
      }
    case TaskType.Ordering:
      return (val: any) => {
        const newVal = val
        newVal.answeredItems = []
        newVal.lastActionIsRight = false
        newVal.lastActionIsWrong = false
        return newVal
      }
    case TaskType.MultiTest:
      return (val: any) => {
        const newVal = val
        newVal.studentItems = []
        newVal.lastActionIsRight = false
        newVal.lastActionIsWrong = false
        return newVal
      }
    case TaskType.MultiAnswer:
      return (val: any) => {
        const newVal = val
        newVal.studentResults = []
        newVal.lastActionIsRight = false
        newVal.lastActionIsWrong = false
        return newVal
      }
    default:
      return (val: any) => val
  }
}
