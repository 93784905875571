import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { useDispatch } from 'react-redux'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { history, showCommentsForEnrollee } from '../../../../config'
import {
  TheoryTask,
  LectureTask,
  CodeTask,
  WordTask,
  GappingTask,
  OrderingTask,
  MultiTestTask,
  MultiInputTask,
  AssociationTask,
  MultiAnswerTask,
} from '../../student/course-page/task-block'
import CommentsBlock from '../../student/course-page/comment-block/comments-block'
import { AuthService } from '../../../../services/auth-service'
import getLoadTaskCallback from '../getLoadTaskCallback'
import { getCoursePageNextStepUrl } from '../getCoursePageNextStepUrl'
import { getSessionStorageNameCallback } from '../../student/course-page/task-block/codeTaskCallbacks'
import Spinner from '../../../spinner'
import { useEffectAllDepsChange } from '../../student/course-page/useEffectAllDepsChange'
import { OrderingTaskDto } from '../../../../model/task-model/OrderingTaskDto'
import { AssociationTaskDto } from '../../../../model/task-model/AssociationTaskDto'
import { MultiInputTaskDto } from '../../../../model/task-model/multiInputTaskDto'
import { MultiTestTaskDto } from '../../../../model/task-model/MultiTestTaskDto'
import { MultiAnswerTaskDto } from '../../../../model/task-model/MultiAnswerTaskDto'
import { GappingTaskDto } from '../../../../model/task-model/GappingTaskDto'
import EnrolleeStudentBreadcrumbs from '../components/enrollee-student-breadcrumbs'
import { LectureTaskDto } from '../../../../model/task-model/LectureTaskDto'
import { TheoryTaskDto } from '../../../../model/task-model/TheoryTaskDto'
import { WordTaskDto } from '../../../../model/task-model/WordTaskDto'
import { CodeTaskDto } from '../../../../model/task-model/CodeTaskDto'
import { ReviewStepTaskDto } from '../../../../model/task-model/ReviewStepTaskDto'
import { MentorCheckTaskDto } from '../../../../model/task-model/MentorCheckTaskDto'
import { getPostResetInterceptorStudent } from '../../student/course-page/getPostResetInterceptorStudent'
import { getSolveTaskCallback } from '../getSolveTaskCallback'
import { getPostSolveInterceptorStudent } from '../../student/course-page/getPostSolveInterceptorStudent'
import DirectionTasksService from '../../../../services/direction-task-service'
import { DirectionTaskHeadingDto, TasksTypesUnion as DirectionTasksTypesUnion } from '../../../../model/direction-model'
import { TaskType } from '../../../../model/task-dto/task-type-enum'
import { updateCoursePoints } from '../../../../store/taskSlice'
import StudentPageMode from '../../../../model/StudentPageMode'

export type TasksTypesUnion =
  | OrderingTaskDto
  | AssociationTaskDto
  | MultiInputTaskDto
  | MultiTestTaskDto
  | MultiAnswerTaskDto
  | GappingTaskDto
  | TheoryTaskDto
  | WordTaskDto
  | CodeTaskDto
  | MentorCheckTaskDto
  | LectureTaskDto
  | ReviewStepTaskDto

interface Params {
  directionTaskPosition: string
}

const directionTasksService = new DirectionTasksService()

const DirectionPage: React.FC<any> = () => {
  const dispatch = useDispatch()
  const { catchErrorAlert } = useReactAlert()
  const { directionTaskPosition } = useParams<Params>()
  const { t } = useTranslation()
  const [task, setTask] = useState<DirectionTasksTypesUnion>()
  const [taskType, setTaskType] = useState<TaskType>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [taskTypeLoading, setTaskTypeLoading] = useState<number>(0)

  const [tasksHeading, setTasksHeading] = useState<DirectionTaskHeadingDto>()
  const [directionTaskId, setDirectionTaskId] = useState<number>(0)
  const [principalRole, setPrincipalRole] = useState<RoleEnum>(RoleEnum.STUDENT)
  const [principalId, setPrincipalId] = useState<number>(-1)

  const solutionsLink = `/direction-user/${directionTaskPosition}/solutions`
  const postResetInterceptor = getPostResetInterceptorStudent(taskType)
  const statusPage = StudentPageMode.NORMAL

  const loadTask = async (interceptor = (val: any) => val) => {
    const load = getLoadTaskCallback(directionTaskId, taskType!)
    return load()
      .then(value => {
        setTask(interceptor(value))
        return value
      })
      .then(value => {
        setIsLoading(false)
        return value
      })
      .catch(err => {
        catchErrorAlert(err)
      })
  }

  const loadTasksHeading = () => {
    directionTasksService.getTasksHeading(directionTaskPosition).then(newTaskHeading => {
      dispatch(
        updateCoursePoints({ coursePoints: newTaskHeading.coursePoints, studentPoints: newTaskHeading.studentPoints })
      )
      setTasksHeading(newTaskHeading)
      setIsLoading(true)
      setTaskType(newTaskHeading.currentTaskType)
      setTaskTypeLoading(prevState => prevState + 1)

      const directionTaskPositionToIdx = newTaskHeading.currentTaskPosition - 1
      const headings = newTaskHeading.taskIcons
      setDirectionTaskId(headings[directionTaskPositionToIdx]!.directionTaskId)
    })
  }

  useEffect(() => {
    const userValue = AuthService.currentUserValue()

    if (userValue && userValue.role.name !== principalRole) {
      setPrincipalRole(userValue.role.name)
    }
    if (userValue && userValue.id !== principalId) {
      setPrincipalId(userValue.id)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (+directionTaskPosition !== 0) {
      loadTasksHeading()
    }
  }, [directionTaskPosition])

  useEffectAllDepsChange(() => {
    if (taskType && directionTaskId) {
      loadTask()
    }
  }, [directionTaskId, taskTypeLoading])

  const hasNextTask = () => {
    if (tasksHeading) {
      return tasksHeading.taskIcons.length > parseInt(directionTaskPosition, 10)
    }
    return false
  }

  const urlNextStep = getCoursePageNextStepUrl(hasNextTask, directionTaskPosition)

  const nextStepButtonUrl = () => {
    history.push(urlNextStep())
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const isCodeTaskExemplarySolutionAvailable = () => false
  const isCodeTaskSolutionHistoryAvailable = () => false
  const isCodeTaskMentorCommentAvailable = () => false
  const isCodeTaskSessionStorageAvailable = () => true
  const getSessionStorageName = getSessionStorageNameCallback(principalId, taskType, directionTaskId)
  let taskRender

  if (!isLoading && task) {
    switch (task.type) {
      case TaskType.Lecture:
        taskRender = (
          <LectureTask
            lectureTask={task}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
          />
        )
        break
      case TaskType.Theory:
        taskRender = (
          <TheoryTask
            theoryTask={task}
            loadTask={loadTask}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            postResetInterceptor={postResetInterceptor}
          />
        )
        break
      case TaskType.Ordering:
        taskRender = (
          <OrderingTask
            orderingTask={task}
            loadTask={loadTask}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
          />
        )
        break
      case TaskType.Association:
        taskRender = (
          <AssociationTask
            associationTask={task}
            loadTask={loadTask}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
          />
        )
        break
      case TaskType.Word:
        taskRender = (
          <WordTask
            wordTask={task}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            onGetTask={loadTask}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
          />
        )
        break
      case TaskType.Code:
        taskRender = (
          <CodeTask
            codeTask={task}
            loadTask={loadTask}
            isExemplarySolutionAvailableCallback={isCodeTaskExemplarySolutionAvailable}
            isSolutionHistoryAvailableCallback={isCodeTaskSolutionHistoryAvailable}
            isMentorCommentAvailableCallback={isCodeTaskMentorCommentAvailable}
            isSessionStorageAvailableCallback={isCodeTaskSessionStorageAvailable}
            getExemplarySolution={() => Promise.resolve()}
            sessionStorageName={getSessionStorageName()}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            solutionsLink={solutionsLink}
            // courseTaskId={directionTaskId}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
            currentRole={principalRole}
          />
        )
        break
      case TaskType.MultiInput:
        taskRender = (
          <MultiInputTask
            multiInputTask={task}
            loadTask={loadTask}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
          />
        )
        break
      case TaskType.MultiTest:
        taskRender = (
          <MultiTestTask
            multiTestTask={task}
            loadTask={loadTask}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
          />
        )
        break
      case TaskType.MultiAnswer:
        taskRender = (
          <MultiAnswerTask
            multiAnswerTask={task}
            loadTask={loadTask}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
          />
        )
        break
      case TaskType.Gapping:
        taskRender = (
          <GappingTask
            gappingTask={task}
            loadTask={loadTask}
            onResetTask={() => directionTasksService.resetTask(directionTaskId)}
            onSolveTask={getSolveTaskCallback(task.type, directionTaskId)}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={getPostSolveInterceptorStudent(task.type)}
          />
        )
        break
      default:
        taskRender = (
          <div className="task-loader">
            <Spinner />
          </div>
        )
        break
    }
  } else {
    taskRender = (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  if (!tasksHeading) {
    return (
      <>
        <div className="task-loader">
          <Spinner />
        </div>
      </>
    )
  }

  let nextStepButton
  if (tasksHeading.currentTaskPosition < tasksHeading.taskIcons.length) {
    nextStepButton = (
      <button type="button" className="next-step-btn" onClick={nextStepButtonUrl}>
        <span>{`${t('NextStep')}`}</span>
        <i className="mdi mdi-chevron-right" />
      </button>
    )
  }

  return (
    <>
      <EnrolleeStudentBreadcrumbs courseTaskPosition={+directionTaskPosition} tasksHeading={tasksHeading!} />
      <div className="step-content-wrap">
        <div className="container">
          <div className="step-content lesson">{taskRender}</div>
          {nextStepButton}
        </div>
      </div>
      {showCommentsForEnrollee && <CommentsBlock taskId={-1} statusPage={statusPage} principalRole={principalRole} />}
    </>
  )
}
export default DirectionPage
