import React, { useEffect, useState } from 'react'
import date from 'date-and-time'
import 'date-and-time/locale/ru'
import CalendarHeatmap from 'react-calendar-heatmap'
import { useTranslation, withTranslation } from 'react-i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import StudentCourseService from '../../../../services/student-services/student-course-service'
import CourseElement from '../components/course-element/course-element'
import QuoteBlock from '../components/quote-block'
import StatisticModal from './statistic-modal/statistic-modal'
import StudentProfileService from '../../../../services/student-services/student-profile-service'
import FakeOpenStudentCourseService from '../../../../services/fake-page-services/open/fake-open-student-course-service'
import FakeOpenStudentProfileService from '../../../../services/fake-page-services/open/fake-open-student-profile-service'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import { convertResponseImageUrl } from '../../../../utils/convertResponseImageUrl'
import 'react-calendar-heatmap/dist/styles.css'
import { useStatusPage } from '../../../../hooks/useStatusPage'
import StudentPageMode from '../../../../model/StudentPageMode'

date.locale('ru')
const studentProfileService = new StudentProfileService()
const fakeOpenStudentProfileService = new FakeOpenStudentProfileService()
const fakeOpenStudentCourseService = new FakeOpenStudentCourseService()
const studentCourseService = new StudentCourseService()

const StatisticPage = () => {
  const { catchErrorAlert } = useReactAlert()
  const [lastCourse, setLastCourse] = useState({})
  const [lastCoursePic, setLastCoursePic] = useState('')
  const [activity, setActivity] = useState([])
  const [days, setDays] = useState({})
  const [topics, setTopics] = useState([])
  const [topicsCount, setTopicsCount] = useState(0)
  const [modalShowed, setModalShowed] = useState(false)
  // eslint-disable-next-line id-length
  const { t } = useTranslation()

  const { statusPage, studentIdParam, principalId, principalRole } = useStatusPage()

  useEffect(() => {
    if (statusPage === StudentPageMode.FAKE && principalRole === RoleEnum.PAY_STUDENT) {
      studentCourseService.getAvailableCourses(principalId).then(items => {
        const courseOfCurrentUser = items.find(element => element.id === lastCourse.id)
        lastCourse.available = courseOfCurrentUser && courseOfCurrentUser.available
      })
    }
  }, [principalId, lastCourse])

  useEffect(() => {
    if (statusPage === StudentPageMode.NOT_READY) return
    const currentService = statusPage !== StudentPageMode.FAKE ? studentCourseService : fakeOpenStudentCourseService
    const currentProfileService =
      statusPage !== StudentPageMode.FAKE ? studentProfileService : fakeOpenStudentProfileService
    const serviceArgs = statusPage !== StudentPageMode.FAKE ? [] : [studentIdParam]

    currentService
      .getLastCourse(...serviceArgs)
      .then(newLastCourse => {
        setLastCourse(newLastCourse)
        setLastCoursePic(convertResponseImageUrl(newLastCourse.coursePicUrl))
      })
      .catch(err => catchErrorAlert(err))
    currentProfileService.getActivity(...serviceArgs).then(newActivity => setActivity(newActivity))
    currentProfileService.getDaysWithoutPause(...serviceArgs).then(newDays => setDays(newDays))
    currentProfileService.getTopics(...serviceArgs).then(newTopics => setTopics(newTopics))
    currentProfileService.getTopicsCount(...serviceArgs).then(newTopicsCount => setTopicsCount(newTopicsCount))
  }, [statusPage, studentIdParam])

  const months = [
    `${t('January')}`,
    `${t('February')}`,
    `${t('March')}`,
    `${t('April')}`,
    `${t('May')}`,
    `${t('June')}`,
    `${t('July')}`,
    `${t('August')}`,
    `${t('September')}`,
    `${t('October')}`,
    `${t('November')}`,
    `${t('December')}`,
  ]

  const { currentDaysWithoutPause, maxDaysWithoutPause } = days

  // eslint-disable-next-line prefer-spread
  const maxVal = Math.max.apply(
    Math,
    activity.map(({ count }) => count)
  )
  const nowDate = new Date()
  const start = `${nowDate.getFullYear() - 1}-${nowDate.getMonth() + 1}-01`
  const end = `${nowDate.getFullYear()}-${nowDate.getMonth() + 1}-${nowDate.getDate()}`

  document.title = t('Statistics')
  return (
    <>
      <div style={{ marginTop: '64px' }} />
      <QuoteBlock />
      <div className="profile-page-wrap">
        <div className="container">
          <div className="profile-container">
            <div className="profile-bottom-wrap">
              <div className="profile-left-wrap">
                <div className="bottom-stats-wrap">
                  <div className="stats-block">
                    <span id="without_pause" className="num">
                      {currentDaysWithoutPause}
                    </span>
                    {t('DaysWithoutABreak')}
                  </div>

                  <div className="stats-block">
                    <span id="without_pause_max" className="num">
                      {maxDaysWithoutPause}
                    </span>
                    {t('DaysWithoutABreakMax')}
                  </div>

                  <div className="stats-block">
                    <span id="topic_count" className="num">
                      {topicsCount}
                    </span>
                    {t('TopicsMastered')}
                    <br />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="stats-detail-link" onClick={() => setModalShowed(true)}>
                      {t('MoreDetails')}
                    </a>
                  </div>
                </div>

                <div className="profile-activity">
                  <div className="profile-activity-head-wrap">
                    <div className="profile-activity-head">{t('YourActivity')}:</div>
                    <div className="profile-activity-sorter">
                      <div id="year_btn" className="activity-sort active">
                        {t('Year')}
                      </div>
                    </div>
                  </div>
                  <div className="profile-activity-graph-year">
                    <CalendarHeatmap
                      startDate={new Date(start)}
                      endDate={new Date(end)}
                      values={activity}
                      monthLabels={months}
                      titleForValue={value => {
                        return value
                          ? `${t('OfSolutions')} ${value.count} ${date.format(
                              new Date(value.date),
                              'dd., DD MMM YYYY',
                              false
                            )}`
                          : ''
                      }}
                      classForValue={value => {
                        if (!value) {
                          return 'color-github-0'
                        }
                        const nStep = value.count / (maxVal / 3)
                        return `color-gitlab-${Math.floor(nStep) + 1}`
                      }}
                    />
                  </div>
                  <div className="profile-activity-graph-month" />
                  <div className="profile-activity-graph-week" />
                </div>
              </div>
              <div className="profile-right-wrap">
                <div className="current-course">
                  <div className="current-course-head">{t('CurrentCourse')}:</div>
                  <CourseElement
                    course={lastCourse}
                    pic={lastCoursePic}
                    studentId={studentIdParam}
                    statusPage={statusPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StatisticModal topics={topics} modalShowed={modalShowed} onClose={() => setModalShowed(false)} />
    </>
  )
}

export default withTranslation()(StatisticPage)
