import { history, serverHost } from '../config'
import ApiService from './api-service'
import { UserDto } from '../model/user-dto/user-dto'
import { HTTPMethods } from './http-enums-methods'

let user: UserDto | null

function authHeaders() {
  return {
    zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
}

function logout() {
  ApiService.request<UserDto>(`${serverHost}/token`, {
    headers: authHeaders(),
    method: HTTPMethods.DELETE,
  })

  if (user) {
    user = null
  }
  history.push('/login?param=logout')
}

function fetchUserProfile() {
  return ApiService.request<UserDto>(`${serverHost}/api/user/profile`, {
    headers: authHeaders(),
  }).then(newUser => {
    user = newUser
    return user
  })
}

function login(authUser: LoginUser) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(authUser),
  }
  return ApiService.request<void>(`${serverHost}/token`, requestOptions).then(fetchUserProfile)
}

function validate(authUser: LoginUser) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(authUser),
  }

  return ApiService.request<boolean>(`${serverHost}/token/validate_user`, requestOptions)
}

function currentUserValue() {
  return user
}

export const AuthService = {
  login,
  validate,
  logout,
  authHeaders,
  currentUserValue,
  fetchUserProfile,
}

interface LoginUser {
  username: string
  password: string
  rememberMe: boolean
}
