import { Dispatch, SetStateAction } from 'react'
import produce from 'immer'
import { Question } from './index'

interface MultiTestSolveResult {
  isRight: boolean
  itemId: number
  itemText: string
  studentItemAnswers: SolveResultItem[]
}

interface SolveResultItem {
  itemProbablyAnswerId: number
  itemProbablyAnswerText?: string
}

const usePostSolveAnswerSetter = (answers: Question[], setAnswers: Dispatch<SetStateAction<Question[]>>) => {
  return (solveResult: MultiTestSolveResult[]) => {
    const produced = produce(answers, draft => {
      draft.forEach(answer => {
        answer.isRight = solveResult.find((result: any) => result.itemId === answer.itemId)?.isRight
      })
    })
    setAnswers(produced)
  }
}

export default usePostSolveAnswerSetter
