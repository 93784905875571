const useIsAnswerCheckedCallback = <Question, Answer>(
  questions: Question[],
  resolveQuestionId: (question: Question) => number,
  resolveQuestionAnswers: (question: Question) => Answer[],
  resolveAnswerId: (answer: Answer) => number
) => {
  return (questionId: number, answerId: number) => {
    const currentQuestion = questions.find(question => resolveQuestionId(question) === questionId)

    if (!currentQuestion) {
      return false
    }

    return resolveQuestionAnswers(currentQuestion).some(answer => resolveAnswerId(answer) === answerId)
  }
}

export default useIsAnswerCheckedCallback
