import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import CourseType from '../../components/pages/student/course-type.model'

type State = {
  courseType: CourseType
}

const initialState: State = {
  courseType: CourseType.Regular,
}

const courseTypeSlice = createSlice({
  name: 'courseType',
  initialState,
  reducers: {
    setCourseType(state, { payload }: PayloadAction<State>) {
      state.courseType = payload.courseType
    },
  },
})

export const { setCourseType } = courseTypeSlice.actions
export default courseTypeSlice.reducer
