import React from 'react'
import { sanitize } from 'dompurify'
import { MentorCheckTaskAnswerType } from '../../../../../../../model/task-model/MentorCheckTaskDto'

export const Answer = ({ value, type }: Props) => {
  const link = (
    <a href={value} target="_blank nooffer">
      {value}
    </a>
  )

  const text = <span>{value}</span>

  const styledText = <div dangerouslySetInnerHTML={{ __html: sanitize(value) }} />

  const answer: Record<MentorCheckTaskAnswerType, JSX.Element> = {
    [MentorCheckTaskAnswerType.SingleLineText]: text,
    [MentorCheckTaskAnswerType.StyledText]: styledText,
    [MentorCheckTaskAnswerType.Link]: link,
    [MentorCheckTaskAnswerType.UniqueLink]: link,
  }

  return answer[type] ?? null
}

type Props = {
  value: string
  type: MentorCheckTaskAnswerType
}
