import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { showChangeLanguageSelect } from '../../../../../config'
import { AuthService } from '../../../../../services/auth-service'
import LocaleService from '../../../../../services/locale-service'
import { Locale } from '../../../../../model/Locale'
import UserProfileService from '../../../../../services/common-services/user-profile-service'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'
import NotificationBlock from '../../../student/components/header/notification-block/notification-block'
import ChangeAvatarModal from '../../../student/components/header/change-avatar-modal'

const localeService = new LocaleService()
const userProfileService = new UserProfileService()

const EnrolleeHeader = () => {
  const { catchErrorAlert } = useReactAlert()
  const { i18n, t } = useTranslation()
  const [locale, setLocale] = useState<string>(i18n.language)
  const [active, setActive] = useState<boolean>(false)
  const [uploadedImageURL, setUploadedImageURL] = useState<string>('')
  const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false)

  useEffect(() => {
    userProfileService
      .getUserPrincipal()
      .then(us => {
        if (us.avatarUrl) {
          setUploadedImageURL(convertResponseImageUrl(us.avatarUrl))
        }
      })
      .catch(err => catchErrorAlert(err))
  }, [uploadedImageURL])

  const changeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocale = e.target.value as Locale
    setLocale(newLocale)
    i18n
      .changeLanguage(newLocale)
      .then(() => localeService.changeLocale(newLocale))
      .catch(err => catchErrorAlert(err))
  }

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // eslint-disable-next-line no-use-before-define
      handleClickOutSide(event)
    }

    window.addEventListener('click', listener)

    return () => {
      window.removeEventListener('click', listener)
    }
  }, [])

  const handleClickOutSide = (event: MouseEvent) => {
    const notificationBlock = document.getElementById(`profile-menu-wrap`)
    // eslint-disable-next-line no-shadow
    const path = event.composedPath()
    if (notificationBlock && !path.includes(notificationBlock)) {
      const notifyBtn = document.getElementById(`profile-icn`)

      if (notifyBtn && !path.includes(notifyBtn)) {
        setActive(false)
      }
    }
  }

  return (
    <div className="header">
      <div className="top-header">
        <div className="container">
          <Link to="/enrollee/1" className="logo">
            <img src="/images/header-logo.svg" alt="logo" />
          </Link>

          <div className="header-right-wrap">
            <span className="change-language-selector">
              {showChangeLanguageSelect && (
                <select
                  className="form-control"
                  defaultValue={locale === `ru` ? `ru` : `en`}
                  onChange={event => changeLanguage(event)}
                >
                  <option value="ru">Русский</option>
                  <option value="en">English</option>
                </select>
              )}
            </span>
            <NotificationBlock />
            <div id="profile-icn" className="profile-icn" onClick={() => setActive(!active)}>
              <img src={uploadedImageURL === '' ? '/images/man.png' : uploadedImageURL} id="user_pic" alt="avatar" />
            </div>
            {active && (
              <div id="profile-menu-wrap" className="profile-menu-wrap">
                <ul>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a type="button" onClick={() => setShowChangeAvatarModal(true)}>
                      {t('ChangeAvatar')}
                    </a>
                  </li>
                  <li>
                    <Link to="/enrollee/password">{t('ChangePassword')}</Link>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a type="button" onClick={() => AuthService.logout()}>
                      {t('Exit')}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <ChangeAvatarModal show={showChangeAvatarModal} onClose={() => setShowChangeAvatarModal(false)} />
    </div>
  )
}

export default EnrolleeHeader
