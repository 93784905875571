import { addDays, format, parse } from 'date-fns'

export const dateForState = (date?: string) => {
  return format(date ? new Date(date) : new Date(), 'dd.MM.yyyy')
}
export const dateForInput = (date?: string) => {
  return format(date ? parse(date, 'dd.MM.yyyy', new Date()) : new Date(), 'yyyy-MM-dd')
}
export const dateForInputMinusNDays = (endDate?: number) => {
  return format(addDays(new Date(), -(endDate ?? 5)), 'yyyy-MM-dd')
}
export const dateForStateMinusNDays = (endDate?: number) => {
  return format(addDays(new Date(), -(endDate ?? 7)), 'dd.MM.yyyy')
}

export const getFirstDayOfMonth = (month: number, year?: number): string => {
  const selectedYear = year || new Date().getFullYear()
  const firstDay = new Date(selectedYear, month, 1)

  return format(firstDay, 'dd.MM.yyyy')
}

export const getDayFromMoth = (day: number, month: number, year?: number) => {
  const selectedYear = year || new Date().getFullYear()
  const selectedDay = new Date(selectedYear, month, day)

  return format(selectedDay, 'dd.MM.yyyy')
}

export const getDayFromRuStringLocal = (date: string) => {
  const day = date.split('.')[0]
  if (day?.startsWith('0')) {
    return day.split('').at(1)
  }
  return day
}

export const getAllDaysInMonth = (year: number, month: number) => {
  const date = new Date(year, month, 1)
  const result = []

  while (date.getMonth() === month) {
    result.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return result
}
