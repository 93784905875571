import React from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import AnimatedProgressBar from '../../../../animated-progress-bar/animated-progress-bar'
import NumberUtil from '../../../../../utils/NumberUtil'
import { AuthService } from '../../../../../services/auth-service'
import FakeProtectEnrollService from '../../../../../services/fake-page-services/fake-protect-enroll-service'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import CourseType from '../../course-type.model'
import 'react-confirm-alert/src/react-confirm-alert.css'

import './course-element.css'
import StudentPageMode from '../../../../../model/StudentPageMode'

const mentorEnrollService = new FakeProtectEnrollService()
export const availableRolesForCompleteCourse = [
  RoleEnum.ADMIN,
  RoleEnum.MENTOR,
  RoleEnum.CHIEF_MENTOR,
  RoleEnum.CURATOR,
]

function getLinkForAdditionalCourseDetailPage(id, studentId, statusPage) {
  const fakeAdditionalCourseUrl = `/user/courses/additional/${id}?studentId=${studentId}`
  const additionalCourseUrl = `/user/courses/additional/${id}`

  return statusPage === StudentPageMode.FAKE ? fakeAdditionalCourseUrl : additionalCourseUrl
}

function getLinkForCourseDetailPage(courseId, studentId, statusPage) {
  const fakeCourseUrl = `/user/courses/${courseId}?studentId=${studentId}`
  const courseUrl = `/user/courses/${courseId}`

  return statusPage === StudentPageMode.FAKE ? fakeCourseUrl : courseUrl
}

function getLinkForDetailPage(statusPage, courseId, studentId, courseType) {
  return courseType === CourseType.Additional
    ? getLinkForAdditionalCourseDetailPage(courseId, studentId, statusPage)
    : getLinkForCourseDetailPage(courseId, studentId, statusPage)
}

const CourseElement = ({
  course: { id, name, studentPoints, coursePoints, available = true, fullPassRequired },
  pic,
  studentId,
  statusPage,
  courseType,
}) => {
  const percentage = NumberUtil.getPercentage(studentPoints, coursePoints)
  const principalRole = AuthService.currentUserValue().role.name
  const needSolve = courseType === CourseType.Additional && fullPassRequired && studentPoints !== coursePoints
  const link = getLinkForDetailPage(statusPage, id, studentId, courseType)

  const finishedCourse = () => {
    confirmAlert({
      title: 'Завершение модуля у студента',
      message: 'Подтверждаешь завершение модуля? Отменить действие будет невозможно!',
      buttons: [
        {
          label: 'Завершить',
          onClick: () => {
            mentorEnrollService.enrollStudentCourse(id, studentId).then(() => {
              // eslint-disable-next-line no-restricted-globals
              history.go()
            })
          },
        },
        {
          label: 'Отмена',
        },
      ],
    })
  }

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {available ? (
        needSolve ? (
          <OverlayTrigger
            overlay={<Tooltip id={`required-fullpass-tooltip-${id}`}>Необходимо полностью пройти этот курс</Tooltip>}
            placement="bottom"
            show
          >
            <Link to={link} className="course-block need-solving-course">
              <AnimatedProgressBar className="course-progress" percentage={percentage} />
              <span className="course-pic">{pic && <img src={pic} alt="course-pic" />}</span>
              <span className="course-title">{name}</span>
            </Link>
          </OverlayTrigger>
        ) : (
          <Link to={link} className="course-block">
            <AnimatedProgressBar className="course-progress" percentage={percentage} />
            <span className="course-pic">{pic && <img src={pic} alt="course-pic" />}</span>
            <span className="course-title">{name}</span>
          </Link>
        )
      ) : (
        <div className="course-block">
          <span className="course-pic">{pic && <img src={pic} alt="course-pic" />}</span>
          <span className="course-title" style={{ color: '#BEBEBE' }}>
            {name}
          </span>
        </div>
      )}
      {availableRolesForCompleteCourse.includes(principalRole) && (
        <button
          type="button"
          className={studentPoints / coursePoints >= 1 ? 'finish-course-block' : 'finish-course'}
          style={{ cursor: 'pointer' }}
          onClick={finishedCourse}
        >
          Завершить курс
        </button>
      )}
    </>
  )
}

export default CourseElement
