import React, { FC } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { easeQuadInOut } from 'd3-ease'
import AnimatedProgressProvider from '../pages/student/all-courses-page/animated-progress-provider/animated-progress-provider'

const AnimatedProgressBar: FC<Props> = ({ className, percentage, valueStart = 0, duration = 1.0 }) => {
  return (
    <AnimatedProgressProvider
      valueStart={valueStart}
      valueEnd={percentage}
      duration={duration}
      easingFunction={easeQuadInOut}
    >
      {(value: number) => {
        const roundedValue = Math.round(value)
        return (
          <CircularProgressbar
            className={className}
            value={roundedValue}
            text={`${roundedValue}%`}
            strokeWidth={10}
            styles={buildStyles({
              pathColor: roundedValue === 100 ? '#00C832' : '#4B8AFF',
              pathTransition: 'none',
            })}
          />
        )
      }}
    </AnimatedProgressProvider>
  )
}

type Props = {
  percentage: number
  className?: string
  valueStart?: number
  duration?: number
}

export default AnimatedProgressBar
