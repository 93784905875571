import React, { FC } from 'react'
import classes from './index.module.scss'

const Category: FC<Props> = ({ label, children, ...props }) => {
  return (
    <div {...props} className={classes.category}>
      <span className={classes.category__label}>{label}</span>
      <div className={classes.category__content}>{children}</div>
    </div>
  )
}

interface Props {
  label: string
}

export default Category
