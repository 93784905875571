import React from 'react'
import DraftEditor from '../../../components/draft-editor'

const EditCommentForm = function EditCommentForm({ comment, setEditComment }) {
  return (
    <div className="comment-block-wrap">
      <DraftEditor
        value={comment.text}
        onChange={text =>
          setEditComment({
            ...comment,
            text,
          })
        }
      />
    </div>
  )
}

export default EditCommentForm
