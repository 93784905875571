import FakeOpenStudentCourseTaskService from '../../../../services/fake-page-services/open/fake-open-student-course-task-service'
import { Answer } from './task-block/association-task'
import { GappingTaskStudentAnswerDto } from '../../../../model/task-model/GappingTaskDto'
import { StudentMultiAnswerTaskAnswerItemDto } from '../../../../model/task-model/MultiAnswerTaskDto'
import { StudentMultiTestTaskItemAnswerDto } from '../../../../model/task-model/MultiTestTaskDto'
import { OrderingTaskItemDto } from '../../../../model/task-model/OrderingTaskDto'
import { TaskType } from '../../../../model/task-dto/task-type-enum'

const fakeStudentCourseTaskService = new FakeOpenStudentCourseTaskService()

const getSolveTaskCallbackFake = (type: TaskType | undefined, courseTaskId: number) => {
  switch (type) {
    case TaskType.MultiInput:
      return (answers: any[]) => {
        return fakeStudentCourseTaskService.sendMultiInputSolution(courseTaskId, {
          studentAnswers: answers.map(answer => {
            return { ...answer, multiInputItemId: answer.itemId, itemId: undefined, right: undefined }
          }),
        })
      }

    case TaskType.Association:
      return (answers: Answer[]) => {
        return fakeStudentCourseTaskService.sendAssociationSolution(courseTaskId, {
          studentAnswers: answers.map(answer => {
            return { ...answer, isRight: undefined }
          }),
        })
      }

    case TaskType.Gapping:
      return (gappingAnswers: GappingTaskStudentAnswerDto[] | undefined) => {
        return fakeStudentCourseTaskService.sendGappingSolution(courseTaskId, { studentAnswers: gappingAnswers })
      }

    case TaskType.Ordering:
      return (orderingAnswers: OrderingTaskItemDto[]) => {
        return fakeStudentCourseTaskService.sendOrderingSolution(courseTaskId, orderingAnswers)
      }

    case TaskType.MultiTest:
      return (multiTestAnswers: StudentMultiTestTaskItemAnswerDto[]) => {
        return fakeStudentCourseTaskService.sendMultiTestSolution(courseTaskId, { studentAnswers: multiTestAnswers })
      }

    case TaskType.MultiAnswer:
      return (multiAnswerAnswers: StudentMultiAnswerTaskAnswerItemDto[]) => {
        return fakeStudentCourseTaskService.sendMultiAnswerSolution(courseTaskId, {
          studentAnswers: multiAnswerAnswers,
        })
      }

    case TaskType.Word:
      return (newAnswer: string) => {
        return fakeStudentCourseTaskService.sendWordSolution(courseTaskId, newAnswer)
      }

    case TaskType.Code:
      return (code: string) => {
        return fakeStudentCourseTaskService.sendCodeSolution(courseTaskId, code)
      }

    case TaskType.MentorCheckTask:
      return Promise.resolve()

    case TaskType.Lecture:
      return () => {
        return Promise.resolve()
      }

    case TaskType.Theory:
      return (answersId: number[]) => {
        return fakeStudentCourseTaskService.sendTheorySolution(courseTaskId, answersId)
      }

    default:
      return () => {}
  }
}

export default getSolveTaskCallbackFake
