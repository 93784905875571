import { DayWithoutPause, StudentSolutionActivity } from '../../model/student-model'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { StudentProfileServiceInterface } from '../interfaces/student-services/student-profile-service'

class StudentProfileService implements StudentProfileServiceInterface {
  private readonly sourceURL = `${serverHost}/api/student/profile`

  public getTopics = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string[]>(`${this.sourceURL}/topics-names`, queryOption)
  }

  public getTopicsCount = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/topics-count`, queryOption)
  }

  public getActivity = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<StudentSolutionActivity[]>(`${this.sourceURL}/solution-activity-year`, queryOption)
  }

  public getDaysWithoutPause = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<DayWithoutPause>(`${this.sourceURL}/day_without_pause`, queryOption)
  }
}

export default StudentProfileService
