import { TaskComment } from 'src/components/pages/student/course-page/comment-block/comments-block'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import { UserCommentServiceInterface } from '../interfaces/user-services/user-comment-service'

class UserCommentService implements UserCommentServiceInterface {
  sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/user/comment`
  }

  getTaskComments = (taskId: number) => {
    const url = `${this.sourceURL}/task/${taskId}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<TaskComment[]>(url, queryOption)
  }

  sendComment = (taskId: number, text: string, mainId: number) => {
    const url = `${this.sourceURL}/task/${taskId}?mainId=${mainId}`
    const queryPotion = {
      method: HTTPMethods.POST,
      body: `text=${encodeURIComponent(text)}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<TaskComment>(url, queryPotion)
  }

  sendAnswerToComment = (mainCommentId: number, text: string) => {
    const url = `${this.sourceURL}/${mainCommentId}/answer`
    const queryPotion = {
      method: HTTPMethods.POST,
      body: `text=${encodeURIComponent(text)}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<TaskComment>(url, queryPotion)
  }

  updateComment = (text: string, commentId: number) => {
    const url = `${this.sourceURL}/${commentId}`
    const queryPotion = {
      method: HTTPMethods.PUT,
      body: `newText=${encodeURIComponent(text)}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(url, queryPotion)
  }

  putLike = (commentId: number) => {
    const queryPotion = {
      method: HTTPMethods.POST,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    const url = `${this.sourceURL}/${commentId}/like`
    return ApiService.request<number>(url, queryPotion)
  }

  deleteLike = (commentId: number) => {
    const queryPotion = {
      method: HTTPMethods.DELETE,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    const url = `${this.sourceURL}/${commentId}/like`
    return ApiService.request<number>(url, queryPotion)
  }

  deleteComment = (commentId: number) => {
    const queryPotion = {
      method: HTTPMethods.DELETE,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    const url = `${this.sourceURL}/${commentId}`
    return ApiService.request(url, queryPotion)
  }
}

export default UserCommentService
