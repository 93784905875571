export enum TaskType {
  Lecture = 'lecture',
  Word = 'word',
  Theory = 'theory',
  Code = 'code',
  MentorCheckTask = 'mentorCheckTask',
  ReviewStep = 'review_step',
  Ordering = 'ordering',
  Association = 'association',
  MultiTest = 'multi_test',
  MultiAnswer = 'multi_answer',
  Gapping = 'gapping',
  MultiInput = 'multi_input',
}
