import React from 'react'
import { Link } from 'react-router-dom'
import { useStatusPage } from '../../../../../../hooks/useStatusPage'
import StudentPageMode from '../../../../../../model/StudentPageMode'

const ListCodeTaskDetails = ({ codeTasks }) => {
  const { statusPage, studentIdParam } = useStatusPage()
  if (codeTasks.length === 0) {
    return <div className="details-data">Список пуст</div>
  }

  return codeTasks.map((entity, key) => {
    const { courseId, modulePosition, chapterPosition, courseTaskPosition, mentorComment } = entity

    const linkToTask = `./courses/${courseId}/${modulePosition}/${chapterPosition}/${courseTaskPosition}${
      statusPage === StudentPageMode.FAKE ? `?studentId=${studentIdParam}` : ''
    }`
    return (
      <div className="details-data" key={`code_debt_${linkToTask}`}>
        <Link
          to={linkToTask}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'inherit', textDecoration: 'underline' }}
        >
          {`${modulePosition}.${chapterPosition}.${courseTaskPosition}:`}
        </Link>
        {` ${mentorComment}`}
      </div>
    )
  })
}

export default ListCodeTaskDetails
