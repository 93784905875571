const useIsQuestionAnswersRightCallback = <Question, CurrentQuestion>(
  questions: Question[],
  resolveQuestionId: (question: Question) => number,
  resolveIsQuestionRight: (question: Question | undefined) => boolean | undefined,
  resolveCurrentQuestionId: (question: CurrentQuestion) => number
) => {
  return (currentQuestion: CurrentQuestion) =>
    resolveIsQuestionRight(
      questions.find(question => resolveCurrentQuestionId(currentQuestion) === resolveQuestionId(question))
    )
}

export default useIsQuestionAnswersRightCallback
