import { Dispatch, SetStateAction } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import TaskDto from '../../../../../model/task-dto/task-dto'

const useResetTaskCallback = <T extends TaskDto, Loading>(
  setLoading: Dispatch<SetStateAction<Partial<Loading>>>,
  onResetTask: () => any,
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<any>,
  postResetInterceptor: (value: any) => any,
  resetAnswers: () => void
) => {
  const { reactAlert } = useReactAlert()
  return async () => {
    setLoading(loading => ({
      ...loading,
      taskResetting: true,
    }))

    try {
      const response = await onResetTask()
      loadTask(postResetInterceptor)
      resetAnswers()
      return response
    } catch (e) {
      reactAlert.error(e)
      return undefined
    } finally {
      setLoading(loading => ({
        ...loading,
        taskResetting: false,
      }))
    }
  }
}

export default useResetTaskCallback
