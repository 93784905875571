import React from 'react'
import Highlight from 'react-highlight'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import '../../../student-page.css'
import { TaskType } from 'src/model/task-dto/task-type-enum'
import cn from 'classnames'
import { Answer } from '../../task-block/check-task/answer'
import CodeEditor from '../../../../../code-editor'

const customStyles = {
  content: {
    left: '50%',
    top: 0,
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: 30,
    margin: '44px 0',
    width: '100%',
    maxWidth: '1060px',
  },
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(30, 30, 30, 0.54)',
    zIndex: 10,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}

const ModalSolution = ({ answer, modalShowed, onClose, result, right, codeLang, type, answerType }) => {
  const { t } = useTranslation()
  const message = right ? `${t('TheRightSolution')}` : `${t('InvalidSolutionCheckAgain')}`
  const icon = right ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />
  const codeClass = `code-editor ${right ? 'success' : 'error'}`

  const isOnCheck = answer && !result
  const isSuccess = !isOnCheck && right
  const isError = !isOnCheck && !right

  const getAnswerInputClassName = () =>
    cn('lesson-input', {
      'on-check': isOnCheck,
      'success': isSuccess,
      'error': isError,
    })

  const getHighlightClassName = () =>
    cn('answer-question', {
      'success': isSuccess,
      'error': isError,
    })

  const renderSolution = () => {
    const mentorCheckTaskSolution = (
      <>
        <div className={getAnswerInputClassName()}>
          <Answer value={answer ?? ''} type={answerType} />
        </div>

        {result && (
          <div className={getHighlightClassName()}>
            <Highlight className="mentor-description" innerHTML>
              {result}
            </Highlight>
          </div>
        )}
      </>
    )

    const codeTaskSolution = (
      <>
        <div className="solution-popup">
          {answer && (
            <CodeEditor
              value={answer}
              className={codeClass}
              language={codeLang}
              options={{
                minLines: 10,
                readOnly: true,
              }}
            />
          )}
        </div>
        {result && (
          <div className="compile-result">
            <div className="terminal-result">
              <samp>{result}</samp>
            </div>
          </div>
        )}
      </>
    )

    const solutions = {
      [TaskType.MentorCheckTask]: mentorCheckTaskSolution,
      [TaskType.Code]: codeTaskSolution,
    }

    // FIXME: probably code task solution should not be the default solution, but this behavior
    //  saved for backward compatibility
    return solutions[type] ?? codeTaskSolution
  }

  return (
    <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
      <div className="solution-popup popup-window">
        <div className="solution-popup-head-wrap">
          <div className="solution-popup-head">
            {right ? t('Right') : t('Wrong')} {t('Solution')}
          </div>
          <div>
            <button
              type="button"
              data-fancybox-close=""
              className="fancybox-close-small"
              title="Close"
              onClick={onClose}
            />
          </div>
        </div>
        {renderSolution()}
        <div className="lesson-result-row">
          <div>
            {icon} {message}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalSolution
