import React, { Component, ErrorInfo, ReactNode } from 'react'
import { withReactAlert } from 'src/components/hoc/withReactAlert'
import { ReactAlert } from 'src/hooks/useReactAlert'
import StudentErrorPage from '../errors/student-error-page'

interface Props {
  children: ReactNode
  reactAlert: ReactAlert
}

interface State {
  hasError: boolean
  location: string | null
}

class StudentErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    location: null,
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.location !== window.location.href) {
      this.setState({ hasError: false, location: window.location.href })
    }
  }

  // eslint-disable-next-line id-length
  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true, location: window.location.href }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { reactAlert } = this.props
    reactAlert.error(`Uncaught error: ${error}, ${errorInfo}`)
  }

  public render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? <StudentErrorPage hasError={hasError} /> : children
  }
}

export default withReactAlert(StudentErrorBoundary)
