import { useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { AuthService } from '../services/auth-service'
import { RoleEnum } from '../utils/select-state/RoleEnum'
import StudentPageMode from '../model/StudentPageMode'

const useStatusPage = () => {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [principalRole, setPrincipalRole] = useState<RoleEnum>(RoleEnum.EMPTY)
  const [principalId, setPrincipalId] = useState<number | null>(null)
  const [studentIdParam, setStudentIdParam] = useState(-1)
  const [statusPage, setStatusPage] = useState<StudentPageMode>(StudentPageMode.NOT_READY)

  useEffect(() => {
    const {
      id,
      role: { name },
    } = AuthService.currentUserValue()!
    const studentIdParamRaw = searchParams.get('studentId')
    setPrincipalRole(name)
    setPrincipalId(id)
    if (studentIdParamRaw && Number(studentIdParamRaw) > 0) {
      setStudentIdParam(Number(studentIdParamRaw))
      if (name !== RoleEnum.PAY_STUDENT || Number(studentIdParamRaw) !== id) {
        setStatusPage(StudentPageMode.FAKE)
      } else {
        setStatusPage(StudentPageMode.NORMAL)
      }
    } else {
      setStatusPage(StudentPageMode.NORMAL)
    }
  }, [search])
  return {
    statusPage,
    studentIdParam,
    principalRole,
    principalId,
  }
}
export { useStatusPage }
