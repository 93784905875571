import { Dispatch, SetStateAction, useEffect } from 'react'
import { GappingTaskDto } from '../../../../../../model/task-model/GappingTaskDto'
import { GappingValues } from './index'
import { GappingDirectionTaskDto } from '../../../../../../model/direction-model'

const useCurrentGappingValuesSetting = (
  gappingTask: GappingTaskDto | GappingDirectionTaskDto | undefined,
  setGappingValues: Dispatch<SetStateAction<GappingValues>>,
  isSolved: boolean,
  updateAnswers: boolean
) => {
  useEffect(() => {
    if (isSolved && gappingTask && updateAnswers) {
      setGappingValues(
        Object.fromEntries(
          gappingTask.studentGappingResults.map(({ gappingItemId, gappingText }) => [gappingItemId, gappingText])
        )
      )
    }
  }, [gappingTask])
}

export default useCurrentGappingValuesSetting
