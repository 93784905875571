import React, { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react'
import { useDrag } from 'react-dnd'
import cn from 'classnames'
import classes from '../style.module.scss'
import isNullish from '../../../utils/isNullish'

const Item = <T,>(props: PropsWithChildren<Props<T>>) => {
  const { itemId, label, position, style, className, disabled, ...restProps } = props

  const [{ opacity }, dragRef] = useDrag({
    canDrag: !disabled && isNullish(position),
    item: { type: 'Item', itemId },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  return (
    <div {...restProps} className={cn(className, classes.item, { [classes.item_disabled!]: !isNullish(position) })}>
      {/*todo: типизация классов в CSS module*/}
      <span
        style={{
          ...style,
          opacity,
        }}
        ref={dragRef}
        className={classes.item__label}
      >
        {label}
      </span>
    </div>
  )
}

interface Props<T> extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  itemId: number | undefined
  label: string | undefined
  position: number | null
  disabled: boolean
}

export default Item
