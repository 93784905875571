import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'
import cn from 'classnames'
import { TFunction } from 'i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { useSelector } from 'react-redux'
import AnimatedProgressBar from '../../../../animated-progress-bar/animated-progress-bar'
import styles from '../../../student/components/student-bread-crumbs/student-bread-crumbs.module.scss'
import DirectionTasksService from '../../../../../services/direction-task-service'
import { DirectionTaskHeadingDto } from '../../../../../model/direction-model'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'
import { TaskType } from '../../../../../model/task-dto/task-type-enum'
import { RootState } from '../../../../../store/rootReducer'
import NumberUtil from '../../../../../utils/NumberUtil'

const taskTypeToString = (taskType: TaskType, translator: TFunction) => {
  switch (taskType) {
    case TaskType.Lecture:
      return `${translator('Lecture')}`
    case TaskType.Code:
      return `${translator('PracticalTask')}`
    case TaskType.Theory:
      return `${translator('TheoreticalTask')}`
    case TaskType.Word:
      return `${translator('SecurityQuestion')}`
    case TaskType.Ordering:
      return `${translator('OrderTask')}`
    case TaskType.Association:
      return `${translator('AssociationTask')}`
    case TaskType.MultiTest:
      return `${translator('MultiTestTask')}`
    case TaskType.MultiAnswer:
      return `${translator('MultiAnswerTask')}`
    case TaskType.Gapping:
      return `${translator('GappingTask')}`
    case TaskType.MentorCheckTask:
      return `${translator('TheTaskVerifiedByTheMentor')}`
    case TaskType.ReviewStep:
      return `${translator('GroupReviewByModule')}`
    case TaskType.MultiInput:
      return `${translator('MultiInputTask')}`
    default:
      return `${translator('Task')}`
  }
}

const directionTasksService = new DirectionTasksService()

interface IProps {
  tasksHeading: DirectionTaskHeadingDto
  courseTaskPosition: number
}

const EnrolleeStudentBreadcrumbs: React.FC<IProps> = ({ tasksHeading, courseTaskPosition = 0 }) => {
  const { catchErrorAlert } = useReactAlert()
  const [coursePic, setCoursePic] = useState<string>('')
  const { t } = useTranslation()

  useEffect(() => {
    directionTasksService
      .getDirectionIcon()
      .then(pic => {
        setCoursePic(convertResponseImageUrl(pic))
      })
      .catch(err => catchErrorAlert(err))
  }, [])

  const { studentPoints, coursePoints } = useSelector((state: RootState) => state.task)
  const progress = useMemo(() => {
    return NumberUtil.getPercentage(studentPoints, coursePoints)
  }, [studentPoints, coursePoints])

  const { taskIcons: iconsDto = [], currentTaskType: headTaskType, directionName } = tasksHeading

  let breadCrumbsTitle = directionName
  document.title = directionName
  if (courseTaskPosition !== 0) {
    breadCrumbsTitle = taskTypeToString(headTaskType, t)
    document.title = breadCrumbsTitle
    breadCrumbsTitle = `${directionName}. ${breadCrumbsTitle} ${courseTaskPosition}`
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const courseLogoClass = useMemo(() => {
    return cn('page-title-course-pic', styles['course-progress-bar'])
  }, [])

  return (
    <div id="middle_container">
      <div className={`middle-header ${courseTaskPosition !== 0 ? 'lesson-step' : ''}`}>
        <div className="container">
          {coursePic && (
            <div className={courseLogoClass}>
              <AnimatedProgressBar percentage={progress} className={styles['course-progress-bar__spinner']} />
              <img alt="course logo" src={coursePic} />
            </div>
          )}
          <div className="page-title-wrap">
            <div className="breadcrumbs">
              <span className="link">
                <Link to="/" onClick={e => e.preventDefault()}>
                  {directionName}
                </Link>
              </span>
            </div>
            <div className="page-title-wrap">
              <div className="page-title">{breadCrumbsTitle}</div>
              <div className="page-title-progress" id="page-title-progress" />
            </div>
            <div className="lesson-navigation" id="lesson-navigation">
              {iconsDto.map(({ solved, taskType }, idx) => {
                const taskPosition = idx + 1
                const currentTaskPosition = courseTaskPosition
                let iconClass = ''
                let iconMdiClass = ''

                // eslint-disable-next-line default-case
                switch (taskType) {
                  case TaskType.Lecture:
                    iconMdiClass = 'mdi-play'
                    break
                  case TaskType.Code:
                    iconMdiClass = 'mdi-code-tags'
                    break
                  case TaskType.MultiTest:
                    iconMdiClass = 'mdi-format-list-text'
                    break
                  case TaskType.MultiAnswer:
                    iconMdiClass = 'mdi-format-list-numbered'
                    break
                  case TaskType.MultiInput:
                    iconMdiClass = 'mdi-clipboard-text-outline'
                    break
                  case TaskType.Theory:
                    iconMdiClass = 'mdi-book-open-page-variant'
                    break
                  case TaskType.Ordering:
                    iconMdiClass = 'mdi-equalizer-outline'
                    break
                  case TaskType.Gapping:
                    iconMdiClass = 'mdi-format-line-style'
                    break
                  case TaskType.Association:
                    iconMdiClass = 'mdi-arrow-expand-horizontal'
                    break
                  case TaskType.Word:
                    iconMdiClass = 'mdi-pencil'
                    break
                  case TaskType.MentorCheckTask:
                    iconMdiClass = 'mdi-code-tags'
                    break
                  case TaskType.ReviewStep:
                    iconMdiClass = 'mdi-account-group'
                    break
                }
                if (taskPosition === currentTaskPosition) {
                  iconClass = 'current '
                } else if (solved) {
                  iconClass = 'completed '
                }
                return (
                  <Link
                    key={`task_${taskPosition}`}
                    title={`${taskTypeToString(taskType, t)} ${taskPosition}`}
                    to={`/enrollee/${taskPosition}`}
                    className={iconClass}
                  >
                    <i className={`mdi ${iconMdiClass}`} onClick={scrollToTop} />
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(EnrolleeStudentBreadcrumbs)
