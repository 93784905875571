import { TaskType } from '../../../../model/task-dto/task-type-enum'

export const getPostResetInterceptorStudent: (type: TaskType | undefined) => (val: any) => any = type => {
  switch (type) {
    case TaskType.Code:
      return (val: any) => {
        const newVal = val
        newVal.answer = val.placeholder
        return newVal
      }
    default:
      return (val: any) => val
  }
}
