import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class UserCourseTaskService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/user/courseTask`
  }

  getIdByChapterIdAndPosition = (chapterId, courseTaskPosition) => {
    const queryOption = {
      method: 'GET',
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(
      `${this.sourceURL}/getIdByChapterIdAndPosition/${courseTaskPosition}?chapterId=${chapterId}`,
      queryOption
    )
  }

  isNextTaskLastInTheCourse = courseTaskId => {
    const url = `${this.sourceURL}/${courseTaskId}/checkIsLast`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(url, queryOption)
  }
}

export default UserCourseTaskService
