import React, { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, useRef } from 'react'
import cn from 'classnames'
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd'
import classes from '../style.module.scss'

const Slot = <T,>(props: PropsWithChildren<Props<T>>) => {
  const { itemId, label, designation, className, position, onItemDrop, disabled, right } = props

  const ref = useRef(null)

  const isFilled = !!itemId

  const [{ isOver, canDrop }, dropRef] = useDrop({
    canDrop: () => !disabled,
    accept: 'Item',
    drop: (item: DragObjectWithType & { itemId: number }) => onItemDrop(item.itemId, position),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const [{ opacity }, dragRef] = useDrag({
    canDrag: !disabled && isFilled,
    item: { type: 'Item', itemId },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  const isActive = isOver && canDrop

  dropRef(dragRef(ref))

  return (
    <div
      className={cn(className, classes.item, {
        [classes.item_active!]: isActive,
        [classes.item_empty!]: !isFilled,
        [classes.item_filled!]: isFilled,
        [classes.item_success!]: disabled && right,
        [classes.item_error!]: disabled && !right,
      })}
    >
      {/*todo: типизация классов в CSS module*/}
      <span
        className={cn(classes.item__designation, {
          [classes.item__designation_wide!]: !!designation,
        })}
      >
        {position}. {designation}
      </span>
      <span ref={ref} className={classes.item__label} style={{ opacity }}>
        {label}
      </span>
    </div>
  )
}

interface Props<T> extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  itemId: number | undefined
  label: string | undefined
  designation?: string
  position: number
  onItemDrop: (itemId: number, position: number) => void
  disabled: boolean
  right: boolean
}

export default Slot
