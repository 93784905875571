import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import Calendar from 'react-calendar'
import { store } from 'react-notifications-component'
import { formatDateToDDMMYYYY, getLastDayOfNextMonth, MONTHS, MONTHS_RU } from '../../../../../utils/dateUtils'
import { ReviewEntryDto, TimeEntryDto } from '../../../../../model/student-model'
import { Locale } from '../../../../../model/Locale'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../../config'
import StudentReviewService from '../../../../../services/student-services/student-review-service'
import { HttpEnumsStatusCode } from '../../../../../model/utils/http-enums-stauts'
import ReviewStepSignUpModal from './review-step-sign-in-modal/review-step-sign-in-modal'
import 'react-calendar/dist/Calendar.css'
import './review-step-modal.scss'

interface Props {
  modalIsOpen: boolean
  setIsOpen: (bool: boolean) => void
  reviewEntryDto: ReviewEntryDto
}

interface CustomStyles<T> {
  content: {
    [key: string]: T
  }
  overlay: {
    [key: string]: T
  }
}

export interface SignUpReviewDate {
  timeInfo: TimeEntryDto[] | undefined
  selectedDay: string
  currentReviewId?: number
}

const customStyles: CustomStyles<string | number> = {
  content: {
    left: '50%',
    top: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    padding: 0,
    width: '600px',
    maxHeight: '800px',
  },
  overlay: {
    overflow: 'scroll',
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
    zIndex: 10,
  },
}

const JANUARY = 1
const LAST_MONTH_OF_YEAR = 12

const ReviewStepModal = ({ modalIsOpen, setIsOpen, reviewEntryDto }: Props): JSX.Element => {
  const [date, onDateChange] = useState<Date>(new Date())
  const [signUpReviewDate, setSignUpReviewDate] = useState<SignUpReviewDate | null>(null)
  const [selectedReviewId, setSelectedReviewId] = useState<number | null>(null)
  const [activeIndex, setActiveIndex] = useState<null | number>(null)
  const calendarRef = useRef(null)
  const { i18n, t } = useTranslation()

  const studentReviewService = new StudentReviewService()

  const getReviewId = (reviewEntries: TimeEntryDto[] | undefined): number => {
    const { reviewId } = reviewEntries?.filter(({ registered }: TimeEntryDto) => registered)[0] as TimeEntryDto
    return reviewId
  }

  const getSelectedAndFormattedMonth = (monthIndex: number) => {
    // indices start at zero so we need index + 1
    const currentMonthIndex = monthIndex + 1
    if (currentMonthIndex > LAST_MONTH_OF_YEAR) {
      return JANUARY
    }
    return currentMonthIndex
  }

  // check if date starts from zero, then get the next value  e.g: '02' return 2
  const removeZero = (currentDay: string) => {
    const isDayStartsFromZero = currentDay.split('')[0] === '0'
    return isDayStartsFromZero ? currentDay.split('')[1] : currentDay
  }

  const getAvailableDaysMonthsPairForReview = (arrayOfDate: string[]): Map<string, string[]> => {
    return arrayOfDate.reduce((acc: any, current: any) => {
      const [day, month] = current.split('.')
      const formattedDay = removeZero(day)
      const formattedMonth = removeZero(month)

      if (acc.has(formattedMonth)) {
        const listOfMonths = acc.get(formattedMonth)
        listOfMonths.push(formattedDay)
        acc.set(formattedMonth, [...listOfMonths].reverse())
      } else {
        acc.set(formattedMonth, [formattedDay])
      }
      return acc
    }, new Map())
  }

  const highlightDays = (updatedMonthProps?: number) => {
    // crutch solution for highlight days for review cuz calendar do not allow to style each date individually
    if (!signUpReviewDate) {
      setTimeout(() => {
        if (calendarRef.current && reviewEntryDto) {
          // @ts-ignore
          const calendarDOMDate = calendarRef.current.querySelector('.react-calendar__month-view__days')?.children
          const availableDaysForReview = getAvailableDaysMonthsPairForReview(
            Object.keys(reviewEntryDto.mapOfListTimeEntryDto)
          )
          const selectedMonth = getSelectedAndFormattedMonth(updatedMonthProps ?? date.getMonth())
          const getHighlightedDaysForSelectedMonth = availableDaysForReview.get(String(selectedMonth))
          calendarDOMDate.forEach((button: HTMLButtonElement): void => {
            button.style.pointerEvents = 'none'
            const attr = button.children[0] as HTMLElement
            if (getHighlightedDaysForSelectedMonth?.includes(attr.textContent as any)) {
              button.classList.add('available-for-review')
              button.style.pointerEvents = 'auto'
            }
          })
        }
      })
    }
  }

  useEffect(() => {
    highlightDays()
  }, [modalIsOpen, signUpReviewDate])

  useEffect(() => {
    if (reviewEntryDto.recordedDay) {
      const recordedDayFullInfo = reviewEntryDto.mapOfListTimeEntryDto[reviewEntryDto.recordedDay]
      const reviewId = getReviewId(recordedDayFullInfo)
      const allAvailableTimeForReview: SignUpReviewDate = {
        timeInfo: recordedDayFullInfo,
        selectedDay: reviewEntryDto.recordedDay,
        currentReviewId: reviewId,
      }
      setSignUpReviewDate(allAvailableTimeForReview)
      setSelectedReviewId(reviewId)
    } else {
      setSignUpReviewDate(null)
    }
  }, [modalIsOpen])

  function closeModal() {
    setIsOpen(false)
  }

  const updateActiveDate = (newDate: Date): void => {
    highlightDays(newDate.getMonth())
  }

  const changeDateHandler = (value: Date) => {
    const allAvailableDateForReview: string[] = Object.keys(reviewEntryDto.mapOfListTimeEntryDto)
    const selectedDay = formatDateToDDMMYYYY(value)
    const studentCanSignUpForReview: boolean = allAvailableDateForReview.includes(selectedDay)

    if (studentCanSignUpForReview) {
      const allAvailableTimeForReview: SignUpReviewDate = {
        timeInfo: reviewEntryDto.mapOfListTimeEntryDto[selectedDay],
        selectedDay,
      }
      setSignUpReviewDate(allAvailableTimeForReview)
    }

    onDateChange(value)
  }

  const backToCalendar = () => {
    setSignUpReviewDate(null)
  }

  const formatTitle = (selectedDay: string): string => {
    const [day, month] = selectedDay.split('.')
    // @ts-ignore  // month - 1 cuz index of months starts from zero
    const formattedMonth = i18n.language === Locale.Ru ? MONTHS_RU[month - 1] : MONTHS[month - 1]
    return `${day} ${formattedMonth}`
  }

  const formatReviewTime = (time: string): string => {
    // format must be for instance 14:00 - 15:00  //10-12
    const partedTime = time.split(' ')[1]
    const timeArray = partedTime!.split(':')
    const hour = timeArray[0]
    const plusOneHour = Number(hour) + 1

    return `${partedTime} - ${plusOneHour}:00`
  }

  const signUpForReview = () => {
    if (selectedReviewId) {
      studentReviewService
        .registerStudentOnReviewByReviewId(selectedReviewId, reviewEntryDto.moduleId)
        .then(r => {
          setSelectedReviewId(null)
          closeModal()
          store.addNotification({
            ...studentSuccessNotyTemplate,
            message: `${t('YouSignedUpForAReview')}`,
          })
        })
        .catch(({ code, text }) => {
          if (code === HttpEnumsStatusCode.BAD_REQUEST)
            store.addNotification({
              ...studentErrorNotyTemplate,
              message: text,
            })
        })
    }
  }

  const cancelReview = () => {
    if (selectedReviewId) {
      studentReviewService
        .deleteStudentFromReviewByReviewId(selectedReviewId)
        .then(r => {
          setSelectedReviewId(null)
          setSignUpReviewDate(null)
          closeModal()
          store.addNotification({
            ...studentSuccessNotyTemplate,
            message: `${t('YouCheckedOutWithAReview')}`,
          })
        })
        .catch(({ code, text }) => {
          if (code === HttpEnumsStatusCode.BAD_REQUEST)
            store.addNotification({
              ...studentErrorNotyTemplate,
              message: text,
            })
        })
    }
  }

  return (
    <div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
        <div className="stat-courses-popup popup-window" style={{ width: 'initial' }}>
          <div className="popup-head">{t('RecordingOnReview')}</div>
          <button type="button" className="close-modal-button" onClick={closeModal} />
          {!signUpReviewDate && (
            <div className="textCustomDatePicker">
              {t('ChooseAConvenientDateFromTheAvailableOnesTheyAreMarkedInGreen')}
            </div>
          )}
        </div>
        {signUpReviewDate ? (
          <ReviewStepSignUpModal
            reviewEntryDto={reviewEntryDto}
            signUpReviewDate={signUpReviewDate}
            formatTitle={formatTitle}
            setSelectedReviewId={setSelectedReviewId}
            formatReviewTime={formatReviewTime}
            cancelReview={cancelReview}
            backToCalendar={backToCalendar}
            signUpForReview={signUpForReview}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
          />
        ) : (
          <Calendar
            locale="ru-RU"
            prev2Label=""
            next2Label=""
            value={date}
            onChange={changeDateHandler}
            className={['calendar']}
            inputRef={calendarRef}
            minDate={new Date()}
            maxDate={getLastDayOfNextMonth()}
            onActiveStartDateChange={({ activeStartDate }) => updateActiveDate(activeStartDate)}
          />
        )}
      </Modal>
    </div>
  )
}

export default ReviewStepModal
