export class SafetyLocalStorage {
  public static getItem(key: string): string | null {
    const rawValue = localStorage.getItem(key)
    if (!rawValue) return null
    const byteMap: [string, number][] = Object.entries(JSON.parse(rawValue))
    const byteArray: number[] = Array.from(byteMap, ([_, value]) => value)
    const decoder = new TextDecoder()
    return decoder.decode(new Uint8Array(byteArray))
  }

  public static setItem(key: string, value: any): void {
    const encoder = new TextEncoder()
    const preparedValue = encoder.encode(value)
    localStorage.setItem(key, JSON.stringify(preparedValue))
  }

  public static removeItem(key: string): void {
    localStorage.removeItem(key)
  }
}
