import FakeStudentCourseTaskInfoService from '../../../../services/fake-page-services/fake-student-course-task-info-service'

const fakeStudentCourseTaskInfoService = new FakeStudentCourseTaskInfoService()

const getMentorCheckCallback = (type: string | undefined, studentCourseTaskInfoId: number) => {
  switch (type) {
    case 'mentorCheckTask':
      return (mentorComment: string, studentAnswerId: number, isRight: boolean) => {
        return fakeStudentCourseTaskInfoService.sendMentorAnswer(
          studentCourseTaskInfoId,
          studentAnswerId,
          mentorComment,
          isRight
        )
      }
    default:
      return () => {}
  }
}

export default getMentorCheckCallback
