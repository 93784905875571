import React, { Dispatch, SetStateAction } from 'react'
import cn from 'classnames'
import classes from './index.module.scss'
import { Gapping, GappingValues } from './index'

const useFillGappingsCallback = (
  gappingValues: GappingValues,
  setGappingValues: Dispatch<SetStateAction<GappingValues>>
) => {
  return (text: string | undefined, gappingsParam: Gapping[] | undefined, showResults: boolean) => {
    const gappings = gappingsParam?.slice()
    if (!gappings || !text) {
      return text
    }

    return gappings
      .sort((first, second) => second.gappingItemPosition - first.gappingItemPosition)
      .reduce<(string | React.ReactNode)[]>(
        (acc, { gappingItemId, gappingItemPosition, isRight }) => {
          const component = (
            <input
              key={gappingItemId}
              value={gappingValues[gappingItemId] || ''}
              disabled={showResults && isRight}
              onChange={e => {
                e.persist()
                setGappingValues(currentGappingValues => ({
                  ...currentGappingValues,
                  [gappingItemId]: e.target.value,
                }))
              }}
              className={cn(classes.gapping, {
                [classes.gapping_success!]: showResults && isRight,
                [classes.gapping_error!]: showResults && !isRight,
              })}
              placeholder="Заполните пропуск"
            />
          )

          return [
            // @ts-ignore
            acc[0].slice(0, gappingItemPosition),
            component,
            // @ts-ignore
            acc[0].slice(gappingItemPosition),
            ...acc.slice(1, acc.length),
          ]
        },
        [text]
      )
  }
}

export default useFillGappingsCallback
