import { Dispatch, SetStateAction, useEffect } from 'react'
import { AssociationTaskDto } from '../../../../../../model/task-model/AssociationTaskDto'
import { Answer } from './index'
import { AssociationDirectionTaskDto } from '../../../../../../model/direction-model'

const useCurrentAnswerSetting = (
  associationTask: AssociationTaskDto | AssociationDirectionTaskDto | undefined,
  setAnswers: Dispatch<SetStateAction<Answer[]>>,
  answers: Answer[]
) => {
  useEffect(() => {
    if (!associationTask) {
      return
    }
    setAnswers(answers.length === 0 ? associationTask.studentAssociationResults : answers)
  }, [associationTask])
}

export default useCurrentAnswerSetting
