import { Dispatch, SetStateAction } from 'react'
import produce from 'immer'
import { Answer } from './index'

const useSetAnswerCallback = (answers: Answer[], setAnswers: Dispatch<SetStateAction<Answer[]>>) => {
  return (itemId: number, newValue: string) => {
    setAnswers(
      produce(answers, draft => {
        const currentAnswerIndex = draft.findIndex(answer => answer.itemId === itemId)
        if (currentAnswerIndex !== -1) {
          draft.splice(currentAnswerIndex, 1)
        }

        draft.push({
          itemId,
          answerText: newValue,
        })
      })
    )
  }
}

export default useSetAnswerCallback
