import React from 'react'
import { store } from 'react-notifications-component'
import GenerateMeetLinkService from '../../../services/generate-meet-link-service'
import styles from './meetPage.module.css'
import { studentErrorNotyTemplate } from '../../../config'

const generateMeetLinkService = new GenerateMeetLinkService()

export const MeetPage = () => {
  const params = new URLSearchParams(window.location.search)
  const code = params.get('linkCode')

  const generateLink = () => {
    generateMeetLinkService
      .getMeetLink(code as string)
      .then(linkToConf => {
        window.location.assign(linkToConf)
      })
      .catch(err => {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: err?.text || `Неверный токен`,
        })
      })
  }

  return (
    <div className={styles.wrapper}>
      <button type="submit" className={styles.button} onClick={generateLink}>
        Подключиться
      </button>
    </div>
  )
}
