export const getWeekDays = t => {
  return [
    `${t('MondayShort')}`,
    `${t('TuesdayShort')}`,
    `${t('WednesdayShort')}`,
    `${t('ThursdayShort')}`,
    `${t('FridayShort')}`,
    `${t('SaturdayShort')}`,
    `${t('SundayShort')}`,
  ]
}

export const getMonthsToRegister = t => {
  return [
    `${t('JanuaryFullDeclination')}`,
    `${t('FebruaryFullDeclination')}`,
    `${t('MarchFullDeclination')}`,
    `${t('AprilFullDeclination')}`,
    `${t('MayFullDeclination')}`,
    `${t('JuneFullDeclination')}`,
    `${t('JulyFullDeclination')}`,
    `${t('AugustFullDeclination')}`,
    `${t('SeptemberFullDeclination')}`,
    `${t('OctoberFullDeclination')}`,
    `${t('NovemberFullDeclination')}`,
    `${t('DecemberFullDeclination')}`,
  ]
}

export const getAllTranslateMonths = t => {
  return [
    `${t('JanuaryFull')}`,
    `${t('FebruaryFull')}`,
    `${t('MarchFull')}`,
    `${t('AprilFull')}`,
    `${t('MayFull')}`,
    `${t('JuneFull')}`,
    `${t('JulyFull')}`,
    `${t('AugustFull')}`,
    `${t('SeptemberFull')}`,
    `${t('OctoberFull')}`,
    `${t('NovemberFull')}`,
    `${t('DecemberFull')}`,
  ]
}

const WEEK_LENGTH = 7

export function getWeeksForMonth(month, year) {
  const firstOfMonth = new Date(year, month, 1)
  const firstDayOfWeek = (firstOfMonth.getDay() + 6) % 7
  const weeks = [[]]

  let currentWeek = weeks[0]
  let currentDate = firstOfMonth

  for (let i = 0; i < firstDayOfWeek; i++) {
    currentWeek.push(null)
  }

  while (currentDate.getMonth() === month) {
    if (currentWeek.length === WEEK_LENGTH) {
      currentWeek = []
      weeks.push(currentWeek)
    }

    currentWeek.push(currentDate)
    currentDate = new Date(year, month, currentDate.getDate() + 1)
  }

  while (currentWeek.length < 7) {
    currentWeek.push(null)
  }

  return weeks
}
