import { Dispatch, SetStateAction } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'

const useSolveTaskCallback = <Task, Loading>(
  task: Task | undefined,
  solveTask: () => Promise<unknown>,
  loadTask: (skipLoading?: boolean) => Promise<unknown>,
  canBeSolved: boolean,
  setLoading: Dispatch<SetStateAction<Partial<Loading>>>
) => {
  const { reactAlert } = useReactAlert()
  return async () => {
    if (!task || !canBeSolved) {
      return Promise.resolve()
    }

    setLoading(loading => ({
      ...loading,
      taskSolving: true,
    }))

    try {
      const solveResult = await solveTask()
      await loadTask(true)
      return solveResult
    } catch (e) {
      reactAlert.error(e)
      return Promise.resolve()
    } finally {
      setLoading(loading => ({
        ...loading,
        taskSolving: false,
      }))
    }
  }
}

export default useSolveTaskCallback
