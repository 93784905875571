import { Dispatch, SetStateAction } from 'react'
import { changeStudentPoints } from '../../../../store/taskSlice'
import { WordTaskDto } from '../../../../model/task-model/WordTaskDto'
import { CodeTaskDto } from '../../../../model/task-model/CodeTaskDto'
import { CodeDirectionTaskDto, WordDirectionTaskDto } from '../../../../model/direction-model'
import { TaskType } from '../../../../model/task-dto/task-type-enum'

interface ReturnValues {
  [TaskType.Lecture]: (isSolved?: boolean, points?: number, dispatch?: Dispatch<any>) => Promise<void>
  [TaskType.Theory]: (
    probablyAnswers: any[],
    previousSolveStatus: boolean,
    solved: boolean,
    points: number,
    testResponse: any,
    setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbability: Dispatch<SetStateAction<Partial<number>>>,
    setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
    setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbablyAnswers: Dispatch<SetStateAction<Partial<any[]>>>,
    dispatch: Dispatch<any>
  ) => void
  [TaskType.Code]: (
    codeTask: any,
    code: string,
    solveResponse: any,
    solved: boolean,
    points: number,
    previousSolveStatus: boolean,
    setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbability: Dispatch<SetStateAction<Partial<number>>>,
    setTestResult: Dispatch<SetStateAction<Partial<string>>>,
    setCode: Dispatch<SetStateAction<Partial<string>>>,
    setIsTestsPassed: Dispatch<SetStateAction<Partial<boolean | null>>>,
    dispatch: Dispatch<any>
  ) => void
  [TaskType.Word]: (
    previousSolveStatus: boolean,
    solveResult: any,
    loadTask: (interceptor?: (value: any) => any) => Promise<WordTaskDto | WordDirectionTaskDto>,
    newAnswer: string,
    setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
    setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
    dispatch: Dispatch<any>
  ) => void
  [TaskType.Gapping]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.Ordering]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.Association]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.MultiAnswer]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.MultiTest]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.MultiInput]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
}

type Values =
  | ReturnValues[TaskType.Lecture]
  | ReturnValues[TaskType.Theory]
  | ReturnValues[TaskType.Word]
  | ReturnValues[TaskType.Code]
  | ReturnValues[TaskType.Gapping]
  | ReturnValues[TaskType.Ordering]
  | ReturnValues[TaskType.Association]
  | ReturnValues[TaskType.MultiAnswer]
  | ReturnValues[TaskType.MultiTest]
  | ReturnValues[TaskType.MultiInput]

export function getPostSolveInterceptorStudent(taskType: TaskType.Lecture): ReturnValues[TaskType.Lecture]
export function getPostSolveInterceptorStudent(taskType: TaskType.Theory): ReturnValues[TaskType.Theory]
export function getPostSolveInterceptorStudent(taskType: TaskType.Word): ReturnValues[TaskType.Word]
export function getPostSolveInterceptorStudent(taskType: TaskType.Code): ReturnValues[TaskType.Code]
export function getPostSolveInterceptorStudent(taskType: TaskType.Gapping): ReturnValues[TaskType.Gapping]
export function getPostSolveInterceptorStudent(taskType: TaskType.Ordering): ReturnValues[TaskType.Ordering]
export function getPostSolveInterceptorStudent(taskType: TaskType.Association): ReturnValues[TaskType.Association]
export function getPostSolveInterceptorStudent(taskType: TaskType.MultiAnswer): ReturnValues[TaskType.MultiAnswer]
export function getPostSolveInterceptorStudent(taskType: TaskType.MultiTest): ReturnValues[TaskType.MultiTest]
export function getPostSolveInterceptorStudent(taskType: TaskType.MultiInput): ReturnValues[TaskType.MultiInput]
export function getPostSolveInterceptorStudent(taskType: keyof ReturnValues): Values {
  switch (taskType) {
    case TaskType.Lecture:
      return async (isSolved: boolean, points: number, dispatch: Dispatch<any>) => {
        if (!isSolved) {
          dispatch(changeStudentPoints({ pointsDelta: points }))
        }
        return Promise.resolve()
      }
    case TaskType.Theory:
      return (
        probablyAnswers: any[],
        previousSolveStatus: boolean,
        solved: boolean,
        points: number,
        testResponse: any,
        setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
        setProbability: Dispatch<SetStateAction<Partial<number>>>,
        setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
        setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
        setProbablyAnswers: Dispatch<SetStateAction<Partial<any[]>>>,
        dispatch: Dispatch<any>
      ) => {
        setReceivedAnswer(true)
        setProbability(Math.random)
        if (!previousSolveStatus && solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: points,
            })
          )
        }
      }
    case TaskType.Code:
      return (
        codeTask: CodeTaskDto | CodeDirectionTaskDto,
        code: string,
        solveResponse: any,
        solved: boolean,
        points: number,
        previousSolveStatus: boolean,
        setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
        setProbability: Dispatch<SetStateAction<Partial<number>>>,
        setTestResult: Dispatch<SetStateAction<Partial<string>>>,
        setCode: Dispatch<SetStateAction<Partial<string>>>,
        setIsTestsPassed: Dispatch<SetStateAction<Partial<boolean | null>>>,
        dispatch: Dispatch<any>
      ) => {
        setReceivedAnswer(true)
        setProbability(Math.random)
        if (!previousSolveStatus && solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: points,
            })
          )
        }
      }
    case TaskType.Word:
      return async (
        previousSolveStatus: boolean,
        solveResult: any,
        loadTask: (interceptor?: (value: any) => any) => Promise<WordTaskDto | WordDirectionTaskDto>,
        newAnswer: string,
        setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
        setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
        dispatch: Dispatch<any>
      ) => {
        const loadedTask = await loadTask()
        setLastActionIsRight(loadedTask.lastActionIsRight)
        setLastActionIsWrong(loadedTask.lastActionIsWrong)
        if (!previousSolveStatus && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
        loadTask()
      }
    case TaskType.Gapping:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.Ordering:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.Association:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.MultiTest:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.MultiAnswer:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.MultiInput:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    default:
      return () => {}
  }
}
