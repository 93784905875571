import { serverHost } from '../config'
import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { GenerateMeetLinkServiceInterface } from './interfaces/generate-meet-link-service'

class GenerateMeetLinkService implements GenerateMeetLinkServiceInterface {
  private readonly URL = `${serverHost}/api/meet/link`

  public getMeetLink = (code: string) => {
    const queryOptions = {
      method: HTTPMethods.GET,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return ApiService.request<string>(`${this.URL}?linkCode=${code}`, queryOptions)
  }
}

export default GenerateMeetLinkService
