import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { parse } from 'date-fns'
import StudentNotificationsService from '../../../../../../services/student-services/student-notifications-service'
import NotificationModalDialog from './notification-modal-dialog'
import { AuthService } from '../../../../../../services/auth-service'
import './notification-block.css'
import { GeneralNotificationDto } from '../../../../../../model/notification-dto/general-notification-dto'

const service = new StudentNotificationsService()

const NotificationBlock = () => {
  const { t } = useTranslation()
  const [notifications, setNotifications] = useState<GeneralNotificationDto[]>([])
  const [openedNotification, setOpenedNotification] = useState<GeneralNotificationDto | null>(null)
  const [showedNotificationBlock, setShowedNotificationBlock] = useState<boolean>(false)
  const REPEAT_REQUEST_AFTER_ONE_MINUTE = 60 * 1000

  const currentUserId = AuthService.currentUserValue()?.id

  const getAllNotifications = (): void => {
    service.getAllNotifications().then(setNotifications)
  }

  useEffect(() => {
    getAllNotifications()
    const interval = setInterval(() => {
      getAllNotifications()
    }, REPEAT_REQUEST_AFTER_ONE_MINUTE)
  }, [])

  const handleClickOutSide = (event: MouseEvent) => {
    const notificationBlock = document.getElementById(`notify-message-wrap`)
    const path = event.composedPath && event.composedPath()

    if (!path.includes(notificationBlock!)) {
      const notifyBtn = document.getElementById('notify-btn')
      const notyWindow = document.getElementById('notification-popup-widow')

      if (!path.includes(notifyBtn!) && !path.includes(notyWindow!)) {
        setShowedNotificationBlock(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClickOutSide)

    return () => {
      window.removeEventListener('click', handleClickOutSide)
    }
  }, [])

  useEffect(() => {
    const importantNoties = notifications
      .filter(noty => !noty.show && noty.important)
      .filter(noty => parse(noty.endTime, 'dd.MM.yyyy HH:mm', new Date()) > new Date())
    if (importantNoties && importantNoties.length > 0) {
      setOpenedNotification(importantNoties[0]!)
    }
  }, [notifications])

  const setOpenedNotificationAsRead = () => {
    if (!openedNotification) return
    const idx = notifications.findIndex(noty => noty.id === openedNotification.id)
    if (idx === -1) return
    setNotifications([
      ...notifications.slice(0, idx),
      {
        ...openedNotification,
        show: true,
      },
      ...notifications.slice(idx + 1),
    ])
    const { id } = openedNotification!
    service.setStudentGeneralNotificationAsRead(id)
    setOpenedNotification(null)
  }

  const isNotificationModalOpen = openedNotification && Object.keys(openedNotification!).length !== 0
  return (
    <div className="notify-wrap">
      <div
        className="notify-btn"
        id="notify-btn"
        onClick={() => {
          setShowedNotificationBlock(!showedNotificationBlock)
        }}
      >
        <i className="mdi mdi-bell" />
        <span className="notify-counter">{notifications.filter(noty => !noty.show).length}</span>
      </div>
      {showedNotificationBlock ? (
        <div id="notify-message-wrap" className="notify-message-wrap">
          {notifications.length === 0 ? (
            <div className="notify-message-head">{t('NoNewNotifications')}</div>
          ) : (
            <div className="notify-message-head">Уведомления</div>
          )}
          <div className="notifications-wrapper">
            {notifications.map(noty => (
              <div className="notification" key={noty.id}>
                <div className="notification__content-wrapper">
                  <span className="notification__icon mdi mdi-school" />
                  <div className="notification__content">
                    <h6
                      className={cn('notification__title', {
                        'notification__title_showed': noty.show,
                      })}
                      onClick={() => setOpenedNotification(noty)}
                    >
                      {noty.title}
                    </h6>
                    <p className="notification__date-time">{noty.dateTime}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {openedNotification && (
        <NotificationModalDialog
          notification={openedNotification}
          isOpen={isNotificationModalOpen}
          onClose={() => setOpenedNotificationAsRead()}
        />
      )}
    </div>
  )
}

export default NotificationBlock
