import React from 'react'
import Editor, { EditorProps, loader } from '@monaco-editor/react'
import Spinner from '../spinner'
import { CodeEditorTheme, useCodeEditorTheme } from '../../hooks/useCodeEditorTheme'

loader.config({ paths: { vs: 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.33.0/min/vs' } })

const CodeEditor = (props: Props) => {
  useCodeEditorTheme(CodeEditorTheme.Tomorrow)

  return (
    <Editor
      {...props}
      width="100%"
      height={500}
      theme="tomorrow"
      loading={<Spinner />}
      options={{
        ...props.options,
        fontSize: 16,
        scrollbar: {
          alwaysConsumeMouseWheel: false,
        },
      }}
    />
  )
}

export default CodeEditor

type Props = EditorProps
