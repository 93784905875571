import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class FakePageService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/fake/protect/user`
  }

  getSingleUser = studentId => {
    const queryOption = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}/singleStudent/${studentId}`, queryOption)
  }

  getStudents = async studentId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/student/${studentId}`, queryOption)
  }
}

export default FakePageService
