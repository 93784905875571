import { serverHost } from '../config'
import { AuthService } from './auth-service'
import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { PreModerCommentsServiceInterface } from './interfaces/premoder-comments-service'

class PreModerCommentsService implements PreModerCommentsServiceInterface {
  private readonly sourceURL = `${serverHost}/api/comment/moderation`

  public submitById(id: number) {
    const queryOption = {
      method: HTTPMethods.PUT,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/${id}`, queryOption)
  }

  public deleteById(id: number) {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/${id}`, queryOption)
  }
}

export default PreModerCommentsService
