import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useReactAlert } from 'src/hooks/useReactAlert'
import ReportFilters from './report-filters'
import ReportList from './report-list'
import StudentReportService from '../../../../services/student-services/student-report-service'
import { StudentReportGetDto } from '../../../../model/student-model'
import { getDayFromMoth, getDayFromRuStringLocal } from './utils/utils'

import styles from './report-page.module.scss'
import ReportCalendar from './report-calendar'
import Spinner from '../../../spinner'
import useSetDocumentTitle from '../../../../hooks/useSetDocumentTitle'

const cx = classNames.bind(styles)

export type FilterCalendar = {
  startPublicDate: string
  endPublicDate: string
  selectedMonthId: number
  selectedYear: number
}
export type TFilterValues = {
  query: string
  isOnlyOwn: boolean
}

interface RefObject<T> {
  current: T
}

export type RefCalendarConfig = RefObject<{
  pointerLeft: number
  pointerRight: number
}>

export type AllReportsByStudents = {
  [key: string]: StudentReportGetDto[]
}

export type StudentReport = {
  [key: string | number]: StudentReportGetDto | null
}

export const MAX_DAYS_PER_VIEW = 5

const ReportPage = () => {
  const { catchErrorAlert } = useReactAlert()
  const { t } = useTranslation()
  useSetDocumentTitle(t('AllReports'))

  const reportService = new StudentReportService()
  const [reports, setReports] = useState<StudentReport[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [filterValues, setFilterValues] = useState<TFilterValues>({
    query: '',
    isOnlyOwn: true,
  })

  const [filterCalendar, setFilterCalendar] = useState<FilterCalendar>(() => {
    const groupNumber = Math.ceil(new Date().getDate() / MAX_DAYS_PER_VIEW)
    const startDay = (groupNumber - 1) * MAX_DAYS_PER_VIEW + 1

    return {
      startPublicDate: getDayFromMoth(startDay, new Date().getMonth()),
      endPublicDate: getDayFromMoth(startDay + MAX_DAYS_PER_VIEW - 1, new Date().getMonth()),
      selectedMonthId: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
    }
  })

  const { query, isOnlyOwn } = filterValues

  const { startPublicDate, endPublicDate, selectedMonthId, selectedYear } = filterCalendar

  const mapDataToAllReports = (studentReports: StudentReportGetDto[]): AllReportsByStudents => {
    return studentReports.reduce((acc: AllReportsByStudents, report) => {
      if (acc[report.studentId]) {
        acc[report.studentId]?.push(report)
      } else {
        acc[report.studentId] = [report]
      }
      return acc
    }, {})
  }

  const mapReportsToCalendarView = (studentReports: StudentReportGetDto[]) => {
    const result: StudentReport = {}
    let leftPointer = Number(filterCalendar.startPublicDate.slice(0, 2))
    const rightPointer = Number(filterCalendar.endPublicDate.slice(0, 2))

    while (leftPointer <= rightPointer) {
      result[leftPointer] = null
      leftPointer += 1
    }

    studentReports.length &&
      studentReports.forEach(item => {
        const reportDay = getDayFromRuStringLocal(item.reportDate)
        if (!reportDay) {
          return
        }
        if (!result[reportDay]) {
          result[reportDay] = null
        }

        result[reportDay] = item
      })
    return result
  }

  const mapAllReportsToCalendarView = (studentReports: AllReportsByStudents): StudentReport[] => {
    return Object.values(studentReports).map((value: StudentReportGetDto[]) => {
      return mapReportsToCalendarView(value)
    })
  }

  const handleReports = (entities: StudentReportGetDto[]): void => {
    const mappedReports = mapAllReportsToCalendarView(mapDataToAllReports(entities))
    setReports(mappedReports)
  }

  const getStudentReports = useCallback(() => {
    setIsLoading(true)
    reportService
      .getStudentReports(isOnlyOwn, query, startPublicDate, endPublicDate)
      .then(({ entities }) => {
        setIsLoading(false)
        handleReports(entities)
      })
      .catch(err => {
        catchErrorAlert(err)
        setIsLoading(false)
      })
  }, [query, isOnlyOwn, startPublicDate, endPublicDate, selectedMonthId])

  useEffect(() => {
    getStudentReports()
  }, [query, isOnlyOwn, startPublicDate, endPublicDate, selectedMonthId])

  return (
    <>
      <div className={cx('report-wrap')}>
        <div className="container">
          <div className={cx('report-block')}>
            <div className={cx('report-filters')}>
              <ReportFilters
                getStudentReports={getStudentReports}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                setFilterCalendar={setFilterCalendar}
                filterCalendar={filterCalendar}
                isLoading={isLoading}
              />
            </div>
            <div className={cx('report-calendar-widget', !filterValues.isOnlyOwn && 'report-calendar-sticky')}>
              <ReportCalendar
                selectedYear={selectedYear}
                setFilterCalendar={setFilterCalendar}
                isLoading={isLoading}
                selectedMonthId={filterCalendar.selectedMonthId}
              />
            </div>
            {isLoading ? (
              <div className={cx('report-loader')}>
                <Spinner />
              </div>
            ) : (
              <div className={cx('report-list')}>
                <ReportList reports={reports} getStudentReports={getStudentReports} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default ReportPage
