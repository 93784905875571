import React from 'react'
import { Animate } from 'react-move'

class AnimatedProgressProvider extends React.Component {
  interval = undefined

  state = {
    isAnimated: false,
  }

  componentDidMount() {
    const { isAnimated } = this.state
    const { repeat, duration } = this.props
    if (repeat) {
      this.interval = window.setInterval(() => {
        this.setState({
          isAnimated: !isAnimated,
        })
      }, duration * 1000)
    } else {
      this.setState({
        isAnimated: !isAnimated,
      })
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  render() {
    const { valueStart, valueEnd, duration, easingFunction, children } = this.props
    const { isAnimated } = this.state

    return (
      <Animate
        start={() => ({
          value: valueStart,
        })}
        update={() => ({
          value: [isAnimated ? valueEnd : valueStart],
          timing: {
            duration: duration * 1000,
            ease: easingFunction,
          },
        })}
      >
        {({ value }) => children(value)}
      </Animate>
    )
  }
}

AnimatedProgressProvider.defaultProps = {
  valueStart: 0,
}

export default AnimatedProgressProvider
