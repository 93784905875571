import { AuthService } from './auth-service'
import { serverHost } from '../config'
import ApiService from './api-service'
import { DirectionTaskHeadingDto, AnswerTypes } from '../model/direction-model'
import { HTTPMethods } from './http-enums-methods'
import { DirectionTasksServiceInterface } from './interfaces/direction-services/direction-task-service'
import { TaskType } from '../model/task-dto/task-type-enum'

class DirectionTasksService implements DirectionTasksServiceInterface {
  private readonly sourceURL = `${serverHost}/api/enrollee`

  public getDirectionIcon = () => {
    // возвращает иконку направления
    const url = `${this.sourceURL}/direction/direction-pic`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    // eslint-disable-next-line no-return-await
    return ApiService.request<string>(url, queryOption)
  }

  public getCourseTask = async (taskId: number, taskType: TaskType) => {
    // возвращает дто задачи
    const url = `${this.sourceURL}/directionTask/${taskId}/${taskType}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    // eslint-disable-next-line no-return-await
    return await ApiService.request(url, queryOption)
  }

  public solveLectureTask = async (taskId: number) => {
    // делает задачу решенной
    const url = `${this.sourceURL}/directionTask/${taskId}/solve-lecture`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    // eslint-disable-next-line no-return-await
    return await ApiService.request(url, queryOption)
  }

  public solveDirectionTask = (taskId: number, answer: AnswerTypes) => {
    // отправка решения задачи направления
    const url = `${this.sourceURL}/directionTask/${taskId}`
    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(answer), // написать боди решения
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(url, queryOption)
  }

  public getTasksHeading = (directionTaskPosition: string) => {
    // возвращает хединги задач
    const url = `${this.sourceURL}/directionTask/heading?directionTaskPosition=${directionTaskPosition}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    // eslint-disable-next-line no-return-await
    return ApiService.request<DirectionTaskHeadingDto>(url, queryOption)
  }

  public resetTask = (taskId: number) => {
    const url = `${this.sourceURL}/directionTask/${taskId}/reset`
    const queryOption = {
      method: HTTPMethods.POST,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(url, queryOption)
  }
}

export default DirectionTasksService
