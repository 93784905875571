import isNullish from '../../../../../../utils/isNullish'
import { OrderingTaskItemDto } from '../../../../../../model/task-model/OrderingTaskDto'

const useCanBeSolved = (taskItems: OrderingTaskItemDto[] | undefined) => {
  if (!taskItems) {
    return false
  }

  return taskItems.every(item => !isNullish(item.position))
}

export default useCanBeSolved
