import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

export interface IFormValues {
  email: string
  firstName: string
  lastName: string
  birthday: string | Date
  password: string
  passwordConfirmation: string
}

export const useEnrolleeSchema = () => {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape<IFormValues>({
    firstName: Yup.string().required(t('registrationEmptyFields')),
    lastName: Yup.string().required(t('registrationEmptyFields')),
    email: Yup.string().required(t('registrationEmptyFields')),
    password: Yup.string()
      .min(6, t('registrationShortPassword'))
      .required(t('registrationEmptyFields')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], t('registrationConfirmPasswordError'))
      .required(t('registrationEmptyFields')),
    birthday: Yup.date().required(t('registrationEmptyFields')),
  })

  return [validationSchema] as const
}
