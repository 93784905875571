import { ModuleViewDto, TaskHeadingDto, AllCoursesView } from '../../model/student-model'
import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import CourseType from '../../components/pages/student/course-type.model'
import { StudentCourseServiceInterface } from '../interfaces/student-services/student-course-service'
import { HTTPMethods } from '../http-enums-methods'

class StudentCourseService implements StudentCourseServiceInterface {
  public readonly courseType: string

  private readonly sourceURL = `${serverHost}/api/student/course`

  constructor(courseType: string = CourseType.Regular) {
    this.courseType = courseType
  }

  public getTasksHeading = (courseId: number, modulePosition?: number, chapterPosition?: number) => {
    let coursePath = `${courseId}/taskHeading`
    if (modulePosition && chapterPosition) {
      coursePath += `?modulePosition=${modulePosition}&chapterPosition=${chapterPosition}`
    }
    const path = this.courseType === CourseType.Additional ? `additional/${coursePath}` : coursePath
    const url = `${this.sourceURL}/${path}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<TaskHeadingDto>(url, queryOption)
  }

  public getLastCourse = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/last-course`, queryOption)
  }

  public getAvailableCourses = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<AllCoursesView[]>(`${this.sourceURL}/all-views`, queryOption)
  }

  public getCourseById = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }

    const coursePath = `views/${courseId}`
    const additionalCoursePath = `additional/${courseId}/views`

    const path = this.courseType === CourseType.Additional ? additionalCoursePath : coursePath

    return ApiService.request<AllCoursesView>(`${this.sourceURL}/${path}`, queryOption)
  }

  public loadEnrollingState = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<boolean>(`${this.sourceURL}/${courseId}/is-enrolling`, queryOption)
  }

  public enrollInCourseById = (courseId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/enroll/${courseId}`, queryOption)
  }

  public loadModuleViewDto = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ModuleViewDto[]>(`${this.sourceURL}/${courseId}/module-dto`, queryOption)
  }

  public getAdditionalCourses = () => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<AllCoursesView[]>(`${this.sourceURL}/additional`, queryOption)
  }
}

export default StudentCourseService
