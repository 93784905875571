import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { store } from 'react-notifications-component'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { AuthService } from '../../../../../services/auth-service'
import ProfileHeader from './edit-profile-header'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate, history } from '../../../../../config'
import UserProfileService from '../../../../../services/common-services/user-profile-service'
import { InactivationReason } from '../../../../../model/inactivation-model'

const service = new UserProfileService()

const EditPasswordPage = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const alert = useAlert()
  const [disabled, setDisabled] = useState('disabled')

  const validate = ({ newPassword, confirmPassword }) => {
    const errors = {}

    if (newPassword !== confirmPassword) {
      errors.passwords = `${t('NewAndConfirmedPasswordsDoNotMatch')}`
      setDisabled('disabled')
    } else {
      setDisabled('')
    }

    return errors
  }

  useEffect(() => {
    document.title = `${t('ChangeYourPassword')}`
    if (state && state.reasonMessage) {
      alert.error(state.reasonMessage)
    }
  }, [])

  return (
    <>
      <ProfileHeader message={t('ChangeYourPassword')} />
      <div className="edit-profile-wrapper">
        <div className="container text-center" style={{ width: '500px' }}>
          <div className="edit-profile-content">
            <div className="edit-password-content">
              <div className="profile-left-wrap">
                <Formik
                  validate={validate}
                  onSubmit={(values, { resetForm }) => {
                    service.setUserNewPassword(values.oldPassword, values.newPassword).then(data => {
                      if (data) {
                        store.addNotification({
                          ...studentSuccessNotyTemplate,
                          message: `${t('YourPasswordHasBeenUpdatedSuccessfully')}`,
                        })
                        resetForm()
                        const { inactivation } = AuthService.currentUserValue()
                        if (inactivation && inactivation.reason === InactivationReason.NeedChangePassword) {
                          AuthService.fetchUserProfile()
                          setTimeout(() => {
                            history.replace('/', { ...state, reasonMessage: null })
                          }, 300)
                        }
                      } else {
                        store.addNotification({
                          ...studentErrorNotyTemplate,
                          message: `${t('PasswordUpdateFailed')}`,
                        })
                      }
                    })
                  }}
                  initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                  }}
                >
                  {({ values, handleChange, handleSubmit, errors }) => (
                    <Form>
                      <div className="col-md-12 form-field">
                        <label htmlFor="oldPassword" className="field-label">
                          {t('OldPassword')}:
                        </label>
                        <input
                          onChange={handleChange}
                          type="password"
                          className="form-control"
                          name="oldPassword"
                          required="required"
                          minLength="6"
                          maxLength="30"
                          value={values.oldPassword || ''}
                        />
                      </div>

                      <div className="col-md-12 form-field">
                        <label className="field-label">{t('NewPassword')}:</label>
                        {errors.passwords && <div style={{ color: '#ff0000' }}>{errors.passwords}</div>}
                        <input
                          onChange={handleChange}
                          type="password"
                          className="form-control"
                          name="newPassword"
                          required="required"
                          minLength="6"
                          maxLength="30"
                          value={values.newPassword || ''}
                        />
                      </div>
                      <div className="col-md-12 form-field">
                        <label className="field-label">{t('PasswordConfirmation')}:</label>
                        <input
                          onChange={handleChange}
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          required="required"
                          minLength="6"
                          maxLength="30"
                          value={values.confirmPassword || ''}
                        />
                      </div>
                      <div className="profile-bottom text-center">
                        <button
                          className="save-changes-btn"
                          type="submit"
                          style={{
                            cursor: 'pointer',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                          }}
                          disabled={disabled}
                          onSubmit={handleSubmit}
                        >
                          {t('SaveChanges')}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditPasswordPage
