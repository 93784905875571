class NumberUtil {
  static getPercentage(number: number, fromNumber: number) {
    if (!number || !fromNumber) {
      return 0
    }

    return Math.floor((100 * number) / fromNumber)
  }
}

export default NumberUtil
