import { StudentGappingAnswerItemDto } from '../../../../../../model/task-model/GappingTaskDto'

const mapGappingValuesToStudentAnswers = (values: Record<number, string>): StudentGappingAnswerItemDto[] => {
  return Object.entries(values).map(([id, value]) => ({
    gappingItemId: +id,
    gappingText: value,
  }))
}

export default mapGappingValuesToStudentAnswers
