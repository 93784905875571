import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { store } from 'react-notifications-component'
import { withLastLocation } from 'react-router-last-location'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { AuthService } from '../../../services/auth-service'
import { linkToMettermostHelpBot, studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../config'
import { RoleEnum } from '../../../utils/select-state/RoleEnum'

import styles from './login.module.scss'

const linkRedirectByRole = {
  [RoleEnum.MENTOR]: '/mentor',
  [RoleEnum.ADMIN]: '/admin',
  [RoleEnum.PAY_STUDENT]: '/user',
  [RoleEnum.CHIEF_MENTOR]: '/chief',
  [RoleEnum.MANAGER]: '/manager',
  [RoleEnum.CHIEF_MANAGER]: '/chief-manager',
}

const notificationByUrlParam = {
  forbidden: 'AccessIsDenied',
  start: 'YouAreSuccessfullyRegistered',
  successRecovery: 'PasswordRecoveredSuccessfully',
  logout: 'YouHaveSuccessfullyLoggedOut',
  invalidRegToken: 'invalidRegistrationToken',
}

const LoginPage = props => {
  const { i18n, t } = useTranslation()
  const [locationHandled, setLocationHandled] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (locationHandled) return
    const urlParams = new URLSearchParams(window.location.search)
    const param = urlParams.get('param')
    if (param) {
      if (param === 'forbidden' || param === 'invalidRegToken') {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: `${t(notificationByUrlParam[param])}`,
        })
      } else {
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `${t(notificationByUrlParam[param])}`,
        })
      }
    }
    setLocationHandled(true)
  }, [locationHandled])
  const validate = ({ email, password }) => {}
  document.title = `${t('Login')}`
  return (
    <div className={styles.wrapper}>
      <img className={styles.logo} src="/images/header-logo.svg" alt="Java Mentor logo" />
      <h1 className={styles.title}>{t('Login')}</h1>
      <Formik
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          username: '',
          password: '',
          rememberMe: false,
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          AuthService.validate(values)
            .then(result => {
              if (result === true) {
                AuthService.login(values).then(
                  () => {
                    setTimeout(() => {
                      const last = props.lastLocation
                      if (
                        !last ||
                        (last.pathname && last.pathname.startsWith('/login')) ||
                        last.pathname.startsWith('/start')
                      ) {
                        props.history.push('/')
                        return
                      }
                      if (last.pathname.startsWith('/user')) {
                        props.history.push(last.pathname + last.search)
                        return
                      }
                      const authUser = AuthService.currentUserValue()
                      if (!last.pathname.startsWith(linkRedirectByRole[authUser.role.name])) {
                        props.history.push('/')
                        return
                      }
                      props.history.push(last.pathname)
                    }, 300)
                  },
                  error => {
                    setSubmitting(false)
                    setStatus(error)
                    store.addNotification({
                      ...studentErrorNotyTemplate,
                      message: `${t('WrongLoginOrPassword')}`,
                    })
                  }
                )
              } else {
                store.addNotification({
                  ...studentErrorNotyTemplate,
                  message: `${t('WrongLoginOrPassword')}`,
                })
              }
            })
            .catch(error => {
              console.error(error)
              const errorKey = ['noLogin', 'noPassword', 'userIsBlocked', 'noSuchUser'].includes(error.text)
                ? error.text
                : 'loginError'
              store.addNotification({
                ...studentErrorNotyTemplate,
                message: `${t(errorKey)}`,
              })
            })
        }}
      >
        {({ values, handleChange, handleSubmit, errors }) => (
          <Form onSubmit={handleSubmit}>
            <label className={styles.label} htmlFor="email">
              Email
            </label>
            <input
              className={styles.input}
              id="email"
              type="email"
              placeholder="Email"
              name="username"
              onChange={handleChange}
              value={values.username}
              required
            />
            <label className={styles.label} htmlFor="password">
              {t('Password')}
            </label>
            <div className={styles.password__wrapper}>
              <input
                className={styles.input}
                id="password"
                type={!showPassword ? 'password' : 'text'}
                placeholder={t('Password')}
                name="password"
                onChange={handleChange}
                value={values.password}
              />
              <button
                type="button"
                className={styles.toggle__password}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <label className={styles.checkbox}>
              <input
                className={styles.checkbox__input}
                type="checkbox"
                name="rememberMe"
                onChange={handleChange}
                value={values.rememberMe}
              />
              {t('RememberMe')}
            </label>
            <button className={styles.button} type="submit">
              {t('SignIn')}
            </button>
            <div className={styles.link__wrapper}>
              <NavLink to={`/recovery-pass?lang=${i18n.language ? i18n.language : 'ru'}`} className={styles.link}>
                {t('RemindPassword')}
              </NavLink>
              <div className={styles.link__wrapper}>
                <a href={linkToMettermostHelpBot} target="_blank" rel="nofollow noreferrer" className={styles.link}>
                  Сообщить о проблеме
                </a>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default withLastLocation(LoginPage)
