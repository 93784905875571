import React, { FC, ComponentType } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { AuthService } from '../../../../../services/auth-service'

type PrivateRouteProps = {
  component: ComponentType<any>
} & RouteProps

const ReportPrivateRoute: FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        AuthService.currentUserValue()?.isViewReport ? <Component {...props} /> : <Redirect to="/user/statistic" />
      }
    />
  )
}

export default ReportPrivateRoute
