import {
  AssociationTaskDto,
  StudentAssociationTaskAnswerItemDto,
} from '../../../../../../model/task-model/AssociationTaskDto'
import { AssociationDirectionTaskDto } from '../../../../../../model/direction-model'

const useCanBeSolved = (
  associationTask: AssociationTaskDto | AssociationDirectionTaskDto | undefined,
  answers: StudentAssociationTaskAnswerItemDto[]
) => {
  if (!associationTask) {
    return false
  }

  return associationTask.terms.length === answers.length && answers.every(answer => answer.definitionId)
}

export default useCanBeSolved
