import { Dispatch, SetStateAction, useEffect } from 'react'
import { MultiInputTaskDto } from '../../../../../../model/task-model/multiInputTaskDto'
import { Answer } from './index'
import { MultiInputDirectionTaskDto } from '../../../../../../model/direction-model'

const useCurrentAnswerSettings = (
  multiInputTask: MultiInputTaskDto | MultiInputDirectionTaskDto | undefined,
  setAnswers: Dispatch<SetStateAction<Answer[]>>,
  answers: Answer[]
) => {
  useEffect(() => {
    if (!multiInputTask) {
      return
    }
    setAnswers(answers.length === 0 ? multiInputTask.studentMultiInputResults : answers)
  }, [multiInputTask])
}

export default useCurrentAnswerSettings
