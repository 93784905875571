import React, { FC } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import './general-error-page.scss'

interface ErrorProps {
  errorStack?: string
  errorMessage: string
}

const GeneralErrorPage: FC<ErrorProps> = ({ errorStack, errorMessage }) => {
  const { t } = useTranslation()
  return (
    <section className="error">
      <div className="middle-header all-courses" style={{ marginTop: 0 }}>
        <div className="container">
          <div className="page-title-wrap">
            <div className="page-title">{t('ErrorPageTitle')}</div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="quote-wrap quote-wrap--error">
          <div className="quote-text">{t('ErrorPageAbout')}</div>
        </div>
        {errorStack && (
          <div className="error-wrapper">
            <h3 className="error-stack--title">{t('ErrorStackTitle')}:</h3>
            <div className="row">
              <pre className="error-stack">
                <p className="error-stack--message">
                  Error Message: <small>{errorMessage}</small>
                </p>
                <code className="error-stack--code">{errorStack}</code>
              </pre>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default withTranslation()(GeneralErrorPage)
