import React from 'react'
import { useTranslation } from 'react-i18next'
import CKEditor from 'ckeditor4-react'
import { MentorCheckTaskAnswerType } from '../../../../../../../model/task-model/MentorCheckTaskDto'

export const AnswerInput = ({ className, value, placeholder, onChange, disabled, type }: Props) => {
  const { t } = useTranslation()

  const HTMLEditor = <CKEditor onChange={e => onChange(e.editor.getData())} data={value} readOnly={disabled} />

  const input = (
    <input
      type="text"
      className={className}
      style={{ color: `black` }}
      name="lesson-answer"
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder ?? t('EnterYourLink')}
      value={value || ''}
      disabled={disabled}
    />
  )

  const answerInput: Record<MentorCheckTaskAnswerType, JSX.Element> = {
    [MentorCheckTaskAnswerType.SingleLineText]: input,
    [MentorCheckTaskAnswerType.StyledText]: HTMLEditor,
    [MentorCheckTaskAnswerType.Link]: input,
    [MentorCheckTaskAnswerType.UniqueLink]: input,
  }

  return answerInput[type] ?? null
}

type Props = {
  className: string
  value: string
  placeholder?: string
  onChange: (value: string) => void
  disabled: boolean
  type: MentorCheckTaskAnswerType
}
