import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import './notification-modal-dialog.css'

const customStyles = {
  content: {
    left: '50%',
    top: '10%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translateX(-50%)',
    width: '560px',
  },
  overlay: {
    backgroundColor: 'rgba(100,100,100,0.75)',
    overflow: 'hidden',
    zIndex: 10,
  },
}

const NotificationModalDialog = ({ isOpen, notification, onClose }) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} style={customStyles}>
        <div id="notification-popup-widow" className="notification-dialog">
          <div className="notification-dialog__title">
            {notification.title}
            <button type="button" className="close-modal-button" onClick={onClose} />
          </div>
          <div className="notification-dialog__content">
            <div
              dangerouslySetInnerHTML={{
                __html: notification.message,
              }}
            />
          </div>
          <div className="content-button-familiarized">
            <span className="familiarized" onClick={onClose}>
              {t('NotificationFamiliarized')}
            </span>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default NotificationModalDialog
