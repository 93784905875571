export enum HttpEnumsStatusCode {
  OK = 200,
  CREATED = 201,
  REDIRECT = 301,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  GONE = 410,
  INTERNAL_SERVER_ERROR = 500,
  SERVER_UNAVAILABLE = 503,
}
