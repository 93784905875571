import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  availableCourseIds: number[]
}

const initialState: State = {
  availableCourseIds: [],
}

export const availableCoursesSlice = createSlice({
  name: 'availableCourses',
  initialState,
  reducers: {
    addAvailableCourseId(state, { payload }: PayloadAction<{ courseId: number }>) {
      state.availableCourseIds.push(payload.courseId)
    },
    clearAvailableCourseIds(state) {
      state.availableCourseIds = []
    },
  },
})

export const { addAvailableCourseId, clearAvailableCourseIds } = availableCoursesSlice.actions
export default availableCoursesSlice.reducer
