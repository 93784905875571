import { Dispatch, SetStateAction, useEffect } from 'react'
import { Question } from './index'
import { MultiAnswerTaskDto } from '../../../../../../model/task-model/MultiAnswerTaskDto'
import { MultiAnswerDirectionTaskDto } from '../../../../../../model/direction-model'

const useCurrentAnswerSetting = (
  multiTestTask: MultiAnswerTaskDto | MultiAnswerDirectionTaskDto | undefined,
  setAnswers: Dispatch<SetStateAction<Question[]>>
) => {
  useEffect(() => {
    if (!multiTestTask) {
      return
    }

    setAnswers(multiTestTask.studentResults)
  }, [multiTestTask])
}

export default useCurrentAnswerSetting
