import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalSolution from '../modal-history-page/modal-solution'
import '../../../student-page.css'

const RowHistorySolution = ({ id, answer, result, date, right, codeLang, type, answerType }) => {
  const { t } = useTranslation()
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <tr>
        <td>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a data-options="touch false" data-fancybox onClick={() => setModalShow(true)}>
            {right ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />}
            <span key={id} className="answer-item" data-id="5">
              {right ? `${t('TheRightSolution')}` : `${t('WrongSolution')}`}
            </span>
          </a>
        </td>
        <td>{date}</td>
      </tr>
      <ModalSolution
        modalShowed={modalShow}
        onClose={() => setModalShow(false)}
        answer={answer}
        result={result}
        right={right}
        codeLang={codeLang}
        type={type}
        answerType={answerType}
      />
    </>
  )
}

export default RowHistorySolution
