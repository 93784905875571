import { Formik } from 'formik'
import { store } from 'react-notifications-component'
import { Col, FormFile } from 'react-bootstrap'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { Styles } from 'react-modal'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../../config'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'
import Spinner from '../../../../spinner'
import UserProfileService from '../../../../../services/common-services/user-profile-service'
import { ProfileDto } from '../../../../../model/user-dto/user-dto'

const userProfileService = new UserProfileService()

type Props = {
  show: boolean
  onClose: () => void
}

const customStyles: Styles = {
  content: {
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    height: 'max-content',
    left: '50%',
    top: '50%',
  },
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(30, 30, 30, 0.54)',
    zIndex: 10,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}

export const ChangeAvatarModal = ({ show, onClose }: Props) => {
  const { t } = useTranslation()
  const [user, setUser] = useState<ProfileDto | null>(null)
  const [uploadedImageURL, setUploadedImageURL] = useState('')

  useEffect(() => {
    userProfileService.getUserPrincipal().then(us => {
      setUser(us)
      if (us.avatarUrl) {
        setUploadedImageURL(convertResponseImageUrl(us.avatarUrl))
      }
    })
  }, [])

  const onSelectNewAvatar = (logoFile: Blob | MediaSource) => {
    const imgUrl = window.URL.createObjectURL(logoFile)
    setUploadedImageURL(imgUrl)
  }

  return (
    <Modal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <div className="solution-popup-head-wrap">
        <div className="solution-popup-head">{t('ChangeAvatar')}</div>
        <div>
          <button
            type="button"
            data-fancybox-close=""
            className="fancybox-close-small"
            title="Close"
            onClick={onClose}
          />
        </div>
      </div>

      {user ? (
        <Formik
          onSubmit={values => {
            const userOnSubmit = {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              birthday: user.birthday,
            }
            userProfileService.updateUserImage(user.id, values.file).then(() => {
              document.getElementById('user_pic')?.setAttribute('src', uploadedImageURL)
            })
            userProfileService
              .updateUser(userOnSubmit)
              .then(() => {
                onClose()
                store.addNotification({
                  ...studentSuccessNotyTemplate,
                  message: `${t('YourDetailsHaveBeenUpdated')}`,
                })
              })
              .catch(() => {
                store.addNotification({
                  ...studentErrorNotyTemplate,
                  message: `${t('ErrorUpdatingData')}`,
                })
              })
          }}
          initialValues={{
            file: '',
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Col>
                <Col>
                  <div className="profile-avatar-wrap profile-avatar-wrap-modal">
                    <div className="profile-avatar-pic">
                      <img
                        id="course_pic"
                        src={uploadedImageURL === '' ? '/images/profile-big-photo.png' : uploadedImageURL}
                        alt={t('UserImage')}
                      />
                    </div>

                    <div>
                      <label
                        style={{
                          display: 'inline-block',
                          cursor: 'pointer',
                        }}
                        htmlFor="course_pic_input"
                        className="change-profile-pic"
                      >
                        {t('ChooseFile')}
                      </label>
                      <FormFile
                        style={{
                          visibility: 'hidden',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '1px',
                        }}
                        id="course_pic_input"
                        name="file"
                        accept="image/gif,image/jpeg,image/png"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const { files } = event.currentTarget
                          if (files && files.length > 0) {
                            const file = files[0]
                            if (file) {
                              setFieldValue('file', file)
                              onSelectNewAvatar(file)
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="profile-bottom">
                    <button
                      className="save-changes-btn save-changes-btn--modal"
                      type="submit"
                      style={{
                        cursor: 'pointer',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                      }}
                    >
                      {t('SaveChanges')}
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          )}
        </Formik>
      ) : (
        <div className="task-loader">
          <Spinner />
        </div>
      )}
    </Modal>
  )
}

export default ChangeAvatarModal
