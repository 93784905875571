import { InviteTokenDto } from 'src/model/invite-dto'
import { serverHost } from '../config'
import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { TokenRegistrationServiceInterface } from './interfaces/token-registration-service'

class TokenRegistrationService implements TokenRegistrationServiceInterface {
  private readonly URL = `${serverHost}/api/registration`

  public getTokenInformation = (hash: string) => {
    const queryOptions = {
      method: HTTPMethods.POST,
      body: hash,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return ApiService.request<InviteTokenDto>(`${this.URL}/token_info`, queryOptions)
  }

  public sendUserDataToTokenRegistration = (data: userData) => {
    const queryOptions = {
      method: HTTPMethods.POST,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return ApiService.request<void>(this.URL, queryOptions)
  }
}

export default TokenRegistrationService

export type userData = {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  day: number | undefined
  month: number | undefined
  year: number | undefined
  hash: string
}
