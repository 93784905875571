import {
  StudentTaskDto,
  StudentReviewDtoRequestBody,
  ReviewEntryDto,
  StudentQuestionDto,
  StudentReviewDto,
  StudentReviewStatus,
} from '../../model/student-model'
import { AuthService } from '../auth-service'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import { StudentReviewServiceInterface } from '../interfaces/student-services/student-review-service'
import { ReviewType } from '../../model/review/review-type'

class StudentReviewService implements StudentReviewServiceInterface {
  sourceURL = `${serverHost}/api/student/review`

  getStudentReviews = async (startDate: string, endDate: string, filter?: StudentReviewStatus | null) => {
    const studentReviewDtoRequestBody: StudentReviewDtoRequestBody = {
      startTime: startDate,
      endTime: endDate,
      reviewStatus: filter,
    }

    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(studentReviewDtoRequestBody),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<StudentReviewDto[]>(`${this.sourceURL}/`, queryOption)
  }

  getAllOpenStudentTaskByStudentReviewId = async (studentReviewId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<StudentTaskDto[]>(`${this.sourceURL}/${studentReviewId}/debt/code`, queryOption)
  }

  getAllTheoryTaskByStudentReview = async (studentReviewId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<StudentQuestionDto[]>(`${this.sourceURL}/${studentReviewId}/debt/theory`, queryOption)
  }

  deleteReviewById = async (studentReviewId: number) => {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/${studentReviewId}`, queryOption)
  }

  getReviewEntryDto = (moduleId: number, reviewType: ReviewType) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ReviewEntryDto>(
      `${this.sourceURL}/module/${moduleId}?reviewType=${reviewType}`,
      queryOption
    )
  }

  deleteStudentFromReviewByReviewId = async (reviewId: number) => {
    const queryOption = {
      method: HTTPMethods.DELETE,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/register/${reviewId}`, queryOption)
  }

  registerStudentOnReviewByReviewId = async (reviewId: number, moduleId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<void>(`${this.sourceURL}/register/${reviewId}?moduleId=${moduleId}`, queryOption)
  }
}

export default StudentReviewService
