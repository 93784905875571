import React, { FC } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import StudentHeader from '../../student/components/header'
import './student-error-page.scss'

const StudentErrorPage: FC<{ hasError?: boolean }> = ({ hasError }) => {
  const { t } = useTranslation()
  const errorBlockStyle = {
    'marginBottom': 'auto',
  }
  return (
    <>
      <StudentHeader hasError={hasError} />
      {/*// @ts-ignore*/}
      <section className="error" style={errorBlockStyle}>
        <div className="middle-header all-courses">
          <div className="container">
            <div className="page-title-wrap">
              <div className="page-title">
                {t('StudentErrorTitle')}
                <img className="page-image" src="/images/studetn-error-icon.svg" alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="quote-wrap quote-wrap--error">
            <div className="quote-text">{t('StudentErrorAbout')}</div>
            <div className="quote-info">{t('StudentSubAbout')}</div>
          </div>
        </div>
      </section>
    </>
  )
}

export default withTranslation()(StudentErrorPage)
