import { serverHost } from '../config'

const convertResponseImageUrl = (imageSrc: string | null): string => {
  if (imageSrc) {
    return serverHost + imageSrc
  }
  return ''
}

export { convertResponseImageUrl }
