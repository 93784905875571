import { Dispatch, SetStateAction } from 'react'
import produce from 'immer'
import { OrderingTaskAnswerDto, OrderingTaskItemDto } from '../../../../../../model/task-model/OrderingTaskDto'

const usePostSolveAnswerSetter = (
  setAnswers: Dispatch<SetStateAction<OrderingTaskAnswerDto[]>>,
  answers: OrderingTaskItemDto[]
) => {
  return (solveResult: any) => {
    if (answers && solveResult) {
      const produced = produce(answers, draft => {
        draft.forEach(answer => {
          answer.right = !!solveResult.right
        })
      })
      // @ts-ignore
      setAnswers(produced)
    }
  }
}

export default usePostSolveAnswerSetter
