import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class FakeStudentReviewService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/fake/protect/student/review`
  }

  getStudentReviews = async (startDate, endDate, filter, studentId) => {
    const studentReviewDtoRequestBody = {
      startTime: startDate,
      endTime: endDate,
      reviewStatus: filter,
    }
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(studentReviewDtoRequestBody),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}?studentId=${studentId}`, queryOption)
  }

  getAllOpenStudentTaskByStudentReviewId = async studentReviewId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${studentReviewId}/debt/code`, queryOption)
  }

  getAllTheoryTaskByStudentReview = async studentReviewId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/${studentReviewId}/debt/theory`, queryOption)
  }
}

export default FakeStudentReviewService
