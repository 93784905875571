import TaskDto from '../task-dto/task-dto'
import { TaskType } from '../task-dto/task-type-enum'

export interface MentorCheckTaskDto extends TaskDto {
  answerId: number
  id: number
  result: string
  studentCourseTaskInfoId: number
  type: TaskType.MentorCheckTask
  resolved: boolean
  solved: boolean
  answerType: MentorCheckTaskAnswerType
}

export enum MentorCheckTaskAnswerType {
  Link = 'LINK',
  UniqueLink = 'UNIQUE_LINK',
  SingleLineText = 'SINGLE_LINE_TEXT',
  StyledText = 'STYLED_TEXT',
}
