import { Dispatch, SetStateAction } from 'react'
import { WordDirectionTaskDto } from 'src/model/direction-model'
import { changeStudentPoints } from '../../../../store/taskSlice'
import { WordTaskDto } from '../../../../model/task-model/WordTaskDto'
import { TaskType } from '../../../../model/task-dto/task-type-enum'

interface ReturnValues {
  [TaskType.Lecture]: (isSolved?: boolean, points?: number, dispatch?: Dispatch<any>) => Promise<void>
  [TaskType.Theory]: (
    probablyAnswers: any[],
    previousSolveStatus: boolean,
    solved: boolean,
    points: number,
    testResponse: any,
    setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbability: Dispatch<SetStateAction<Partial<number>>>,
    setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
    setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbablyAnswers: Dispatch<SetStateAction<Partial<any[]>>>,
    dispatch: Dispatch<any>
  ) => void
  [TaskType.Code]: (
    codeTask: any,
    code: string,
    solveResponse: any,
    solved: boolean,
    points: number,
    previousSolveStatus: boolean,
    setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbability: Dispatch<SetStateAction<Partial<number>>>,
    setTestResult: Dispatch<SetStateAction<Partial<string>>>,
    setCode: Dispatch<SetStateAction<Partial<string>>>,
    setIsTestsPassed: Dispatch<SetStateAction<Partial<boolean | null>>>,
    dispatch: Dispatch<any>
  ) => void
  [TaskType.Word]: (
    previousSolveStatus: boolean,
    solveResult: any,
    loadTask: (interceptor?: (value: any) => any) => Promise<WordTaskDto | WordDirectionTaskDto>,
    newAnswer: string,
    setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
    setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
    dispatch: Dispatch<any>
  ) => void
  [TaskType.MentorCheckTask]: (
    previousSolveStatus: boolean,
    currentSolveStatus: boolean,
    points: number,
    dispatch: Dispatch<any>
  ) => void
  [TaskType.Gapping]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.Ordering]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.Association]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.MultiTest]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.MultiInput]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
  [TaskType.MultiAnswer]: (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => void
}

type Values =
  | ReturnValues[TaskType.Lecture]
  | ReturnValues[TaskType.Theory]
  | ReturnValues[TaskType.Word]
  | ReturnValues[TaskType.Code]
  | ReturnValues[TaskType.MentorCheckTask]
  | ReturnValues[TaskType.Gapping]
  | ReturnValues[TaskType.Ordering]
  | ReturnValues[TaskType.Association]
  | ReturnValues[TaskType.MultiTest]
  | ReturnValues[TaskType.MultiInput]
  | ReturnValues[TaskType.MultiAnswer]

export function getPostSolveInterceptorFake(taskType: TaskType.Lecture): ReturnValues[TaskType.Lecture]
export function getPostSolveInterceptorFake(taskType: TaskType.Theory): ReturnValues[TaskType.Theory]
export function getPostSolveInterceptorFake(taskType: TaskType.Code): ReturnValues[TaskType.Code]
export function getPostSolveInterceptorFake(taskType: TaskType.Word): ReturnValues[TaskType.Word]
export function getPostSolveInterceptorFake(taskType: TaskType.Gapping): ReturnValues[TaskType.Gapping]
export function getPostSolveInterceptorFake(taskType: TaskType.Ordering): ReturnValues[TaskType.Ordering]
export function getPostSolveInterceptorFake(taskType: TaskType.Association): ReturnValues[TaskType.Association]
export function getPostSolveInterceptorFake(taskType: TaskType.MultiTest): ReturnValues[TaskType.MultiTest]
export function getPostSolveInterceptorFake(taskType: TaskType.MultiInput): ReturnValues[TaskType.MultiInput]
export function getPostSolveInterceptorFake(taskType: TaskType.MultiAnswer): ReturnValues[TaskType.MultiAnswer]
export function getPostSolveInterceptorFake(taskType: TaskType.MentorCheckTask): ReturnValues[TaskType.MentorCheckTask]
export function getPostSolveInterceptorFake(taskType: keyof ReturnValues): Values {
  switch (taskType) {
    case TaskType.Lecture:
      return async () => {
        return Promise.resolve()
      }
    case TaskType.Theory:
      return (
        probablyAnswers: any[],
        previousSolveStatus: boolean,
        solved: boolean,
        points: number,
        testResponse: any,
        setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
        setProbability: Dispatch<SetStateAction<Partial<number>>>,
        setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
        setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
        setProbablyAnswers: Dispatch<SetStateAction<Partial<any[]>>>,
        dispatch: Dispatch<any>
      ) => {
        setLastActionIsRight(testResponse.right)
        setLastActionIsWrong(!testResponse.right)
        setProbablyAnswers(probablyAnswers)
      }
    case TaskType.Code:
      return (
        codeTask: any,
        code: string,
        solveResponse: any,
        solved: boolean,
        points: number,
        previousSolveStatus: boolean,
        setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
        setProbability: Dispatch<SetStateAction<Partial<number>>>,
        setTestResult: Dispatch<SetStateAction<Partial<string>>>,
        setCode: Dispatch<SetStateAction<Partial<string>>>,
        setIsTestsPassed: Dispatch<SetStateAction<Partial<boolean | null>>>,
        dispatch: Dispatch<any>
      ) => {
        setTestResult(solveResponse.testResponse.message)
        setCode(code)
        setIsTestsPassed(solveResponse.right)
      }
    case TaskType.Word:
      return async (
        previousSolveStatus: boolean,
        solveResult: any,
        loadTask: (interceptor?: (value: any) => any) => Promise<WordTaskDto | WordDirectionTaskDto>,
        newAnswer: string,
        setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
        setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
        dispatch: Dispatch<any>
      ) => {
        const newVal = await loadTask()
        newVal.answer = newAnswer
        setLastActionIsRight(solveResult.right)
        setLastActionIsWrong(!solveResult.right)
      }
    case TaskType.MentorCheckTask:
      return async (
        previousSolveStatus: boolean,
        currentSolveStatus: boolean,
        points: number,
        dispatch: Dispatch<any>
      ) => {
        if (!previousSolveStatus && currentSolveStatus) {
          dispatch(
            changeStudentPoints({
              pointsDelta: points,
            })
          )
        }
      }
    case TaskType.Gapping:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.Ordering:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.Association:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.MultiTest:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.MultiInput:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    case TaskType.MultiAnswer:
      return async (isSolved: boolean, loadTask: any, dispatch: Dispatch<any>) => {
        const loadedTask = await loadTask()
        if (!isSolved && loadedTask.solved) {
          dispatch(
            changeStudentPoints({
              pointsDelta: loadedTask.taskPoints,
            })
          )
        }
      }
    default:
      return () => {}
  }
}
