import React from 'react'
import { Route, Switch } from 'react-router-dom'
import StudentErrorBoundary from '../student/student-error-boundary'
import DirectionPage from './direction-page/direction-page'
import ContactsPage from '../student/contacts-page'
import LegacyEntityPage from '../student/legacy-entity-page'
import PublicOfferPage from '../student/public-offer-page'
import StudentFooter from '../student/components/footer'
import EnrolleeHeader from './components/enrollee-header/enrollee-header'
import EditPasswordPage from '../student/components/profile/edit-password-page'
import NotFound from '../../not-found'

const EnrolleePage = () => {
  return (
    <>
      <StudentErrorBoundary>
        <EnrolleeHeader />
        <Switch>
          <Route path="/enrollee/password" component={EditPasswordPage} />
          <Route path="/enrollee/contacts" exact component={ContactsPage} />
          <Route path="/enrollee/legal_entity" exact component={LegacyEntityPage} />
          <Route path="/enrollee/public_offer" exact component={PublicOfferPage} />
          <Route path="/enrollee/:directionTaskPosition" exact component={DirectionPage} />
          <Route path="" component={NotFound} />
        </Switch>
      </StudentErrorBoundary>
      <StudentFooter />
    </>
  )
}

export default EnrolleePage
