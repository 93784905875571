import produce from 'immer'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useReactAlert } from 'src/hooks/useReactAlert'
import Spinner from '../../../../../spinner'
import ModalRightAnswer from '../../modalIsRithtAnswer/modal-right-answer'
import TaskDescription from '../task-description'
import { TaskAnswerDto, TheoryTaskDto } from '../../../../../../model/task-model/TheoryTaskDto'
import { TheoryDirectionTaskDto } from '../../../../../../model/direction-model'

const TheoryTask: FC<IProps> = ({
  theoryTask,
  loadTask,
  onSolveTask,
  postSolveInterceptor,
  onResetTask,
  postResetInterceptor,
}) => {
  const { catchErrorAlert } = useReactAlert()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [probablyAnswersInfo, setProbablyAnswersInfo] = useState<IProbablyAnswerInfo[]>([])
  const [solvingTask, setSolvingTask] = useState<boolean>(false)
  const [taskLoaded, setTaskLoaded] = useState<boolean>(true)
  const [receivedAnswer, setReceivedAnswer] = useState<boolean>(false)
  const [probability, setProbability] = useState<number>(0)
  const [lastActionIsRight, setLastActionIsRight] = useState<boolean>(false)
  const [lastActionIsWrong, setLastActionIsWrong] = useState<boolean>(false)

  const loadTheoryTask = (interceptor?: typeof postResetInterceptor) => {
    return loadTask(interceptor).then(task => {
      if (task !== undefined && task !== null) {
        setTaskLoaded(false)
        setLastActionIsRight(task.lastActionIsRight)
        setLastActionIsWrong(task.lastActionIsWrong)
      }
      if (solvingTask) {
        setSolvingTask(false)
      }
      return task
    })
  }

  useEffect(() => {
    if (theoryTask) {
      setTaskLoaded(false)
      setLastActionIsRight(theoryTask.lastActionIsRight)
      setLastActionIsWrong(theoryTask.lastActionIsWrong)
    }
  }, [theoryTask])

  const onClose = () => {
    setReceivedAnswer(false)
    setSolvingTask(false)
  }

  const createProbablyAnswersInfo = () => {
    const { probablyAnswers, resolved, studentAnswers } = theoryTask
    const answersIds = studentAnswers.map(({ id }) => id)
    const newProbablyAnswersInfo = probablyAnswers.map(probablyAnswer => {
      return {
        ...probablyAnswer,
        checked: answersIds.includes(probablyAnswer.id) && !resolved,
      }
    })
    setProbablyAnswersInfo(newProbablyAnswersInfo)
  }

  useEffect(() => {
    if (theoryTask !== null && theoryTask !== undefined) {
      createProbablyAnswersInfo()
    }
  }, [theoryTask])

  const solveTheoryTask = () => {
    const answersIds: number[] = []

    probablyAnswersInfo.forEach(answer => {
      const { id, checked } = answer
      if (checked) {
        answersIds.push(id)
      }
    })

    const { solved: previousSolveStatus } = theoryTask

    let solveResponse: any
    onSolveTask(answersIds)
      .then((val: any) => {
        solveResponse = val
      })
      .then(() => loadTheoryTask())
      .then((loadedValue: any) => {
        postSolveInterceptor(
          probablyAnswersInfo,
          previousSolveStatus,
          loadedValue.solved,
          loadedValue.taskPoints,
          solveResponse,
          setReceivedAnswer,
          setProbability,
          setLastActionIsRight,
          setLastActionIsWrong,
          setProbablyAnswersInfo,
          dispatch
        )
      })
      .catch((error: any) => catchErrorAlert(error))
  }

  useEffect(() => {
    if (solvingTask) {
      solveTheoryTask()
    }
  }, [solvingTask])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e
    const id = target.value
    const probablyAnswerInfoIdx = probablyAnswersInfo.findIndex(element => element.id === +id)
    const probablyAnswerInfo = probablyAnswersInfo[probablyAnswerInfoIdx]
    const newProbablyAnswersInfo = [
      ...probablyAnswersInfo.slice(0, probablyAnswerInfoIdx),
      {
        ...probablyAnswerInfo!,
        checked: target.checked,
      },
      ...probablyAnswersInfo.slice(probablyAnswerInfoIdx + 1),
    ]
    setProbablyAnswersInfo(newProbablyAnswersInfo)
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e
    const id = target.value
    const newProbablyAnswers = produce(probablyAnswersInfo, draft => {
      const oldAnswer = draft.find(el => el.checked === true)
      const newAnswer = draft.find(el => el.id === +id)

      if (oldAnswer) {
        oldAnswer.checked = false
      }
      if (newAnswer) {
        newAnswer.checked = true
      }
    })

    setProbablyAnswersInfo(newProbablyAnswers)
  }

  const resetTask = () => {
    onResetTask()
      .then(() => {
        return loadTheoryTask(postResetInterceptor)
      })
      .then(() => {
        // eslint-disable-next-line array-callback-return
        probablyAnswersInfo.map(answer => {
          answer.checked = false
        })
        setProbablyAnswersInfo(probablyAnswersInfo)
      })
      .catch(err => catchErrorAlert(err))
  }

  if (taskLoaded || theoryTask === null || theoryTask === undefined) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const { description, multipleAnswer, resolved } = theoryTask
  const disabled = probablyAnswersInfo.findIndex(element => element.checked === true) === -1

  let className = lastActionIsRight ? 'lesson-result success' : 'lesson-result error'
  let message = lastActionIsRight ? `${t('TheRightSolution')}` : `${t('InvalidSolutionCheckAgain')}`
  let icon: JSX.Element | null = lastActionIsRight ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />

  if (!lastActionIsRight && !lastActionIsWrong) {
    className = `lesson-result`
    message = ``
    icon = null
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('ChooseTheCorrectAnswer')}</div>
      <TaskDescription description={description} />
      <form action="#" className="radio-selects">
        <div className="radio-selects-items">
          {probablyAnswersInfo.map(info => {
            const classToElement = multipleAnswer ? 'multy' : 'checkmark'
            const inputType = multipleAnswer ? 'checkbox' : 'radio'
            return (
              <label key={`probably_answer_${info.id}`}>
                <input
                  type={inputType}
                  checked={info.checked}
                  className="myCheckBox"
                  name="lesson-radio"
                  disabled={lastActionIsRight && !resolved}
                  onChange={multipleAnswer ? handleChange : handleRadioChange}
                  value={info.id}
                />
                <span dangerouslySetInnerHTML={{ __html: info.text }} />
                <span className={classToElement} />
              </label>
            )
          })}
        </div>
        <div className="lesson-result-row">
          {resolved || solvingTask ? null : (
            <div className={className}>
              {icon}
              <span>{message}</span>
            </div>
          )}
          <div className="lesson-result-right-wrap">
            <button type="button" style={{ cursor: 'pointer' }} className="reset-value-btn" onClick={() => resetTask()}>
              {t('ResetSolution')}
            </button>
            {solvingTask ? (
              <button type="button" className="send-result-btn" disabled>
                {t('SubmittingSolution')}
              </button>
            ) : (
              <button
                type="button"
                className="send-result-btn"
                disabled={disabled}
                onClick={() => setSolvingTask(true)}
              >
                {t('SubmitSolution')}
              </button>
            )}
          </div>
        </div>
      </form>
      <ModalRightAnswer
        modalShowed={receivedAnswer && theoryTask.lastActionIsRight && probability < 0.2}
        onClose={onClose}
      />
    </div>
  )
}

export default TheoryTask

interface IProps {
  theoryTask: TheoryTaskDto | TheoryDirectionTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<TheoryTaskDto | TheoryDirectionTaskDto>
  onSolveTask: any
  postSolveInterceptor: (
    probablyAnswers: any[],
    previousSolveStatus: boolean,
    solved: boolean,
    points: number,
    testResponse: any,
    setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbability: Dispatch<SetStateAction<Partial<number>>>,
    setLastActionIsRight: Dispatch<SetStateAction<Partial<boolean>>>,
    setLastActionIsWrong: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbablyAnswers: Dispatch<SetStateAction<Partial<any[]>>>,
    dispatch: Dispatch<any>
  ) => void
  onResetTask: () => Promise<void>
  postResetInterceptor: (val: any) => any
}

interface IProbablyAnswerInfo extends TaskAnswerDto {
  checked: boolean
}
