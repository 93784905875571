import DirectionTasksService from '../../../services/direction-task-service'
import {
  AssociationTaskAnswer,
  GappingAnswer,
  MultiAnswerStudentAnswer,
  MultiInputStudentAnswer,
  MultiTestStudentAnswer,
  OrderingTaskAnswerItem,
} from '../../../model/direction-model'
import { TaskType } from '../../../model/task-dto/task-type-enum'

const directionTaskService = new DirectionTasksService()

interface ReturnValues {
  [TaskType.Theory]: (answerIds: number[]) => Promise<any>
  [TaskType.Lecture]: () => Promise<any>
  [TaskType.Word]: (answer: string) => Promise<any>
  [TaskType.Code]: (solution: string) => Promise<any>
  [TaskType.Association]: (answerIds: AssociationTaskAnswer[]) => Promise<any>
  [TaskType.Ordering]: (answerItemDtos: OrderingTaskAnswerItem[]) => Promise<any>
  [TaskType.Gapping]: (studentAnswers: GappingAnswer[]) => Promise<any>
  [TaskType.MultiTest]: (studentAnswers: MultiTestStudentAnswer[]) => Promise<any>
  [TaskType.MultiAnswer]: (answerIds: MultiAnswerStudentAnswer[]) => Promise<any>
  [TaskType.MultiInput]: (studentAnswers: { answerText: string; itemId: number }[]) => Promise<any>
}

type Values =
  | ReturnValues[TaskType.MultiInput]
  | ReturnValues[TaskType.Association]
  | ReturnValues[TaskType.Gapping]
  | ReturnValues[TaskType.Ordering]
  | ReturnValues[TaskType.MultiTest]
  | ReturnValues[TaskType.MultiAnswer]
  | ReturnValues[TaskType.Word]
  | ReturnValues[TaskType.Code]
  | ReturnValues[TaskType.Lecture]
  | ReturnValues[TaskType.Theory]

export function getSolveTaskCallback(taskType: TaskType.Theory, taskId: number): ReturnValues[TaskType.Theory]
export function getSolveTaskCallback(taskType: TaskType.Word, taskId: number): ReturnValues[TaskType.Word]
export function getSolveTaskCallback(taskType: TaskType.Code, taskId: number): ReturnValues[TaskType.Code]
export function getSolveTaskCallback(taskType: TaskType.Association, taskId: number): ReturnValues[TaskType.Association]
export function getSolveTaskCallback(taskType: TaskType.Ordering, taskId: number): ReturnValues[TaskType.Ordering]
export function getSolveTaskCallback(taskType: TaskType.Gapping, taskId: number): ReturnValues[TaskType.Gapping]
export function getSolveTaskCallback(taskType: TaskType.MultiTest, taskId: number): ReturnValues[TaskType.MultiTest]
export function getSolveTaskCallback(taskType: TaskType.MultiAnswer, taskId: number): ReturnValues[TaskType.MultiAnswer]
export function getSolveTaskCallback(taskType: TaskType.MultiInput, taskId: number): ReturnValues[TaskType.MultiInput]
export function getSolveTaskCallback(taskType: TaskType.Lecture, taskId: number): ReturnValues[TaskType.Lecture]
export function getSolveTaskCallback(taskType: keyof ReturnValues, taskId: number): Values {
  switch (taskType) {
    case TaskType.Theory:
      return (answerIds: number[]) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          answerIds,
        })
      }
    case TaskType.Word:
      return (answer: string) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          answer,
        })
      }
    case TaskType.Code:
      return (solution: string) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          solution,
        })
      }
    case TaskType.Association:
      return (studentAnswers: AssociationTaskAnswer[]) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          studentAnswers,
        })
      }
    case TaskType.Ordering:
      return (answerItemDtos: OrderingTaskAnswerItem[]) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          answerItemDtos,
        })
      }
    case TaskType.Gapping:
      return (studentAnswers: GappingAnswer[]) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          studentAnswers,
        })
      }
    case TaskType.MultiTest:
      return (studentAnswers: MultiTestStudentAnswer[]) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          studentAnswers,
        })
      }
    case TaskType.MultiAnswer:
      return (studentAnswers: MultiAnswerStudentAnswer[]) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          studentAnswers,
        })
      }
    case TaskType.MultiInput:
      return (studentAnswers: { answerText: string; itemId: number }[]) => {
        return directionTaskService.solveDirectionTask(taskId, {
          taskType,
          studentAnswers: studentAnswers.map(({ answerText, itemId }) => {
            return { answerText, multiInputItemId: itemId }
          }),
        })
      }
    case TaskType.Lecture:
      return () => {
        return directionTaskService.solveLectureTask(taskId)
      }
    default:
      // @ts-ignore
      return () => {}
  }
}
