import { OrderingTaskItemDto } from '../../../model/task-model/OrderingTaskDto'
import { AssociationTaskStudentAnswerDto } from '../../../model/task-model/AssociationTaskDto'
import {
  AbstractStudentCourseTaskAnswerDto,
  StudentAssociationTaskResultDto,
  StudentGappingResultDto,
  StudentMultiAnswerTaskItemResultDto,
  StudentMultiInputResultDto,
  StudentMultiTestItemResultDto,
} from '../../../model/student-model'
import { MultiInputTaskStudentAnswerDto } from '../../../model/task-model/multiInputTaskDto'
import { MultiTestTaskStudentAnswerDto } from '../../../model/task-model/MultiTestTaskDto'
import { MultiAnswerTaskStudentAnswerDto } from '../../../model/task-model/MultiAnswerTaskDto'
import { GappingTaskStudentAnswerDto } from '../../../model/task-model/GappingTaskDto'
import { TaskType } from '../../../model/task-dto/task-type-enum'
import TaskDto from '../../../model/task-dto/task-dto'

export enum FilterResult {
  All = 'ALL',
}

export enum FilterDate {
  New = 'NEW',
}

export interface StudentCourseTaskServiceInterface {
  getCourseTask(courseTaskId: number, type: TaskType): Promise<TaskDto>
  getStudentCourseTaskIdByCourseTaskId(courseTaskId: number): Promise<number>
}

export interface TasksServiceInterface {
  solveCodeCourseTask(studentCourseTaskId: number, solution: string): Promise<string>
  solveTheoryTask(studentCourseTaskId: number, answerIds: number[]): Promise<string>
  solveWordTask(studentCourseTaskId: number, answer: string): Promise<string>
  solveOrderingTask(studentCourseTaskId: number, answers: OrderingTaskItemDto[] | undefined): Promise<string>
  solveAssociationTask(
    studentCourseTaskId: number,
    answers: AssociationTaskStudentAnswerDto
  ): Promise<StudentAssociationTaskResultDto[]>
  solveMultiInputTask(
    studentCourseTaskId: number,
    answers: MultiInputTaskStudentAnswerDto | undefined
  ): Promise<StudentMultiInputResultDto[]>
  solveMultiTestTask(
    studentCourseTaskId: number,
    answers: MultiTestTaskStudentAnswerDto | undefined
  ): Promise<StudentMultiTestItemResultDto[]>
  solveMultiAnswerTask(
    studentCourseTaskId: number,
    answers: MultiAnswerTaskStudentAnswerDto | undefined
  ): Promise<StudentMultiAnswerTaskItemResultDto[]>
  solveGappingTask(
    studentCourseTaskId: number,
    answers: GappingTaskStudentAnswerDto
  ): Promise<StudentGappingResultDto[]>
  solveLectureTask(studentCourseTaskId: number): Promise<void>
  resetTask(studentCourseTaskId: number): Promise<void>
}

export interface StudentCourseTaskInfoServiceInterface extends TasksServiceInterface {
  getUsersTaskSolutions(
    studentCourseTaskId: number,
    filterResult: FilterResult,
    filterDate: FilterDate
  ): Promise<AbstractStudentCourseTaskAnswerDto>
  sendToCheck(studentCourseTaskId: number, answer: string): Promise<string>
}
