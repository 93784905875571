import { Dispatch, SetStateAction } from 'react'
import produce from 'immer'
import { Answer } from './index'

export interface AssociationTaskSolveResult {
  termId: number
  term: string
  definitionId: number
  definition: string
  isRight: boolean
}

const usePostSolveAnswerSetterCallback = (setAnswers: Dispatch<SetStateAction<Answer[]>>, answers: Answer[]) => {
  return (solveResult: AssociationTaskSolveResult[]) => {
    const produced = produce(answers, draft => {
      solveResult.forEach(() => {
        draft.forEach(answer => {
          answer.isRight = solveResult.find(
            (result: AssociationTaskSolveResult) => result.definitionId === answer.definitionId
          )?.isRight
        })
      })
    })
    setAnswers(produced)
    return solveResult
  }
}

export default usePostSolveAnswerSetterCallback
