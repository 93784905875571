import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { store } from 'react-notifications-component'
import { Button } from 'react-bootstrap'
import CKEditor, { EditorChangeEvent } from 'ckeditor4-react'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { MentorCheckTaskAnswerType, MentorCheckTaskDto } from '../../../../../../model/task-model/MentorCheckTaskDto'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../../../config'
import Spinner from '../../../../../spinner'
import TaskDescription from '../task-description'
import FakePageCheckPredProjectModal from '../../../../fake-page/fake-page-check-predproject-modal/fake-page-check-predproject-modal'
import { Answer } from './answer'
import { SafetyLocalStorage } from '../../../../../../storage'

interface IProps {
  handleUpdate: () => void
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<MentorCheckTaskDto>
  mentorCheckTask: MentorCheckTaskDto
  onCheckTask?: any // используется для фейковой страницы, внутри фейковый сервис - не типизирован
  isPermittedToCheckCallback: () => boolean
  isAnswerAvailable: boolean
  solutionsLink: string
  role: RoleEnum
}

const CheckTask: FC<IProps> = ({
  handleUpdate,
  loadTask,
  mentorCheckTask,
  onCheckTask,
  isPermittedToCheckCallback,
  isAnswerAvailable,
  solutionsLink,
  role,
}) => {
  // eslint-disable-next-line id-length
  const { t } = useTranslation()
  const [newAnswer, setNewAnswer] = useState<string>(``)
  const [solvingTask, setSolvingTask] = useState<boolean>(false)
  const [taskLoaded, setTaskLoaded] = useState<boolean>(true)
  const [mentorComment, setMentorComment] = useState<string>('')
  const [lastActionIsRight, setLastActionIsRight] = useState<boolean>(false)
  const [right, setRight] = useState<boolean>(false)
  const [lastAnswerId, setLastAnswerId] = useState<number>()
  const [modalShowed, setModalShowed] = useState<boolean>(false)

  const { pathname, search } = useLocation()

  const MENTOR_COMMENT_KEY = `MENTOR_COMMENT-${pathname}${search}`

  const loadMentorCheckTask = (interceptor?: any) => {
    return loadTask(interceptor).then(task => {
      if (task !== undefined && task !== null) {
        setTaskLoaded(false)
        setLastActionIsRight(task.lastActionIsRight)
        setLastAnswerId(task.answerId)
      }
      if (solvingTask) {
        setSolvingTask(false)
      }
      return task
    })
  }

  useEffect(() => {
    if (mentorCheckTask !== undefined && mentorCheckTask !== null) {
      setTaskLoaded(false)
      setLastActionIsRight(mentorCheckTask.lastActionIsRight)
      setLastAnswerId(mentorCheckTask.answerId)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    const storedMentorComment = SafetyLocalStorage.getItem(MENTOR_COMMENT_KEY)
    if (storedMentorComment) {
      setMentorComment(storedMentorComment)
    }
  }, [])

  const updateComments = ({ editor }: EditorChangeEvent) => {
    const changedMentorComment = editor.getData()
    SafetyLocalStorage.setItem(MENTOR_COMMENT_KEY, changedMentorComment)
    setMentorComment(changedMentorComment)
  }

  const sendSolution = () => {
    onCheckTask(mentorComment, lastAnswerId, right)
      .then(() => {
        setModalShowed(false)
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `Результат проверки успешно сохранен`,
        })
        SafetyLocalStorage.removeItem(MENTOR_COMMENT_KEY)
        loadMentorCheckTask()
        handleUpdate()
      })
      .catch(() => {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: `Во время сохранения результата проверки произошла ошибка`,
        })
        loadMentorCheckTask()
      })
  }

  useEffect(() => {
    if (mentorCheckTask) {
      const { answer, resolved } = mentorCheckTask
      const chooseAnswer = resolved ? `` : answer || ``
      setNewAnswer(chooseAnswer)
    }
  }, [mentorCheckTask])

  useEffect(() => {
    if (mentorCheckTask) {
      if (solvingTask) {
        setSolvingTask(false)
      }
      const { answerId, result } = mentorCheckTask
      setTaskLoaded(false)
      const storedMentorComment = SafetyLocalStorage.getItem(MENTOR_COMMENT_KEY)
      if (result) {
        setMentorComment(result)
      } else if (storedMentorComment) {
        setMentorComment(storedMentorComment)
      }
      setLastAnswerId(answerId)
    }
  }, [mentorCheckTask])

  if (taskLoaded || mentorCheckTask === null) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const onClose = () => {
    setModalShowed(false)
  }

  const canBeChecked = isPermittedToCheckCallback()

  const isLinkAnswer = [MentorCheckTaskAnswerType.Link, MentorCheckTaskAnswerType.UniqueLink].includes(
    mentorCheckTask.answerType
  )

  return (
    <div className="task-content">
      <div className="step-content-head">{t('TheTaskVerifiedByTheMentor')}</div>
      {mentorCheckTask && mentorCheckTask.description && <TaskDescription description={mentorCheckTask.description} />}
      {newAnswer && (
        <>
          {isAnswerAvailable && (
            <div style={{ marginBottom: '32px' }}>
              <label htmlFor="mentor-link">{isLinkAnswer ? 'Ссылка на репозиторий:' : 'Ответ:'}</label>
              <div className="fake-page-mentor-link">
                <Answer value={newAnswer} type={mentorCheckTask.answerType} />
              </div>
            </div>
          )}
          {role !== RoleEnum.PAY_STUDENT && mentorCheckTask.prevResult && (
            <div className="compile-result">
              <div className="compile-title-wrap">
                <div className="compile-title">Предыдущий результат проверки:</div>
              </div>
              <div className="answer-result">
                <TaskDescription description={mentorCheckTask.prevResult} isMentorAnswer />
              </div>
            </div>
          )}
          <div className="mentor-check-form">
            {canBeChecked && (
              <>
                <CKEditor name="description" onChange={updateComments} data={mentorComment} />

                <div className="right">
                  <Button className="mt-2" variant="success" onClick={() => setModalShowed(true)} size="lg">
                    Сохранить результат проверки
                  </Button>
                </div>
              </>
            )}
          </div>
          {isAnswerAvailable && (
            <div className="allComments">
              <Link to={solutionsLink} className="history-link">
                {t('AllComments')}
              </Link>
            </div>
          )}
          {canBeChecked && (
            <FakePageCheckPredProjectModal
              modalShowed={modalShowed}
              onClose={onClose}
              sendSolution={sendSolution}
              right={right}
              setRight={setRight}
              lastActionIsRight={lastActionIsRight}
            />
          )}
        </>
      )}
      {canBeChecked && !newAnswer && <div className="no-solution-history">Не было попыток решить задачу</div>}
    </div>
  )
}

export default CheckTask
