import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const LegacyEntityPage = () => {
  const { t } = useTranslation()

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop()
  }, [])

  document.title = `${t('LegalEntity')}`
  return (
    <div className="step-content-wrap">
      <div className="container">
        <div className="info-jm-block">
          <div className="info-jm-content">
            <h3>Юридическое лицо:</h3>
            <div>
              <p>Индивидуальный предприниматель Севостьянов Герман Дмитриевич</p>
              <p>ИНН780102722502</p>
              <p>Счёт (₽) 40802810703500007657</p>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <p>Банк получателя ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"</p>
              <p>Город МОСКВА</p>
              <p>БИК 044525999</p>
              <p>Корр. счёт 3010181084525000099</p>

              <h3>{t('Contacts')}:</h3>
              <p>info@kata.academy</p>

              <p>8-800-350-32-96</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegacyEntityPage
