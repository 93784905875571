import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class FakeProtectEnrollService {
  private readonly sourceURL = `${serverHost}/api/fake/protect`

  public enrollStudentCourse = (courseId: number, studentId: number) => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/enroll/course/${courseId}/${studentId}`
    return ApiService.request<void>(url, queryOption)
  }

  public enrollStudentModule = (courseId: number, moduleId: number, studentId: number) => {
    const queryOption = {
      method: 'POST',
      headers: AuthService.authHeaders(),
    }
    const url = `${this.sourceURL}/enroll/module/${courseId}/${moduleId}/${studentId}`
    return ApiService.request<void>(url, queryOption)
  }
}

export default FakeProtectEnrollService
