import cn from 'classnames'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Result: FC<Props> = ({ isSolved, isSolvedRight, customErrorText }) => {
  const { t } = useTranslation()

  return isSolved ? (
    <div
      className={cn('lesson-result', {
        success: isSolvedRight,
        error: !isSolvedRight,
      })}
    >
      {isSolvedRight ? (
        <>
          <i className="mdi mdi-check" />
          <span>{t('TheRightSolution')}</span>
        </>
      ) : (
        <>
          <i className="mdi mdi-close" />
          <span>{customErrorText || t('InvalidSolutionCheckAgain')}</span>
        </>
      )}
    </div>
  ) : null
}

interface Props {
  isSolved: boolean
  isSolvedRight: boolean
  customErrorText?: string
}

export default Result
