import 'draft-js-emoji-plugin/lib/plugin.css'
import React, { Component } from 'react'
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor'
import { EditorState } from 'draft-js'
import createEmojiPlugin from 'draft-js-emoji-plugin'
import editorStyles from './editor-styles.css'

const emojiPlugin = createEmojiPlugin()
const { EmojiSuggestions, EmojiSelect } = emojiPlugin
const plugins = [emojiPlugin]

class DraftEditor extends Component {
  unmount = false

  state = {
    editorState: EditorState.createEmpty(),
  }

  componentDidMount() {
    const { value } = this.props
    if (value !== '') {
      this.setState({
        editorState: createEditorStateWithText(value),
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value } = this.props
    const { editorState } = this.state
    if (value !== prevProps.value && editorState.getCurrentContent().getPlainText() !== value) {
      this.setState({
        editorState: createEditorStateWithText(value),
      })
    }
  }

  componentWillUnmount() {
    this.unmount = true
  }

  onChangeState = editorState => {
    if (this.unmount) {
      return
    }
    const { onChange } = this.props
    this.setState(
      {
        editorState,
      },
      () => {
        const { editorState: newState } = this.state
        const plainText = newState.getCurrentContent().getPlainText()
        onChange(plainText)
      }
    )
  }

  focus = () => {
    this.editor.focus()
  }

  render() {
    const { editorState } = this.state
    return (
      <div>
        <div className={editorStyles.editor} onClick={this.focus}>
          <Editor
            editorState={editorState}
            onChange={this.onChangeState}
            plugins={plugins}
            ref={element => {
              this.editor = element
            }}
          />
          <EmojiSuggestions />
        </div>
        <div className={editorStyles.options}>
          <EmojiSelect />
        </div>
      </div>
    )
  }
}

export default DraftEditor
