import React from 'react'
import './list-review-student.css'
import { useTranslation } from 'react-i18next'
import RowReviewStudent from './row-review-student'

const ListReviewStudent = function ListReviewStudent({ entities, onSelectReviewDetails, onSelectReviewFuture }) {
  const { t } = useTranslation()
  const entitiesRender = entities.map(entity => {
    return (
      <RowReviewStudent
        key={entity.studentReviewId}
        entity={entity}
        onSelectReviewConfirm={studentReviewConfirm => onSelectReviewFuture(studentReviewConfirm)}
        onSelectReviewDetails={studentReviewDetail => onSelectReviewDetails(studentReviewDetail)}
      />
    )
  })
  return (
    <>
      <div className="student-review">
        <table className="table-review-style">
          <colgroup>
            <col className="module-title-col" />
            <col className="date-col" />
          </colgroup>
          <thead>
            <tr className="table-row-review">
              <th className="table-header-review">{t('ModuleName')}</th>
              <th className="table-header-review">{t('TimeSpending')}</th>
              <th className="table-header-review">Статус</th>
              <th className="table-header-review">Ссылка</th>
            </tr>
          </thead>
          <tbody className="tbody-review">{entitiesRender}</tbody>
        </table>
      </div>
    </>
  )
}
export default ListReviewStudent
