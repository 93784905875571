import React from 'react'
import Modal from 'react-modal'
import './statistic-modal.css'

function StatisticModal({ topics, modalShowed, onClose }) {
  const customStyles = {
    content: {
      left: '50%',
      top: 0,
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translateX(-50%)',
      padding: 0,
      margin: '44px 0',
    },
    overlay: {
      backgroundColor: 'rgba(46, 43, 43, 0.75)',
      overflow: 'scroll',
      zIndex: 10,
    },
  }

  return (
    <>
      <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
        <div className="stat-courses-popup popup-window">
          <div className="popup-head">
            Пройденные темы
            <button type="button" className="close-modal-button" onClick={onClose} />
          </div>
          <div className="courses-popup-wrap">
            <ul
              style={{
                listStyleType: 'none',
                margin: 0,
                padding: 0,
                textAlign: 'center',
                color: '#404040',
                fontSize: '20px',
                fontWeight: 700,
              }}
            >
              {/* eslint-disable-next-line react/no-array-index-key */}
              {topics && topics.map((topic, idx) => <li key={`topic_${idx}`}>{topic}</li>)}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StatisticModal
