import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'

class FakeProtectedStudentCourseTaskService {
  protectedURL

  constructor() {
    this.protectedURL = `${serverHost}/api/fake/protect/student/task`
  }

  getReferenceSolution = courseTaskId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.protectedURL}/referenceSolution?courseTaskId=${courseTaskId}`, queryOption)
  }
}

export default FakeProtectedStudentCourseTaskService
