import React from 'react'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import TokenizedPasswordRecovery from './tokenized-password-recovery'
import FormPasswordRecovery from './form-password-recovery'

const PasswordRecoveryContainer = props => {
  const { catchErrorAlert } = useReactAlert()
  const { location } = props
  const params = queryString.parse(location.search)
  const { t, i18n } = useTranslation()

  i18n.changeLanguage(params.lang).catch(error => {
    catchErrorAlert(error)
  })

  document.title = `${t('passwordRecovery')}`
  if (params.token !== undefined) {
    return <TokenizedPasswordRecovery token={params.token} />
  }
  return <FormPasswordRecovery />
}

export default PasswordRecoveryContainer
