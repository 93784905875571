import React, { FC } from 'react'
import Highlight from 'react-highlight'
import './task-descrption.css'

const TaskDescription: FC<Props> = React.memo(function TaskDescription({ description, isMentorAnswer }) {
  return (
    <div className={isMentorAnswer ? 'answer-question' : 'lesson-question'}>
      <Highlight className={isMentorAnswer ? 'mentor-description' : 'lesson-description'} innerHTML>
        {description}
      </Highlight>
    </div>
  )
})

interface Props {
  description: string
  isMentorAnswer?: boolean
}

export default TaskDescription
