import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import { UserProfileServiceInterface } from '../interfaces/user-services/user-profile-service'
import UserLaconicDto from '../../model/user-dto/user-laconic-dto'
import { UserDto, ProfileDto } from '../../model/user-dto/user-dto'
import { UserChangePasswordDto } from '../../model/user-dto/user-change-password-dto'

class UserProfileService implements UserProfileServiceInterface {
  sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/user/profile`
  }

  getUserPrincipal = () => {
    const queryOptions = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<ProfileDto>(`${this.sourceURL}`, queryOptions)
  }

  getUserProfileById = (userId: number) => {
    const queryOptions = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<UserDto>(`${this.sourceURL}/${userId}`, queryOptions)
  }

  setUserNewPassword = (oldPass: string, newPass: string) => {
    const changePasswordDto: UserChangePasswordDto = {
      newPassword: newPass,
      oldPassword: oldPass,
    }
    const queryOptions = {
      method: HTTPMethods.PUT,
      body: JSON.stringify(changePasswordDto),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}/password`, queryOptions)
  }

  updateUser = (user: UserDto | UserLaconicDto) => {
    const queryOptions = {
      method: HTTPMethods.PUT,
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.sourceURL}`, queryOptions)
  }

  updateUserImage = (userId: number, file: string | Blob) => {
    const data = new FormData()
    data.append('file', file)
    const queryOption = {
      method: HTTPMethods.POST,
      enctype: 'multipart/form-data',
      headers: AuthService.authHeaders(),
      body: data,
    }
    return ApiService.request(`${this.sourceURL}/image?userId=${userId}`, queryOption)
  }

  getAvatarByUserId = (userId: number) => {
    const queryOptions = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/${userId}/image`, queryOptions)
  }
}

export default UserProfileService
