import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthService } from '../../services/auth-service'

const PrivateRoute = ({ component: Component, authRole, ...rest }) => {
  const [currentUser, setCurrentUser] = useState(AuthService.currentUserValue())
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!isReady && !currentUser) {
      AuthService.fetchUserProfile().then(user => {
        setCurrentUser(user)
        setIsReady(true)
      })
    } else {
      setIsReady(true)
    }
  }, [])

  return isReady ? (
    <Route
      {...rest}
      render={props =>
        authRole.includes(currentUser.role.name) ? <Component {...props} /> : <Redirect to="/login?param=forbidden" />
      }
    />
  ) : null
}

export default PrivateRoute
