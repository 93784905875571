import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import classes from './index.module.scss'
import Checkbox from '../../../../../checkbox'

const Test = <Question, Answer>({
  tests,
  resolveQuestionId,
  resolveQuestionText,
  resolveQuestionAnswers,
  resolveIsQuestionAnswersRight,
  resolveAnswerId,
  resolveAnswerText,
  isChecked,
  onChange,
  disabled,
}: PropsWithChildren<Props<Question, Answer>>) => {
  const resolveQuestionIcon = (question: Question) => {
    const isQuestionAnswersRight = resolveIsQuestionAnswersRight(question)

    if (typeof isQuestionAnswersRight !== 'boolean') {
      return cn('mdi-library-books', classes.icon, classes.icon_blue)
    }

    if (isQuestionAnswersRight) {
      return cn('mdi-check', classes.icon, classes.icon_green)
    }

    return cn('mdi-close', classes.icon, classes.icon_red)
  }

  return (
    <div className={classes.questions}>
      {tests?.map((question, index) => {
        const questionId = resolveQuestionId(question)

        return (
          <div key={questionId} className={cn(classes.question, classes.questions__question)}>
            <p className={classes.question__text}>
              <i className={cn('mdi', resolveQuestionIcon(question), classes.question__icon)} /> {index + 1}){' '}
              {resolveQuestionText(question)}
            </p>
            <div className={classes.question__answers}>
              {resolveQuestionAnswers(question)?.map((answer, answerIndex) => {
                const answerId = resolveAnswerId(answer)

                return (
                  <Checkbox
                    key={answerId}
                    label={resolveAnswerText(answer, answerIndex)}
                    onChange={e => onChange(questionId, answerId, e.target.checked)}
                    checked={isChecked(questionId, answerId)}
                    disabled={disabled}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

interface Props<Question, Answer> {
  tests: Question[] | undefined
  resolveQuestionId: (question: Question) => number
  resolveQuestionText: (question: Question) => string
  resolveQuestionAnswers: (question: Question) => Answer[] | undefined
  resolveIsQuestionAnswersRight: (question: Question) => boolean | undefined
  resolveAnswerId: (answer: Answer) => number
  resolveAnswerText: (answer: Answer, index: number) => string
  isChecked: (questionId: number, answerId: number) => boolean
  onChange: (questionId: number, answerId: number, checked: boolean) => void
  disabled: boolean
}

export default Test
