import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import cn from 'classnames'
import classes from './index.module.scss'

const Checkbox: FC<Props> = ({ label, ...props }) => {
  const { className, disabled } = props

  return (
    <label className={classes.checkbox} style={{ pointerEvents: disabled ? 'none' : 'all' }}>
      <input
        {...props}
        disabled={disabled}
        className={cn(classes.checkbox__input, classes.hidden, className)}
        type="checkbox"
      />
      <div className={cn(classes.checkbox__checkmark)} />
      <span className={cn(classes.checkbox__label)}>{label}</span>
    </label>
  )
}

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string
}

export default Checkbox
