import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import './fake-page-check-predproject-modal.css'
import '../fake-page.css'

const FakePageCheckPredProjectModal = ({ modalShowed, onClose, sendSolution, right, setRight, lastActionIsRight }) => {
  const [classNameCheckBox, setClassNameCheckBox] = useState('')
  const [labelValueCheckBox, setLabelValueCheckBox] = useState('')

  useEffect(() => {
    setClassNameCheckBox('badge badge-danger checkbox-task-group')
    setLabelValueCheckBox('Неверно')
  }, [])

  const customStyles = {
    content: {
      left: '50%',
      top: 0,
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translateX(-50%)',
      padding: 0,
      margin: '30px 0',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    },
    overlay: {
      overflow: 'scroll',
      zIndex: 10,
      backgroundColor: 'none',
    },
  }

  const changedCheckBox = event => {
    const {
      target: { checked },
    } = event
    if (checked) {
      setClassNameCheckBox(`badge badge-success checkbox-task-group`)
      setLabelValueCheckBox('Верно')
      setRight(true)
    } else {
      setClassNameCheckBox(`badge badge-danger checkbox-task-group`)
      setLabelValueCheckBox('Неверно')
      setRight(false)
    }
  }

  return (
    <>
      <Modal isOpen={modalShowed} onRequestClose={onClose} style={customStyles}>
        <div className="stat-courses-popup popup-window-premoderation">
          <div className="popup-head">
            <button type="button" className="close-modal-button" onClick={onClose} />
            <h4 className="modal-title" id="myModalLabel">
              Выберите статус проверки
            </h4>
          </div>
          <div className="modal-body">
            <div>
              <span className={classNameCheckBox}>
                <input
                  type="checkbox"
                  className="checkbox-task"
                  name="task-checked-status"
                  id="check_result"
                  onChange={changedCheckBox}
                  checked={right}
                  disabled={lastActionIsRight}
                />
                <label id="check_result_label" className="checkbox-task-label" htmlFor="check_result">
                  {labelValueCheckBox}
                </label>
              </span>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>
              Закрыть
            </button>
            <button type="button" className="btn btn-primary" id="checkTaskForm" onClick={sendSolution}>
              Подтвердить
            </button>
          </div>
          <div className="courses-popup-wrap" />
        </div>
      </Modal>
    </>
  )
}

export default FakePageCheckPredProjectModal
