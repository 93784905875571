import React, { FC, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { StudentReportGetDto } from '../../../../../model/student-model'
import styles from './report-modal-item.module.scss'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'
import UserProfileService from '../../../../../services/common-services/user-profile-service'
import { useReactAlert } from '../../../../../hooks/useReactAlert'

const cx = classNames.bind(styles)
const customStyles = {
  content: {
    maxHeight: '100%',
    zIndex: 100,
    width: '35%',
    top: '40%',
    left: '50%',
    bottom: 'initial',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
  },
}
type ReportModalProps = {
  data: StudentReportGetDto | null
  onClose: () => void
}
const ReportModalItem: FC<ReportModalProps> = ({ data, onClose }) => {
  const [uploadedImageURL, setUploadedImageURL] = useState<string | null>()

  const { t } = useTranslation()
  const { catchErrorAlert } = useReactAlert()
  const userProfileService = new UserProfileService()

  useEffect(() => {
    if (!data) return
    userProfileService
      .getUserProfileById(data?.studentId as number)
      .then(user => {
        if (user.avatarUrl) {
          setUploadedImageURL(convertResponseImageUrl(user.avatarUrl))
        }
      })
      .catch(err => catchErrorAlert(err))
  }, [])

  ReactModal.setAppElement('#root')

  return (
    <ReactModal isOpen={!!data?.id} style={customStyles} onRequestClose={onClose}>
      <div className={cx('report')}>
        <div className={cx('report__avatar')}>
          <img className={cx('report__logo')} src={uploadedImageURL ?? '/images/man.png'} id="user_pic" alt="avatar" />
        </div>
        <div className={cx('report__data')}>
          <div className={cx('report__name')}>{`${data?.firstName} ${data?.lastName}`}</div>
          <div className={cx('report__birthday')}>{data?.reportDate}</div>
          <p className={cx('report__spend-time')}>
            <b>{t('TimeTaken')}:</b> {data?.countHours}
            {t('HourShort')}
          </p>
          <p className={cx('report__task-progress')}>
            <b>{t('WhatDone')}:</b> {data?.text}
          </p>
          <button type="button" onClick={onClose} className={cx('report__hide')}>
            {t('Hide')}
          </button>
        </div>
      </div>
    </ReactModal>
  )
}
export default ReportModalItem
