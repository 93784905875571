import { serverHost } from '../../../config'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'

class FakeOpenService {
  sourceURL

  constructor() {
    this.sourceURL = `${serverHost}/api/fake/open`
  }

  isCodeTaskAvailable = courseTaskId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/check/codeTask?courseTaskId=${courseTaskId}`, queryOption)
  }

  isCommonTaskAvailable = courseTaskId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/check/common?courseTaskId=${courseTaskId}`, queryOption)
  }

  isMentorCheckTaskAvailable = courseTaskId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.sourceURL}/check/mentorCheckTask?courseTaskId=${courseTaskId}`, queryOption)
  }
}

export default FakeOpenService
