import React, { Dispatch, FC, SetStateAction } from 'react'
import './row-review-student.css'
import { useTranslation } from 'react-i18next'
import { StudentReviewDto, StudentReviewStatus } from '../../../../../../model/student-model'

type Props = {
  entity: StudentReviewDto
  onSelectReviewConfirm: Dispatch<SetStateAction<StudentReviewDto>>
  onSelectReviewDetails: Dispatch<SetStateAction<StudentReviewDto>>
}

const RowReviewStudent: FC<Props> = ({ entity, onSelectReviewConfirm, onSelectReviewDetails }) => {
  const { t } = useTranslation()

  const statusReview = () => {
    const { status } = entity
    if (status === StudentReviewStatus.SUCCESS) {
      return (
        <>
          <p onClick={() => onSelectReviewDetails(entity)}>
            <i className="mdi mdi-check-outline status-ok" />
            <u className="cursor-hand">{t('Passed')}</u>
          </p>
        </>
      )
    }
    if (status === StudentReviewStatus.FAILED) {
      return (
        <>
          <p onClick={() => onSelectReviewDetails(entity)}>
            <i className="mdi mdi-help-circle-outline status-fail" />
            <u className="cursor-hand">{t('Details')}</u>
          </p>
        </>
      )
    }
    if (status === StudentReviewStatus.CANCELED) {
      return (
        <>
          <p onClick={() => onSelectReviewConfirm(entity)}>
            <i className="mdi mdi-replay status-fail" />
            <u className="cursor-hand">{t('ReReview')}</u>
          </p>
        </>
      )
    }
    if (status === StudentReviewStatus.WAITED) {
      return (
        <>
          <p onClick={() => onSelectReviewConfirm(entity)}>
            <i className="mdi mdi-minus-circle-outline status-future" />
            <u className="cursor-hand">{t('OptOut')}</u>
          </p>
        </>
      )
    }
    if (status === StudentReviewStatus.MISSED) {
      return (
        <>
          <p onClick={() => onSelectReviewConfirm(entity)}>
            <i className="mdi mdi-account-minus-outline  status-future" />
            <u className="cursor-hand">{t('Missed')}</u>
          </p>
        </>
      )
    }

    return ''
  }

  const createLinkToReview = () => {
    if (entity.link != null) {
      return (
        <>
          <a href={entity.link} className="link-to-review" target="_blank" rel="noopener noreferrer">
            Перейти в конференцию
          </a>
        </>
      )
    }
    return ''
  }

  return (
    <tr className="table-row-review">
      <td className="table-data-review">{entity.moduleTitle}</td>
      <td className="table-data-review">{entity.reviewTime}</td>
      <td className="table-data-review">{statusReview()}</td>
      <td className="table-data-review">{createLinkToReview()}</td>
    </tr>
  )
}

export default RowReviewStudent
