import { HTTPMethods } from '../http-enums-methods'
import { serverHost } from '../../config'
import ApiService from '../api-service'
import { InviteTokenDto } from '../../model/invite-dto'
import { EnrolleeRegistrationServiceInterface } from '../interfaces/enrollee-services/enrollee-regestration-service'
import { EnrolleeRegistrationDto } from '../../model/enrollee-dto/enrollee-registration-dto'

const Url = {
  ROOT: `${serverHost}/api/registration`,
  TOKEN_INFO: 'enrollee/token_info',
  ENROLLEE: 'enrollee',
}

const requestEnrolleeController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const EnrolleeRegistrationService: EnrolleeRegistrationServiceInterface = class EnrolleeRegistrationService {
  static getTokenInformation = (hash: string) => {
    const queryOptions = {
      method: HTTPMethods.POST,
      body: hash,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return requestEnrolleeController<InviteTokenDto>(Url.TOKEN_INFO, queryOptions)
  }

  static enrolleeRegistration = (data: EnrolleeRegistrationDto) => {
    const queryOptions = {
      method: HTTPMethods.POST,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return requestEnrolleeController<void>(Url.ENROLLEE, queryOptions)
  }
}
