import TaskDto from 'src/model/task-dto/task-dto'
import { serverHost } from '../../../config'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'

class FakeOpenStudentCourseTaskService {
  openSourceURL

  constructor() {
    this.openSourceURL = `${serverHost}/api/fake/user/task`
  }

  /**
   * @returns {Promise<TaskDto>}
   */
  getCourseTask = async (courseTaskId, type, studentId) => {
    const url = `${this.openSourceURL}/${courseTaskId}/${type}?studentId=${studentId}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    // eslint-disable-next-line no-return-await
    return await ApiService.request(url, queryOption)
  }

  getCourseTaskProblemPart = async (courseTaskId, studentId) => {
    const url = `${this.openSourceURL}/part/${courseTaskId}/problem?studentId=${studentId}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    // eslint-disable-next-line no-return-await
    return await ApiService.request(url, queryOption)
  }

  getStudentCourseTaskIdByCourseTaskId = (courseTaskId, studentId) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(
      `${this.openSourceURL}/${courseTaskId}/studentCourseTaskInfoId?studentId=${studentId}`,
      queryOption
    )
  }

  sendWordSolution = (courseTaskId, textAnswer) => {
    const queryOption = {
      method: 'POST',
      body: textAnswer,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/word`, queryOption)
  }

  sendTheorySolution = (courseTaskId, answersIds) => {
    const queryOption = {
      method: 'POST',
      body: JSON.stringify(answersIds),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/theory`, queryOption)
  }

  sendCodeSolution = (courseTaskId, solution) => {
    const queryOption = {
      method: 'POST',
      body: solution,
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/code`, queryOption)
  }

  /**
   *
   * @param courseTaskId {number}
   * @param answers {OrderingTaskItemDto[] | undefined}
   * @returns {Promise<any>}
   */
  sendOrderingSolution = (courseTaskId, answers) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/ordering`, queryOption)
  }

  /**
   *
   * @param courseTaskId {number}
   * @param answers {AssociationTaskStudentAnswerDto | undefined}
   * @returns {Promise<any>}
   */
  sendAssociationSolution = (courseTaskId, answers) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/association`, queryOption)
  }

  /**
   *
   * @param courseTaskId {number}
   * @param answers {MultiInputTaskStudentAnswerDto | undefined}
   * @returns {Promise<any>}
   */
  sendMultiInputSolution = (courseTaskId, answers) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/multiInput`, queryOption)
  }

  /**
   *
   * @param courseTaskId {number}
   * @param answers {MultiTestTaskStudentAnswerDto | undefined}
   * @returns {Promise<any>}
   */
  sendMultiTestSolution = (courseTaskId, answers) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/multiTest`, queryOption)
  }

  /**
   *
   * @param courseTaskId {number}
   * @param answers {MultiAnswerTaskStudentAnswerDto | undefined}
   * @returns {Promise<any>}
   */
  sendMultiAnswerSolution = (courseTaskId, answers) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/multiAnswer`, queryOption)
  }

  /**
   *
   * @param courseTaskId {number}
   * @param answers {GappingTaskStudentAnswerDto | undefined}
   * @returns {Promise<any>}
   */
  sendGappingSolution = (courseTaskId, answers) => {
    const queryOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
      body: JSON.stringify(answers),
    }

    return ApiService.request(`${this.openSourceURL}/${courseTaskId}/gapping`, queryOption)
  }
}

export default FakeOpenStudentCourseTaskService
