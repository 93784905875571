import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import { MentorReviewServiceInterface } from '../interfaces/mentor-services/mentor-review-service'
import { serverHost } from '../../config'

export default class MentorReviewService implements MentorReviewServiceInterface {
  sourceURL: string

  constructor() {
    this.sourceURL = ''
  }

  public discardReview = (moduleId: number, studentId: number) => {
    const queryOption = {
      method: HTTPMethods.PATCH,
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(
      `${serverHost}/api/mentor/review/discardReview?moduleId=${moduleId}&studentId=${studentId}`,
      queryOption
    )
  }
}
