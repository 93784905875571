import { Dispatch, SetStateAction } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'

const useLoadTaskCallback = <Task, Loading>(
  setLoading: Dispatch<SetStateAction<Partial<Loading>>>,
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<Task>,
  interceptor: (value: any) => any = value => value
) => {
  const { reactAlert } = useReactAlert()
  return async (skipLoading?: boolean) => {
    if (!skipLoading) {
      setLoading(loading => ({
        ...loading,
        taskLoading: true,
      }))
    }

    try {
      if (loadTask) {
        loadTask(interceptor)
      }
    } catch (e) {
      reactAlert.error(e)
    } finally {
      if (!skipLoading) {
        setLoading(loading => ({
          ...loading,
          taskLoading: false,
        }))
      }
    }
  }
}

export default useLoadTaskCallback
