import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import StudentHeader from './components/header'
import StudentFooter from './components/footer'
import AllCoursesPage from './all-courses-page'
import CourseDetailsPage from './course-details-page'
import CoursePage from './course-page'
import StatisticPage from './statistic-page'
import EditPasswordPage from './components/profile/edit-password-page'

import './student-page.css'
import PublicOfferPage from './public-offer-page'
import LegacyEntityPage from './legacy-entity-page'
import ContactsPage from './contacts-page'
import SolutionsHistory from './course-page/history-page/history-page'
import ReviewPage from './review-page'
import StudentErrorBoundary from './student-error-boundary'
import ReportPage from './report-page'
import ReportPrivateRoute from './report-page/report-private-route'

import withCourseType from '../../hoc/with-course-type'
import CourseType from './course-type.model'
import NotFound from '../../not-found'
import { UserWithoutMentor } from '../../../features/Modals/UserWithoutMentor'

const RegularCourseDetailsPage = withCourseType(CourseDetailsPage, CourseType.Regular)
const RegularCoursePage = withCourseType(CoursePage, CourseType.Regular)
const RegularSolutionsHistoryPage = withCourseType(SolutionsHistory, CourseType.Regular)

const AdditionalCourseDetailsPage = withCourseType(CourseDetailsPage, CourseType.Additional)
const AdditionalCoursePage = withCourseType(CoursePage, CourseType.Additional)
const AdditionalSolutionsHistoryPage = withCourseType(SolutionsHistory, CourseType.Additional)

const StudentPage = () => {
  return (
    <>
      <StudentErrorBoundary>
        <StudentHeader />
        <Switch>
          <Route path="/user/statistic" component={StatisticPage} />
          <Route path="/user/password" component={EditPasswordPage} />
          <Route path="/user/courses" exact component={AllCoursesPage} />
          <Route path="/user/courses/:courseId" exact component={RegularCourseDetailsPage} />
          <Route
            path="/user/courses/:courseId/:modulePosition/:chapterPosition/:courseTaskPosition"
            exact
            component={RegularCoursePage}
          />
          <Route
            path="/user/courses/:courseId/:modulePosition/:chapterPosition/:courseTaskPosition/solutions"
            exact
            component={RegularSolutionsHistoryPage}
          />

          <Route path="/user/courses/additional/:courseId" exact component={AdditionalCourseDetailsPage} />
          <Route
            path="/user/courses/additional/:courseId/:modulePosition/:chapterPosition/:courseTaskPosition"
            exact
            component={AdditionalCoursePage}
          />
          <Route
            path="/user/courses/additional/:courseId/:modulePosition/:chapterPosition/:courseTaskPosition/solutions"
            exact
            component={AdditionalSolutionsHistoryPage}
          />
          <Route path="/user/review" component={ReviewPage} />

          <ReportPrivateRoute path="/user/report" component={ReportPage} />
          <Route path="" render={(props: RouteComponentProps<any>) => <NotFound renderBreadCrumbs {...props} />} />
        </Switch>
      </StudentErrorBoundary>
      <Switch>
        <Route path="/user/contacts" exact component={ContactsPage} />
        <Route path="/user/legal_entity" exact component={LegacyEntityPage} />
        <Route path="/user/public_offer" exact component={PublicOfferPage} />
      </Switch>
      <StudentFooter />
      <UserWithoutMentor />
    </>
  )
}

export default StudentPage
