import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const PublicOfferPage = () => {
  const { t } = useTranslation()
  document.title = `${t('PublicOffer')}`

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <div className="step-content-wrap">
      <div className="container">
        <div className="info-jm-block">
          <div className="info-jm-content">
            <div>
              <p className="paragraph-44">
                <strong>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</strong>
              </p>
              <p className="paragraph-45">
                г. Санкт-Петербург «24» мая 2018 года. <br />
                Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности)
                действует в отношении всей информации, предоставленной на сайте{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-29">
                  https://kata.academy
                </a>
                , которая может быть получена о Пользователе во время использования сайта.
              </p>
              <p className="paragraph-44">
                <strong>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</strong>
              </p>
              <p className="paragraph-45">
                1.1. В настоящей Политике конфиденциальности используются следующие термины:
                <br />
                1.1.1. «Администрация сайта https://kata.academy (далее – Администрация сайта) » – уполномоченные
                сотрудники на управления сайтом, действующие от имени ИП Севостьянов Герман Дмитриевич, которые
                организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки
                персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые
                с персональными данными.
                <br />
                1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или
                определяемому физическому лицу (субъекту персональных данных).
                <br />
                1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций),
                совершаемых с использованием средств автоматизации или без использования таких средств с персональными
                данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных.
                <br />
                1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным
                получившим доступ к персональным данным лицом требование не допускать их распространения без согласия
                субъекта персональных данных или наличия иного законного основания.
                <br />
                1.1.5. «Пользователь сайта https://kata.academy (далее ‑ Пользователь)» – лицо, имеющее доступ к Сайту,
                Сайту, посредством сети Интернет и использующее Сайт{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-32">
                  https://kata.academy
                </a>
                <br />
                1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере
                пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
                попытке открыть страницу соответствующего сайта.
                <br />
                1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
              </p>
              <p className="paragraph-44">
                <strong>2. ОБЩИЕ ПОЛОЖЕНИЯ</strong>
              </p>
              <p className="paragraph-45">
                2.1. Использование Пользователем сайта https://kata.academy означает согласие с настоящей Политикой
                конфиденциальности и условиями обработки персональных данных Пользователя.
                <br />
                2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить
                использование сайта https://kata.academy.
                <br />
                2.3. Настоящая Политика конфиденциальности применяется только к сайту https://kata.academy,
                https://kata.academy не контролирует и не несет ответственность за сайты третьих лиц, на которые
                Пользователь может перейти по ссылкам, доступным на сайте https://kata.academy.
                <br />
                2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем
                сайта https://kata.academy.
              </p>
              <p className="paragraph-44">
                <strong>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</strong>
              </p>
              <p className="paragraph-45">
                3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-28">
                  https://kata.academy
                </a>{' '}
                по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые
                Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-27">
                  https://kata.academy
                </a>{' '}
                или при оформлении заказа для приобретения Билета.
                <br />
                3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
                предоставляются Пользователем путём заполнения регистрационной формы на Сайте{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-26">
                  https://kata.academy
                </a>{' '}
                включают в себя следующую информацию:
                <br />
                3.2.1. фамилию, имя, отчество Пользователя;
                <br />
                3.2.2. контактный телефон Пользователя;
                <br />
                3.2.3. адрес электронной почты (e-mail);
                <br />
                3.2.4. Страна проживания <br />
                3.2.5. Город проживания3.3.{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-25">
                  https://kata.academy
                </a>{' '}
                защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении
                страниц, на которых установлен статистический скрипт системы (&quot;пиксель&quot;):· IP адрес;·
                информация из cookies;· информация о браузере (или иной программе, которая осуществляет доступ к показу
                рекламы);· время доступа;· адрес страницы, на которой расположен рекламный блок;· реферер (адрес
                предыдущей страницы).
                <br />
                3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-24">
                  {' '}
                  https://kata.academy
                </a>
                , требующим авторизации.
                <br />
                3.3.2.{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-23">
                  https://kata.academy
                </a>{' '}
                осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью
                выявления и решения технических проблем, для контроля законности проводимых финансовых платежей
                <br />
                3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и
                операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев,
                предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
              </p>
              <p className="paragraph-44">
                <strong>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</strong>
              </p>
              <p className="paragraph-45">
                4.1. Персональные данные Пользователя Администрация сайта{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-22">
                  https://kata.academy
                </a>{' '}
                может использовать в целях:
                <br />
                4.1.1. Идентификации Пользователя, зарегистрированного на сайте{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-21">
                  https://kata.academy
                </a>
                , для оформления заказа и (или) заключения Оферты (Договора) дистанционным способом.
                <br />
                4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-19">
                  https://kata.academy
                </a>
                .<br />
                4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
                касающихся использования Сайта{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-20">
                  https://kata.academy
                </a>{' '}
                оказания услуг, обработка запросов и заявок от Пользователя, рекламной информации.
                <br />
                4.1.4. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
                <br />
                4.1.5. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание
                учетной записи.
                <br />
                4.1.6. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении
                проблем связанных с использованием Сайта{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-18">
                  https://kata.academy
                </a>
                .<br />
                4.1.7. Предоставления Пользователю с его согласия, специальных предложений, информации о ценах,
                новостной рассылки и иных сведений от имени{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-17">
                  https://kata.academy
                </a>
                .<br />
                4.1.8. Осуществления рекламной деятельности с согласия Пользователя с условиями Оферты.
              </p>
              <p className="paragraph-44">
                <strong>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong>
              </p>
              <p className="paragraph-45">
                5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным
                способом, в том числе в информационных системах персональных данных с использованием средств
                автоматизации или без использования таких средств.
                <br />
                5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные
                третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи,
                исключительно в целях выполнения заказа Пользователя, оформленного на Сайте{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-16">
                  https://kata.academy
                </a>
                , включая доставку Товара.
                <br />
                5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-15">
                  https://kata.academy
                </a>{' '}
                Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
                <br />
                5.4. Администрация сайта принимает необходимые организационные и технические меры для защиты
                персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
              </p>
              <p className="paragraph-44">
                <strong>6. ОБЯЗАТЕЛЬСТВА СТОРОН</strong>
              </p>
              <p className="paragraph-45">
                6.1. Пользователь обязан:
                <br />
                6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-10">
                  https://kata.academy
                </a>
                .<br />
                <img src="images/256.png" width="128" alt="" className="image-51" />
                <p className="paragraph-44">
                  <strong>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</strong>
                </p>
                <p className="paragraph-45">
                  г. Санкт-Петербург «24» мая 2018 года. <br />
                  Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности)
                  действует в отношении всей информации, предоставленной на сайте{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-29">
                    https://kata.academy
                  </a>
                  , которая может быть получена о Пользователе во время использования сайта.
                </p>
                <p className="paragraph-44">
                  <strong>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</strong>
                </p>
                <p className="paragraph-45">
                  1.1. В настоящей Политике конфиденциальности используются следующие термины:
                  <br />
                  1.1.1. «Администрация сайта https://kata.academy (далее – Администрация сайта) » – уполномоченные
                  сотрудники на управления сайтом, действующие от имени ИП Севостьянов Герман Дмитриевич, которые
                  организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки
                  персональных данных, состав персональных данных, подлежащих обработке, действия (операции),
                  совершаемые с персональными данными.
                  <br />
                  1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или
                  определяемому физическому лицу (субъекту персональных данных).
                  <br />
                  1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий
                  (операций), совершаемых с использованием средств автоматизации или без использования таких средств с
                  персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение
                  (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
                  доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                  <br />
                  1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным
                  получившим доступ к персональным данным лицом требование не допускать их распространения без согласия
                  субъекта персональных данных или наличия иного законного основания.
                  <br />
                  1.1.5. «Пользователь сайта https://kata.academy (далее ‑ Пользователь)» – лицо, имеющее доступ к
                  Сайту, посредством сети Интернет и использующее Сайт{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-32">
                    https://kata.academy
                  </a>
                  <br />
                  1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере
                  пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
                  попытке открыть страницу соответствующего сайта.
                  <br />
                  1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
                </p>
                <p className="paragraph-44">
                  <strong>2. ОБЩИЕ ПОЛОЖЕНИЯ</strong>
                </p>
                <p className="paragraph-45">
                  2.1. Использование Пользователем сайта https://kata.academy означает согласие с настоящей Политикой
                  конфиденциальности и условиями обработки персональных данных Пользователя.
                  <br />
                  2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить
                  использование сайта https://kata.academy.
                  <br />
                  2.3. Настоящая Политика конфиденциальности применяется только к сайту https://kata.academy,
                  https://kata.academy не контролирует и не несет ответственность за сайты третьих лиц, на которые
                  Пользователь может перейти по ссылкам, доступным на сайте https://kata.academy.
                  <br />
                  2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем
                  сайта https://kata.academy.
                </p>
                <p className="paragraph-44">
                  <strong>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</strong>
                </p>
                <p className="paragraph-45">
                  3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-28">
                    https://kata.academy
                  </a>{' '}
                  по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые
                  Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-27">
                    https://kata.academy
                  </a>{' '}
                  или при оформлении заказа для приобретения Билета.
                  <br />
                  3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
                  предоставляются Пользователем путём заполнения регистрационной формы на Сайте{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-26">
                    https://kata.academy
                  </a>{' '}
                  включают в себя следующую информацию:
                  <br />
                  3.2.1. фамилию, имя, отчество Пользователя;
                  <br />
                  3.2.2. контактный телефон Пользователя;
                  <br />
                  3.2.3. адрес электронной почты (e-mail);
                  <br />
                  3.2.4. Страна проживания <br />
                  3.2.5. Город проживания3.3.{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-25">
                    https://kata.academy
                  </a>{' '}
                  защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при
                  посещении страниц, на которых установлен статистический скрипт системы (&quot;пиксель&quot;):· IP
                  адрес;· информация из cookies;· информация о браузере (или иной программе, которая осуществляет доступ
                  к показу рекламы);· время доступа;· адрес страницы, на которой расположен рекламный блок;· реферер
                  (адрес предыдущей страницы).
                  <br />
                  3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-24">
                    {' '}
                    https://kata.academy
                  </a>
                  , требующим авторизации.
                  <br />
                  3.3.2.{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-23">
                    https://kata.academy
                  </a>{' '}
                  осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью
                  выявления и решения технических проблем, для контроля законности проводимых финансовых платежей
                  <br />
                  3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и
                  операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев,
                  предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
                </p>
                <p className="paragraph-44">
                  <strong>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</strong>
                </p>
                <p className="paragraph-45">
                  4.1. Персональные данные Пользователя Администрация сайта{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-22">
                    https://kata.academy
                  </a>{' '}
                  может использовать в целях:
                  <br />
                  4.1.1. Идентификации Пользователя, зарегистрированного на сайте{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-21">
                    https://kata.academy
                  </a>
                  , для оформления заказа и (или) заключения Оферты (Договора) дистанционным способом.
                  <br />
                  4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-19">
                    https://kata.academy
                  </a>
                  .<br />
                  4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
                  касающихся использования Сайта{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-20">
                    https://kata.academy
                  </a>{' '}
                  оказания услуг, обработка запросов и заявок от Пользователя, рекламной информации.
                  <br />
                  4.1.4. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
                  <br />
                  4.1.5. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание
                  учетной записи.
                  <br />
                  4.1.6. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении
                  проблем связанных с использованием Сайта{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-18">
                    https://kata.academy
                  </a>
                  .<br />
                  4.1.7. Предоставления Пользователю с его согласия, специальных предложений, информации о ценах,
                  новостной рассылки и иных сведений от имени{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-17">
                    https://kata.academy
                  </a>
                  .<br />
                  4.1.8. Осуществления рекламной деятельности с согласия Пользователя с условиями Оферты.
                </p>
                <p className="paragraph-44">
                  <strong>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong>
                </p>
                <p className="paragraph-45">
                  5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным
                  способом, в том числе в информационных системах персональных данных с использованием средств
                  автоматизации или без использования таких средств.
                  <br />
                  5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные
                  третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи,
                  исключительно в целях выполнения заказа Пользователя, оформленного на Сайте{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-16">
                    https://kata.academy
                  </a>
                  , включая доставку Товара.
                  <br />
                  5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной
                  власти{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-15">
                    https://kata.academy
                  </a>{' '}
                  Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
                  <br />
                  5.4. Администрация сайта принимает необходимые организационные и технические меры для защиты
                  персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                  блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                </p>
                <p className="paragraph-44">
                  <strong>6. ОБЯЗАТЕЛЬСТВА СТОРОН</strong>
                </p>
                <p className="paragraph-45">
                  6.1. Пользователь обязан:
                  <br />
                  6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-10">
                    https://kata.academy
                  </a>
                  .<br />
                  6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной
                  информации.
                  <br />
                  6.2. Администрация сайта обязана:
                  <br />
                  6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики
                  конфиденциальности.
                  <br />
                  6.2.2. Обеспечить хранение конфиденциальной информации в тайне, а также не осуществлять продажу,
                  обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных
                  Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
                  <br />
                  6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя
                  согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом
                  обороте.
                </p>
                <p className="paragraph-44">
                  <strong>7. ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
                </p>
                <p className="paragraph-45">
                  7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки,
                  понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с
                  законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2.
                  настоящей Политики Конфиденциальности.
                  <br />
                  7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт
                  ответственность, если данная конфиденциальная информация:
                  <br />
                  7.2.1. Стала публичным достоянием до её утраты или разглашения.
                  <br />
                  7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
                  <br />
                  7.2.3. Была разглашена с согласия Пользователя.
                </p>
                <p className="paragraph-44">
                  <strong>8. РАЗРЕШЕНИЕ СПОРОВ</strong>
                </p>
                <p className="paragraph-45">
                  8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-14">
                    https://kata.academy
                  </a>{' '}
                  и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о
                  добровольном урегулировании спора).
                  <br />
                  8.2 .Получатель претензии в течение 14 календарных дней со дня получения претензии, письменно
                  уведомляет заявителя претензии о результатах рассмотрения претензии.
                  <br />
                  8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с
                  действующим законодательством Российской Федерации.
                  <br />
                  8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта
                  применяется действующее законодательство Российской Федерации.
                </p>
                <p className="paragraph-44">
                  <strong>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong>
                </p>
                <p className="paragraph-45">
                  9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия
                  Пользователя.
                  <br />
                  9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-12">
                    https://kata.academy
                  </a>
                  , если иное не предусмотрено новой редакцией Политики конфиденциальности.
                  <br />
                  9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать на сайте{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-13">
                    https://kata.academy
                  </a>
                  .<br />
                  9.4. Действующая Политика конфиденциальности размещена на странице по адресу{' '}
                  <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-11">
                    https://kata.academy
                  </a>
                  <br />
                  <br />
                  Обновлено «24» мая 2018 г.
                </p>
                6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной
                информации.
                <br />
                6.2. Администрация сайта обязана:
                <br />
                6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики
                конфиденциальности.
                <br />
                6.2.2. Обеспечить хранение конфиденциальной информации в тайне, а также не осуществлять продажу, обмен,
                опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя,
                за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
                <br />
                6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя
                согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
              </p>
              <p className="paragraph-44">
                <strong>7. ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
              </p>
              <p className="paragraph-45">
                7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые
                Пользователем в связи с неправомерным использованием персональных данных, в соответствии с
                законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2.
                настоящей Политики Конфиденциальности.
                <br />
                7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт
                ответственность, если данная конфиденциальная информация:
                <br />
                7.2.1. Стала публичным достоянием до её утраты или разглашения.
                <br />
                7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.
                <br />
                7.2.3. Была разглашена с согласия Пользователя.
              </p>
              <p className="paragraph-44">
                <strong>8. РАЗРЕШЕНИЕ СПОРОВ</strong>
              </p>
              <p className="paragraph-45">
                8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-14">
                  https://kata.academy
                </a>{' '}
                и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о
                добровольном урегулировании спора).
                <br />
                8.2 .Получатель претензии в течение 14 календарных дней со дня получения претензии, письменно уведомляет
                заявителя претензии о результатах рассмотрения претензии.
                <br />
                8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с
                действующим законодательством Российской Федерации.
                <br />
                8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта
                применяется действующее законодательство Российской Федерации.
              </p>
              <p className="paragraph-44">
                <strong>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong>
              </p>
              <p className="paragraph-45">
                9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия
                Пользователя.
                <br />
                9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-12">
                  https://kata.academy
                </a>
                , если иное не предусмотрено новой редакцией Политики конфиденциальности.
                <br />
                9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать на сайте{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-13">
                  https://kata.academy
                </a>
                .<br />
                9.4. Действующая Политика конфиденциальности размещена на странице по адресу{' '}
                <a href="https://kata.academy/" target="_blank" rel="noreferrer noopener" className="link-11">
                  https://kata.academy
                </a>
                <br />
                <br />
                Обновлено «24» мая 2018 г.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicOfferPage
