import { Services } from './getTaskService'
import RaceConditionGuard from './raceConditionGuard'

const raceConditionGuard = new RaceConditionGuard()

const getLoadTaskCallback = (courseTaskId: number, type: string = '', studentId: number, service: Services) => {
  switch (type) {
    default:
      return () => {
        // @ts-ignore
        return raceConditionGuard.getGuardedPromise(service.getCourseTask(courseTaskId, type, studentId))
      }
  }
}

export default getLoadTaskCallback
