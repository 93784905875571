import { combineReducers } from '@reduxjs/toolkit'
import taskReducer from './taskSlice'
import availableCoursesSlice from './available-courses/availableCoursesSlice'
import courseType from './course-type/course-type.slice'

const rootReducer = combineReducers({
  task: taskReducer,
  availableCourses: availableCoursesSlice,
  courseType,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
