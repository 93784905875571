import { AssociationTaskDto } from '../../../../../../model/task-model/AssociationTaskDto'
import { AssociationDirectionTaskDto } from '../../../../../../model/direction-model'

const useIsSolved = (associationTask: AssociationTaskDto | AssociationDirectionTaskDto | undefined) => {
  return !!(
    associationTask &&
    associationTask.studentAssociationResults &&
    associationTask.studentAssociationResults.length
  )
}

export default useIsSolved
