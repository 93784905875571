import ReactModal from 'react-modal'
import React from 'react'
import './details-modal.css'
import { useTranslation } from 'react-i18next'
import ListCodeTaskDetails from './list-code-task-details'
import ListTheoryTaskDetails from './list-theory-task-details'

const customStyles = {
  content: {
    zIndex: 9999,
    width: '70%',
    top: '40%',
    left: '50%',
    right: '50%',
    bottom: '0%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
    zIndex: 10,
  },
}

const DetailsModalReview = function DetailsModalReview({
  show,
  onClose,
  studentReview: { moduleTitle, reviewTime },
  theoryTask,
  codeTask,
}) {
  ReactModal.setAppElement('#root')
  const { t } = useTranslation()

  const taskNotes = []
  const mentorComments = []
  codeTask.forEach(task => (task.debt ? taskNotes.push(task) : mentorComments.push(task)))

  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <div className="details-block">
        <div className="details-outside">
          <div className="details-content">
            <div className="details-header">
              <div className="details-box">
                <div className="details-title">{t('ViewReviewResults')}</div>
                <div className="close-button-details">
                  <i className="mdi mdi-close" onClick={onClose} />
                </div>
              </div>
              <div className="details-body">
                <div className="detail-body-header">{moduleTitle}</div>
                <div className="detail-body-date">{reviewTime}</div>
              </div>
            </div>
            <div className="details-data-container">
              <div className="details-wrapper">
                <div className="details-questions">
                  <div className="details-data-header">{t('TheoryQuestionsToRepeat')}</div>
                  <ListTheoryTaskDetails theoryTasks={theoryTask} />
                </div>
              </div>
              <div className="details-wrapper">
                <div className="details-task">
                  <div className="details-data-header">{t('TaskNotes')}</div>
                  <ListCodeTaskDetails codeTasks={taskNotes} />
                </div>
              </div>
              <div className="details-wrapper">
                <div className="details-task">
                  <div className="details-data-header">{t('MentorComments')}</div>
                  <ListCodeTaskDetails codeTasks={mentorComments} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

export default DetailsModalReview
