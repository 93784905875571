export const years = [
  1950,
  1951,
  1952,
  1953,
  1954,
  1955,
  1956,
  1957,
  1958,
  1959,
  1960,
  1961,
  1962,
  1963,
  1964,
  1965,
  1966,
  1967,
  1968,
  1969,
  1970,
  1971,
  1972,
  1973,
  1974,
  1975,
  1976,
  1977,
  1978,
  1979,
  1980,
  1981,
  1982,
  1983,
  1984,
  1985,
  1986,
  1987,
  1988,
  1989,
  1990,
  1991,
  1992,
  1993,
  1994,
  1995,
  1996,
  1997,
  1998,
  1999,
  2000,
  2001,
  2002,
  2003,
  2004,
  2005,
  2006,
  2007,
  2008,
  2009,
  2010,
  2011,
  2012,
  2013,
  2014,
  2015,
  2016,
  2017,
  2018,
  2019,
  2020,
  2021,
  2022,
  2023,
  2024,
  2025,
  2026,
  2027,
  2028,
  2029,
  2030,
  2031,
  2032,
  2033,
  2034,
  2035,
  2036,
  2037,
]
export const days = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
]
export const months = [
  { value: 1, name: 'Январь', index: 0 },
  { value: 2, name: 'Февраль', index: 1 },
  { value: 3, name: 'Март', index: 2 },
  { value: 4, name: 'Апрель', index: 3 },
  { value: 5, name: 'Май', index: 4 },
  { value: 6, name: 'Июнь', index: 5 },
  { value: 7, name: 'Июль', index: 6 },
  { value: 8, name: 'Август', index: 7 },
  { value: 9, name: 'Сентябрь', index: 8 },
  { value: 10, name: 'Октябрь', index: 9 },
  { value: 11, name: 'Ноябрь', index: 10 },
  { value: 12, name: 'Декабрь', index: 11 },
]
