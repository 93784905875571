import { RoleEnum } from '../utils/select-state/RoleEnum'

export type Inactivation = {
  inactive: boolean
  reason: InactivationReason
}

export enum InactivationReason {
  NeedChangePassword = 'NEED_CHANGE_PASSWORD',
}

export type AvailableRoutesForBannedRoles = {
  readonly [key in RoleEnum]: string[]
}

export type AvailableRoutesByInactivationType = {
  readonly [key in InactivationReason]: AvailableRoutesForBannedRoles
}

export type MessagesForInactivationReasonsType = {
  readonly [key in InactivationReason]: string
}

export const availableRoutesByInactivation: AvailableRoutesByInactivationType = {
  [InactivationReason.NeedChangePassword]: {
    [RoleEnum.ADMIN]: ['/admin/edit/password'],
    [RoleEnum.MENTOR]: ['/mentor/edit/password'],
    [RoleEnum.MANAGER]: ['/manager/edit/password'],
    [RoleEnum.CURATOR]: ['/curator/edit/password'],
    [RoleEnum.CHIEF_MENTOR]: ['/chief-mentor/edit/password'],
    [RoleEnum.STUDENT]: ['/enrollee/password'],
    [RoleEnum.PAY_STUDENT]: ['/user/password'],
    [RoleEnum.CHIEF_MANAGER]: ['/chief-manager/edit/password'],
    [RoleEnum.EMPTY]: ['/login?param=forbidden'],
    [RoleEnum.CHIEF]: ['/chief/edit/password'],
  },
}

export const messagesForInactivationReasons: MessagesForInactivationReasonsType = {
  [InactivationReason.NeedChangePassword]: 'Для того чтобы продолжить работу необходимо поменять пароль',
}
