import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { BrowserRouter } from 'react-router-dom'
import App from './components/app'
import GlobalErrorBoundary from './components/global-error-boundary'

import 'plyr/dist/plyr.css'
import 'highlight.js/styles/atom-one-light.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap/dist/react-bootstrap.min'
import 'bootstrap/dist/js/bootstrap.min'
import './index.css'
import i18n from './components/i18n/i18n'
import store from './store'

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <GlobalErrorBoundary>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter forceRefresh>
            <App />
          </BrowserRouter>
        </DndProvider>
      </GlobalErrorBoundary>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
)
