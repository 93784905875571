import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './student-header.scss'
import { useTranslation } from 'react-i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { adminServerHost, linkToMettermostHelpBot } from 'src/config.ts'
import { Button } from 'react-bootstrap'
import { format } from 'date-fns'
import { history, showChangeLanguageSelect, showReportForStudent } from '../../../../../config'
import NotificationBlock from './notification-block/notification-block'
import { AuthService } from '../../../../../services/auth-service'
import LocaleService from '../../../../../services/locale-service'

import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { getIndexByKeyValue } from '../../../../../utils/ArraysUtils'
import FakePageService from '../../../../../services/fake-page-services/fake-page-service'
import UserProfileService from '../../../../../services/common-services/user-profile-service'
import FakeOpenPageService from '../../../../../services/fake-page-services/open/fake-open-page-service'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'
import ChangeAvatarModal from './change-avatar-modal'
import { useStatusPage } from '../../../../../hooks/useStatusPage'
import StudentPageMode from '../../../../../model/StudentPageMode'
import ReportModal from '../../report-page/report-modal'

const fakePageService = new FakePageService()
const fakeOpenPageService = new FakeOpenPageService()
const userProfileService = new UserProfileService()
const localeService = new LocaleService()

const StudentHeader = ({ hasError = false }) => {
  const { catchErrorAlert } = useReactAlert()
  //for user page
  const { i18n, t } = useTranslation()
  const [active, setActive] = useState(false)
  const [uploadedImageURL, setUploadedImageURL] = useState('')
  const [studentPaymentType, setStudentPaymentType] = useState('')
  const [selectSection, onChooseSection] = useState(1)
  const [locale, setLocale] = useState(i18n.language)

  //for fake page
  const [mentorsStudents, setMentorsStudents] = useState([])
  const [mentorName, setMentorName] = useState('')
  const [singleStudent, setSingleStudent] = useState(null)
  const [isFound, setIsFound] = useState(true)

  //for fake-student page
  const [hostOfPage, setHostOfPage] = useState('')

  const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false)
  const { statusPage, studentIdParam, principalRole } = useStatusPage()

  const [isDebtReport, setIsDebtReport] = useState(false)

  const yesterdayDate = new Date()
  yesterdayDate.setDate(yesterdayDate.getDate() - 1)

  useEffect(() => {
    if (statusPage === StudentPageMode.NORMAL) {
      userProfileService
        .getUserPrincipal()
        .then(us => {
          // Необходимо поддерживать актуальную информацию о состоянии задолженности по отчетам в localStorage
          const storagedUser = localStorage.getItem('user')
          const user = JSON.parse(storagedUser)
          const requireCreateReport =
            'requireCreateReport' in us
              ? {
                  requireCreateReport: us.requireCreateReport,
                }
              : {}
          const updatedUser = { ...user, ...requireCreateReport }
          localStorage.setItem('user', JSON.stringify(updatedUser))

          if (us.requireCreateReport) {
            setIsDebtReport(true)
          }
          if (us.avatarUrl) {
            setUploadedImageURL(convertResponseImageUrl(us.avatarUrl))
          }
          setStudentPaymentType(us.paymentType)
        })
        .catch(err => catchErrorAlert(err))
    }
  }, [uploadedImageURL, statusPage, principalRole])

  useEffect(() => {
    const currentLocation = history.location.pathname

    if (/statistic/.test(currentLocation)) {
      onChooseSection(1)
    } else if (/courses/.test(currentLocation)) {
      onChooseSection(2)
    } else if (/review/.test(currentLocation)) {
      onChooseSection(3)
    } else if (/report/.test(currentLocation)) {
      onChooseSection(5)
    }
  }, [history.location])

  useEffect(() => {
    const listener = event => {
      // eslint-disable-next-line no-use-before-define
      handleClickOutSide(event)
    }

    if (statusPage === StudentPageMode.NORMAL) {
      window.addEventListener('click', listener)
    }

    return () => {
      window.removeEventListener('click', listener)
    }
  }, [statusPage])

  const handleClickOutSide = event => {
    const notificationBlock = document.getElementById(`profile-menu-wrap`)
    // eslint-disable-next-line no-shadow
    const path = event.path || (event.composedPath && event.composedPath())

    if (!path.includes(notificationBlock)) {
      const notifyBtn = document.getElementById(`profile-icn`)

      if (!path.includes(notifyBtn)) {
        setActive(false)
      }
    }
  }

  const changeLanguage = ({ target }) => {
    const newLocale = target.value
    setLocale(newLocale)
    i18n
      .changeLanguage(newLocale)
      .then(() => localeService.changeLocale(newLocale))
      .catch(err => catchErrorAlert(err))
  }

  //fake page
  const changeStudent = ({ target }) => {
    // eslint-disable-next-line no-restricted-globals
    history.push({
      pathname: history.location.pathname,
      search: `?studentId=${target.value}`,
    })
  }

  const getAllStudents = student => {
    return <option key={student.id} value={student.id}>{`${student.firstName} ${student.lastName}`}</option>
  }

  const foundStudentFromList = () => {
    return getIndexByKeyValue(mentorsStudents, 'id', studentIdParam)
  }

  const getSingleStudent = () => {
    if (mentorsStudents.length > 0 && !isFound) {
      fakePageService.getSingleUser(studentIdParam).then(student => {
        setSingleStudent(student)
      })
    }
  }

  const getNameSingleStudent = () => {
    if (singleStudent) {
      return (
        <option key={singleStudent.id} value={singleStudent.id}>
          {`${singleStudent.firstName} ${singleStudent.lastName}`}
        </option>
      )
    }
    return ''
  }

  useEffect(() => {
    if (statusPage === StudentPageMode.FAKE && principalRole && principalRole !== RoleEnum.PAY_STUDENT) {
      fakePageService
        .getStudents(studentIdParam)
        .then(students => {
          setMentorsStudents(students)
        })
        .catch(err => catchErrorAlert(err))
    }
  }, [studentIdParam, statusPage, principalRole])

  useEffect(() => {
    if (statusPage === StudentPageMode.FAKE && principalRole && principalRole !== RoleEnum.PAY_STUDENT) {
      fakeOpenPageService
        .getMentorNameByStudentId(studentIdParam)
        .then(name => setMentorName(name))
        .catch(err => catchErrorAlert(err))
    }
  }, [studentIdParam, statusPage, principalRole])

  useEffect(() => {
    if (statusPage === StudentPageMode.FAKE && mentorsStudents.length > 0) {
      setIsFound(foundStudentFromList() >= 0)
    }
  }, [mentorsStudents, statusPage])

  useEffect(() => {
    if (statusPage === StudentPageMode.FAKE) {
      getSingleStudent()
    }
  }, [isFound, statusPage])

  useEffect(() => {
    if (statusPage === StudentPageMode.FAKE && principalRole === RoleEnum.PAY_STUDENT) {
      fakeOpenPageService.getStudentNameByStudentId(studentIdParam).then(name => {
        setHostOfPage(name)
      })
    }
  }, [studentIdParam, principalRole])

  return (
    <div className="header">
      <div className="top-header">
        <div className="container">
          {statusPage === StudentPageMode.FAKE ? (
            <Link to={`/user/courses?studentId=${studentIdParam}`} className="logo">
              <img src="/images/header-logo.svg" alt="logo" />
            </Link>
          ) : (
            <Link to="/user/courses" className="logo">
              <img src="/images/header-logo.svg" alt="logo" />
            </Link>
          )}
          <ul className="top-nav">
            <li className={selectSection === 1 ? 'active' : ' '}>
              {statusPage === StudentPageMode.FAKE ? (
                <Link to={`/user/statistic?studentId=${studentIdParam}`}>{t('Main')}</Link>
              ) : (
                <Link to="/user/statistic">{t('Main')}</Link>
              )}
            </li>
            <li className={selectSection === 2 ? 'active' : ' '}>
              {statusPage === StudentPageMode.FAKE ? (
                <Link to={`/user/courses?studentId=${studentIdParam}`}>{t('Study')}</Link>
              ) : (
                <Link to="/user/courses">{t('Study')}</Link>
              )}
            </li>

            <li className={selectSection === 3 ? 'active' : ' '}>
              {statusPage === StudentPageMode.FAKE &&
              [RoleEnum.MENTOR, RoleEnum.ADMIN, RoleEnum.CHIEF_MENTOR, RoleEnum.CURATOR].includes(principalRole) ? (
                <Link to={`/user/review?studentId=${studentIdParam}`}>{t('Review')}</Link>
              ) : (
                statusPage === StudentPageMode.NORMAL && <Link to="/user/review">{t('Review')}</Link>
              )}
            </li>

            <li className={selectSection === 4 ? 'active' : ' '}>
              {statusPage === StudentPageMode.FAKE ? (
                <a href={principalRole === RoleEnum.PAY_STUDENT ? '/' : adminServerHost}>{t('BackToYourDashboard')}</a>
              ) : null}
            </li>

            <li className={selectSection === 5 ? 'active' : ' '}>
              {statusPage === StudentPageMode.NORMAL && showReportForStudent && studentPaymentType === 'UNTIL_PAID' && (
                <Link to="/user/report">{t('Reports')}</Link>
              )}
            </li>
            {/* времменно скрыта */}
          </ul>

          {statusPage === StudentPageMode.NORMAL && (
            <div className="header-right-wrap">
              <div className="help-btn">
                <Button variant="primary" onClick={() => window.open(linkToMettermostHelpBot, '_blank')?.focus()}>
                  Сообщить о проблеме
                </Button>
              </div>
              <span className="change-language-selector">
                {showChangeLanguageSelect && (
                  <select
                    className="form-control"
                    defaultValue={locale === `ru` ? `ru` : `en`}
                    onChange={event => changeLanguage(event)}
                  >
                    <option value="ru">Русский</option>
                    <option value="en">English</option>
                  </select>
                )}
              </span>
              {!hasError && <NotificationBlock />}
              <div id="profile-icn" className="profile-icn" onClick={() => setActive(!active)}>
                <img src={uploadedImageURL === '' ? '/images/man.png' : uploadedImageURL} id="user_pic" alt="avatar" />
              </div>
              {active && (
                <div id="profile-menu-wrap" className="profile-menu-wrap">
                  <ul>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a type="button" onClick={() => setShowChangeAvatarModal(true)}>
                        {t('ChangeAvatar')}
                      </a>
                    </li>
                    <li>
                      <Link to="/user/password">{t('ChangePassword')}</Link>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a type="button" onClick={() => AuthService.logout()}>
                        {t('Exit')}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}

          {statusPage === StudentPageMode.FAKE && (
            <div className="header-mentor-panel">
              {[RoleEnum.MENTOR, RoleEnum.ADMIN, RoleEnum.CHIEF_MENTOR, RoleEnum.CURATOR].includes(principalRole) && (
                <>
                  <div className="info">
                    <div className="info_select-wrapper">
                      <p className="info_text">Выбранный студент:</p>
                      <div className="info_select">
                        <select id="selectStudent" value={studentIdParam} onChange={changeStudent}>
                          {getNameSingleStudent()}
                          {mentorsStudents.map(getAllStudents)}
                        </select>
                      </div>
                    </div>
                    <div className="info_mentor">
                      <p className="info_text">Ментор:</p>
                      <small className="info_text">{mentorName}</small>
                    </div>
                  </div>
                </>
              )}
              {[RoleEnum.PAY_STUDENT].includes(principalRole) && (
                <>
                  {hostOfPage ? <span className="pageHostLabel">{`${t('pageOfStudent')}: ${hostOfPage}`}</span> : null}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <ChangeAvatarModal show={showChangeAvatarModal} onClose={() => setShowChangeAvatarModal(false)} />
      <ReportModal
        show={isDebtReport}
        onClose={() => {
          setIsDebtReport(false)
        }}
        getStudentReports={() => {}}
        reportDate={format(yesterdayDate, 'dd.MM.yyyy')}
        note={t('FillReportOfYesterday')}
        debtReport={isDebtReport}
      />
    </div>
  )
}

export default StudentHeader
