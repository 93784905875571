const getItemByIndex = (array, index) => {
  return array[index]
}

const deleteItemByIndex = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

const addItemByIndex = (array, index, item) => {
  return [...array.slice(0, index), item, ...array.slice(index)]
}

const addItem = (array, item) => {
  return [...array, item]
}

const copyArray = array => {
  return [...array]
}

const getIndexByKeyValue = (array, key, value) => {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][key] === value) {
      return i
    }
  }
  return -1
}

const sortArrayByKey = (array, key) => {
  return array.sort((value1, value2) => {
    if (value1[key] < value2[key]) {
      return -1
    }
    if (value1[key] > value2[key]) {
      return 1
    }
    return 0
  })
}

export { copyArray, addItemByIndex, deleteItemByIndex, sortArrayByKey, getItemByIndex, getIndexByKeyValue, addItem }
