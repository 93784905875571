import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import classes from './style.module.scss'
import Item from './item'
import Slot from './slot'
import range from '../../utils/range'

const ItemArranger = <Item,>(props: PropsWithChildren<Props<Item>>) => {
  const {
    items,
    resolveItemId,
    resolveItemLabel,
    resolveItemPosition,
    resolveSlotDesignation,
    onItemDrop,
    disabled,
    isItemRight,
  } = props

  const findItemByPosition = (position: number) => {
    if (!items) {
      return undefined
    }

    return items.find(item => resolveItemPosition(item) === position)
  }

  return (
    <div className={classes.wrap}>
      {/*todo: типизация классов в CSS module*/}
      <div className={cn(classes.items, { [classes.items_wide!]: !!resolveSlotDesignation })}>
        {items &&
          Array.from(range(1, items.length, true)).map(i => {
            const item = findItemByPosition(i)

            return (
              <Slot
                key={i}
                position={i}
                designation={resolveSlotDesignation && resolveSlotDesignation(i)}
                onItemDrop={onItemDrop}
                itemId={item && resolveItemId(item)}
                label={item && resolveItemLabel(item)}
                disabled={disabled}
                right={!!(item && isItemRight(item))}
              />
            )
          })}
      </div>

      {/*todo: типизация классов в CSS module*/}
      <div className={cn(classes.items, { [classes.items_narrow!]: !!resolveSlotDesignation })}>
        {items &&
          items.map(item => (
            <Item
              itemId={resolveItemId(item)}
              label={resolveItemLabel(item)}
              position={resolveItemPosition(item)}
              disabled={disabled}
            />
          ))}
      </div>
    </div>
  )
}

interface Props<Item> {
  items: Item[] | undefined
  resolveItemId: (item: Item) => number
  resolveItemLabel: (item: Item) => string
  resolveItemPosition: (item: Item) => number | null
  resolveSlotDesignation?: (position: number) => string | undefined
  isItemRight: (item: Item) => boolean | null
  onItemDrop: (itemId: number, position: number | null) => void
  disabled: boolean
}

export default ItemArranger
