enum RoleEnum {
  MENTOR = 'MENTOR',
  ADMIN = 'ADMIN',
  PAY_STUDENT = 'PAY_STUDENT',
  STUDENT = 'STUDENT',
  CHIEF_MENTOR = 'CHIEF_MENTOR',
  MANAGER = 'MANAGER',
  CHIEF_MANAGER = 'CHIEF_MANAGER',
  CURATOR = 'CURATOR',
  EMPTY = '',
  CHIEF = 'CHIEF',
}

export { RoleEnum }
