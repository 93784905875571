import { StudentMultiTestTaskItemAnswerDto } from '../../../../../../model/task-model/MultiTestTaskDto'
import mapAnswerToProbableAnswer from './mapAnswerToProbableAnswer'
import { Question } from './index'

const mapQuestionAnswersToProbableAnswers = (question: Question): StudentMultiTestTaskItemAnswerDto => ({
  ...question,
  studentItemAnswers: question.studentItemAnswers.map(mapAnswerToProbableAnswer),
})

export default mapQuestionAnswersToProbableAnswers
