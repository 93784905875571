import React from 'react'
import { useDispatch } from 'react-redux'
import { setCourseType } from '../../store/course-type/course-type.slice'
import CourseType from '../pages/student/course-type.model'

const withCourseType = (Component: React.ElementType, type: CourseType = CourseType.Regular) => (props: object) => {
  const dispatch = useDispatch()
  dispatch(setCourseType({ courseType: type }))

  return <Component {...props} />
}

export default withCourseType
