import { Dispatch, SetStateAction } from 'react'
import produce from 'immer'
import { Answer } from './index'

export interface MultiInputTaskSolveResult {
  multiInputItemId: number
  answerText: string
  isRight: boolean
}

const usePostSolveAnswerSetterCallback = (setAnswers: Dispatch<SetStateAction<Answer[]>>, answers: Answer[]) => {
  return (solveResult: MultiInputTaskSolveResult[]) => {
    const produced = produce(answers, draft => {
      solveResult.forEach(() => {
        draft.forEach(answer => {
          answer.right = solveResult.find(
            (result: MultiInputTaskSolveResult) => result.multiInputItemId === answer.itemId
          )?.isRight
        })
      })
    })
    setAnswers(produced)
    return solveResult
  }
}

export default usePostSolveAnswerSetterCallback
