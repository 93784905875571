import React from 'react'

const ListTheoryTaskDetails = ({ theoryTasks }) => {
  if (theoryTasks.length === 0) {
    return <div className="details-data">Список пуст</div>
  }
  return theoryTasks.map((entity, key) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <div className="details-data" key={`theory_debt_${key}`}>
        {key + 1}. {entity.value}
      </div>
    )
  })
}

export default ListTheoryTaskDetails
