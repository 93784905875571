import { TaskType } from 'src/model/task-dto/task-type-enum'
import {
  StudentAssociationTaskResultDto,
  StudentGappingResultDto,
  StudentMultiAnswerTaskItemResultDto,
  StudentMultiInputResultDto,
  StudentMultiTestItemResultDto,
} from '../../../../model/student-model'
import StudentCourseTaskInfoService from '../../../../services/student-services/student-course-task-info-service'
import { Answer } from './task-block/association-task'
import { StudentGappingAnswerItemDto } from '../../../../model/task-model/GappingTaskDto'
import { StudentMultiAnswerTaskAnswerItemDto } from '../../../../model/task-model/MultiAnswerTaskDto'
import { StudentMultiTestTaskItemAnswerDto } from '../../../../model/task-model/MultiTestTaskDto'
import { OrderingTaskItemDto } from '../../../../model/task-model/OrderingTaskDto'
import { StudentMultiInputTaskResultDto } from '../../../../model/task-model/multiInputTaskDto'

interface ReturnValues {
  [TaskType.MultiInput]: (answers: any[]) => Promise<StudentMultiInputResultDto[]>
  [TaskType.Association]: (answers: Answer[]) => Promise<StudentAssociationTaskResultDto[]>
  [TaskType.Gapping]: (gappingAnswers: StudentGappingAnswerItemDto[]) => Promise<StudentGappingResultDto[]>
  [TaskType.Ordering]: (orderingAnswers: OrderingTaskItemDto[]) => Promise<string>
  [TaskType.MultiTest]: (
    multiTestAnswers: StudentMultiTestTaskItemAnswerDto[]
  ) => Promise<StudentMultiTestItemResultDto[]>
  [TaskType.MultiAnswer]: (
    multiAnswerAnswers: StudentMultiAnswerTaskAnswerItemDto[]
  ) => Promise<StudentMultiAnswerTaskItemResultDto[]>
  [TaskType.Word]: (newAnswer: string) => Promise<string>
  [TaskType.Code]: (code: string) => Promise<string>
  [TaskType.Lecture]: (solved: boolean) => Promise<void>
  [TaskType.Theory]: (answersId: number[]) => Promise<string>
  [TaskType.MentorCheckTask]: (answer: string) => Promise<string>
}

type Values =
  | ReturnValues[TaskType.MultiInput]
  | ReturnValues[TaskType.Association]
  | ReturnValues[TaskType.Gapping]
  | ReturnValues[TaskType.Ordering]
  | ReturnValues[TaskType.MultiTest]
  | ReturnValues[TaskType.MultiAnswer]
  | ReturnValues[TaskType.Word]
  | ReturnValues[TaskType.Code]
  | ReturnValues[TaskType.Lecture]
  | ReturnValues[TaskType.Theory]

const studentCourseTaskInfoService = new StudentCourseTaskInfoService()

export function getSolveTaskCallbackStudent(
  type: TaskType.MultiInput,
  courseTaskId: number
): ReturnValues[TaskType.MultiInput]
export function getSolveTaskCallbackStudent(
  type: TaskType.Association,
  courseTaskId: number
): ReturnValues[TaskType.Association]
export function getSolveTaskCallbackStudent(
  type: TaskType.Gapping,
  courseTaskId: number
): ReturnValues[TaskType.Gapping]
export function getSolveTaskCallbackStudent(
  type: TaskType.Ordering,
  courseTaskId: number
): ReturnValues[TaskType.Ordering]
export function getSolveTaskCallbackStudent(
  type: TaskType.MultiTest,
  courseTaskId: number
): ReturnValues[TaskType.MultiTest]
export function getSolveTaskCallbackStudent(
  type: TaskType.MultiAnswer,
  courseTaskId: number
): ReturnValues[TaskType.MultiAnswer]
export function getSolveTaskCallbackStudent(type: TaskType.Word, courseTaskId: number): ReturnValues[TaskType.Word]
export function getSolveTaskCallbackStudent(type: TaskType.Code, courseTaskId: number): ReturnValues[TaskType.Code]
export function getSolveTaskCallbackStudent(
  type: TaskType.Lecture,
  courseTaskId: number
): ReturnValues[TaskType.Lecture]
export function getSolveTaskCallbackStudent(type: TaskType.Theory, courseTaskId: number): ReturnValues[TaskType.Theory]
export function getSolveTaskCallbackStudent(taskType: keyof ReturnValues, courseTaskId: number): Values {
  switch (taskType) {
    case TaskType.MultiInput:
      return (answers: StudentMultiInputTaskResultDto[]) => {
        return studentCourseTaskInfoService.solveMultiInputTask(courseTaskId, {
          studentAnswers: answers.map(({ answerText, itemId }) => {
            return { answerText, multiInputItemId: itemId, itemId: undefined, right: undefined }
          }),
        })
      }

    case TaskType.Association:
      return (studentAnswers: Answer[]) => {
        return studentCourseTaskInfoService.solveAssociationTask(courseTaskId, {
          studentAnswers,
        })
      }

    case TaskType.Gapping:
      return (studentAnswers: StudentGappingAnswerItemDto[]) => {
        return studentCourseTaskInfoService.solveGappingTask(courseTaskId, {
          studentAnswers,
        })
      }

    case TaskType.Ordering:
      return (orderingAnswers: OrderingTaskItemDto[]) => {
        return studentCourseTaskInfoService.solveOrderingTask(courseTaskId, orderingAnswers)
      }

    case TaskType.MultiTest:
      return (studentAnswers: StudentMultiTestTaskItemAnswerDto[]) => {
        return studentCourseTaskInfoService.solveMultiTestTask(courseTaskId, {
          studentAnswers,
        })
      }

    case TaskType.MultiAnswer:
      return (studentAnswers: StudentMultiAnswerTaskAnswerItemDto[]) => {
        return studentCourseTaskInfoService.solveMultiAnswerTask(courseTaskId, {
          studentAnswers,
        })
      }

    case TaskType.Word:
      return (newAnswer: string) => {
        return studentCourseTaskInfoService.solveWordTask(courseTaskId, newAnswer)
      }

    case TaskType.Code:
      return (code: string) => {
        return studentCourseTaskInfoService.solveCodeCourseTask(courseTaskId, code)
      }

    case TaskType.Lecture:
      return (solved: boolean) => {
        if (!solved) return studentCourseTaskInfoService.solveLectureTask(courseTaskId)
        return Promise.resolve()
      }

    case TaskType.Theory:
      return (answersId: number[]) => {
        return studentCourseTaskInfoService.solveTheoryTask(courseTaskId, answersId)
      }

    case TaskType.MentorCheckTask:
      return (answer: string) => {
        return studentCourseTaskInfoService.sendToCheck(courseTaskId, answer)
      }

    default:
      // @ts-ignore
      return () => {}
  }
}
