import { GappingTaskDto } from '../../../../../../model/task-model/GappingTaskDto'
import { GappingDirectionTaskDto } from '../../../../../../model/direction-model'

const useCanBeSolved = (
  gappingTask: GappingTaskDto | GappingDirectionTaskDto | undefined,
  gappingValues: Record<number, string>
) => {
  if (!gappingTask) {
    return false
  }

  return gappingTask.taskGappings.every(({ gappingItemId }) => gappingValues[gappingItemId])
}

export default useCanBeSolved
