import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store/rootReducer'
import { TFunction } from 'i18next'
import { ChapterNavigation } from '../../../../../services/common-services/user-navigation-service'
import AnimatedProgressBar from '../../../../animated-progress-bar/animated-progress-bar'
import styles from './student-bread-crumbs.module.scss'
import { getCourseProgress } from '../../../../../store/taskSlice'
import NumberUtil from '../../../../../utils/NumberUtil'
import { AbstractTaskIconDto, TaskHeadingDto } from '../../../../../model/student-model'

import { generateCourseURL } from '../../../../../utils/generateCourseURL'
import { selectCourseType } from '../../../../../store/course-type/course-type.selectors'
import StudentPageMode from '../../../../../model/StudentPageMode'
import { TaskType } from '../../../../../model/task-dto/task-type-enum'
import { convertResponseImageUrl } from '../../../../../utils/convertResponseImageUrl'

const taskTypeToString = (taskType: TaskType, translator: (word: string) => string) => {
  switch (taskType) {
    case TaskType.Lecture:
      return `${translator('Lecture')}`
    case TaskType.Code:
      return `${translator('PracticalTask')}`
    case TaskType.Theory:
      return `${translator('TheoreticalTask')}`
    case TaskType.Word:
      return `${translator('SecurityQuestion')}`
    case TaskType.Ordering:
      return `${translator('OrderTask')}`
    case TaskType.Association:
      return `${translator('AssociationTask')}`
    case TaskType.MultiTest:
      return `${translator('MultiTestTask')}`
    case TaskType.MultiAnswer:
      return `${translator('MultiAnswerTask')}`
    case TaskType.Gapping:
      return `${translator('GappingTask')}`
    case TaskType.MentorCheckTask:
      return `${translator('TheTaskVerifiedByTheMentor')}`
    case TaskType.ReviewStep:
      return `${translator('GroupReviewByModule')}`
    case TaskType.MultiInput:
      return `${translator('MultiInputTask')}`
    default:
      return `${translator('Task')}`
  }
}

const getBreadcrumbsTitle = (
  t: TFunction,
  headTaskType: TaskType,
  modulePosition: number,
  chapterPosition: number,
  courseTaskPosition: number,
  taskTitle: string = ''
) => {
  return `${taskTypeToString(headTaskType, t)} ${modulePosition}.${chapterPosition}.${courseTaskPosition} ${taskTitle}`
}

interface StudentBreadCrumbsProps {
  tasksHeading: TaskHeadingDto | null
  chapterNavigation: ChapterNavigation | null
  courseId: number
  modulePosition?: number
  chapterPosition?: number
  courseTaskPosition?: number
  statusPage: StudentPageMode
  studentId?: number
  isLoading: boolean
}
const StudentBreadCrumbs: React.FC<StudentBreadCrumbsProps> = ({
  tasksHeading,
  chapterNavigation,
  courseId,
  modulePosition = 0,
  chapterPosition = 0,
  courseTaskPosition = 0,
  statusPage,
  studentId = -1,
  isLoading,
}) => {
  const [currentTaskIcon, setCurrentTaskIcon] = useState<AbstractTaskIconDto>()
  const courseType = useSelector(selectCourseType)
  const dispatch = useDispatch()
  // const [breadCrumbsTitle, setBreadCrumbsTitle] = useState<string>()

  // eslint-disable-next-line id-length
  const { t } = useTranslation()
  const { studentPoints, coursePoints } = useSelector((state: RootState) => state.task)

  useEffect(() => {
    if (statusPage === StudentPageMode.NOT_READY) return
    dispatch(getCourseProgress({ courseId, studentId, statusPage }))
  }, [courseId, statusPage, studentId])

  const progress = useMemo(() => {
    return NumberUtil.getPercentage(studentPoints, coursePoints)
  }, [studentPoints, coursePoints])

  useEffect(() => {
    if (!tasksHeading) return
    const { taskHeadingDtos } = tasksHeading
    if (!taskHeadingDtos || taskHeadingDtos.length <= courseTaskPosition - 1) return
    setCurrentTaskIcon(taskHeadingDtos[courseTaskPosition - 1])
  }, [tasksHeading])

  const { courseName = '', chapterName, coursePicUrl = null, taskHeadingDtos: iconsDto } = tasksHeading ?? {}

  const coursePic = convertResponseImageUrl(coursePicUrl)

  useEffect(() => {
    if (!tasksHeading) return
    const { taskHeadingDtos } = tasksHeading
    if (taskHeadingDtos && taskHeadingDtos.length > courseTaskPosition - 1) {
      const { taskType } = taskHeadingDtos[courseTaskPosition - 1]!
      document.title = taskTypeToString(taskType, t)
    } else {
      const { courseName } = tasksHeading
      document.title = courseName
    }
  }, [tasksHeading])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const prevPageLink = useMemo(() => {
    let link
    if (isLoading || !chapterNavigation) return '#'
    const {
      prevModuleChapterCount: previousModuleChapterCount,
      taskCountInPrevChapter: previousChapterTaskCount,
    } = chapterNavigation
    if (+chapterPosition === 1) {
      link = `/user/courses/${courseId}/${+modulePosition -
        1}/${previousModuleChapterCount}/${previousChapterTaskCount}`
    } else {
      link = `/user/courses/${courseId}/${modulePosition}/${+chapterPosition - 1}/${previousChapterTaskCount}`
    }
    if (statusPage === StudentPageMode.FAKE) {
      link += `?studentId=${studentId}`
    }
    return generateCourseURL(link, courseType)
  }, [courseId, modulePosition, chapterPosition, chapterNavigation, isLoading, studentId, statusPage])

  const nextPageLink = useMemo(() => {
    if (isLoading || !chapterNavigation) return '#'
    const { currentModuleChapterCount: chapterCount } = chapterNavigation
    let link
    if (+chapterPosition === +chapterCount) {
      link = `/user/courses/${courseId}/${+modulePosition + 1}/1/1`
    } else {
      link = `/user/courses/${courseId}/${modulePosition}/${+chapterPosition + 1}/1`
    }
    if (statusPage === StudentPageMode.FAKE) {
      link += `?studentId=${studentId}`
    }

    return generateCourseURL(link, courseType)
  }, [courseId, modulePosition, chapterPosition, chapterNavigation, isLoading, studentId, statusPage])

  const isFirstChapter = useMemo(() => {
    return +chapterPosition === 1 && +modulePosition === 1
  }, [chapterPosition, modulePosition])

  const isLastChapter = useMemo(() => {
    if (!chapterNavigation) return true
    const { currentModuleChapterCount: chapterCount, moduleCount } = chapterNavigation
    return +chapterPosition === +chapterCount && +modulePosition === +moduleCount
  }, [chapterPosition, modulePosition, chapterNavigation])

  const courseLogoClass = useMemo(() => {
    return cn('page-title-course-pic', styles['course-progress-bar'])
  }, [])

  const navigationButtonClass = useMemo(() => {
    return cn({
      'non-clickable': isLoading,
      'current completed': !isLoading,
    })
  }, [isLoading])

  const isNextChapterAvailable = !isLastChapter && chapterNavigation && !chapterNavigation.nextChapterBlocked

  let coursesLink = `/user/courses`
  let courseLink = `/user/courses/${courseId}`
  if (statusPage === StudentPageMode.FAKE) {
    coursesLink += `?studentId=${studentId}`
    courseLink += `?studentId=${studentId}`
  }

  return (
    <div id="middle_container">
      <div className={`middle-header ${courseTaskPosition !== 0 ? 'lesson-step' : ''}`}>
        <div className="container">
          <div className="row mx-auto flex-nowrap">
            {coursePic && (
              <div className={courseLogoClass}>
                <AnimatedProgressBar percentage={progress} className={styles['course-progress-bar__spinner']} />
                <img alt="course logo" src={coursePic} />
              </div>
            )}
            <div className="page-title-wrap">
              <div className="breadcrumbs">
                <span className="link">
                  <Link to={coursesLink}>{t('Courses')}</Link>
                </span>
                <span className="link">
                  <Link to={generateCourseURL(courseLink, courseType)}>{courseName}</Link>
                </span>
                {chapterName && (
                  <span className="link">
                    <span>{chapterName}</span>
                  </span>
                )}
              </div>
              <div className="page-title-wrap">
                <div className="page-title">
                  {currentTaskIcon &&
                    getBreadcrumbsTitle(
                      t,
                      currentTaskIcon.taskType,
                      modulePosition,
                      chapterPosition,
                      courseTaskPosition,
                      currentTaskIcon.taskTitle
                    )}
                </div>
                <div className="page-title-progress" id="page-title-progress" />
              </div>
              <div className="lesson-navigation" id="lesson-navigation">
                {iconsDto && !isFirstChapter && (
                  <Link className={navigationButtonClass} to={prevPageLink}>
                    <i className="mdi mdi-arrow-left" onClick={scrollToTop} />
                  </Link>
                )}

                {iconsDto &&
                  iconsDto.map(({ taskTitle, checked, solved, video, taskType, needToResolve }, idx) => {
                    const iconMdiClass = {
                      lecture: video ? 'mdi-play' : 'mdi-format-align-left',
                      code: 'mdi-code-tags',
                      multi_test: 'mdi-format-list-text',
                      multi_answer: 'mdi-format-list-numbered',
                      multi_input: 'mdi-clipboard-text-outline',
                      theory: 'mdi-format-list-bulleted',
                      ordering: 'mdi-equalizer-outline',
                      gapping: 'mdi-format-line-style',
                      association: 'mdi-arrow-expand-horizontal',
                      word: 'mdi-pencil',
                      mentorCheckTask: 'mdi-code-tags',
                      review_step: 'mdi-account-group',
                    }[taskType]

                    const iconClass = cn({
                      'current': idx + 1 == courseTaskPosition, //большая
                      'completed':
                        (taskType === TaskType.MentorCheckTask && checked && solved) ||
                        (taskType === TaskType.Code && solved && !needToResolve) ||
                        (taskType !== TaskType.Code && taskType !== TaskType.MentorCheckTask && solved), //зелееная
                      'need-check': taskType === TaskType.MentorCheckTask && !checked && solved, //желтая
                      'unright':
                        (taskType === TaskType.MentorCheckTask && checked && !solved) ||
                        (taskType === TaskType.Code && needToResolve), //красная
                    })

                    let taskLink = `/user/courses/${courseId}/${modulePosition}/${chapterPosition}/${idx + 1}`
                    if (statusPage == StudentPageMode.FAKE) {
                      taskLink += `?studentId=${studentId}`
                    }
                    return (
                      <Link
                        key={`task_${idx}`}
                        title={getBreadcrumbsTitle(t, taskType, modulePosition, chapterPosition, idx + 1, taskTitle)}
                        to={generateCourseURL(taskLink, courseType)}
                        className={iconClass}
                      >
                        <i className={`mdi ${iconMdiClass}`} onClick={scrollToTop} />
                      </Link>
                    )
                  })}

                {iconsDto && isNextChapterAvailable && (
                  <Link className={navigationButtonClass} to={nextPageLink}>
                    <i className="mdi mdi-arrow-right" onClick={scrollToTop} />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(StudentBreadCrumbs)
