import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import { RouteComponentProps } from 'react-router-dom'
import useSetDocumentTitle from 'src/hooks/useSetDocumentTitle'
import moment from 'moment'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { history } from '../../../../../config'
import { InviteTokenDto } from '../../../../../model/invite-dto'
import { IFormValues, useEnrolleeSchema } from './enrollee-registration-schema'
import { EnrolleeRegistrationService } from '../../../../../services/enrollee-services/enrollee-regestration-services'
import styles from './enrollee-registration.module.css'
import { EnrolleeRegistrationDto } from '../../../../../model/enrollee-dto/enrollee-registration-dto'

type TParams = { hash: string }

const EnrolleeRegistration = ({ match }: RouteComponentProps<TParams>): JSX.Element => {
  const {
    params: { hash },
  } = match

  const { catchErrorAlert, reactAlert } = useReactAlert()
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [serverError, setServerError] = useState('')
  const [schema] = useEnrolleeSchema()
  const borderColor: string = 'rgb(184, 68, 66)'
  const initialValues = {
    firstName: '',
    lastName: '',
    birthday: new Date(),
    password: '',
    passwordConfirmation: '',
    email,
  }

  useSetDocumentTitle(t('registration'))

  const handleFormSubmit = (values: IFormValues) => {
    const momentBirthday = moment(values.birthday).format('DD.MM.YYYY')
    const data: EnrolleeRegistrationDto = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      hash: match.params.hash,
      birthday: momentBirthday,
    }
    EnrolleeRegistrationService.enrolleeRegistration(data)
      .then(() => {
        history.push({
          pathname: '/login',
          search: '?param=start',
        })
      })
      .catch((error: any) => {
        catchErrorAlert(error)
        setServerError(error.text ? error.text : t('unknownServerError'))
      })
  }

  useEffect(() => {
    EnrolleeRegistrationService.getTokenInformation(hash)
      .then((result: InviteTokenDto) => {
        setEmail(result.email)
      })
      .catch(() => {
        history.push({
          pathname: '/login',
          search: '?param=invalidRegToken',
        })
      })
  }, [hash])

  return (
    <div className="auth-wrap register">
      <div className="logo">
        <img alt="" src="/../../images/header-logo.svg" />
      </div>
      <div className="auth-head">
        <p>{t('registration')}</p>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={schema}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <Field
              style={{ borderColor: errors.firstName && touched.firstName ? borderColor : null }}
              type="text"
              name="firstName"
              id="firstName"
              placeholder={t('YourName')}
              value={values.firstName}
              onChange={handleChange}
            />
            <Field
              style={{ borderColor: errors.lastName && touched.lastName ? borderColor : null }}
              type="text"
              name="lastName"
              id="lastName"
              placeholder={t('Surname')}
              value={values.lastName}
              onChange={handleChange}
            />
            <Field
              style={{ borderColor: errors.birthday && touched.birthday ? borderColor : null }}
              id="birthday"
              type="date"
              className={`${styles.birthday}`}
              value={moment(new Date(values.birthday)).format('yyyy-MM-DD')}
              onChange={handleChange}
            />
            <Field
              disabled
              type="text"
              name="email"
              id="email"
              placeholder={t('firstCallEmail')}
              value={values.email}
              onChange={handleChange}
            />
            <Field
              style={{ borderColor: errors.password && touched.password ? borderColor : null }}
              type="password"
              name="password"
              id="password"
              placeholder={t('Password')}
              value={values.password}
              onChange={handleChange}
            />
            <Field
              style={{
                borderColor: errors.passwordConfirmation && touched.passwordConfirmation ? borderColor : null,
              }}
              type="password"
              name="passwordConfirmation"
              id="passwordConfirmation"
              placeholder={t('PasswordConfirmation')}
              value={values.passwordConfirmation}
              onChange={handleChange}
            />
            {[errors.firstName, errors.lastName, errors.password, errors.passwordConfirmation].includes(
              t('registrationEmptyFields')
            ) ? (
              <div className="alert alert-danger">{t('registrationEmptyFields')}</div>
            ) : null}

            {errors.password && touched.password && errors.password !== t('registrationEmptyFields') ? (
              <div className="alert alert-danger">{errors.password}</div>
            ) : null}

            {errors.passwordConfirmation &&
            touched.passwordConfirmation &&
            errors.passwordConfirmation !== t('registrationEmptyFields') ? (
              <div className="alert alert-danger">{errors.passwordConfirmation}</div>
            ) : null}

            <div className="alert alert-danger" style={{ display: serverError === '' ? 'none' : 'block' }}>
              <span>{serverError}</span>
            </div>

            <button className="login-btn" type="submit">
              {t('firstCallSignUp')}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EnrolleeRegistration
