import { Dispatch, SetStateAction } from 'react'
import produce from 'immer'

export interface GappingTaskSolveResult {
  gappingItemId: number
  gappingItemPosition: number
  gappingText: string
  isRight: boolean
}

const usePostSolveAnswerSetterCallback = (setAnswers: Dispatch<SetStateAction<any>>, answers: any) => {
  return (solveResult: GappingTaskSolveResult[]) => {
    const produced = produce(answers, (draft: any[]) => {
      solveResult.forEach(() => {
        draft.forEach(answer => {
          answer.isRight = solveResult.find(result => result.gappingItemId === answer.gappingItemId)?.isRight
        })
      })
    })
    setAnswers(produced)
  }
}

export default usePostSolveAnswerSetterCallback
