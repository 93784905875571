import React, { Component, ErrorInfo, ReactNode } from 'react'
import GeneralErrorPage from '../pages/errors/general-error-page'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  errorMessage: string
  errorStack: string
}

class GlobalErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: '',
    errorStack: '',
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState(prevState => ({
      ...prevState,
      hasError: true,
      errorMessage: error.message,
      errorStack: errorInfo.componentStack,
    }))
  }

  render() {
    const { hasError, errorStack, errorMessage } = this.state
    const { children } = this.props
    return hasError ? <GeneralErrorPage errorStack={errorStack} errorMessage={errorMessage} /> : children
  }
}

export default GlobalErrorBoundary
