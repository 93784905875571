import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { ReviewType } from 'src/model/review/review-type'
import { ReviewStepTaskDto } from '../../../../../../model/task-model/ReviewStepTaskDto'
import TaskDescription from '../task-description'
import { ReviewEntryDto } from '../../../../../../model/student-model'
import ReviewStepModal from '../../review-step-modal/review-step-modal'

interface IProps {
  reviewStepTask: ReviewStepTaskDto
  isReviewAssignable: boolean
  loadReviewDto: any // () => Promise<ReviewEntryDto> | Promise<void>
  moduleId: number
  available: boolean
  completed: boolean
}

const ReviewStepTask = ({
  reviewStepTask,
  isReviewAssignable,
  loadReviewDto,
  moduleId,
  available,
  completed,
}: IProps) => {
  const { catchErrorAlert } = useReactAlert()
  const { t } = useTranslation()
  const [reviewEntryDto, setReviewEntryDto] = useState<any>({})
  const [modalIsOpen, setIsOpen] = useState(false)

  const getReviewEntryDto = () => {
    if (isReviewAssignable) {
      loadReviewDto()
        .then((reviewDto: ReviewEntryDto) => {
          setReviewEntryDto(reviewDto)
        })
        .catch((error: Error) => {
          catchErrorAlert(error)
        })
    }
  }

  useEffect(() => {
    if (isReviewAssignable) {
      getReviewEntryDto()
    }
  }, [moduleId])

  useEffect(() => {
    getReviewEntryDto()
  }, [modalIsOpen])

  return (
    <div className="task-content">
      <div className="step-content-head">{t('RecordingOnReview')}</div>
      <div className="step-content-head" />
      <TaskDescription description={reviewStepTask.description} />
      <div className="lesson-result-row">
        {isReviewAssignable && (
          <div className="lesson-result-right-wrap">
            {available ? (
              <button
                type="button"
                onClick={() => setIsOpen(true)}
                className="send-result-btn"
                disabled={completed}
                title={t('ReviewPassed')}
              >
                {reviewEntryDto.recordedDay ? t('GoToPost') : t('SignUpForAReview')}
              </button>
            ) : (
              <button type="button" className="send-result-btn" disabled title={t('ReviewCompletedWarning')}>
                {t('RecordingOnReview')}
              </button>
            )}
          </div>
        )}
      </div>
      <ReviewStepModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} reviewEntryDto={reviewEntryDto} />
    </div>
  )
}

export default ReviewStepTask
