import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { StudentCourseTaskServiceInterface } from '../interfaces/student-services/student-course-task-service'
import { TaskType } from '../../model/task-dto/task-type-enum'
import TaskDto from '../../model/task-dto/task-dto'

class StudentCourseTaskService implements StudentCourseTaskServiceInterface {
  private readonly sourceURL = `${serverHost}/api/student/task`

  public getCourseTask = async (courseTaskId: number, type: TaskType) => {
    const url = `${this.sourceURL}/${courseTaskId}/${type}`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    // eslint-disable-next-line no-return-await
    return await ApiService.request<TaskDto>(url, queryOption)
  }

  public getStudentCourseTaskIdByCourseTaskId = (courseTaskId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<number>(`${this.sourceURL}/${courseTaskId}/studentCourseTaskInfoId`, queryOption)
  }
}

export default StudentCourseTaskService
