import { serverHost } from '../config'
import ApiService from './api-service'
import { ExcerptionDto } from '../model/excerption-dto/excerption-dto'
import { QuoteServiceInterface } from './interfaces/quote-service'

const Url = {
  ROOT: `${serverHost}/api/excerption`,
}

const requestQuoteController = ApiService.generateControllerRequestHelper(Url.ROOT)

export const QuoteService: QuoteServiceInterface = class QuoteService {
  static getQuote = () => {
    return requestQuoteController<ExcerptionDto>('')
  }
}
