import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import { CourseInfoDto } from '../../model/courses-dto/course-info-dto'
import { UserCourseServiceInterface } from '../interfaces/user-services/user-course-service'
import { CourseAuthorDto } from '../../model/courses-dto/course-author-dto'

class UserCourseService implements UserCourseServiceInterface {
  private sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/user/course`
  }

  getCoursePic = (courseId: number) => {
    const queryOption = {
      method: HTTPMethods.GET,
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/${courseId}/course-pic`, queryOption)
  }

  getCourseName = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/${courseId}/name`, queryOption)
  }

  getCourseInfo = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseInfoDto>(`${this.sourceURL}/${courseId}/course-info`, queryOption)
  }

  getCourseAuthor = (courseId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<CourseAuthorDto>(`${this.sourceURL}/${courseId}/course-author`, queryOption)
  }

  moduleCount = (courseId: number) => {
    const url = `${this.sourceURL}/${courseId}/size`
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<number>(url, queryOption)
  }

  loadDirectionIdByStudentId = (studentId: number) => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<number>(`${this.sourceURL}/direction?studentId=${studentId}`, queryOption)
  }

  public getDirectionName(studentId: number) {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request<string>(`${this.sourceURL}/directionName?studentId=${studentId}`, queryOption)
  }
}

export default UserCourseService
