class JSONUtil {
  public static tryParseJSON(str: string) {
    try {
      return JSON.parse(str)
    } catch (e) {
      return str
    }
  }
}

export default JSONUtil
