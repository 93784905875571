/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { prepareCommentText, replaceColonsEmojiToNative, replaceNativeEmojiToColons } from 'src/utils/ComentsUtils'
import { RoleEnum } from 'src/utils/select-state/RoleEnum'
import { parse } from 'date-fns'
import cn from 'classnames'
import CommentForm from '../comment-form'
import DependComment from './depend-comment'
import EditCommentForm from '../edit-comment-form/edit-comment-form'
import StudentPageMode from '../../../../../../model/StudentPageMode'

const TaskComment = function TaskComment({
  taskComment: { id, text, date, user, commentsLikes, likes, dependentComments, preModerate },
  idx,
  authUser,
  avatars,
  changeLike,
  changeAnswerCommentLike,
  setOpenedAnswerForm,
  openedAnswerForm,
  editComment,
  setEditComment,
  updateComment,
  addAnswerToComment,
  setShowDependsComments,
  showDependsComments,
  updateAnswerComment,
  deleteComment,
  statusPage,
  principalRole,
  handleSubmitComment,
  handleDeleteComment,
}) {
  const { t } = useTranslation()
  const showMoreRef = useRef(null)
  const commentRef = useRef(null)
  const [isFullComment, setIsFullComment] = React.useState(false)

  useEffect(() => {
    if (commentRef) {
      commentRef.current.scrollHeight < 20 * 5 ? setIsFullComment(null) : setIsFullComment(false)
    }
  }, [commentRef])

  const handleCollapseClick = setFullComment => {
    const top = window.scrollY

    window.scrollTo(0, top)

    setFullComment(prevState => !prevState)
  }

  const handleAnswers = () => {
    id === showDependsComments ? setShowDependsComments(0) : setShowDependsComments(id)
  }

  const clicked = commentsLikes.some(like => authUser.id === like.id)
  const likesClass = `mdi glyphicon-comments icon icon-like ${clicked ? 'mdi-heart clicked' : 'mdi-heart-outline '}`
  const d = parse(date, 'dd.MM.yyyy HH:mm', new Date())
  d.setMinutes(d.getMinutes() + 5)
  const dependsComment = dependentComments.map((comment, index) => {
    return (
      <DependComment
        comment={comment}
        avatars={avatars}
        authUser={authUser}
        setEditComment={setEditComment}
        editComment={editComment}
        updateAnswerComment={updateAnswerComment}
        mainCommentId={id}
        mainIndex={idx}
        idx={index}
        key={`comment_${comment.id}`}
        deleteComment={deleteComment}
        statusPage={statusPage}
        principalRole={principalRole}
        changeAnswerCommentLike={changeAnswerCommentLike}
        handleSubmitComment={handleSubmitComment}
        handleDeleteComment={handleDeleteComment}
        handleCollapseClick={handleCollapseClick}
      />
    )
  })

  const studentActionPanel = d > new Date() && (
    <div className="comments-student-action-panel">
      <span
        className="icon-btn"
        onClick={() => {
          setEditComment({
            id,
            text: replaceColonsEmojiToNative(text),
          })
        }}
      >
        <span className="mdi mdi-pencil icon icon-edit" />
      </span>
      <span
        className="icon-btn"
        onClick={() => {
          deleteComment(id)
        }}
      >
        <span className="mdi mdi-delete icon icon-edit" />
      </span>
    </div>
  )

  const studentEditCommentActionPanel = (
    <div className="comments-student-action-panel">
      <span
        className="icon-btn"
        onClick={() => updateComment(replaceNativeEmojiToColons(editComment.text), editComment.id)}
      >
        <span className="glyphicon-comments mdi mdi-check icon icon-edit" style={{ color: `green` }} />
      </span>
      <span
        className="icon-btn"
        onClick={() => {
          setEditComment({ id: 0, text: '' })
        }}
      >
        <span className="glyphicon-comments mdi mdi-close-outline icon icon-edit" style={{ color: `red` }} />
      </span>
    </div>
  )
  return (
    <div className="comment-block" key={`comment_${id}`}>
      <div className="top-row">
        <div className="user-wrap">
          <div className="user-pic">
            <img src={avatars[user.id] || '/images/avatar.png'} alt="user avatar" />
          </div>
          <div className="user-detail">
            <div className="user-name">
              <span>{`${user.firstName} ${user.lastName}`}</span>
              {authUser.id === user.id && (editComment.id === id ? studentEditCommentActionPanel : studentActionPanel)}
            </div>
            <div className="review-date">{date}</div>
          </div>
          {statusPage === StudentPageMode.FAKE && principalRole !== RoleEnum.PAY_STUDENT && !preModerate ? (
            <div className="premoder-buttons-wrapper">
              <button type="button" className="premoder-button__submit" onClick={() => handleSubmitComment(id, idx)} />
              <button type="button" className="premoder-button__delete" onClick={() => handleDeleteComment(id)} />
            </div>
          ) : null}
        </div>
      </div>
      {editComment.id === id ? (
        <EditCommentForm comment={editComment} setEditComment={setEditComment} />
      ) : (
        <>
          <div
            className={cn('comment-text', { 'comment-text--collapse': !isFullComment })}
            id={`comment-text${id}`}
            ref={commentRef}
            dangerouslySetInnerHTML={{
              __html: prepareCommentText(text.replace(/[<>]/g, match => (match === '<' ? '&lt;' : '&gt;'))),
            }}
          />
          {isFullComment !== null && (
            <button
              ref={showMoreRef}
              type="button"
              onClick={() => handleCollapseClick(setIsFullComment)}
              className="commentCollapse commentCollapse--show"
            >
              {isFullComment ? 'Скрыть' : 'Показать полностью'}
            </button>
          )}
        </>
      )}
      <div className="comment-interaction">
        <span title="Ответить на комментарий" onClick={() => setOpenedAnswerForm(openedAnswerForm === id ? {} : id)}>
          <span style={{ paddingLeft: '5px' }} className="glyphicon-comments mdi mdi-comment" />
        </span>
        <span className="comment-like-block" onClick={() => changeLike(idx)} style={{ paddingLeft: '7px' }}>
          <span className={likesClass} />
          {` ${likes}`}
        </span>
        {dependentComments.length > 0 && (
          <span
            onClick={() => {
              handleAnswers()
              setIsFullComment(false)
            }}
            className="depend-comment-button"
          >
            {t('Answers')} ({dependentComments.length})
          </span>
        )}
      </div>
      <div className="comment-plain-text" hidden="hidden" style={{ whiteSpace: 'pre' }}>
        {text}
      </div>
      <div style={{ paddingBottom: '10px' }}>
        {openedAnswerForm === id && (
          <div
            className="add-comment-wrap"
            style={{
              borderBottom: 'none',
              paddingBottom: 0,
            }}
          >
            <CommentForm isAnswerComment addAnswerComment={addAnswerToComment} />
          </div>
        )}
        {id === showDependsComments && <div className="answers">{dependsComment}</div>}
      </div>
    </div>
  )
}

export default TaskComment
