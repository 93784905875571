import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useSelector } from 'react-redux'
import { Collapse } from 'react-collapse'
import { useTranslation, withTranslation } from 'react-i18next'
import clx from 'classnames'
import { Icon } from '@iconify/react'
import lockOutline from '@iconify/icons-mdi/lock-outline'
import StudentCourseService from '../../../../services/student-services/student-course-service'
import StudentBreadCrumbs from '../components/student-bread-crumbs'
import UserCourseService from '../../../../services/common-services/user-course-service'
import FakeOpenStudentCourseService from '../../../../services/fake-page-services/open/fake-open-student-course-service'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import FakeProtectEnrollService from '../../../../services/fake-page-services/fake-protect-enroll-service'
import MentorReviewService from '../../../../services/mentor-services/mentor-review-service'

import { generateCourseURL } from '../../../../utils/generateCourseURL'
import { selectCourseType } from '../../../../store/course-type/course-type.selectors'

import style from './course-detail-page.module.scss'
import { availableRolesForCompleteCourse } from '../components/course-element/course-element'
import store from '../../../../store'
import { useStatusPage } from '../../../../hooks/useStatusPage'
import StudentPageMode from '../../../../model/StudentPageMode'

let studentCourseService = new StudentCourseService()
let fakeStudentCourseService = new FakeOpenStudentCourseService()

const mentorEnrollService = new FakeProtectEnrollService()
const userCourseService = new UserCourseService()
const mentorReviewService = new MentorReviewService()

store.subscribe(() => {
  const { courseType } = store.getState().courseType

  if (studentCourseService.courseType === courseType) {
    return
  }
  studentCourseService = new StudentCourseService(courseType)
  fakeStudentCourseService = new FakeOpenStudentCourseService(courseType)
})

const Row = ({ statusPage, moduleView, chapterView, courseId, isEnrolling, courseType, studentId }) => {
  const [isOpen, setOpen] = useState(false)
  const { blocked, position: modulePosition } = moduleView
  const { position, allTaskAmount, solvedTaskAmount, name, chapterId, taskViews = [] } = chapterView
  const disabledClass = allTaskAmount === 0 ? 'chapter-disabled' : ''
  const viewCollapse = taskViews?.length > 0 && !blocked
  const fullChapterLink = `/user/courses/${courseId}/${modulePosition}/${position}`
  let generatedLink = generateCourseURL(`${fullChapterLink}/1`, courseType)
  if (statusPage === StudentPageMode.FAKE) {
    generatedLink += `?studentId=${studentId}`
  }
  return (
    <>
      <tr key={`chapter_indicate_${chapterId}`}>
        <td className={disabledClass}>
          {viewCollapse && (
            <button className="chapter-tasks-btn" onClick={() => setOpen(open => !open)} type="button">
              {isOpen ? <i className="bi bi-chevron-down" /> : <i className="bi bi-chevron-right" />}
            </button>
          )}
          {blocked || !isEnrolling ? (
            <span>{`${modulePosition}.${position} ${name}`}</span>
          ) : (
            <Link to={generatedLink}>{`${modulePosition}.${position} ${name}`}</Link>
          )}
        </td>
        {blocked ? (
          <>
            <td className={disabledClass}>
              <Icon
                icon={lockOutline}
                style={{
                  color: '#f3616f',
                  fontSize: '18px',
                }}
              />
            </td>
            <td className={disabledClass} />
          </>
        ) : (
          <>
            <td className={disabledClass}>{`${solvedTaskAmount}/${allTaskAmount}`}</td>
            <td className={disabledClass}>
              {allTaskAmount !== 0 && allTaskAmount === solvedTaskAmount && (
                <i className="completed mdi mdi-check-circle" />
              )}
            </td>
          </>
        )}
      </tr>
      {viewCollapse && (
        <tr>
          <td className="container-chapter-tasks" colSpan="5">
            <Collapse isOpened={isOpen}>
              <div className="chapter-tasks">
                {taskViews.map(taskView => {
                  let generatedTaskLink = generateCourseURL(`${fullChapterLink}/${taskView.position}`, courseType)
                  if (statusPage === StudentPageMode.FAKE) {
                    generatedTaskLink += `?studentId=${studentId}`
                  }
                  let iconClass
                  const iconMdiClass = {
                    lecture: 'mdi-play',
                    code: 'mdi-code-tags',
                    multi_test: 'mdi-format-list-text',
                    multi_answer: 'mdi-format-list-numbered',
                    multi_input: 'mdi-clipboard-text-outline',
                    theory: 'mdi-format-list-bulleted',
                    ordering: 'mdi-equalizer-outline',
                    gapping: 'mdi-format-line-style',
                    association: 'mdi-arrow-expand-horizontal',
                    word: 'mdi-pencil',
                    mentorCheckTask: 'mdi-code-tags',
                    review_step: 'mdi-account-group',
                  }[taskView.type]

                  if (taskView.solved) {
                    iconClass = 'completed'
                  } else {
                    iconClass = ''
                  }

                  return (
                    <div className="chapter-task" key={taskView.taskId}>
                      <div
                        className={clx(style.iconWrapper, {
                          [style[`${iconClass}`]]: iconClass,
                        })}
                      >
                        <i className={`mdi ${iconMdiClass}`} />
                      </div>

                      {isEnrolling ? (
                        <Link to={generatedTaskLink}>{taskView.title}</Link>
                      ) : (
                        <span>{taskView.title}</span>
                      )}
                    </div>
                  )
                })}
              </div>
            </Collapse>
          </td>
        </tr>
      )}
    </>
  )
}

const CourseContent = ({
  moduleViewDtos,
  courseId,
  isEnrolling,
  statusPage,
  actionAvailable,
  studentId = -1,
  setReloadDto,
}) => {
  const courseType = useSelector(selectCourseType)
  const enrollStudentModule = moduleId => {
    mentorEnrollService.enrollStudentModule(courseId, moduleId, studentId).then(() => {
      setReloadDto(reload => !reload)
    })
  }
  const discardReview = moduleId => {
    mentorReviewService.discardReview(moduleId, studentId).then(() => {
      setReloadDto(reload => !reload)
    })
  }

  const isNotAvailableToEnroll = (allTaskAmount, solvedTaskAmount, moduleId) => {
    if (allTaskAmount !== 0 && solvedTaskAmount === allTaskAmount) {
      return (
        <span style={{ float: 'right' }}>
          <button className={style.moduleBtnReset} type="button" onClick={() => discardReview(moduleId)}>
            Сбросить ревью
          </button>
        </span>
      )
    }
    return (
      <span style={{ float: 'right' }}>
        <button className={style.moduleBtnDone} type="button" onClick={() => enrollStudentModule(moduleId)}>
          Сделать выполненным
        </button>
      </span>
    )
  }

  return (
    <div className="course-lessons">
      {moduleViewDtos.map(moduleViewDto => {
        const {
          chapterViewDtos,
          moduleId: id,
          position: modulePosition,
          allTaskAmount: allAmount,
          solvedTaskAmount: solveAmount,
        } = moduleViewDto
        return (
          <table className="lesson-table" key={`module_${modulePosition}`}>
            <thead>
              <tr>
                <td colSpan="3">
                  {`${moduleViewDto.position} ${moduleViewDto.name}`}
                  {actionAvailable && isNotAvailableToEnroll(parseInt(allAmount, 10), parseInt(solveAmount, 10), id)}
                </td>
              </tr>
            </thead>
            <tbody>
              {chapterViewDtos.map(chapterView => (
                <Row
                  key={chapterView.chapterId}
                  courseId={courseId}
                  studentId={studentId}
                  courseType={courseType}
                  isEnrolling={isEnrolling}
                  moduleView={moduleViewDto}
                  statusPage={statusPage}
                  chapterView={chapterView}
                />
              ))}
            </tbody>
          </table>
        )
      })}
    </div>
  )
}

const CourseDetailsPage = ({ history, match: { params: { courseId } = {} } = {} }) => {
  const courseType = useSelector(selectCourseType)
  const { t } = useTranslation()
  const [moduleViewDtos, setModuleViewDtos] = useState([])
  const [courseInfo, setCourseInfo] = useState({})
  const [courseAuthor, setCourseAuthor] = useState({})
  const [isEnrolling, setIsEnrolling] = useState(true)
  const [reloadDto, setReloadDto] = useState(true)
  const { target, demands, description, filling, transitTime, coursePicUrl } = courseInfo
  const { name, email, about } = courseAuthor
  const { statusPage, studentIdParam, principalRole, principalId } = useStatusPage()
  const [tasksHeading, setTasksHeading] = useState()

  useEffect(() => {
    if (principalRole === RoleEnum.EMPTY || courseId === 0) return
    userCourseService.getCourseInfo(courseId).then(setCourseInfo)
    userCourseService.getCourseAuthor(courseId).then(setCourseAuthor)

    if (studentIdParam !== -1 && principalRole === RoleEnum.PAY_STUDENT && statusPage === StudentPageMode.FAKE) {
      Promise.all([
        fakeStudentCourseService.loadEnrollingState(courseId, principalId),
        fakeStudentCourseService.loadEnrollingState(courseId, studentIdParam),
      ]).then(values => {
        setIsEnrolling(values[0] || (values[0] && values[1]))
      })
    }
    if (studentIdParam !== -1 && statusPage === StudentPageMode.FAKE) {
      fakeStudentCourseService.loadEnrollingState(courseId, studentIdParam).then(setIsEnrolling)
      fakeStudentCourseService.loadModuleViewDto(courseId, studentIdParam).then(setModuleViewDtos)
      fakeStudentCourseService.getTasksHeading(studentIdParam, courseId).then(setTasksHeading)
    } else {
      studentCourseService.getTasksHeading(courseId).then(setTasksHeading)
      studentCourseService.loadEnrollingState(courseId).then(setIsEnrolling)
      studentCourseService.loadModuleViewDto(courseId).then(setModuleViewDtos)
    }
  }, [courseId, studentIdParam, reloadDto, principalRole])

  const enrollInCourse = () => {
    studentCourseService
      .enrollInCourseById(courseId)
      .then(() => {
        history.push(generateCourseURL(`/user/courses/${courseId}/1/1/1`, courseType))
      })
      .catch()
  }

  return (
    <>
      <StudentBreadCrumbs
        statusPage={statusPage}
        studentId={studentIdParam}
        courseId={courseId}
        tasksHeading={tasksHeading}
        isLoading={tasksHeading === undefined}
      />
      <div className="detail-course-info-wrap">
        <div className="container">
          <div className="detail-course-info-block">
            <div className="detail-course-text">{description}</div>

            <div className="right-wrap">
              <div className="info-block load">
                <i className="mdi mdi-school" />
                <div className="block-text">
                  <b>{t('Load')}:</b>
                  <span>{filling}</span>
                </div>
              </div>

              <div className="info-block time">
                <i className="mdi mdi-clock" />
                <div className="block-text">
                  <b>{t('CourseTime')}:</b>
                  <span>{transitTime}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="detail-course-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-8 course-left-wrap">
              <div className="main-course-info sbox">
                <Tabs>
                  <TabList>
                    <div className="tabs-wrap">
                      <Tab selectedClassName="active" className="course-tab">
                        {t('Content')}
                      </Tab>
                    </div>
                  </TabList>
                  <TabPanel selectedClassName="active" className="tab-content">
                    <CourseContent
                      moduleViewDtos={moduleViewDtos}
                      courseId={courseId}
                      isEnrolling={isEnrolling}
                      studentId={studentIdParam}
                      statusPage={statusPage}
                      setReloadDto={setReloadDto}
                      actionAvailable={availableRolesForCompleteCourse.includes(principalRole)}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            <div className="col-md-4">
              <div className="about-author sbox">
                <div className="about-author-title">{t('AboutCourseAuthor')}</div>
                <div className="author-wrap">
                  <div className="author-detail">
                    <div className="author-name">{name}</div>
                    <div className="author-detail-info">{email}</div>
                  </div>
                </div>
                <div className="author-text">{about}</div>
              </div>

              <div className="requirements-wrap sbox">
                <div className="req-wrap">
                  <div className="req-title">{t('Requirements')}</div>
                  <div id="requirement" className="req-text">
                    {demands}
                  </div>
                </div>

                <div className="target-wrap">
                  <div className="target-title">{t('TheTargetAudience')}</div>
                  <div className="target-text">{target}</div>
                </div>
              </div>
              {!isEnrolling && statusPage === StudentPageMode.NORMAL && (
                <button type="button" className="select-course-btn" onClick={enrollInCourse}>
                  <i className="mdi mdi-check" />
                  <span>Поступить на курс</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(CourseDetailsPage)
