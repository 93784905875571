import { AuthService } from './auth-service'
import ApiService from './api-service'
import { Locale } from '../model/Locale'
import { HTTPMethods } from './http-enums-methods'
import { UserRegistrationDto } from '../model/user-dto/user-registration-dto'
import { serverHost } from '../config'

class RegistrationService {
  public registrationStudent = (user: UserRegistrationDto, locale: Locale) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<void>(`${serverHost}/api/registration/free/${locale}`, queryOption)
  }
}

export default RegistrationService
