import { Dispatch, SetStateAction, useEffect } from 'react'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import FakeOpenService from '../../../../services/fake-page-services/open/fake-open-service'
import { TaskHeadingDto } from '../../../../model/student-model'
import { TaskType } from '../../../../model/task-dto/task-type-enum'

const fakeOpenService = new FakeOpenService()

export const useOpenFakeAvailability = (
  role: RoleEnum | undefined,
  tasksHeading: TaskHeadingDto | undefined,
  courseTaskPosition: number,
  setAvailability: Dispatch<SetStateAction<Partial<boolean>>>
) => {
  const { catchErrorAlert } = useReactAlert()
  useEffect(() => {
    if (!tasksHeading || tasksHeading.taskHeadingDtos.length < courseTaskPosition) return
    const currentTaskIcon = tasksHeading.taskHeadingDtos[courseTaskPosition - 1]
    if (currentTaskIcon!.courseTaskId !== 0) {
      switch (currentTaskIcon!.taskType) {
        case TaskType.MentorCheckTask:
          if (role === RoleEnum.MENTOR) {
            setAvailability(true)
          } else {
            fakeOpenService
              .isMentorCheckTaskAvailable(currentTaskIcon!.courseTaskId)
              // @ts-ignore
              .then(setAvailability)
              .catch(err => catchErrorAlert(err))
          }
          break
        case TaskType.Code:
          if (role === RoleEnum.MENTOR) {
            setAvailability(true)
          } else {
            fakeOpenService
              .isCodeTaskAvailable(currentTaskIcon!.courseTaskId)
              // @ts-ignore
              .then(setAvailability)
              .catch(err => catchErrorAlert(err))
          }
          break
        default:
      }
    }
  }, [tasksHeading])
}
