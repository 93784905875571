import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { AuthService } from '../../services/auth-service'
import { RoleEnum } from '../../utils/select-state/RoleEnum'
import { adminServerHost } from '../../config'
import LinkButton from '../link-button'

type Props = {
  renderBreadCrumbs?: boolean
}

const NotFound: React.FC<Props & RouteComponentProps<any>> = ({ renderBreadCrumbs, location }) => {
  const infoRoutes = ['/user/contacts', '/user/legal_entity', '/user/public_offer']
  const [authUser, setAuthUser] = useState(AuthService.currentUserValue())
  const [isReady, setReady] = React.useState(false)

  useEffect(() => {
    if (!isReady && !authUser) {
      AuthService.fetchUserProfile().then(user => {
        setAuthUser(user)
        setReady(true)
      })
    } else {
      setReady(true)
    }
  }, [])

  if (infoRoutes.includes(location.pathname)) {
    return <></>
  }

  const renderDescription = () => {
    if (authUser?.role && ![RoleEnum.STUDENT, RoleEnum.PAY_STUDENT].includes(authUser.role.name)) {
      return (
        <div className="not-found-text">
          Вы авторизованы в качестве {authUser.role.name}, для которого не существует указанной страницы. <br />
          Вы можете перейти в <a href={adminServerHost}>панель администратора</a>,{' '}
          <LinkButton onClick={AuthService.logout}>выйти из аккаунта</LinkButton> или скорректировать ссылку вручную.
        </div>
      )
    }

    return (
      <div className="not-found-text">
        К сожалению, запрашиваемая вами страница не найдена. <br />
        Вернитесь на <Link to="/">главную</Link>.
      </div>
    )
  }

  return (
    <>
      {isReady && (
        <>
          {renderBreadCrumbs && (
            <div className="middle-header all-courses">
              <div className="container">
                <div className="page-title-wrap">
                  <div className="breadcrumbs">
                    <span className="link">
                      <Link to="/">Главная</Link>
                    </span>
                  </div>
                  <div className="page-title">Страница не найдена</div>
                </div>
              </div>
            </div>
          )}
          <div className="simple-page">
            <div className="container">
              <div className="content page-not-found">
                <div className="big-text">404</div>
                <div className="not-found-head">Страница не найдена</div>

                {renderDescription()}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default NotFound
