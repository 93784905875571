import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import styles from './multi-input-task.module.scss'

interface Props {
  answer: string
  question: string
  id: number
  setAnswer: (id: number, value: string) => void
  disabled: boolean
  isRight: boolean | undefined
}

const Item = (props: Props) => {
  const { t } = useTranslation()
  const { answer, disabled, question, setAnswer, id, isRight } = props

  let inputClassName
  if (isRight === undefined) {
    inputClassName = `form-control`
  } else {
    inputClassName = isRight ? `form-control ${styles.success}` : `form-control ${styles.error}`
  }

  return (
    <div className={styles.inputItem}>
      <ReactMarkdown source={question} />
      <input
        type="text"
        onChange={event => setAnswer(id, event.target.value)}
        className={`${inputClassName} ${styles.inputField}`}
        style={{ color: `black` }}
        placeholder={t('EnterYourAnswer')}
        value={answer || ''}
        disabled={disabled}
      />
    </div>
  )
}

export default Item
