import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import classes from './index.module.scss'

const LinkButton = (props: Props) => {
  return <button type="button" {...props} className={classes.linkButton} />
}

export default LinkButton

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {}
