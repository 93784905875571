import isNullish from '../../../../../../utils/isNullish'
import { DefinitionDto, TermDto } from '../../../../../../model/task-model/AssociationTaskDto'
import { Answer } from './index'

const useResolveItemPositionCallback = (answers: Answer[], terms: TermDto[] | undefined) => {
  return (item: DefinitionDto) => {
    if (!terms) {
      return null
    }

    const foundAnswer = answers.find(answer => answer.definitionId === item.definitionId)

    if (!foundAnswer) {
      return null
    }

    const index = terms.findIndex(term => term.termId === foundAnswer.termId)

    if (isNullish(index)) {
      return null
    }

    return index + 1
  }
}

export default useResolveItemPositionCallback
