import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from 'src/components/spinner'
import { useDispatch } from 'react-redux'
import TaskLoading from '../TaskLoading'
import TaskDescription from '../task-description'
import Result from '../result'
import Actions from '../actions'
import useLoadTaskCallback from '../useLoadTaskCallback'
import {
  MultiTestAnswerDto,
  MultiTestQuestionDto,
  MultiTestTaskDto,
} from '../../../../../../model/task-model/MultiTestTaskDto'
import Test from '../test'
import useIsSolvedRight from '../useIsSolvedRight'
import useCanBeSolved from '../useCanBeSolved'
import useIsSolved from './useIsSolved'
import useResetTaskCallback from '../useResetTaskCallback'
import useIsAnswerCheckedCallback from '../useIsAnswerCheckedCallback'
import useChangeAnswersCallback from '../useChangeAnswersCallback'
import useIsQuestionAnswersRightCallback from '../useIsQuestionAnswersRightCallback'
import mapQuestionAnswersToProbableAnswers from './mapQuestionAnswersToProbableAnswers'
import Category from '../category'
import usePostSolveAnswerSetter from './usePostSolveAnswerSetter'
import { MultiTestDirectionTaskDto } from '../../../../../../model/direction-model'

export interface Question {
  itemId: number
  studentItemAnswers: Answer[]
  isRight?: boolean
}

export interface Answer {
  itemAnswerId: number
}

const MultiTestTask: FC<Props> = ({
  multiTestTask,
  postResetInterceptor,
  onResetTask,
  onSolveTask,
  loadTask,
  postSolveInterceptor,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { description, tests } = multiTestTask || {}
  const [loading, setLoading] = useState<Partial<Loading>>({})
  const [questions, setQuestions] = useState<Question[]>([])
  const [isAnswersUpdatable, setIsAnswersUpdatable] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string>()

  const isSolved = useIsSolved(multiTestTask)
  const isSolvedRight = useIsSolvedRight(multiTestTask, questions, question => question.isRight as boolean)
  const canBeSolved = useCanBeSolved(
    multiTestTask?.tests,
    questions,
    question => question.itemId,
    answer => answer.itemId,
    answer => answer.studentItemAnswers.length
  )

  const loadMultiTestTask = useLoadTaskCallback(setLoading, loadTask)

  const resetTaskLocal = useResetTaskCallback(setLoading, onResetTask, loadTask, postResetInterceptor, () => {
    setQuestions([])
    setErrorText('')
  })

  const resetMultiTestTask = () => {
    return resetTaskLocal()
  }

  const postSolveAnswerSetter = usePostSolveAnswerSetter(questions, setQuestions)

  const solveMultiTestTask = async () => {
    try {
      setLoading(prevLoading => ({
        ...prevLoading,
        taskSolving: true,
      }))
      setErrorText('')
      const response = await onSolveTask(questions.map(mapQuestionAnswersToProbableAnswers))
      await loadMultiTestTask(true)
      if (response) setIsAnswersUpdatable(false)
      postSolveAnswerSetter(response)
      postSolveInterceptor(isSolved, loadTask, dispatch)
    } catch (error) {
      if (error.code === 423) {
        setErrorText(error.text)
      }
    } finally {
      setLoading(prevTaskLoading => ({
        ...prevTaskLoading,
        taskSolving: false,
      }))
    }
  }

  const isAnswerChecked = useIsAnswerCheckedCallback(
    questions,
    question => question.itemId,
    question => question.studentItemAnswers,
    answer => answer.itemAnswerId
  )
  const changeAnswers = useChangeAnswersCallback(
    questions,
    setQuestions,
    question => question.itemId,
    question => question.studentItemAnswers,
    answer => answer.itemAnswerId,
    (id, answers) => ({ itemId: id, studentItemAnswers: answers }),
    id => ({ itemAnswerId: id })
  )
  const isQuestionAnswersRight = useIsQuestionAnswersRightCallback<Question, MultiTestQuestionDto>(
    questions,
    question => question.itemId,
    question => question?.isRight,
    currentQuestion => currentQuestion.itemId
  )

  useEffect(() => {
    if (multiTestTask && isAnswersUpdatable) {
      setQuestions(multiTestTask.studentItems)
    }
  }, [multiTestTask])

  if (loading.taskLoading) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('MultiTestTask')}</div>
      <TaskDescription description={description} />

      <Category label="Вопросы задачи">
        {/* todo: Удалить типы, когда станет понятно, почему дженерик не резолвит их автоматически */}
        <Test<MultiTestQuestionDto, MultiTestAnswerDto>
          tests={tests}
          resolveQuestionId={question => question.itemId}
          resolveQuestionText={question => question.itemText}
          resolveQuestionAnswers={question => question.itemAnswers}
          resolveIsQuestionAnswersRight={isQuestionAnswersRight}
          resolveAnswerId={answer => answer.itemAnswerId}
          resolveAnswerText={answer => answer.itemAnswerText}
          isChecked={isAnswerChecked}
          onChange={changeAnswers}
          disabled={isSolved}
        />
      </Category>

      <div className="lesson-result-row">
        <Result isSolved={isSolved || Boolean(errorText)} isSolvedRight={isSolvedRight} customErrorText={errorText} />
        <Actions
          taskResettingLoading={loading.taskResetting}
          taskSolvingLoading={loading.taskSolving}
          canBeSolved={canBeSolved}
          isSolved={isSolved}
          resetTask={resetMultiTestTask}
          solveTask={solveMultiTestTask}
        />
      </div>
    </div>
  )
}

interface Props {
  multiTestTask: MultiTestTaskDto | MultiTestDirectionTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<MultiTestTaskDto | MultiTestDirectionTaskDto>
  onResetTask: () => Promise<any>
  onSolveTask: any
  postResetInterceptor: (val: any, ...args: any[]) => any
  postSolveInterceptor: (val: any, ...args: any[]) => any
}

export interface Loading extends TaskLoading {}

export default MultiTestTask
