import { serverHost } from '../../config'
import { AuthService } from '../auth-service'
import ApiService from '../api-service'
import { HTTPMethods } from '../http-enums-methods'
import { StudentNotificationsServiceInterface } from '../interfaces/student-services/student-notifications-service'
import { GeneralNotificationDto } from '../../model/notification-dto/general-notification-dto'

class StudentNotificationsService implements StudentNotificationsServiceInterface {
  private readonly sourceURL = `${serverHost}/api/student/generalNotification`

  public setStudentGeneralNotificationAsRead = (notificationId: number) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: `sgnId=${notificationId}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<string>(`${this.sourceURL}`, queryOption)
  }

  public getAllNotifications = () => {
    const queryOption = {
      headers: {
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request<GeneralNotificationDto[]>(`${this.sourceURL}`, queryOption)
  }
}

export default StudentNotificationsService
