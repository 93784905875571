import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import ReportCard from '../report-card'
import ReportModalItem from '../report-modal-item'
import { StudentReportGetDto } from '../../../../../model/student-model'
import { AuthService } from '../../../../../services/auth-service'
import { StudentReport } from '../report-page'
import styles from './report-list.module.scss'

const cx = classNames.bind(styles)
type Props = {
  getStudentReports: () => void
  reports: StudentReport[]
}

const ReportList: FC<Props> = ({ reports, getStudentReports }) => {
  const [currentStudentId, setCurrentStudentId] = useState<number>()
  const [selectedItem, setSelectedItem] = useState<StudentReportGetDto | null>(null)

  const closeSelectedModalItem = () => {
    setSelectedItem(null)
  }

  useEffect(() => {
    setCurrentStudentId(AuthService.currentUserValue()?.id)
  }, [])

  const renderNotFoundMessage = (
    <div className={cx('report-not-found')}>
      <h5 className={cx('report-message')}>По вашему запросу данных не найдено</h5>
    </div>
  )

  const renderReports = (
    <div className={cx('report-grid')}>
      {Object.values(reports).map((reportsRow: StudentReport, rowIndex: number) => (
        <div className={cx('report-row')} key={rowIndex}>
          {Object.values(reportsRow).map((report: StudentReportGetDto | null, idx: number) => {
            if (!report) {
              return <div className={cx('report-empty')} key={idx} />
            }
            return (
              <ReportCard
                key={report.id}
                report={report}
                currentStudentId={currentStudentId}
                getStudentReports={getStudentReports}
                setSelectedItem={setSelectedItem}
              />
            )
          })}
        </div>
      ))}
    </div>
  )

  return (
    <div className={cx('report-list')}>
      {renderReports}
      <ReportModalItem onClose={closeSelectedModalItem} data={selectedItem} />
    </div>
  )
}

export default ReportList
