import React from 'react'

import './alert-danger.css'

const AlertDanger = function AlertDanger({ description }) {
  return (
    <div className="lesson-question">
      <p className="alert alert-danger link-validation-error">{description}</p>
    </div>
  )
}

export default AlertDanger
