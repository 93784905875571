import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Spinner from 'src/components/spinner'
import {
  AssociationTaskDto,
  StudentAssociationTaskAnswerItemDto,
} from '../../../../../../model/task-model/AssociationTaskDto'
import TaskLoading from '../TaskLoading'
import TaskDescription from '../task-description'
import ItemArranger from '../../../../../item-arranger'
import useIsSolved from './useIsSolved'
import useSolveTaskCallback from '../useSolveTaskCallback'
import useCanBeSolved from './useCanBeSolved'
import Result from '../result'
import Actions from '../actions'
import useIsSolvedRight from '../useIsSolvedRight'
import useResetTaskCallback from '../useResetTaskCallback'
import useLoadTaskCallback from '../useLoadTaskCallback'
import useCurrentAnswerSetting from './useCurrentAnswerSetting'
import useIsItemRightCallback from './useIsItemRightCallback'
import useSetAnswerCallback from './useSetAnswerCallback'
import useResolveItemPositionCallback from './useResolveItemPositionCallback'
import usePostSolveAnswerSetterCallback from './usePostSolveAnswerSetterCallback'
import { AssociationDirectionTaskDto } from '../../../../../../model/direction-model'

interface Props {
  associationTask: AssociationTaskDto | AssociationDirectionTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<AssociationTaskDto | AssociationDirectionTaskDto>
  onResetTask: () => Promise<any>
  onSolveTask: any
  postResetInterceptor: (val: any, ...args: any[]) => any
  postSolveInterceptor: (val: any, ...args: any[]) => any
}

export type Answer = StudentAssociationTaskAnswerItemDto & { isRight?: boolean }

const AssociationTask: FC<Props> = ({
  associationTask,
  loadTask,
  onSolveTask,
  postResetInterceptor,
  onResetTask,
  postSolveInterceptor,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { description, definitions, terms } = associationTask || {}
  const [loading, setLoading] = useState<Partial<Loading>>({})
  const [answers, setAnswers] = useState<Answer[]>([])

  const isSolved = useIsSolved(associationTask)
  const isSolvedRight = useIsSolvedRight(associationTask, answers, answer => answer.isRight || false)
  const canBeSolved = useCanBeSolved(associationTask, answers)

  const loadAssociationTask = useLoadTaskCallback(setLoading, loadTask)

  const resetTaskLocal = useResetTaskCallback(setLoading, onResetTask, loadTask, postResetInterceptor, () =>
    setAnswers([])
  )

  const solveTaskLocal = useSolveTaskCallback(
    associationTask,
    async () => {
      return onSolveTask(answers)
    },
    async skipLoading => loadAssociationTask(skipLoading),
    canBeSolved,
    setLoading
  )

  const postSolveAnswerSetter = usePostSolveAnswerSetterCallback(setAnswers, answers)

  const solveAssociationTask = () => {
    solveTaskLocal()
      // @ts-ignore
      .then(postSolveAnswerSetter)
      .then(() => {
        postSolveInterceptor(isSolved, loadTask, dispatch)
      })
  }

  const isItemRight = useIsItemRightCallback(answers)
  const setAnswer = useSetAnswerCallback(answers, setAnswers, terms)
  const resolveItemPosition = useResolveItemPositionCallback(answers, terms)

  useCurrentAnswerSetting(associationTask, setAnswers, answers)

  if (loading.taskLoading) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="task-content">
      <div className="step-content-head">{t('AssociationTask')}</div>
      <TaskDescription description={description} />

      <ItemArranger
        disabled={isSolved}
        items={definitions}
        resolveItemId={item => item.definitionId}
        resolveItemLabel={item => item.text}
        resolveItemPosition={resolveItemPosition}
        resolveSlotDesignation={position => terms?.[position - 1]?.text}
        isItemRight={isItemRight}
        onItemDrop={setAnswer}
      />

      <div className="lesson-result-row">
        <Result isSolved={isSolved} isSolvedRight={isSolvedRight} />
        <Actions
          taskResettingLoading={loading.taskResetting}
          taskSolvingLoading={loading.taskSolving}
          canBeSolved={canBeSolved}
          isSolved={isSolved}
          resetTask={resetTaskLocal}
          solveTask={solveAssociationTask}
        />
      </div>
    </div>
  )
}

export interface Loading extends TaskLoading {}

export default AssociationTask
