import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import classNames from 'classnames/bind'

import styles from './edit-button-popup.module.scss'

const cx = classNames.bind(styles)
type Props = {
  setShowReportModal: () => void
  deleteReport: () => void
}
const EditButtonsPopup: FC<Props> = ({ setShowReportModal, deleteReport }) => {
  const { t } = useTranslation()

  return (
    <div className={cx('edit-buttons')}>
      <button type="button" className={cx('edit-btn', 'btn')} onClick={setShowReportModal}>
        {t('Edit')}
      </button>
      <button type="button" className={cx('edit-btn', 'btn')} onClick={deleteReport}>
        {t('Delete')}
      </button>
    </div>
  )
}
export default EditButtonsPopup
