import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useReactAlert } from 'src/hooks/useReactAlert'
import StudentBreadCrumbs from '../../components/student-bread-crumbs'
import '../../student-page.css'
import RowHistorySolution from './row-history-solution/row-history-solution'
import StudentCourseTaskInfoService from '../../../../../services/student-services/student-course-task-info-service'
import StudentCourseTaskService from '../../../../../services/student-services/student-course-task-service'
import FakeOpenStudentCourseTaskService from '../../../../../services/fake-page-services/open/fake-open-student-course-task-service'
import StudentCourseService from '../../../../../services/student-services/student-course-service'
import FakeOpenStudentCourseService from '../../../../../services/fake-page-services/open/fake-open-student-course-service'
import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import { addAvailableCourseId } from '../../../../../store/available-courses/availableCoursesSlice'
import FakeOpenStudentCourseTaskInfoService from '../../../../../services/fake-page-services/open/fake-open-student-course-task-info-service'
import { generateCourseURL } from '../../../../../utils/generateCourseURL'
import { selectCourseType } from '../../../../../store/course-type/course-type.selectors'
import { useStatusPage } from '../../../../../hooks/useStatusPage'
import StudentPageMode from '../../../../../model/StudentPageMode'

const fakeStudentCourseTaskService = new FakeOpenStudentCourseTaskService()
const studentCourseTaskService = new StudentCourseTaskService()
const studentCourseTaskInfoService = new StudentCourseTaskInfoService()
const fakeOpenStudentCourseTaskInfoService = new FakeOpenStudentCourseTaskInfoService()

const SolutionsHistory = ({ match, history }) => {
  const { principalId, principalRole, statusPage, studentIdParam } = useStatusPage()
  const { catchErrorAlert } = useReactAlert()

  const courseType = useSelector(selectCourseType)
  const studentCourseService = useMemo(() => new StudentCourseService(courseType), [])
  const fakeStudentCourseService = useMemo(() => new FakeOpenStudentCourseService(courseType), [])

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [tasksHeading, setTasksHeading] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [usersTasksSolutions, setUsersTaskSolutions] = useState([])
  const [sortByDate, setSortByDate] = useState('NEW')
  const [sortByResult, setSortByResult] = useState('ALL')

  const [studentCourseTaskInfoId, setStudentCourseTaskInfoId] = useState(0)
  const { availableCourseIds } = useSelector(({ availableCourses }) => availableCourses)

  const { courseId, modulePosition, chapterPosition, courseTaskPosition } = match.params

  const currentStudentCourseTaskInfoService =
    statusPage === StudentPageMode.NORMAL ? studentCourseTaskInfoService : fakeOpenStudentCourseTaskInfoService

  document.title = `${t('SolutionHistory')}`

  useEffect(() => {
    if (studentIdParam !== -1 && principalRole === RoleEnum.PAY_STUDENT && !availableCourseIds.includes(courseId)) {
      fakeStudentCourseService.loadEnrollingState(courseId, principalId).then(isEnrolled => {
        if (isEnrolled) {
          dispatch(addAvailableCourseId({ courseId }))
        } else {
          history.push(generateCourseURL('/user/courses?param=forbidden', courseType))
        }
      })
    }
  }, [courseId, studentIdParam, statusPage, principalRole])

  useEffect(() => {
    if (statusPage === StudentPageMode.NOT_READY) return
    if (statusPage === StudentPageMode.FAKE) {
      fakeStudentCourseService
        .getTasksHeading(studentIdParam, courseId, modulePosition, chapterPosition)
        .then(heading => {
          setTasksHeading(heading)
          setLoaded(true)
        })
    } else {
      studentCourseService.getTasksHeading(courseId, modulePosition, chapterPosition).then(heading => {
        setTasksHeading(heading)
        setLoaded(true)
      })
    }
  }, [courseId, modulePosition, chapterPosition, courseTaskPosition, statusPage, studentIdParam])

  useEffect(() => {
    if (!tasksHeading) return
    const { taskHeadingDtos } = tasksHeading
    if (!taskHeadingDtos || taskHeadingDtos.length < courseTaskPosition) return
    const { courseTaskId } = taskHeadingDtos[courseTaskPosition - 1]
    if (courseTaskId !== 0) {
      if (statusPage === StudentPageMode.NORMAL) {
        studentCourseTaskService.getStudentCourseTaskIdByCourseTaskId(courseTaskId).then(setStudentCourseTaskInfoId)
      } else if (statusPage === StudentPageMode.FAKE) {
        fakeStudentCourseTaskService
          .getStudentCourseTaskIdByCourseTaskId(courseTaskId, studentIdParam)
          .then(setStudentCourseTaskInfoId)
      }
    }
  }, [tasksHeading])

  useEffect(() => {
    if (studentCourseTaskInfoId !== 0) {
      currentStudentCourseTaskInfoService
        .getUsersTaskSolutions(studentCourseTaskInfoId, sortByResult, sortByDate)
        .then(solutions => {
          setUsersTaskSolutions(solutions)
        })
        .catch(error => {
          catchErrorAlert(error)
        })
    }
  }, [studentCourseTaskInfoId, sortByResult, sortByDate])

  const sortByDateChange = event => {
    setSortByDate(event.target.value)
  }

  const sortByResultChange = event => {
    setSortByResult(event.target.value)
  }

  return loaded ? (
    <div>
      <StudentBreadCrumbs
        isLoading={!loaded}
        courseId={courseId}
        modulePosition={modulePosition}
        chapterPosition={chapterPosition}
        courseTaskPosition={courseTaskPosition}
        tasksHeading={tasksHeading}
        statusPage={statusPage}
        studentId={studentIdParam}
      />
      <div className="solutions-wrapper">
        <div className="container">
          <div className="solution-content ">
            <div className="solution-content-head">
              <span>{t('Solutions')}</span>{' '}
              <span
                className="return-to-lesson-link"
                onClick={() => {
                  history.push(
                    generateCourseURL(
                      `/user/courses/${courseId}/${modulePosition}/${chapterPosition}/${courseTaskPosition}${
                        statusPage === StudentPageMode.FAKE ? `?studentId=${studentIdParam}` : ''
                      }`,
                      courseType
                    )
                  )
                }}
              >
                {t('ReturnToTask')}
              </span>
            </div>

            <div className="solution-sort-wrap">
              <Row>
                <Col>
                  <div className="select-wrap">
                    <select className="sort-select" onChange={sortByResultChange}>
                      <option name="by-result" value="ALL" defaultValue="ALL">
                        {t('AnyStatus')}
                      </option>
                      <option name="by-result" value="SUCCESS">
                        {t('TheRightSolution')}
                      </option>
                      <option name="by-result" value="FAILED">
                        {t('WrongSolution')}
                      </option>
                    </select>
                  </div>
                </Col>

                <Col>
                  <div className="select-wrap">
                    <select className="sort-select" onChange={sortByDateChange}>
                      <option name="by-date" value="NEW" defaultValue="NEW">
                        {t('NewFirst')}
                      </option>
                      <option name="by-date" value="OLD">
                        {t('FirstOld')}
                      </option>
                    </select>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="solutions-list">
              <table>
                <tbody>
                  {usersTasksSolutions.map(solution => (
                    <RowHistorySolution
                      id={solution.id}
                      key={`solution_${solution.id}`}
                      date={solution.date}
                      answer={solution.answer || solution.result}
                      result={solution.result}
                      right={solution.right}
                      codeLang={solution.codeLang}
                      type={solution.courseTask.task.type}
                      answerType={solution.answerType}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  )
}

export default withRouter(SolutionsHistory)
