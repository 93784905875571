import { AbstractStudentDirectionTaskDto } from '../../../../../model/direction-model'
import TaskDto from '../../../../../model/task-dto/task-dto'

const useIsSolvedRight = <AnswerType>(
  task: TaskDto | AbstractStudentDirectionTaskDto | undefined,
  answers: AnswerType[],
  isAnswerRight: (answer: AnswerType) => boolean
) => {
  if (answers.length === 0) return !!(task && task.lastActionIsRight)
  return answers.find(answer => !isAnswerRight(answer)) === undefined
}

export default useIsSolvedRight
