import { StudentMultiAnswerTaskAnswerItemDto } from '../../../../../../model/task-model/MultiAnswerTaskDto'
import mapAnswerToProbableAnswer from './mapAnswerToProbableAnswer'
import { Question } from './index'

const mapQuestionAnswersToProbableAnswers = (question: Question): StudentMultiAnswerTaskAnswerItemDto => ({
  questionId: question.questionId,
  studentItemAnswersIds: question.studentItemAnswers.map(mapAnswerToProbableAnswer),
})

export default mapQuestionAnswersToProbableAnswers
