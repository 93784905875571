import { serverHost } from '../config'
import { AuthService } from './auth-service'
import ApiService from './api-service'
import { HTTPMethods } from './http-enums-methods'
import { LocaleServiceInterface } from './interfaces/locale-service'
import { Locale } from '../model/Locale'

export default class LocaleService implements LocaleServiceInterface {
  sourceURL: string

  constructor() {
    this.sourceURL = `${serverHost}/api/locale`
  }

  changeLocale = (locale: Locale) => {
    const queryOption = {
      method: HTTPMethods.POST,
      body: `lang=${locale}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        ...AuthService.authHeaders(),
      },
    }

    return ApiService.request(`${this.sourceURL}/select_lang`, queryOption)
  }
}
