import ReactModal from 'react-modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './confirm-modal.css'

const customStyles = {
  content: {
    maxHeight: '100%',
    zIndex: 9999,
    width: '35%',
    top: '50%',
    left: '50%',
    right: '30%',
    bottom: 'initial',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
    zIndex: 10,
  },
}

const ConfirmModalReview = function ConfirmModalReview({
  show,
  onClose,
  onDeleteReview,
  studentReview: { moduleTitle, studentReviewId, reviewTime },
}) {
  ReactModal.setAppElement('#root')
  const { t } = useTranslation()
  return (
    <ReactModal isOpen={show} onRequestClose={onClose} style={customStyles}>
      <div className="confirm-header">
        <div className="box">
          <div className="confirm-title">{t('rejectionOfTheReviewHeader')}</div>
          <div className="close-button">
            <i className="mdi mdi-close" onClick={onClose} />
          </div>
        </div>
      </div>
      <div className="confirm-body">
        <p className="text-style">
          {t('rejectionOfTheReviewBodyPart1')}
          {moduleTitle}
          {t('rejectionOfTheReviewBodyPart2')}
          {reviewTime}
        </p>
      </div>
      <div className="footer-confirm">
        <button type="button" className="confirm-button" onClick={() => onDeleteReview(studentReviewId)}>
          {t('rejectionOfTheReviewFooter')}
        </button>
      </div>
    </ReactModal>
  )
}

export default ConfirmModalReview
