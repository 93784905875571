import React, { PropsWithChildren } from 'react'
import Item from './item'

interface Props<Item> {
  items: Item[] | undefined
  disabled: boolean
  resolveAnswer: (item: Item) => string | null
  resolveQuestion: (item: Item) => string | null
  resolveItemId: (item: Item) => number
  resolveIsRight: (item: Item) => boolean | undefined
  setAnswer: (id: number, value: string) => void
}

const InputItems = <Item,>(props: PropsWithChildren<Props<Item>>) => {
  const { items, disabled, resolveAnswer, resolveQuestion, resolveItemId, setAnswer, resolveIsRight } = props

  return (
    <div>
      {items?.map(item => {
        return (
          <div key={resolveItemId(item)}>
            <Item
              answer={resolveAnswer(item) || ''}
              question={resolveQuestion(item) || ''}
              isRight={resolveIsRight(item)}
              id={resolveItemId(item)}
              setAnswer={setAnswer}
              disabled={disabled}
            />
          </div>
        )
      })}
    </div>
  )
}

export default InputItems
