const useCanBeSolved = <Question, Answer>(
  questions: Question[] | undefined,
  answers: Answer[],
  resolveQuestionId: (question: Question) => number,
  resolveAnswerId: (answer: Answer) => number,
  resolveAnswerItemsLength: (answer: Answer) => number
) => {
  if (!questions) {
    return false
  }

  return questions.every(question =>
    answers.some(answer => resolveAnswerId(answer) === resolveQuestionId(question) && resolveAnswerItemsLength(answer))
  )
}

export default useCanBeSolved
