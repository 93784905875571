import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useReactAlert } from 'src/hooks/useReactAlert'
import TokenRegistrationService, { userData } from '../../../services/token-registration-service'
import styles from './registration-page.module.css'

const TokenRegistrationPage = (props: any) => {
  const { catchErrorAlert } = useReactAlert()
  const { t } = useTranslation()
  const borderColor: string = 'rgb(184, 68, 66)'
  const tokenRegistrationService = new TokenRegistrationService()
  const [email, setEmail] = useState('')
  const [serverError, setServerError] = useState('')
  const initialValues = {
    firstName: '',
    lastName: '',
    birthday: new Date(),
    password: '',
    passwordConfirmation: '',
    email,
  }

  document.title = t('registration')
  const {
    match: {
      params: { hash },
    },
  } = props
  tokenRegistrationService
    .getTokenInformation(hash)
    .then(result => {
      setEmail(result.email)
    })
    .catch(() => {
      document.location.href = '/login?param=invalidRegToken'
    })

  return (
    <div className="auth-wrap register">
      <div className="logo">
        <img alt="" src="/../../images/header-logo.svg" />
      </div>
      <div className="auth-head">
        <p>{t('registration')}</p>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          firstName: Yup.string().required(t('registrationEmptyFields')),
          lastName: Yup.string().required(t('registrationEmptyFields')),
          email: Yup.string().required(t('registrationEmptyFields')),
          password: Yup.string()
            .min(6, t('registrationShortPassword'))
            .required(t('registrationEmptyFields')),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password')], t('registrationConfirmPasswordError'))
            .required(t('registrationEmptyFields')),
          birthday: Yup.date().required(t('registrationEmptyFields')),
        })}
        onSubmit={values => {
          const momentBirthday = moment(values.birthday)
          const data: userData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            confirmPassword: values.passwordConfirmation,
            day: momentBirthday.date(),
            month: momentBirthday.month() + 1,
            year: momentBirthday.year(),
            hash: props.match.params.hash,
          }

          tokenRegistrationService
            .sendUserDataToTokenRegistration(data)
            .then(() => {
              document.location.href = '/login?param=start'
            })
            .catch(error => {
              console.error(error)
              setServerError(error.text ? error.text : t('unknownServerError'))
            })
        }}
      >
        {({ values, handleChange, setFieldValue, errors, touched }) => (
          <Form>
            <Field
              style={{ borderColor: errors.firstName && touched.firstName ? borderColor : null }}
              type="text"
              name="firstName"
              id="firstName"
              placeholder={t('YourName')}
              value={values.firstName}
              onChange={handleChange}
            />
            <Field
              style={{ borderColor: errors.lastName && touched.lastName ? borderColor : null }}
              type="text"
              name="lastName"
              id="lastName"
              placeholder={t('Surname')}
              value={values.lastName}
              onChange={handleChange}
            />
            <Field
              style={{ borderColor: errors.birthday && touched.birthday ? borderColor : null }}
              id="birthday"
              type="date"
              className={`${styles.birthday}`}
              value={moment(new Date(values.birthday)).format('yyyy-MM-DD')}
              onChange={handleChange}
            />
            <Field
              disabled
              type="text"
              name="email"
              id="email"
              placeholder={t('firstCallEmail')}
              value={values.email}
              onChange={handleChange}
            />
            <Field
              style={{ borderColor: errors.password && touched.password ? borderColor : null }}
              type="password"
              name="password"
              id="password"
              placeholder={t('Password')}
              value={values.password}
              onChange={handleChange}
            />
            <Field
              style={{
                borderColor: errors.passwordConfirmation && touched.passwordConfirmation ? borderColor : null,
              }}
              type="password"
              name="passwordConfirmation"
              id="passwordConfirmation"
              placeholder={t('PasswordConfirmation')}
              value={values.passwordConfirmation}
              onChange={handleChange}
            />
            {[errors.firstName, errors.lastName, errors.password, errors.passwordConfirmation].includes(
              t('registrationEmptyFields')
            ) ? (
              <div className="alert alert-danger">{t('registrationEmptyFields')}</div>
            ) : null}

            {errors.password && touched.password && errors.password !== t('registrationEmptyFields') ? (
              <div className="alert alert-danger">{errors.password}</div>
            ) : null}

            {errors.passwordConfirmation &&
            touched.passwordConfirmation &&
            errors.passwordConfirmation !== t('registrationEmptyFields') ? (
              <div className="alert alert-danger">{errors.passwordConfirmation}</div>
            ) : null}

            <div className="alert alert-danger" style={{ display: serverError === '' ? 'none' : 'block' }}>
              <span>{serverError}</span>
            </div>

            <button className="login-btn" type="submit">
              {t('firstCallSignUp')}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TokenRegistrationPage
