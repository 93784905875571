import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import './student-footer.css'
import { useTranslation } from 'react-i18next'

const currentYear = new Date().getFullYear()

const StudentFooter = () => {
  const { t, i18n } = useTranslation()
  const { path } = useRouteMatch()

  return (
    <div className="footer">
      <div className="container">
        <div className="left-wrap">
          <Link to="/" className="logo">
            <img src="/images/header-logo.svg" alt="logo" />
          </Link>
          <div className="footer-links">
            <ul>
              <li>
                <Link to={`${path}/contacts`}>{t('Contacts')}</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to={`${path}/legal_entity`}>{t('LegalEntity')}</Link>
              </li>
            </ul>
            <ul>
              <li>{i18n.language === `ru` && <Link to={`${path}/public_offer`}>{t('PublicOffer')}</Link>}</li>
            </ul>
          </div>
        </div>
        <div className="right-wrap">
          <ul className="soc-links">
            <li>
              <a href="https://t.me/kata_academy" target="_blank" rel="noopener noreferrer">
                <i className="mdi mdi-telegram" />
              </a>
            </li>
            <li>
              <a href="https://vk.com/kataacademy" target="_blank" rel="noopener noreferrer">
                <i className="mdi mdi-vk" />
              </a>
            </li>
          </ul>

          <div className="copyright">{currentYear} © KataAcademy</div>
        </div>
      </div>
    </div>
  )
}

export default StudentFooter
