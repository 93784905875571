import { serverHost } from '../../../config'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'

class FakeOpenStudentProfileService {
  openSourceURL

  constructor() {
    this.openSourceURL = `${serverHost}/api/fake/profile`
  }

  getTopics = studentId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.openSourceURL}/topics-names?studentId=${studentId}`, queryOption)
  }

  getTopicsCount = studentId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.openSourceURL}/topics-count?studentId=${studentId}`, queryOption)
  }

  getActivity = studentId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.openSourceURL}/solution-activity-year?studentId=${studentId}`, queryOption)
  }

  getDaysWithoutPause = studentId => {
    const queryOption = {
      headers: AuthService.authHeaders(),
    }
    return ApiService.request(`${this.openSourceURL}/day_without_pause?studentId=${studentId}`, queryOption)
  }
}

export default FakeOpenStudentProfileService
