import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import StudentCourseService from '../services/student-services/student-course-service'
import FakeOpenStudentCourseService from '../services/fake-page-services/open/fake-open-student-course-service'
import { RootState } from './rootReducer'
import StudentPageMode from '../model/StudentPageMode'

let studentCourseService = new StudentCourseService()
const fakeStudentCourseService = new FakeOpenStudentCourseService()

const initialState: State = {
  studentPoints: 0,
  coursePoints: 0,
}

export const getCourseProgress = createAsyncThunk('task/getCourseProgress', async (data: any, thunkAPI) => {
  const { courseType } = (thunkAPI.getState() as RootState).courseType

  if (studentCourseService.courseType !== courseType) {
    studentCourseService = new StudentCourseService(courseType)
  }

  const currentCourse =
    data.statusPage === StudentPageMode.NORMAL
      ? await studentCourseService.getCourseById(data.courseId)
      : await fakeStudentCourseService.getCourseById(data.courseId, data.studentId)

  if (!currentCourse) {
    return {
      studentPoints: 0,
      coursePoints: 0,
    }
  }

  const { studentPoints, coursePoints } = currentCourse

  return {
    studentPoints,
    coursePoints,
  }
})

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    changeStudentPoints(state, { payload }: PayloadAction<{ pointsDelta: number }>) {
      state.studentPoints += payload.pointsDelta
    },
    updateCoursePoints(state, { payload }: PayloadAction<{ coursePoints: number; studentPoints: number }>) {
      state.coursePoints = payload.coursePoints
      state.studentPoints = payload.studentPoints
    },
  },
  extraReducers: {
    [getCourseProgress.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ studentPoints: number; coursePoints: number }>
    ) => {
      state.studentPoints = payload.studentPoints
      state.coursePoints = payload.coursePoints
    },
  },
})

export const { changeStudentPoints, updateCoursePoints } = taskSlice.actions
export default taskSlice.reducer

type State = {
  studentPoints: number
  coursePoints: number
}
