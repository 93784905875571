import React from 'react'
import { QuoteService } from '../../../../../services/quote-service'

class QuoteBlock extends React.Component {
  state = {
    quote: {
      text: '',
      author: '',
    },
  }

  componentDidMount() {
    QuoteService.getQuote().then(quote => this.setState({ quote }))
  }

  render() {
    const { quote } = this.state

    if (!quote) {
      return null
    }

    const { text, author } = quote

    return (
      <div className="quote-wrap">
        <div className="quote-text">{text}</div>
        <div className="quote-author">{author}</div>
      </div>
    )
  }
}

export default QuoteBlock
