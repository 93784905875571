import { Dispatch, SetStateAction } from 'react'
import produce, { Draft } from 'immer'

const useChangeAnswersCallback = <Question, Answer>(
  questions: Question[],
  setQuestions: Dispatch<SetStateAction<Question[]>>,
  resolveQuestionId: (question: Draft<Question>) => number,
  resolveQuestionAnswers: (question: Draft<Question>) => Answer[],
  resolveAnswerId: (answer: Answer) => number,
  createQuestion: (id: number, answers: Answer[]) => Draft<Question>,
  createAnswer: (id: number) => Answer
) => {
  return (questionId: number, answerId: number, checked: boolean) => {
    setQuestions(
      produce(questions, draftQuestions => {
        const changedQuestionIndex = draftQuestions.findIndex(question => resolveQuestionId(question) === questionId)
        const changedQuestion = draftQuestions[changedQuestionIndex]

        if (!changedQuestion) {
          if (checked) {
            draftQuestions.push(createQuestion(questionId, [createAnswer(answerId)]))
          }

          return
        }

        const answers = resolveQuestionAnswers(changedQuestion)

        const changedAnswerIndex = answers.findIndex(answer => resolveAnswerId(answer) === answerId)

        if (checked) {
          if (changedAnswerIndex !== -1) {
            return
          }

          answers.push(createAnswer(answerId))
          return
        }

        answers.splice(changedAnswerIndex, 1)

        if (!answers.length) {
          draftQuestions.splice(changedQuestionIndex, 1)
        }
      })
    )
  }
}

export default useChangeAnswersCallback
