import { useEffect, useState } from 'react'
import { useMonaco } from '@monaco-editor/react'
import { editor } from 'monaco-editor'

export const useCodeEditorTheme = (theme: CodeEditorTheme) => {
  const monaco = useMonaco()
  const [themeData, setThemeData] = useState<editor.IStandaloneThemeData>()

  useEffect(() => {
    import(`../../public/monaco/themes/${theme}.json`).then(module => {
      setThemeData(module.default)
    })
  }, [theme])

  useEffect(() => {
    if (!monaco?.editor || !themeData) {
      return
    }

    monaco.editor.defineTheme(theme, themeData)
    monaco.editor.setTheme(theme)
  }, [themeData, monaco?.editor])
}

export enum CodeEditorTheme {
  Tomorrow = 'tomorrow',
}
