import { serverHost } from '../../../config'
import { AuthService } from '../../auth-service'
import ApiService from '../../api-service'

class FakeOpenPageService {
  openSourceUrl

  constructor() {
    this.openSourceUrl = `${serverHost}/api/fake/user`
  }

  getMentorNameByStudentId = studentId => {
    const queryOption = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.openSourceUrl}/mentorName/${studentId}`, queryOption)
  }

  getStudentNameByStudentId = studentId => {
    const queryOption = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...AuthService.authHeaders(),
      },
    }
    return ApiService.request(`${this.openSourceUrl}/name/${studentId}`, queryOption)
  }
}
export default FakeOpenPageService
