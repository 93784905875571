import i18n from 'i18next'

i18n.init({
  resources: {
    en: {
      translations: {
        ShowStudents: 'show students',
        FirstName: 'First name',
        LastName: 'Last Name',
        Statistics: 'Statistics',
        PreparationDirection: 'Preparation direction',
        ChannelName: 'Channel name',
        Student: 'Student',
        ErrorPageTitle: 'Error Page',
        ErrorStackTitle: 'Error Stack Trice',
        ErrorPageAbout: 'Oops... Unfortunately, you ended up on an error page :(',
        StudentErrorTitle: 'Oops!',
        StudentErrorAbout: 'There was a problem while using the platform :(',
        StudentSubAbout:
          'We apologize for the inconveniences caused. If the problem occurs again, please inform administration',
        GroupReview: 'Group Review',
        AllReview: 'All review',
        YourReview: 'Your review',
        ReviewsOfStudent: 'Reviews of the Student',
        PassedReviews: 'Passed',
        FailedReviews: 'Failed',
        FutureReviews: 'Future reviews',
        ProgressToEmployment: `Progress to employment`,
        Progress: `Progress`,
        YourActivity: `Your activity`,
        DaysWithoutABreak: `Days without a break`,
        DaysWithoutABreakMax: `Days without a break (max.)`,
        TopicsMastered: `Topics mastered`,
        MoreDetails: `More details`,
        Week: `Week`,
        Month: `Month`,
        Year: `Year`,
        OfSolutions: `Of solutions`,
        CurrentCourse: `Current course`,
        CurrentModule: 'Current module',
        CurrentProgress: 'Current progress',
        LastAnswerDate: 'Last answer date',
        DeadlineDate: 'Deadline date',
        TeamProject: 'TEAM\nPROJECT',
        JobInterviewSummary: `INTERVIEW\nSUMMARY`,
        WorkInCompany: `WORK\nIN COMPANY`,
        January: `Jan.`,
        February: `Feb.`,
        March: `Mar.`,
        April: `Apr.`,
        May: `May`,
        June: `June`,
        July: `July`,
        August: `Aug.`,
        September: `Sept.`,
        October: `Oct.`,
        November: `Nov.`,
        December: `Dec.`,
        Main: `Main`,
        Study: `Study`,
        Review: `Review`,
        Contacts: `Contacts`,
        LegalEntity: `Legal entity`,
        PublicOffer: `Public offer`,
        AllCourses: `All courses`,
        MyCourses: `Courses of direction`,
        AdditionalCourses: `Courses`,
        Actions: 'Actions',
        Courses: `Courses`,
        Information: `Information`,
        Content: `Content`,
        AboutCourseAuthor: `About course author`,
        Requirements: `Requirements`,
        TheTargetAudience: `The target audience`,
        Load: `Load`,
        CourseTime: `Course time`,
        NoNewNotifications: `No new notifications`,
        ChangeProfile: `Change Profile`,
        ChangePassword: `Change password`,
        Exit: `Exit`,
        ChangeYourPassword: `Change your password`,
        OldPassword: `Old password`,
        NewPassword: `New password`,
        PasswordConfirmation: `Password Confirmation`,
        SaveChanges: `Save changes`,
        YourName: `Your name`,
        ProfileEditing: `Profile editing`,
        Surname: `Surname`,
        Birthday: `Birthday`,
        ChangeAvatar: `Change avatar`,
        ChooseFile: `Choose file`,
        NextStep: `Next step`,
        ToCourseList: `To course list`,
        ResetSolution: `Reset solution`,
        ResettingSolution: 'Resetting solution...',
        Comments: `Comments`,
        StudentsComments: `Students comments`,
        AddAComment: `Add a comment`,
        SubmitComment: `Submit comment`,
        TheRightSolution: `The right solution`,
        InvalidSolutionCheckAgain: `Invalid solution. Check again.`,
        FailedToSubmitSolution: `Failed to submit solution`,
        AnswerLesson: `Short answer`,
        EnterYourAnswer: `Enter your answer`,
        EnterYourLink: `Paste your link to git project`,
        WrongSolution: `Wrong solution`,
        AnyStatus: `Any status`,
        ReturnToTask: `Return to task`,
        SolutionHistory: `Solution history`,
        AllComments: `All comments`,
        Solutions: `Solutions`,
        SubmitSolution: `Submit solution`,
        SubmittingSolution: `Submitting solution...`,
        TestSolution: `Test solution`,
        ChooseTheCorrectAnswer: `Choose the correct answer`,
        CompilationResult: `Compilation result`,
        MentorChecking: `The solution is being reviewed by a mentor`,
        MentorCheckingSuccess: `The solution was successfully reviewed by a mentor`,
        MentorCheckingFailure: `The task requires a repeated solution`,
        MentorCheckResult: `Mentor check result`,
        SolutionIsPending: 'Решение находится на проверке',
        Lecture: `Lecture`,
        PracticalTask: `Practical task`,
        TheoreticalTask: `Theoretical task`,
        SecurityQuestion: `Security question`,
        OrderTask: `Order task`,
        AssociationTask: `Association task`,
        MultiInputTask: 'Multi input task',
        MultiTestTask: 'Multi test task',
        GappingTask: 'Gapping task',
        MultiAnswerTask: 'Multi answer task',
        TheTaskVerifiedByTheMentor: `Practical task`,
        LessonWithACode: `Lesson with a code`,
        Upstairs: `Upstairs`,
        CopyLinkForMentor: `Copy link for mentor`,
        Answers: `Answers`,
        Add: `Add`,
        PhoneNumber: `Phone number`,
        Mail: `Mail`,
        SignIn: `Sign in`,
        RemindPassword: `Remind password`,
        RememberMe: `Remember me`,
        WrongLoginOrPassword: `Wrong login or password`,
        Login: `Login`,
        YouAreSuccessfullyRegistered: `You are successfully registered`,
        AccessIsDenied: `Access is denied`,
        PasswordRecoveredSuccessfully: `Password recovered successfully`,
        YourDetailsHaveBeenUpdated: `Your details have been updated`,
        ErrorUpdatingData: `Error updating data`,
        ContactYourCuratorToChangeYourEmailAddress: `Contact your curator to change your email address`,
        UserImage: `User Image`,
        SurnameMustNotContainNumbersAndExtraCharacters: `Surname must not contain numbers and extra characters!`,
        TheNameMustNotContainNumbersAndExtraCharacters: `The name must not contain numbers and extra characters!`,
        WriteYourName: `Write your name`,
        WriteYourLastName: `Write your last name`,
        NewAndConfirmedPasswordsDoNotMatch: `New and confirmed passwords do not match`,
        YourPasswordHasBeenUpdatedSuccessfully: `Your password has been updated successfully`,
        PasswordUpdateFailed: `Old password is incorrect`,
        Website: `Website`,
        VKGroup: `VK Group`,
        ForAnyQuestionsWriteTo: `If you have any questions write to `,
        KataHelpBotLink: 'Kata Help Bot',
        JanuaryFull: `January`,
        FebruaryFull: `February`,
        MarchFull: `March`,
        AprilFull: `April`,
        MayFull: `May`,
        JuneFull: `June`,
        JulyFull: `July`,
        AugustFull: `August`,
        SeptemberFull: `September`,
        OctoberFull: `October`,
        NovemberFull: `November`,
        DecemberFull: `December`,
        ReviewPeriodWith: `Review period with`,
        By: `by`,
        Filter: `filter:`,
        Passed: `Passed`,
        Details: `Details`,
        OptOut: `Opt out`,
        ReReview: `Re-review`,
        Missed: `Missed`,
        ViewReviewResults: `View review results`,
        TheoryQuestionsToRepeat: `Theory Questions to Repeat`,
        TaskNotes: `Task notes`,
        MentorComments: `Mentor's comments`,
        YouHaveSuccessfullyLoggedOut: `You have successfully logged out`,
        Password: `Password`,
        LinkCopiedToClipboard: `Link copied to clipboard`,
        FailedToCopyLink: `Failed to copy link`,
        NewFirst: `New first`,
        FirstOld: `First old`,
        Right: `Right`,
        Wrong: `Wrong`,
        Solution: `Solution`,
        ModuleName: `Module name`,
        TimeSpending: `Time spending`,
        RecordingOnReview: `Recording on Review`,
        YouSignedUpForAReview: `You signed up for a review`,
        ChooseAConvenientDateFromTheAvailableOnesTheyAreMarkedInGreen: `Choose a convenient date from the available ones they are marked in green:`,
        YouCheckedOutWithAReview: `You checked out with a review`,
        MyRecordOn: `My record on`,
        ChooseAConvenientTimeFor: `Choose a convenient time for`,
        ToDateSelection: `To date selection`,
        SignUp: `Sign up`,
        JanuaryFullDeclination: `January`,
        FebruaryFullDeclination: `February`,
        MarchFullDeclination: `March`,
        AprilFullDeclination: `April`,
        MayFullDeclination: `May`,
        JuneFullDeclination: `June`,
        JulyFullDeclination: `July`,
        AugustFullDeclination: `August`,
        SeptemberFullDeclination: `September`,
        OctoberFullDeclination: `October`,
        NovemberFullDeclination: `November`,
        DecemberFullDeclination: `December`,
        MondayShort: `Mo`,
        TuesdayShort: `Tu`,
        WednesdayShort: `We`,
        ThursdayShort: `Th`,
        FridayShort: `Fr`,
        SaturdayShort: `Sa`,
        SundayShort: `Su`,
        SignUpForAReview: `Sign up for a review`,
        GoToPost: `Go to post`,
        ReviewPassed: `Review already passed`,
        ReviewCompletedWarning: `To sign up for a review, you have to solve all the tasks`,
        GroupReviewByModule: `Review by module. Task #`,
        Task: `Task`,
        ModalHead: `Notification`,
        NotificationFamiliarized: `Familiarized`,
        RightSolutionModalTitle: `Right solution`,
        WriteComment: `Write comment`,
        rejectionOfTheReviewHeader: `Confirmation of refusal to participate`,
        rejectionOfTheReviewBodyPart1: `Are you sure you don't want to participate in a group review `,
        rejectionOfTheReviewBodyPart2: `, which will take place in `,
        rejectionOfTheReviewFooter: `Confirm`,
        BackToYourDashboard: `Go back to your dashboard`,
        registrationEmptyFields: `All fields must be filled in`,
        registrationShortPassword: 'Password must be longer than 6 characters',
        registrationConfirmPasswordError: `You've entered two different passwords`,
        firstCallSendInvitation: `Send invitation`,
        firstCallName: `Name`,
        firstCallSurname: `Surname`,
        firstCallEmail: `Email`,
        firstCallPassword: `Password`,
        firstCallSignUp: `Sign up`,
        firstCallErrorBirthday: `Birthday error`,
        firstCallErrorName: `The name must start with a capital letter, at least 2 characters, and only letters`,
        firstCallErrorSurname: `The last name must begin with a capital letter, at least 2 characters, and you can use letters, spaces, and hyphens in compound surnames.`,
        firstCallErrorPassword: `The password must contain at least 6 characters`,
        firstCallErrorEmail: `Invalid Email format`,
        firstCallButtonTextProcess: `Process ... `,
        registration: `Registration`,
        userIsBlocked: `User is blocked`,
        noPassword: `No password`,
        noLogin: `No login`,
        loginError: 'Authorisation Error',
        passwordRecovery: 'Password recovery',
        passwordRecoveryInstruction:
          'Enter the email you specified on the Education Platform. We will send you instructions on how to reset your password.',
        restoreAccess: 'Restore access',
        toMainPage: 'Back to the main page',
        passwordRecoveryServerError:
          'An internal error has occurred. To recover your password, contact the administration.',
        passwordRecoveryErrorInvalidToken: 'The token is invalid',
        passwordRecoveryErrorInvalidTokenDescription: 'The link has expired or the token is not valid',
        passwordRecoveryErrorGoToMainPage: 'To get the token again, go to the main page',
        passwordRecoverySuccess: 'Password was successfully restored',
        passwordRecoverySuccessDescription: 'An email with a new password has been sent to your email address',
        passwordRecoverySuccessCheckYourInbox: 'Please check your Inbox',
        passwordRecoveryLinkSent:
          'An email with instructions for password recovery has been sent to your email address',
        invalidRegistrationToken: 'Registration link is invalid',
        unknownServerError: 'Unknown Server Error',
        signUpForm: 'Sign up form',
        createYourAccount: 'Create your account',
        startPageSubtitle: 'Make your first step to the successful career of developer',
        noSuchUser: 'No such user',
        messageFromMentor: 'Message from mentor',
        pageOfStudent: 'Page of student',
        sortBy: 'sort by',
        direction: 'direction',
        AnalyticsForThePeriodFrom: 'Date start',
        by: 'by',
        myStudents: 'My students',
        Manager: 'Manager',
        search: 'search',
        EndPreparationDate: 'End preparation date',
        startTraining: 'Start Training',
        recruitment: 'recruitment',
        CommonSlackChannel: 'Common slack channel',
        mentor: 'mentor',
        noMentor: 'no mentor',
        noPlaceToSign: 'no place to sign',
        Reports: 'Reports',
        AllReports: 'All reports',
        RegistrationDate: 'Date of registration',
        lastReplyDate: 'Date of last reply',
        AddReport: 'Add report',
        SearchReports: 'Search in reports',
        PublicationDate: 'Date of publication',
        OnlyOwn: 'Only own',
        ReportSearchPlaceholder: 'Enter student name or module number',
        Yes: 'Yes',
        No: 'No',
        Yesterday: 'Yesterday',
        Today: 'Today',
        ThisWeek: 'This week',
        ThisMonth: 'This month',
        TimeTaken: 'Time taken',
        WhatDone: 'What done',
        Hide: 'Hide',
        Show: 'Show in full',
        Edit: 'Edit',
        EditReport: 'Edit report',
        FillReportOfYesterday: 'Fill report of yesterday, to continue the training',
        Delete: 'Delete',
        HourShort: 'h',
        ValidateReportTimeTaken: 'Write how much time was spent',
        ValidateReportWhatDone: 'Write what has been done',
        ValidateReportEnterNumber: 'Enter a numeric value',
        ValidateReportEnterPositiveNumber: 'The value must be positive',
        ValidateReportLessThenDay: 'Enter a value less than a day',
        ValidateReportTooLongReport: 'Too long report',
        ReportTimeTakenPlaceholder: 'How much time did you spend?',
        ReportWhatDonePlaceholder: 'What have you done?',
        NoReportData: 'No data for your request',
        DirectionStudents: 'direction students',
      },
    },

    ru: {
      translations: {
        DirectionStudents: 'студенты потока',
        ShowStudents: 'показать студентов',
        FirstName: 'Имя',
        LastName: 'Фамилия',
        Statistics: 'Статистика',
        noMentor: 'нет ментора',
        noPlaceToSign: 'нет мест',
        mentor: 'ментор',
        Manager: 'Менеджер',
        CommonSlackChannel: 'Общий slack канал',
        recruitment: 'набор',
        EndPreparationDate: 'Дата завершения подготовки',
        PreparationDirection: 'Направление подготовки',
        ChannelName: 'Канал набора',
        Student: 'Студент',
        search: 'поиск',
        myStudents: 'Мои студенты',
        by: 'по',
        AnalyticsForThePeriodFrom: 'Дата с',
        direction: 'направление',
        sortBy: 'сортировка по',
        ErrorStackTitle: 'Стэк ошибки',
        ErrorPageTitle: 'Страница Ошибки',
        ErrorPageAbout: 'Ой... К сожалению, вы попали на страницу ошибки :(',
        StudentErrorTitle: 'Ой!',
        StudentErrorAbout: 'Во время работы платформы возникла ошибка :(',
        StudentSubAbout:
          'Приносим свои извинения! При повторном возникновении проблемы, просьба сообщить администрации!',
        noLogin: `Нет логина`,
        loginError: 'Ошибка авторизации',
        noPassword: `Нет пароля`,
        userIsBlocked: `Пользователь заблокирован`,
        GroupReview: 'Групповые ревью',
        AllReview: 'Все ревью',
        YourReview: 'Твои ревью',
        ReviewsOfStudent: 'Ревью студента',
        PassedReviews: 'Пройденные',
        FailedReviews: 'Проваленные',
        FutureReviews: 'Будущие ревью',
        ProgressToEmployment: `Прогресс до трудоустройства`,
        Progress: `Прогресс`,
        YourActivity: `Ваша активность`,
        DaysWithoutABreak: `Дней без перерыва`,
        DaysWithoutABreakMax: `Дней без перерыва (макс.)`,
        TopicsMastered: `Тем освоено`,
        MoreDetails: `Подробнее`,
        Week: `Неделя`,
        Month: `Месяц`,
        Year: `Год`,
        OfSolutions: `Решений`,
        CurrentCourse: `Текущий курс`,
        CurrentModule: 'Текущий модуль',
        CurrentProgress: 'Прогресс по курсу',
        LastAnswerDate: 'Дата последнего решения',
        DeadlineDate: 'Дата дэдлайна',
        AdditionalCourses: 'Курсы',
        Actions: 'Действия',
        TeamProject: 'КОМАНДНЫЙ\nПРОЕКТ',
        JobInterviewSummary: `РЕЗЮМЕ\nСОБЕСЕДОВАНИЯ`,
        WorkInCompany: `РАБОТА\nВ КОМПАНИИ`,
        January: `янв.`,
        February: `февр.`,
        March: `март`,
        April: `апр.`,
        May: `май`,
        June: `июнь`,
        July: `июль`,
        August: `авг.`,
        September: `сент.`,
        October: `окт.`,
        November: `нояб.`,
        December: `дек.`,
        Main: `Главная`,
        Study: `Учёба`,
        Review: `Ревью`,
        Contacts: `Контакты`,
        LegalEntity: `Юр.лицо`,
        PublicOffer: `Публичная оферта`,
        AllCourses: `Все курсы`,
        MyCourses: `Курсы направления`,
        Courses: `Курсы`,
        Information: `Информация`,
        Content: `Содержание`,
        AboutCourseAuthor: `Об авторе курса`,
        Requirements: `Требования`,
        TheTargetAudience: `Целевая аудитория`,
        Load: `Нагрузка`,
        CourseTime: `Время прохождения курса`,
        NoNewNotifications: `Новых уведомлений нет`,
        ChangeProfile: `Изменить профиль`,
        ChangePassword: `Изменить пароль`,
        Exit: `Выход`,
        ChangeYourPassword: `Изменение пароля`,
        OldPassword: `Старый пароль`,
        NewPassword: `Новый пароль`,
        PasswordConfirmation: `Подтверждение пароля`,
        SaveChanges: `Сохранить изменения`,
        YourName: `Имя`,
        ProfileEditing: `Изменение профиля`,
        Surname: `Фамилия`,
        Birthday: `День рождения`,
        ChangeAvatar: `Изменить аватар`,
        ChooseFile: `Выбрать файл`,
        NextStep: `Следующий шаг`,
        ToCourseList: `К списку курсов`,
        ResetSolution: `Сбросить решение`,
        ResettingSolution: 'Сбрасываем решение...',
        Comments: `Комментарии`,
        StudentsComments: `Комментарии учеников`,
        AddAComment: `Добавить комментарий`,
        SubmitComment: `Оставить комментарий`,
        TheRightSolution: `Верное решение`,
        InvalidSolutionCheckAgain: `Неверное решение. Проверьте еще раз.`,
        FailedToSubmitSolution: `Не удалось отправить решение`,
        AnswerLesson: `Урок с вводом ответа`,
        EnterYourAnswer: `Введите ответ`,
        EnterYouLink: `Вставте ссылку на гит репозиторий вашего проекта`,
        WrongSolution: `Неверное решение`,
        AnyStatus: `Любой статус`,
        ReturnToTask: `Вернуться к задаче`,
        SolutionHistory: `История решений`,
        AllComments: `Все комментарии`,
        Solutions: `Решения`,
        SubmitSolution: `Отправить решение`,
        SubmittingSolution: `Отправка решения...`,
        TestSolution: `Протестировать решение`,
        ChooseTheCorrectAnswer: `Выберите правильный ответ`,
        CompilationResult: `Результат компиляции`,
        MentorCheckResult: `Результат проверки ментором`,
        SolutionIsPending: 'Решение находится на проверке',
        MentorChecking: `Решение задачи находится на проверке`,
        MentorCheckingSuccess: `Решение успешно прошло проверку`,
        MentorCheckingFailure: `Задача требует повторного решения`,
        Lecture: `Лекция`,
        PracticalTask: `Практическая задача`,
        TheoreticalTask: `Теоретическая задача`,
        SecurityQuestion: `Контрольный вопрос`,
        OrderTask: `Задача на порядок`,
        AssociationTask: `Задача на ассоциации`,
        MultiInputTask: 'Задача с мульти инпутом',
        MultiTestTask: 'Задача с несколькими тестами',
        MultiAnswerTask: 'Задача с повторными ответами',
        GappingTask: 'Задача с пропусками',
        TheTaskVerifiedByTheMentor: `Практическая задача`,
        LessonWithACode: `Урок с кодом`,
        Upstairs: `Наверх`,
        CopyLinkForMentor: `Скопировать ссылку для ментора`,
        Answers: `Ответы`,
        Add: `Добавить`,
        PhoneNumber: `Телефон`,
        Mail: `Почта`,
        SignIn: `Войти`,
        RemindPassword: `Напомнить пароль`,
        RememberMe: `Запомнить меня`,
        WrongLoginOrPassword: `Неверный логин или пароль`,
        Login: `Авторизация`,
        YouAreSuccessfullyRegistered: `Вы успешно зарегистрированы`,
        AccessIsDenied: `Доступ запрещен`,
        PasswordRecoveredSuccessfully: `Пароль успешно восстановлен`,
        YourDetailsHaveBeenUpdated: `Ваши данные обновлены`,
        ErrorUpdatingData: `Произошла ошибка при обновлении данных`,
        ContactYourCuratorToChangeYourEmailAddress: `Для изменения адреса электронной почты обратитесь к своему куратору`,
        UserImage: `Изображение пользователя`,
        SurnameMustNotContainNumbersAndExtraCharacters: `Фамилия не должна содержать цифры и лишние символы!`,
        TheNameMustNotContainNumbersAndExtraCharacters: `Имя не должно содержать цифры и лишние символы!`,
        WriteYourName: `Напишите ваше имя`,
        WriteYourLastName: `Напишите вашу фамилию`,
        NewAndConfirmedPasswordsDoNotMatch: `Новый и подтвержденные пароли не совпадают`,
        YourPasswordHasBeenUpdatedSuccessfully: `Ваш пароль успешно обновлен`,
        PasswordUpdateFailed: `Неверно указан старый пароль`,
        Website: `Наш сайт`,
        VKGroup: `Группа ВК`,
        ForAnyQuestionsWriteTo: `Если есть какие-то вопросы - пишите в `,
        KataHelpBotLink: 'Ката Хелп Бот',
        JanuaryFull: `Январь`,
        FebruaryFull: `Февраль`,
        MarchFull: `Март`,
        AprilFull: `Апрель`,
        MayFull: `Май`,
        JuneFull: `Июнь`,
        JulyFull: `Июль`,
        AugustFull: `Август`,
        SeptemberFull: `Сентябрь`,
        OctoberFull: `Октябрь`,
        NovemberFull: `Ноябрь`,
        DecemberFull: `Декабрь`,
        ReviewPeriodWith: `Ревью период с`,
        By: `по`,
        Filter: `фильтр:`,
        Passed: `Пройдено`,
        Details: `Детали`,
        OptOut: `Отказаться от участия`,
        ReReview: `Требует пересдачи`,
        Missed: `Пропущенное ревью`,
        ViewReviewResults: `Просмотр результатов ревью`,
        TheoryQuestionsToRepeat: `Теор Вопросы для повторения`,
        TaskNotes: `Замечания по задачам`,
        MentorComments: `Комментарии ментора`,
        YouHaveSuccessfullyLoggedOut: `Вы успешно вышли из системы`,
        Password: `Пароль`,
        LinkCopiedToClipboard: `Ссылка скопирована в буфер обмена`,
        FailedToCopyLink: `Не удалось скопировать ссылку`,
        NewFirst: `Сначала новые`,
        FirstOld: `Сначала старые`,
        Right: `Верное`,
        Wrong: `Неверное`,
        Solution: `Решение`,
        ModuleName: `Название модуля`,
        TimeSpending: `Время проведения`,
        RecordingOnReview: `Запись на ревью`,
        YouSignedUpForAReview: `Вы записались на ревью`,
        ChooseAConvenientDateFromTheAvailableOnesTheyAreMarkedInGreen: `Выбери удобную дату из доступных они помечены зеленым:`,
        YouCheckedOutWithAReview: `Вы выписались с ревью`,
        MyRecordOn: `Моя запись на`,
        ChooseAConvenientTimeFor: `Выбери удобное время на`,
        ToDateSelection: `К выбору даты`,
        SignUp: `Записаться`,
        JanuaryFullDeclination: `Января`,
        FebruaryFullDeclination: `Февраля`,
        MarchFullDeclination: `Марта`,
        AprilFullDeclination: `Апреля`,
        MayFullDeclination: `Мая`,
        JuneFullDeclination: `Июня`,
        JulyFullDeclination: `Июля`,
        AugustFullDeclination: `Августа`,
        SeptemberFullDeclination: `Сентября`,
        OctoberFullDeclination: `Октября`,
        NovemberFullDeclination: `Ноября`,
        DecemberFullDeclination: `Декабря`,
        MondayShort: `пн`,
        TuesdayShort: `вт`,
        WednesdayShort: `ср`,
        ThursdayShort: `чт`,
        FridayShort: `пт`,
        SaturdayShort: `сб`,
        SundayShort: `вс`,
        SignUpForAReview: `Записаться на ревью`,
        GoToPost: `Перейти к записи`,
        ReviewPassed: `Ревью уже пройдено`,
        ReviewCompletedWarning: `Для записи на ревью нужно решить все задачи`,
        GroupReviewByModule: `Ревью по модулю. Задача #`,
        Task: `Задача`,
        ModalHead: `Уведомление`,
        NotificationFamiliarized: `Ознакомлен`,
        RightSolutionModalTitle: `Верное решение`,
        WriteComment: `Написать комментарий`,
        rejectionOfTheReviewHeader: `Подтверждение отказа от участия`,
        rejectionOfTheReviewBodyPart1: `Ты уверен(-a), что хочешь отказаться от участия в груповом ревью по `,
        rejectionOfTheReviewBodyPart2: `, которое состоиться в `,
        rejectionOfTheReviewFooter: `Подтверждаю`,
        BackToYourDashboard: `Вернуться на свою панель`,
        registrationEmptyFields: `Все поля должны быть заполнены`,
        registrationShortPassword: `Пароль должен быть длиннее 6 символов`,
        registrationConfirmPasswordError: `Пароли не должны отличаться`,
        firstCallSendInvitation: `Отправить приглашение`,
        firstCallName: `Имя`,
        firstCallSurname: `Фамилия`,
        firstCallEmail: `Email`,
        firstCallPassword: `Пароль`,
        firstCallSignUp: `Зарегистрироваться`,
        firstCallErrorBirthday: `Ошибка дня рождения`,
        firstCallErrorName: `Имя должно начинаться с заглавной буквы, минимум из 2-х символов и только буквы`,
        firstCallErrorSurname: `Фамилия должна начинаться с заглавной буквы, минимум из 2-х символов, можно использовать буквы, пробелы и дефис в составных фамилиях.`,
        firstCallErrorPassword: `Пароль должен содержать не менее 6 символов`,
        firstCallErrorEmail: `Неверный формат Email`,
        firstCallButtonTextProcess: `Обрабатывается ... `,
        registration: `Регистрация`,
        passwordRecovery: 'Восстановление пароля',
        passwordRecoveryInstruction:
          'Введи почту, указанную вами на Education Platform. На неё мы вышлем инструкции по восстановлению пароля.',
        restoreAccess: 'Восстановить доступ',
        toMainPage: 'На главную',
        passwordRecoveryServerError:
          'Произошла внутренняя ошибка. Для восстановления пароля обратитесь к администрации.',
        passwordRecoveryErrorInvalidToken: 'Токен недействителен',
        passwordRecoveryErrorInvalidTokenDescription: 'Срок годности ссылки истёк или токен не является корректным',
        passwordRecoveryErrorGoToMainPage: 'Для повторного получения токена перейдите на главную',
        passwordRecoverySuccess: 'Пароль успешно восстановлен',
        passwordRecoverySuccessDescription: 'На ваш email отправлено письмо с новым паролем',
        passwordRecoverySuccessCheckYourInbox: 'Пожалуйста, проверьте папку с входящими сообщениями',
        passwordRecoveryLinkSent: 'На ваш email отправлено письмо с инструкцией для восстановления пароля',
        invalidRegistrationToken: 'Ссылка для регистрации недействительна',
        unknownServerError: 'Неизвестная ошибка сервера',
        signUpForm: 'Форма регистрации',
        createYourAccount: 'Создайте ваш аккаунт',
        startPageSubtitle: 'Ваш первый шаг к успешной карьере разработчика',
        noSuchUser: 'Такого пользователя нет',
        messageFromMentor: 'Сообщение от ментора',
        pageOfStudent: 'Страница студента',
        startTraining: 'Начало обучения',
        Reports: 'Отчеты',
        AllReports: 'Все отчеты',
        AddReport: 'Добавить отчет',
        SearchReports: 'Поиск по отчетам',
        PublicationDate: 'Дата публикации',
        RegistrationDate: 'Дата регистрации',
        lastReplyDate: 'Дата последнего ответа',
        OnlyOwn: 'Только мои',
        ReportSearchPlaceholder: 'Введи имя студента для поиска',
        Yesterday: 'Вчера',
        Yes: 'Да',
        No: 'Нет',
        Today: 'Сегодня',
        ThisWeek: 'За неделю',
        ThisMonth: 'За месяц',
        TimeTaken: 'Затрачено времени',
        WhatDone: 'Сделано',
        Hide: 'Скрыть',
        Show: 'Показать полностью',
        Edit: 'Редактировать',
        EditReport: 'Редактировать отчет',
        FillReportOfYesterday: 'Заполни отчет за вчерашний день, чтобы продолжить обучение',
        Delete: 'Удалить',
        HourShort: 'ч',
        ValidateReportTimeTaken: 'Напиши сколько часов затратил',
        ValidateReportWhatDone: 'Напиши что было сделано',
        ValidateReportEnterNumber: 'Введи число',
        ValidateReportEnterPositiveNumber: 'Значение должно быть положительным',
        ValidateReportLessThenDay: 'Введи значение меньше суток',
        ValidateReportTooLongReport: 'Слишком длинный отчет',
        ReportTimeTakenPlaceholder: 'Сколько часов затратил?',
        ReportWhatDonePlaceholder: 'Что сделал?',
        NoReportData: 'Нет данных по вашему запросу',
      },
    },
  },
  debug: true,
  preload: ['ru'],
  whitelist: ['ru'],
  lng: 'ru',
  fallbackLng: 'ru',

  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false,

  interpolation: {
    escapeValue: true,
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
})

export default i18n
