import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Spinner from '../../../../../spinner'
import TaskDescription from '../task-description'
import ModalRightAnswer from '../../modalIsRithtAnswer/modal-right-answer'
import FakeExemplarySolutionModal from '../../../../fake-page/fake-exemplary-solution-modal'
import { CodeTaskDto } from '../../../../../../model/task-model/CodeTaskDto'
import { CodeDirectionTaskDto } from '../../../../../../model/direction-model'
import { RoleEnum } from '../../../../../../utils/select-state/RoleEnum'
import { showCommentsForEnrollee } from '../../../../../../config'
import CodeEditor from '../../../../../code-editor'
import { ReferenceSolutionDto } from '../../../../../../model/task-dto/task-solution-dto'
import '../task.css'

interface IProps {
  codeTask: CodeTaskDto | CodeDirectionTaskDto
  loadTask: (interceptor?: (value: any) => Promise<any>) => Promise<CodeTaskDto | CodeDirectionTaskDto>
  isExemplarySolutionAvailableCallback: () => boolean
  isSolutionHistoryAvailableCallback: () => boolean
  isMentorCommentAvailableCallback: () => boolean
  isSessionStorageAvailableCallback: () => boolean
  getExemplarySolution: () => any // Используется сервис, который не типизирован
  sessionStorageName: string
  onSolveTask: any
  onResetTask: () => Promise<void>
  solutionsLink: string
  postSolveInterceptor: (
    codeTask: CodeTaskDto | CodeDirectionTaskDto,
    code: string,
    solveResponse: any,
    solved: boolean,
    points: number,
    previousSolveStatus: boolean,
    setReceivedAnswer: Dispatch<SetStateAction<Partial<boolean>>>,
    setProbability: Dispatch<SetStateAction<Partial<number>>>,
    setTestResult: Dispatch<SetStateAction<Partial<string>>>,
    setCode: Dispatch<SetStateAction<Partial<string>>>,
    setIsTestsPassed: Dispatch<SetStateAction<Partial<boolean | null>>>,
    dispatch: Dispatch<any>
  ) => void
  postResetInterceptor: (val: any, ...args: any[]) => any
  currentRole?: RoleEnum
}

const CodeTask: FC<IProps> = ({
  codeTask,
  loadTask,
  isExemplarySolutionAvailableCallback,
  isSolutionHistoryAvailableCallback,
  isMentorCommentAvailableCallback,
  isSessionStorageAvailableCallback,
  getExemplarySolution,
  sessionStorageName,
  onSolveTask,
  onResetTask,
  solutionsLink,
  postSolveInterceptor,
  postResetInterceptor,
  currentRole,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [code, setCode] = useState<string>(``)
  const [solvingTask, setSolvingTask] = useState<boolean>(false)
  const [receivedAnswer, setReceivedAnswer] = useState<boolean>(false)
  const [taskLoaded, setTaskLoaded] = useState<boolean>(true)
  const [testResult, setTestResult] = useState<string>(``)
  const [isTestsPassed, setIsTestsPassed] = useState<boolean | null>(null)
  const [testWithError, setTestWithError] = useState<boolean>(false)
  const [probability, setProbability] = useState<number>(0)
  const [reset, setReset] = useState<boolean>(false)
  const [modalShowed, setModalShowed] = useState<boolean>(false)
  const [referenceSolution, setReferenceSolution] = useState<ReferenceSolutionDto>()

  const [lastActionIsRight, setLastActionIsRight] = useState<boolean>(false)
  const [lastActionIsWrong, setLastActionIsWrong] = useState<boolean>(false)

  const isExemplarySolutionAvailable = isExemplarySolutionAvailableCallback()
  const isSolutionHistoryAvailable = isSolutionHistoryAvailableCallback()
  const isMentorCommentAvailable = isMentorCommentAvailableCallback()
  const isSessionStorageAvailable = isSessionStorageAvailableCallback()
  const isEnrollee = currentRole === RoleEnum.STUDENT

  const onChange = (codeValue: string) => {
    setCode(codeValue)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const loadCodeTask = (interceptor?: any) => {
    return loadTask(interceptor).then(task => {
      if (solvingTask) {
        setSolvingTask(false)
      }
      return task
    })
  }

  useEffect(() => {
    setLastActionIsWrong(codeTask.lastActionIsWrong)
    setLastActionIsRight(codeTask.lastActionIsRight)
  }, [codeTask])

  const onClose = () => {
    setReceivedAnswer(false)
    setSolvingTask(false)
  }

  const onCloseExemplarySolution = () => {
    setModalShowed(false)
  }

  useEffect(() => {
    if (code !== '' && isSessionStorageAvailable) {
      sessionStorage.setItem(sessionStorageName, code)
    }
  }, [code])

  // useEffect(() => {
  //   if (courseTaskId !== 0) {
  //     loadCodeTask()
  //   }
  // }, [courseTaskId])

  useEffect(() => {
    if (solvingTask) {
      const oldCode = code
      let solveResponse: any
      const { solved: previousSolveStatus } = codeTask
      onSolveTask(code)
        .then((val: any) => {
          solveResponse = val
        })
        .then(() => loadCodeTask())
        .then(({ solved, taskPoints: points }: { solved: boolean; taskPoints: number }) =>
          postSolveInterceptor(
            codeTask,
            oldCode,
            solveResponse,
            solved,
            points,
            previousSolveStatus,
            setReceivedAnswer,
            setProbability,
            setTestResult,
            setCode,
            setIsTestsPassed,
            dispatch
          )
        )
        .catch(() => setTestWithError(true))
    }
  }, [solvingTask])

  useEffect(() => {
    if (testWithError) {
      setTestResult(`${t('FailedToSubmitSolution')}`)
      setSolvingTask(false)
    }
  }, [testWithError])

  useEffect(() => {
    if (codeTask !== null) {
      setTaskLoaded(false)
      if (solvingTask) {
        setSolvingTask(false)
      }
      if (testWithError) {
        setTestWithError(false)
      }
      const { answer, placeholder, resolved, result } = codeTask
      const storage = sessionStorage.getItem(sessionStorageName)
      let newCode = resolved ? placeholder : answer || placeholder
      if (storage != null) {
        const answerOrStorage = lastActionIsRight && !resolved ? newCode : storage
        newCode = reset ? placeholder : answerOrStorage
        setReset(false)
      }
      setCode(newCode)
      setTestResult(result)
    }
  }, [codeTask])

  useEffect(() => {
    setIsTestsPassed(null)
    setSolvingTask(false)
  }, [codeTask])

  const resetTask = () => {
    setReset(true)

    onResetTask()
      .then(() => {
        return loadCodeTask(postResetInterceptor)
      })
      .then(loadedTask => {
        setCode(loadedTask.answer)
        setIsTestsPassed(null)
      })
  }

  if (taskLoaded || codeTask === null) {
    return (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const { description, resolved, codeLang, mentorTaskReviewCommentDto } = codeTask

  const getMentorCommentBlock = () => {
    if (mentorTaskReviewCommentDto) {
      const { value: mentorCommentValue, needChange } = mentorTaskReviewCommentDto
      if (mentorCommentValue && isMentorCommentAvailable) {
        return (
          <div
            className={`task-content__mentor-comment-block ${
              needChange ? 'task-content__mentor-comment-failed' : 'task-content__mentor-comment-success'
            }`}
          >
            <h3 className="task-content__mentor-comment-header">{t('messageFromMentor')}</h3>
            <p className="task-content__mentor-comment-body">{mentorCommentValue}</p>
          </div>
        )
      }
    }
    return undefined
  }

  let codeClass
  let lessonClassName
  let message
  let icon
  if (isTestsPassed === null) {
    codeClass = `code-editor ${lastActionIsRight ? 'success-code-result' : 'error-code-result'}`
    lessonClassName = lastActionIsRight ? `lesson-result success` : `lesson-result error`
    message = lastActionIsRight ? `${t('TheRightSolution')}` : `${t('InvalidSolutionCheckAgain')}`
    icon = lastActionIsRight ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />
  } else {
    codeClass = `code-editor ${isTestsPassed ? 'success-code-result' : 'error-code-result'}`
    lessonClassName = isTestsPassed ? `lesson-result success` : `lesson-result error`
    message = isTestsPassed ? `${t('TheRightSolution')}` : `${t('InvalidSolutionCheckAgain')}`
    icon = isTestsPassed ? <i className="mdi mdi-check" /> : <i className="mdi mdi-close" />
  }

  if (!lastActionIsRight && !lastActionIsWrong) {
    codeClass = `code-editor`
    lessonClassName = `lesson-result`
    icon = null
    message = ``
  }

  if (resolved) {
    codeClass = `code-editor`
  }

  if (testWithError) {
    codeClass = 'code-editor error-code-result'
  }

  function referenceSolutionModal() {
    if (isExemplarySolutionAvailable) {
      setModalShowed(true)
      getExemplarySolution().then((solution: any) => {
        setReferenceSolution(solution)
      })
    }
  }

  const isInternalChecktype = codeTask.checkType === 'INTERNAL'

  return (
    <div className="task-content">
      <div className="step-content-head">{t('LessonWithACode')}</div>
      <TaskDescription description={description} />

      {isInternalChecktype && (
        <CodeEditor value={code} onChange={value => onChange(value ?? '')} className={codeClass} language={codeLang} />
      )}

      {getMentorCommentBlock()}
      {testResult !== null && (
        <div className="compile-result">
          <div className="compile-title-wrap">
            <div className="compile-title">{t('CompilationResult')}</div>
            {isSolutionHistoryAvailable && (
              <Link to={solutionsLink} className="history-link">
                {t('SolutionHistory')}
              </Link>
            )}
            {isExemplarySolutionAvailable && (
              <span className="exemplary-solution" onClick={() => referenceSolutionModal()}>
                Эталонное решение
              </span>
            )}
          </div>
          {solvingTask || (resolved && !testWithError) ? null : (
            <div className="terminal-result">
              <samp>{testResult}</samp>
            </div>
          )}
        </div>
      )}
      <div className="lesson-result-row">
        {solvingTask || resolved || testWithError ? null : (
          <div className={lessonClassName}>
            {icon}
            <span>{message}</span>
          </div>
        )}
        <div className="lesson-result-right-wrap">
          {isInternalChecktype && (
            <button type="button" className="reset-value-btn" style={{ cursor: 'pointer' }} onClick={() => resetTask()}>
              {t('ResetSolution')}
            </button>
          )}
          <button type="button" className="scroll-up" id="scroll-up" onClick={scrollToTop}>
            {t('Upstairs')}
          </button>
          {isInternalChecktype && (
            <>
              {solvingTask ? (
                <button type="button" className="send-result-btn">
                  {t('SubmittingSolution')}
                </button>
              ) : (
                <button type="button" className="send-result-btn" onClick={() => setSolvingTask(true)}>
                  {t('SubmitSolution')}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {isEnrollee && showCommentsForEnrollee && (
        <ModalRightAnswer modalShowed={receivedAnswer && lastActionIsRight && probability < 0.2} onClose={onClose} />
      )}

      <FakeExemplarySolutionModal
        modalShowed={modalShowed}
        studentCode={referenceSolution}
        onClose={() => onCloseExemplarySolution()}
      />
    </div>
  )
}

export default CodeTask
