import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from 'react-notifications-component'
import { useTranslation } from 'react-i18next'
import { History } from 'history'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { selectCourseType } from 'src/store/course-type/course-type.selectors'
import FakeOpenStudentCourseService from '../../../../services/fake-page-services/open/fake-open-student-course-service'
import FakeOpenStudentCourseTaskService from '../../../../services/fake-page-services/open/fake-open-student-course-task-service'
import UserCourseTaskService from '../../../../services/common-services/user-course-task-service'
import getLoadTaskCallback from './getLoadTaskCallback'
import UserNavigationService, { ChapterNavigation } from '../../../../services/common-services/user-navigation-service'
import { studentErrorNotyTemplate, studentSuccessNotyTemplate } from '../../../../config'
import StudentCourseTaskInfoService from '../../../../services/student-services/student-course-task-info-service'
import StudentBreadCrumbs from '../components/student-bread-crumbs'
import CommentsBlock from './comment-block/comments-block'
import Spinner from '../../../spinner'
import { TasksTypesUnion } from '../../enrollee/direction-page/direction-page'

import StudentCourseService from '../../../../services/student-services/student-course-service'
import StudentCourseTaskService from '../../../../services/student-services/student-course-task-service'
import { getCoursePageNextStepUrlStudent } from './getCoursePageNextStepUrlStudent'
import { generateCourseURL } from '../../../../utils/generateCourseURL'
import { useStatusPage } from '../../../../hooks/useStatusPage'
import StudentPageMode from '../../../../model/StudentPageMode'
import { TaskHeadingDto } from '../../../../model/student-model'
import { TaskType } from '../../../../model/task-dto/task-type-enum'
import {
  TheoryTask,
  LectureTask,
  OrderingTask,
  AssociationTask,
  WordTask,
  GappingTask,
  MultiAnswerTask,
  MultiTestTask,
  MultiInputTask,
  ReviewStepTask,
  CodeTask,
  MentorCheckTask,
  CheckTask,
} from './task-block'
import { TheoryTaskDto } from '../../../../model/task-model/TheoryTaskDto'
import { LectureTaskDto } from '../../../../model/task-model/LectureTaskDto'
import { MentorCheckTaskDto } from '../../../../model/task-model/MentorCheckTaskDto'
import { getSolveTaskCallbackStudent } from './getSolveTaskCallbackStudent'
import { getPostResetInterceptorStudent } from './getPostResetInterceptorStudent'
import { getPostSolveInterceptorStudent } from './getPostSolveInterceptorStudent'
import { OrderingTaskDto } from '../../../../model/task-model/OrderingTaskDto'
import { AssociationTaskDto } from '../../../../model/task-model/AssociationTaskDto'
import { GappingTaskDto } from '../../../../model/task-model/GappingTaskDto'
import { MultiAnswerTaskDto } from '../../../../model/task-model/MultiAnswerTaskDto'
import { MultiTestTaskDto } from '../../../../model/task-model/MultiTestTaskDto'
import { MultiInputTaskDto } from '../../../../model/task-model/multiInputTaskDto'
import { ReviewStepTaskDto } from '../../../../model/task-model/ReviewStepTaskDto'
import { getReviewEntryCallbackStudent } from './task-block/getReviewEntryCallbackStudent'
import {
  getReferenceSolutionCallback,
  getSessionStorageNameCallback,
  isCodeTaskExemplarySolutionAvailableCallback,
  isCodeTaskMentorCommentAvailableCallback,
  isCodeTaskSessionStorageAvailableCallback,
  isCodeTaskSolutionHistoryAvailableCallback,
} from './task-block/codeTaskCallbacks'
import { getCheckAvailabilityCallbackStudent } from './getCheckAvailabilityCallbackStudent'
import { getCheckAvailabilityCallbackFake } from './getCheckAvailabilityCallbackFake'
import { getPostSolveInterceptorFake } from './getPostSolveInterceptorFake'
import { getPostResetInterceptorFake } from './getPostResetInterceptorFake'
import { useOpenFakeAvailability } from './useOpenFakeAvailability'
import getMentorCheckCallback from './getMentorCheckCallback'
import getSolveTaskCallbackFake from './getSolveTaskCallbackFake'
import { RootState } from '../../../../store/rootReducer'
import { RoleEnum } from '../../../../utils/select-state/RoleEnum'
import { addAvailableCourseId } from '../../../../store/available-courses/availableCoursesSlice'

interface Props {
  history: History
  match: {
    params: {
      courseId: number
      modulePosition: number
      chapterPosition: number
      courseTaskPosition: number
    }
  }
}

const studentCourseTaskService = new StudentCourseTaskService()
const fakeOpenStudentCourseTaskService = new FakeOpenStudentCourseTaskService()
const studentCourseTaskInfoService = new StudentCourseTaskInfoService()
const userCourseTaskService = new UserCourseTaskService()

const CoursePage = ({
  history,
  match: {
    params: { courseId = 0, modulePosition = 0, chapterPosition = 0, courseTaskPosition = 0 },
  },
}: Props) => {
  const { principalId, principalRole, statusPage, studentIdParam } = useStatusPage()
  const { catchErrorAlert, reactAlert } = useReactAlert()
  const dispatch = useDispatch()
  const courseType = useSelector(selectCourseType)
  const studentCourseService = useMemo(() => new StudentCourseService(courseType), [])
  const fakeOpenStudentCourseService = useMemo(() => new FakeOpenStudentCourseService(courseType), [])
  const { t } = useTranslation()

  const [tasksHeading, setTasksHeading] = useState<TaskHeadingDto>()
  const [chapterNavigation, setChapterNavigation] = useState<ChapterNavigation>()
  const [isLoadingNavigation, setIsLoadingNavigation] = useState<boolean>(true)
  const [isLoadingHeading, setIsLoadingHeading] = useState<boolean>(true)
  const [task, setTask] = useState<TasksTypesUnion>()

  const [studentCourseTaskInfoId, setStudentCourseTaskInfoId] = useState<number>(0)
  const [nextButtonText, setNextButtonText] = useState<string>(t('NextStep'))
  const [nextTaskLastInTheCourse, setNextTaskLastInTheCourse] = useState<boolean>(false)
  const [isTaskAvailableForPrincipal, setIsTaskAvailableForPrincipal] = useState<boolean>(false)

  const { availableCourseIds } = useSelector((state: RootState) => state.availableCourses)

  const solutionsLink = `/user/courses/${courseId}/${modulePosition}/${chapterPosition}/${courseTaskPosition}/solutions${
    statusPage === StudentPageMode.FAKE ? `?studentId=${studentIdParam}` : ''
  }`

  const loadTasksHeading = () => {
    setIsLoadingHeading(true)
    if (statusPage === StudentPageMode.NORMAL) {
      studentCourseService.getTasksHeading(courseId, modulePosition, chapterPosition).then(heading => {
        setTasksHeading(heading)
        setIsLoadingHeading(false)
      })
    } else if (statusPage === StudentPageMode.FAKE) {
      fakeOpenStudentCourseService
        .getTasksHeading(studentIdParam, courseId, modulePosition, chapterPosition)
        .then(heading => {
          setTasksHeading(heading)
          setIsLoadingHeading(false)
        })
    }
  }

  const loadTask = async (interceptor = (val: any) => val) => {
    if (!tasksHeading) return
    const { taskHeadingDtos } = tasksHeading
    if (!taskHeadingDtos || taskHeadingDtos.length < courseTaskPosition) return
    const { courseTaskId, taskType } = taskHeadingDtos[courseTaskPosition - 1]!
    if (courseTaskId <= 0 || statusPage === StudentPageMode.NOT_READY) {
      return null
    }
    let load
    if (statusPage === StudentPageMode.NORMAL) {
      load = getLoadTaskCallback(courseTaskId, taskType, studentIdParam, studentCourseTaskService)
    } else {
      load = getLoadTaskCallback(courseTaskId, taskType, studentIdParam, fakeOpenStudentCourseTaskService)
    }
    return load()
      .then(value => {
        setTask(interceptor(value))
        return value
      })
      .catch(err => {
        catchErrorAlert(err)

        if (err.code === 423) {
          reactAlert.error(err.text)
          history.replace(`/user/courses/${courseId}?studentId=${studentIdParam}`)
        }
      })
  }

  useOpenFakeAvailability(principalRole, tasksHeading, courseTaskPosition, setIsTaskAvailableForPrincipal)

  // only for fake page
  useEffect(() => {
    if (studentIdParam !== -1 && principalRole === RoleEnum.PAY_STUDENT && !availableCourseIds.includes(courseId)) {
      fakeOpenStudentCourseService.loadEnrollingState(courseId, principalId!).then(isEnrolled => {
        if (isEnrolled) {
          dispatch(addAvailableCourseId({ courseId }))
        } else {
          history.push('/user/courses?param=forbidden')
        }
      })
    }
  }, [courseId])

  useEffect(() => {
    if (isLoadingHeading || !tasksHeading) return
  }, [tasksHeading])
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (statusPage === StudentPageMode.NOT_READY) return
    if (courseId * modulePosition * chapterPosition * courseTaskPosition !== 0) {
      loadTasksHeading()
    }
  }, [courseId, modulePosition, chapterPosition, courseTaskPosition, studentIdParam, statusPage])

  useEffect(() => {
    if (!tasksHeading) return
    const { chapterId } = tasksHeading
    setIsLoadingNavigation(true)
    UserNavigationService.getChapterNavigationInfoById(chapterId)
      .then(navigation => {
        setChapterNavigation(navigation)
        setIsLoadingNavigation(false)
      })
      .catch(err => {
        catchErrorAlert(err)
      })
  }, [tasksHeading])

  useEffect(() => {
    if (!tasksHeading) return
    const { taskHeadingDtos } = tasksHeading
    if (!taskHeadingDtos || taskHeadingDtos.length < courseTaskPosition) return
    const { courseTaskId } = taskHeadingDtos[courseTaskPosition - 1]!
    if (courseTaskId !== 0) {
      userCourseTaskService.isNextTaskLastInTheCourse(courseTaskId).then(setNextTaskLastInTheCourse)
      if (statusPage === StudentPageMode.NORMAL) {
        studentCourseTaskService.getStudentCourseTaskIdByCourseTaskId(courseTaskId).then(setStudentCourseTaskInfoId)
      } else if (statusPage === StudentPageMode.FAKE) {
        fakeOpenStudentCourseTaskService
          .getStudentCourseTaskIdByCourseTaskId(courseTaskId, studentIdParam)
          .then(studentCourseTaskInfoId => {
            setStudentCourseTaskInfoId(studentCourseTaskInfoId as number)
          })
      }
    }
  }, [tasksHeading])

  useEffect(() => {
    if (!isLoadingHeading && !isLoadingNavigation) {
      setTask(undefined)
      loadTask()
    }
  }, [courseId, modulePosition, chapterPosition, courseTaskPosition, isLoadingHeading, isLoadingNavigation])

  const hasNextTask = () => {
    if (!tasksHeading) return false
    return tasksHeading!.taskHeadingDtos?.length > courseTaskPosition
  }

  const hasNextChapter = () => {
    if (!chapterNavigation) return false
    return chapterNavigation.currentModuleChapterCount > chapterPosition && !chapterNavigation.nextChapterBlocked
  }

  const hasNextModule = () => {
    if (!chapterNavigation) return false
    return chapterNavigation.moduleCount > modulePosition
  }

  const linkToMentor = () => {
    navigator.clipboard
      .writeText(
        `${window.location.protocol}//${window.location.hostname}${history.location.pathname}?studentId=${principalId}`
      )
      .then(() => {
        store.addNotification({
          ...studentSuccessNotyTemplate,
          message: `${t('LinkCopiedToClipboard')}`,
        })
      })
      .catch(() => {
        store.addNotification({
          ...studentErrorNotyTemplate,
          message: `${t('FailedToCopyLink')}`,
        })
      })
  }

  const nextStepButtonUrl = () => {
    const url = generateCourseURL(
      getCoursePageNextStepUrlStudent(
        hasNextTask,
        hasNextChapter,
        hasNextModule,
        courseId,
        modulePosition,
        chapterPosition,
        courseTaskPosition
      )(),
      courseType
    )
    history.push(url + (statusPage === StudentPageMode.FAKE ? `?studentId=${studentIdParam}` : ''))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleCancelTaskCheck = (courseTaskId: number) => async () => {
    await studentCourseTaskInfoService.cancelTaskCheck(courseTaskId)
    setTask({
      ...task,
      answer: '',
      answerId: -1,
      solved: false,
      lastActionIsRight: false,
      lastActionIsWrong: false,
    } as MentorCheckTaskDto)
  }

  let taskRender

  if (nextTaskLastInTheCourse) {
    if (nextButtonText !== `${t('ToCourseList')}`) {
      setNextButtonText(`${t('ToCourseList')}`)
    }
  } else if (nextButtonText !== `${t('NextStep')}`) {
    setNextButtonText(`${t('NextStep')}`)
  }

  if (!isLoadingNavigation && !isLoadingHeading && task) {
    let currentTaskIcon
    if (tasksHeading!.taskHeadingDtos.length >= courseTaskPosition) {
      currentTaskIcon = tasksHeading!.taskHeadingDtos[courseTaskPosition - 1]
    }

    let onSolveTask
    let onResetTask
    let postSolveInterceptor
    let postResetInterceptor

    const { type } = task
    switch (statusPage) {
      case StudentPageMode.NORMAL:
        // @ts-ignore
        onSolveTask = getSolveTaskCallbackStudent(type, currentTaskIcon?.courseTaskId ?? -1)
        onResetTask = () => studentCourseTaskInfoService.resetTask(studentCourseTaskInfoId)
        // @ts-ignore
        postSolveInterceptor = getPostSolveInterceptorStudent(type)
        postResetInterceptor = getPostResetInterceptorStudent(type)
        break
      case StudentPageMode.NOT_READY:
      case StudentPageMode.FAKE:
        onSolveTask = getSolveTaskCallbackFake(type, currentTaskIcon?.courseTaskId ?? -1)
        onResetTask = () => Promise.resolve()
        // @ts-ignore
        postSolveInterceptor = getPostSolveInterceptorFake(type)
        // @ts-ignore
        postResetInterceptor = getPostResetInterceptorFake(type)
        break
    }

    const mentorTaskSolved = currentTaskIcon?.solved ?? false
    const mentorTaskChecked = currentTaskIcon?.checked ?? false
    const mentorTaskAvailable = currentTaskIcon?.available ?? false

    switch (type) {
      case TaskType.Lecture:
        taskRender = (
          <LectureTask
            lectureTask={task as LectureTaskDto}
            onSolveTask={onSolveTask}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case TaskType.Theory:
        taskRender = (
          <TheoryTask
            theoryTask={task as TheoryTaskDto}
            loadTask={loadTask}
            onSolveTask={onSolveTask}
            postSolveInterceptor={postSolveInterceptor}
            onResetTask={onResetTask}
            postResetInterceptor={postResetInterceptor}
          />
        )
        break
      case TaskType.Ordering:
        taskRender = (
          <OrderingTask
            orderingTask={task as OrderingTaskDto}
            loadTask={loadTask}
            onSolveTask={onSolveTask}
            onResetTask={onResetTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case TaskType.Association:
        taskRender = (
          <AssociationTask
            associationTask={task as AssociationTaskDto}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case TaskType.Word:
        taskRender = (
          <WordTask
            wordTask={task}
            onResetTask={onResetTask}
            onGetTask={loadTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case TaskType.Code:
        const isCodeTaskExemplarySolutionAvailable = isCodeTaskExemplarySolutionAvailableCallback(principalRole)
        const isCodeTaskSolutionHistoryAvailable = isCodeTaskSolutionHistoryAvailableCallback(
          principalRole,
          statusPage,
          isTaskAvailableForPrincipal
        )
        const isCodeTaskMentorCommentAvailable = isCodeTaskMentorCommentAvailableCallback(
          statusPage,
          isTaskAvailableForPrincipal
        )
        const isCodeTaskSessionStorageAvailable = isCodeTaskSessionStorageAvailableCallback(statusPage)
        const getSessionStorageName = getSessionStorageNameCallback(
          principalId!,
          type,
          currentTaskIcon?.courseTaskId ?? -1
        )
        const getCodeTaskExemplarySolution = getReferenceSolutionCallback(currentTaskIcon?.courseTaskId ?? -1)

        taskRender = (
          <CodeTask
            codeTask={task}
            loadTask={loadTask}
            isExemplarySolutionAvailableCallback={isCodeTaskExemplarySolutionAvailable}
            isSolutionHistoryAvailableCallback={isCodeTaskSolutionHistoryAvailable}
            isMentorCommentAvailableCallback={isCodeTaskMentorCommentAvailable}
            isSessionStorageAvailableCallback={isCodeTaskSessionStorageAvailable}
            getExemplarySolution={getCodeTaskExemplarySolution}
            sessionStorageName={getSessionStorageName()}
            onSolveTask={onSolveTask}
            onResetTask={onResetTask}
            solutionsLink={generateCourseURL(solutionsLink, courseType)}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
            currentRole={principalRole}
          />
        )
        break
      case TaskType.MultiInput:
        taskRender = (
          <MultiInputTask
            multiInputTask={task as MultiInputTaskDto}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case TaskType.MultiTest:
        taskRender = (
          <MultiTestTask
            multiTestTask={task as MultiTestTaskDto}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case TaskType.MultiAnswer:
        taskRender = (
          <MultiAnswerTask
            multiAnswerTask={task as MultiAnswerTaskDto}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break
      case TaskType.Gapping:
        taskRender = (
          <GappingTask
            gappingTask={task as GappingTaskDto}
            loadTask={loadTask}
            onResetTask={onResetTask}
            onSolveTask={onSolveTask}
            postResetInterceptor={postResetInterceptor}
            postSolveInterceptor={postSolveInterceptor}
          />
        )
        break

      case TaskType.MentorCheckTask:
        const onCheckTask = getMentorCheckCallback(type, currentTaskIcon?.courseTaskId ?? -1)
        if (statusPage === StudentPageMode.FAKE) {
          taskRender = (
            <CheckTask
              handleUpdate={() => loadTasksHeading()}
              loadTask={loadTask}
              mentorCheckTask={task}
              onCheckTask={onCheckTask}
              isAnswerAvailable={isTaskAvailableForPrincipal}
              solutionsLink={solutionsLink}
              isPermittedToCheckCallback={getCheckAvailabilityCallbackFake(principalRole)}
              role={principalRole}
            />
          )
        } else {
          taskRender = (
            <MentorCheckTask
              solved={mentorTaskSolved}
              checked={mentorTaskChecked}
              loadTask={loadTask}
              mentorCheckTask={task as MentorCheckTaskDto}
              onSolveTask={onSolveTask}
              onCancelTaskCheck={handleCancelTaskCheck(currentTaskIcon?.courseTaskId ?? -1)}
              postSolveInterceptor={postSolveInterceptor}
              solutionsLink={generateCourseURL(solutionsLink, courseType)}
              isAnswerAvailable
              isPermittedToCheckCallback={getCheckAvailabilityCallbackStudent()}
            />
          )
        }
        break
      case TaskType.ReviewStep:
        taskRender = (
          <ReviewStepTask
            reviewStepTask={task as ReviewStepTaskDto}
            loadReviewDto={getReviewEntryCallbackStudent(
              tasksHeading!.moduleId,
              (task as ReviewStepTaskDto).reviewType
            )}
            isReviewAssignable={statusPage === StudentPageMode.NORMAL}
            moduleId={tasksHeading!.moduleId}
            available={mentorTaskAvailable}
            completed={mentorTaskSolved}
          />
        )
        break
      default:
        taskRender = (
          <div className="task-loader">
            <Spinner />
          </div>
        )
    }
  } else {
    taskRender = (
      <div className="task-loader">
        <Spinner />
      </div>
    )
  }

  const isNextStepAvailable = hasNextTask() || hasNextChapter()

  return statusPage !== StudentPageMode.NOT_READY ? (
    <>
      <StudentBreadCrumbs
        isLoading={isLoadingNavigation || isLoadingHeading}
        courseId={courseId}
        modulePosition={modulePosition}
        chapterPosition={chapterPosition}
        courseTaskPosition={courseTaskPosition}
        tasksHeading={tasksHeading!}
        chapterNavigation={chapterNavigation!}
        statusPage={statusPage}
        studentId={studentIdParam}
      />
      <div className="step-content-wrap">
        <div className="container">
          <div className="step-content lesson">{taskRender}</div>
          {isNextStepAvailable && (
            <button type="button" className="next-step-btn" onClick={nextStepButtonUrl}>
              <span>{nextButtonText}</span>
              <i className="mdi mdi-chevron-right" />
            </button>
          )}
          {statusPage === StudentPageMode.NORMAL && (
            <p>
              <button type="button" className="btn btn-light" style={{ verticalAlign: 'top' }} onClick={linkToMentor}>
                {t('CopyLinkForMentor')}
              </button>
            </p>
          )}
        </div>
      </div>
      {task && <CommentsBlock statusPage={statusPage} principalRole={principalRole} taskId={task!.taskId} />}
    </>
  ) : (
    <></>
  )
}

export default CoursePage
