import StudentReviewService from '../../../../../services/student-services/student-review-service'
import { ReviewType } from '../../../../../model/review/review-type'

const reviewService = new StudentReviewService()

export const getReviewEntryCallbackStudent = (moduleId: number, reviewType: ReviewType) => {
  return () => {
    return reviewService.getReviewEntryDto(moduleId, reviewType)
  }
}
