import React, { PropsWithChildren } from 'react'
import classes from './index.module.scss'

const List = <T,>({ items, resolveItemId, resolveItemText }: PropsWithChildren<Props<T>>) => {
  return (
    <div className={classes.list}>
      {items?.map((item, index) => (
        <span key={resolveItemId(item)}>
          <i className="mdi mdi-chevron-right" />
          {index + 1}. {resolveItemText(item)}
        </span>
      ))}
    </div>
  )
}

interface Props<T> {
  items: T[] | undefined
  resolveItemId: (item: T) => number
  resolveItemText: (item: T) => string
}

export default List
