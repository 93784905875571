import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tippy from '@tippyjs/react'
import classNames from 'classnames/bind'
import { useReactAlert } from 'src/hooks/useReactAlert'
import { StudentReportGetDto } from '../../../../../model/student-model'
import EditButtonsPopup from './edit-button-popup'
import ReportModal from '../report-modal'

import styles from './report-card.module.scss'
import StudentReportService from '../../../../../services/student-services/student-report-service'

const cx = classNames.bind(styles)

type ReportCardProps = {
  report: StudentReportGetDto
  currentStudentId?: number
  getStudentReports: () => void
  setSelectedItem: (dto: StudentReportGetDto) => void
}
const ReportCard: FC<ReportCardProps> = ({ report, currentStudentId, getStudentReports, setSelectedItem }) => {
  const { catchErrorAlert } = useReactAlert()
  const { text, countHours, firstName, lastName, reportDate, id, studentId } = report
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)

  const show = () => {
    setVisible(true)
  }
  const hide = () => {
    setVisible(false)
  }
  const deleteReport = () => {
    const reportService = new StudentReportService()
    reportService
      .deleteStudentReport(id)
      .then(() => getStudentReports())
      .catch(err => catchErrorAlert(err))
  }
  return (
    <div className={cx('report-card')}>
      <ReportModal
        show={showReportModal}
        onClose={() => setShowReportModal(false)}
        text={text}
        countHours={countHours}
        reportDate={reportDate}
        reportId={id}
        getStudentReports={getStudentReports}
      />
      {currentStudentId === studentId && (
        <div>
          <Tippy
            content={
              <EditButtonsPopup
                setShowReportModal={() => {
                  hide()
                  setShowReportModal(true)
                }}
                deleteReport={() => {
                  hide()
                  deleteReport()
                }}
              />
            }
            visible={visible}
            onClickOutside={hide}
            interactive
            placement="left"
            interactiveBorder={30}
            offset={[0, 0]}
          >
            <button
              aria-expanded="false"
              type="button"
              className={cx('edit-menu-btn', 'btn')}
              onClick={visible ? hide : show}
            >
              <i className="mdi mdi-dots-vertical" />
            </button>
          </Tippy>
        </div>
      )}
      <div className={cx('report-data')}>
        <div className={cx('student-name')}>{`${firstName} ${lastName}`}</div>
        <div className={cx('spend-time')}>
          <span className={cx('spend-bold')}>{t('TimeTaken')}:</span>
          <span className={cx('spend-text', 'spend-light-text')}>
            {countHours}
            {t('HourShort')}
          </span>
        </div>
        <p
          className={cx('task-progress-text', {
            'task-progress-text--gradient': text.length > 100,
          })}
        >
          <span className={cx('spend-text')}>{t('WhatDone')}:</span>
          <span className={cx('spend-light-text')}>{text}</span>
        </p>
        {text.length > 100 && (
          <button type="button" onClick={() => setSelectedItem(report)} className={cx('toggle-expand-text')}>
            {t('Show')}
          </button>
        )}
      </div>
    </div>
  )
}

export default ReportCard
