import React, { useEffect, useRef } from 'react'
import { parse } from 'date-fns'
import cn from 'classnames'
import {
  prepareCommentText,
  replaceColonsEmojiToNative,
  replaceNativeEmojiToColons,
} from '../../../../../../../utils/ComentsUtils'
import EditCommentForm from '../../edit-comment-form/edit-comment-form'
import { RoleEnum } from '../../../../../../../utils/select-state/RoleEnum'
import StudentPageMode from '../../../../../../../model/StudentPageMode'

const DependComment = function DependComment({
  comment: { id, user, text, date, commentsLikes, likes, preModerate },
  avatars,
  authUser,
  setEditComment,
  editComment,
  updateAnswerComment,
  mainCommentId,
  mainIndex,
  deleteComment,
  statusPage,
  principalRole,
  changeAnswerCommentLike,
  idx,
  handleSubmitComment,
  handleDeleteComment,
  handleCollapseClick,
}) {
  const clicked = commentsLikes.filter(({ id: likedUserId }) => authUser.id === likedUserId).length !== 0
  const [isFullComment, setIsFullComment] = React.useState(null)
  const commentRef = useRef(null)

  useEffect(() => {
    if (commentRef) {
      commentRef.current.scrollHeight < 20 * 5 ? setIsFullComment(null) : setIsFullComment(false)
    }
  }, [commentRef])

  const likesClass = `mdi icon icon-like ${clicked ? 'mdi-heart clicked' : 'mdi-heart-outline '}`

  const d = parse(date, 'dd.MM.yyyy HH:mm', new Date())
  d.setMinutes(d.getMinutes() + 5)

  const studentActionPanel = d > new Date() && (
    <div className="comments-student-action-panel">
      <span
        className="icon-btn"
        onClick={() => {
          setEditComment({
            id,
            text: replaceColonsEmojiToNative(text),
          })
        }}
      >
        <span className="mdi mdi-pencil icon icon-edit" />
      </span>
      <span
        className="icon-btn"
        onClick={() => {
          deleteComment(id)
        }}
      >
        <span className="mdi mdi-delete icon icon-edit" />
      </span>
    </div>
  )

  const studentEditCommentActionPanel = (
    <div className="comments-student-action-panel">
      <span
        className="icon-btn"
        onClick={() => updateAnswerComment(replaceNativeEmojiToColons(editComment.text), editComment.id, mainCommentId)}
      >
        <span className="mdi glyphicon-comments mdi-check icon icon-edit" style={{ color: '#00ff00' }} />
      </span>
      <span
        className="icon-btn"
        onClick={() => {
          setEditComment({ id: 0, text: `` })
        }}
      >
        <span className="mdi glyphicon-comments mdi-delete icon icon-edit" style={{ color: '#c83939' }} />
      </span>
    </div>
  )

  return (
    <div className="comment-block answer-block" key={`depend_comment_${id}`}>
      <div className="top-row">
        <div className="user-wrap">
          <div className="user-pic">
            <img src={avatars[user.id] || '/images/avatar.png'} alt="user avatar" />
          </div>
          <div className="user-detail">
            <div className="user-name">
              {user.firstName} {user.lastName}
              {authUser.id === user.id && (editComment.id === id ? studentEditCommentActionPanel : studentActionPanel)}
            </div>
            <div className="review-date">{date}</div>
          </div>
          {statusPage === StudentPageMode.FAKE && principalRole !== RoleEnum.PAY_STUDENT && !preModerate ? (
            <div className="premoder-buttons-wrapper">
              <button
                type="button"
                className="premoder-button__submit"
                onClick={() => handleSubmitComment(id, mainIndex, mainCommentId)}
              />
              <button
                type="button"
                className="premoder-button__delete"
                onClick={() => handleDeleteComment(id, mainCommentId, mainIndex)}
              />
            </div>
          ) : null}
        </div>
      </div>
      {editComment.id === id ? (
        <EditCommentForm comment={editComment} setEditComment={setEditComment} />
      ) : (
        <>
          <div
            className={cn('comment-text', { 'comment-text--collapse': !isFullComment })}
            id={`comment-text${id}`}
            ref={commentRef}
            dangerouslySetInnerHTML={{
              __html: prepareCommentText(text.replace(/[<>]/g, match => (match === '<' ? '&lt;' : '&gt;'))),
            }}
          />
          {isFullComment !== null && (
            <button
              type="button"
              onClick={() => handleCollapseClick(setIsFullComment)}
              className="commentCollapse commentCollapse--show"
            >
              {isFullComment ? 'Скрыть' : 'Показать полностью'}
            </button>
          )}
        </>
      )}
      <div />
      <span className="comment-like-block" onClick={() => changeAnswerCommentLike(idx, mainCommentId)}>
        <span className={likesClass} />
        {` ${likes}`}
      </span>
    </div>
  )
}

export default DependComment
