import React, { FC } from 'react'
import ReactModal from 'react-modal'
import { Formik } from 'formik'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useAlert } from 'react-alert'
import { format } from 'date-fns'
import StudentReportService from '../../../../../services/student-services/student-report-service'
import { dateForInput, dateForState } from '../utils/utils'

import styles from './report-modal.module.scss'

const cx = classNames.bind(styles)
const customStyles = {
  content: {
    maxHeight: '100%',
    zIndex: 9999,
    width: '35%',
    top: '50%',
    left: '50%',
    right: '30%',
    bottom: 'initial',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9998,
  },
}
type ReportModalProps = {
  getStudentReports: () => void
  show: boolean
  onClose: () => void
  text?: string
  countHours?: number
  reportDate?: string
  reportId?: number
  note?: string
  debtReport?: boolean // является ли отчет задолженным
}
const ReportModal: FC<ReportModalProps> = ({
  getStudentReports,
  show,
  onClose,
  text,
  countHours,
  reportId,
  reportDate,
  note,
  debtReport,
}) => {
  ReactModal.setAppElement('#root')
  const { t } = useTranslation()
  const alert = useAlert()
  const reportService = new StudentReportService()

  const yesterdayDate = new Date()
  yesterdayDate.setDate(yesterdayDate.getDate() - 1)

  return (
    <ReactModal isOpen={show} style={customStyles} onRequestClose={debtReport ? undefined : onClose}>
      <div className={cx('modal-wrap')}>
        <div className={cx('modal-header')}>
          {reportId || reportId === 0 ? `${t('EditReport')}` : `${t('AddReport')}`}
          {!debtReport && (
            <div className={cx('close-button')}>
              <i className="mdi mdi-close" onClick={onClose} />
            </div>
          )}
        </div>
        <div className={cx('modal-form')}>
          <Formik
            initialValues={{
              reportInputDate: reportDate ? dateForInput(reportDate) : dateForInput(),
              spendTime: countHours ?? '',
              reportText: text ?? '',
            }}
            validationSchema={Yup.object().shape({
              spendTime: Yup.number()
                .typeError(t('ValidateReportEnterNumber'))
                .required(t('ValidateReportTimeTaken'))
                .max(24, t('ValidateReportLessThenDay')),
              reportText: Yup.string()
                .required(t('ValidateReportWhatDone'))
                .max(200, t('ValidateReportTooLongReport')),
            })}
            onSubmit={({ reportInputDate, spendTime, reportText }) => {
              if (reportId || reportId === 0) {
                reportService
                  .updateStudentReport(reportId, reportText, +spendTime, dateForState(reportInputDate))
                  .then(() => getStudentReports())
                  .catch(error => alert.error(error.text))
              } else {
                reportService
                  .addStudentReport(reportText, +spendTime, dateForState(reportInputDate))
                  .then(() => {
                    const storagedUser = localStorage.getItem('user')

                    // Обноляем requireCreateReport поле в localStorage в случае если пользователь отправил отчет за вчерашний день
                    if (storagedUser && format(yesterdayDate, 'dd.MM.yyyy') === dateForState(reportInputDate)) {
                      const user = JSON.parse(storagedUser)
                      const requireCreateReport = {
                        requireCreateReport: false,
                      }
                      const updatedUser = { ...user, ...requireCreateReport }
                      localStorage.setItem('user', JSON.stringify(updatedUser))
                    }

                    getStudentReports()
                  })
                  .catch(error => alert.error(error.text))
              }
              onClose()
            }}
          >
            {({ values, errors, handleBlur, handleChange, touched, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                {note && <div className={cx('note')}>{note}</div>}
                <input
                  className={cx('report-date', 'form-item')}
                  type="date"
                  name="reportInputDate"
                  placeholder="От"
                  value={values.reportInputDate}
                  onChange={handleChange}
                  min="2018-01-01"
                  max={dateForInput()}
                  disabled={debtReport}
                />
                <input
                  className={cx('spend-time', 'form-item', { 'error-border': errors.spendTime && touched.spendTime })}
                  type="number"
                  name="spendTime"
                  placeholder={t('ReportTimeTakenPlaceholder')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.spendTime}
                  min={0}
                />
                {errors.spendTime && touched.spendTime && <div className={cx('error')}>{errors.spendTime}</div>}

                <textarea
                  className={cx('report-text', 'form-item', {
                    'error-border': errors.reportText && touched.reportText,
                  })}
                  name="reportText"
                  placeholder={t('ReportWhatDonePlaceholder')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.reportText}
                  cols={30}
                  rows={4}
                />
                {errors.reportText && touched.reportText && <div className={cx('error')}>{errors.reportText}</div>}

                <div className={cx('button-wrap')}>
                  <button className={cx('report-done')} type="submit" disabled={isSubmitting}>
                    {reportId || reportId === 0 ? t('Edit') : t('Add')}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </ReactModal>
  )
}
export default ReportModal
