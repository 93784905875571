import { RoleEnum } from '../../../../../utils/select-state/RoleEnum'
import FakeProtectedStudentCourseTaskService from '../../../../../services/fake-page-services/fake-protected-student-course-task-service'
import StudentPageMode from '../../../../../model/StudentPageMode'

export const isCodeTaskExemplarySolutionAvailableCallback = (principalRole: RoleEnum | undefined): (() => boolean) => {
  return () => {
    return principalRole === 'MENTOR'
  }
}

export const isCodeTaskSolutionHistoryAvailableCallback = (
  principalRole: RoleEnum | undefined,
  statusPage: StudentPageMode,
  isTaskAvailableForPrincipal?: boolean
): (() => boolean) => {
  return () => {
    return (
      isTaskAvailableForPrincipal || statusPage === StudentPageMode.NORMAL || principalRole !== RoleEnum.PAY_STUDENT
    )
  }
}

export const isCodeTaskMentorCommentAvailableCallback = (
  statusPage: StudentPageMode,
  isTaskAvailableForPrincipal?: boolean
): (() => boolean) => {
  return () => {
    return isTaskAvailableForPrincipal || statusPage !== StudentPageMode.FAKE
  }
}

export const isCodeTaskSessionStorageAvailableCallback = (statusPage: StudentPageMode): (() => boolean) => {
  return () => {
    return statusPage !== StudentPageMode.FAKE
  }
}

export const getSessionStorageNameCallback = (
  userId: number,
  taskType: string = '',
  courseTaskId: number
): (() => string) => {
  return () => {
    return `${userId}${taskType}${courseTaskId}`
  }
}

export const getReferenceSolutionCallback = (courseTaskId: number) => {
  const service = new FakeProtectedStudentCourseTaskService()
  return () => {
    return service.getReferenceSolution(courseTaskId)
  }
}
