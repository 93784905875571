import React from 'react'

const ProfileHeader = props => {
  const { message } = props
  return (
    <div className="middle-header all-courses">
      <div className="container">
        <div className="page-title-wrap">
          <div className="page-title">{message}</div>
        </div>
      </div>
    </div>
  )
}

export default ProfileHeader
