import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DraftEditor from '../../../components/draft-editor/draft-editor'
import { replaceNativeEmojiToColons } from '../../../../../../utils/ComentsUtils'

const CommentForm = function CommentForm({ addNewComment, addAnswerComment, isAnswerComment }) {
  const { t } = useTranslation()
  const [areaValue, setAreaValue] = useState(``)

  const handleChange = newVal => {
    setAreaValue(newVal)
  }

  const onSubmitComment = e => {
    e.preventDefault()
    if (areaValue.length !== 0) {
      const text = replaceNativeEmojiToColons(areaValue)
      addNewComment(text)
      setAreaValue(``)
    }
  }

  const onSubmitAnswerComment = e => {
    e.preventDefault()
    if (areaValue.length !== 0) {
      const text = replaceNativeEmojiToColons(areaValue)
      addAnswerComment(text)
      setAreaValue(``)
    }
  }
  return (
    <div className="comment-block-wrap">
      <form onSubmit={isAnswerComment ? onSubmitAnswerComment : onSubmitComment}>
        <DraftEditor value={areaValue} onChange={handleChange} />
        <button type="submit" className="place-comment-btn">
          {t('SubmitComment')}
        </button>
      </form>
    </div>
  )
}

export default CommentForm
