import React, { useEffect } from 'react'
import Plyr from 'plyr'
import { useDispatch } from 'react-redux'
import { useReactAlert } from 'src/hooks/useReactAlert'
import TaskDescription from './task-description'

const LectureTask = function LectureTask({ lectureTask, onSolveTask, postSolveInterceptor }) {
  const { catchErrorAlert } = useReactAlert()
  const dispatch = useDispatch()
  let player

  useEffect(() => {
    if (lectureTask !== undefined && lectureTask !== null) {
      if (player) {
        player.destroy()
      }
      const { solved, taskPoints: points } = lectureTask
      onSolveTask(solved)
        .then(() => {
          postSolveInterceptor(solved, points, dispatch)
          player = new Plyr('#player', {
            keyboard: { focused: true, global: false },
          })
        })
        .catch(error => catchErrorAlert(error))
    }
  }, [lectureTask])

  const { description } = lectureTask
  return (
    <div className="task-content">
      <div className="video-block" />
      <div className="with-video-wrap-text">
        <div className="step-content-head" />
        <TaskDescription description={description} />
      </div>
    </div>
  )
}

export default LectureTask
